import { v4 as uuidv4 } from 'uuid';

export type Phase = number;

export type ProjectSynchronimeterData = {
    legend: Array<LegendItem>,
    values: Record<Phase, Record<string, number>>
}

export type LegendItem = {
    id: string,
    name: string,
    color: string,
}

export const newLegendItem = (): LegendItem => {
    return {
        id: uuidv4(),
        name: "Neue Kategorie",
        color: "#43728b",
    }
}

export const emptyProjectSynchronimeterData: ProjectSynchronimeterData = {
    legend: [],
    values: {}
};
