import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import circalindDeciderImage from "../../resources/circa/compass/Persönlichkeit.png";
import { getCurrentUserId } from "../../utils/Auth";
import { useEffect, useState } from "react";
import { GroupUser } from "../../circalind/dataTypes/generated";
import { CircalindApi } from "../../circalind/circalindApi";

import "./Start.scss";

export const DeciderStart = () => {
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState<GroupUser | null>(null);
    const currentUserId = getCurrentUserId("token-circalind");

    useEffect(() => {
        const loadUser = async () => {
            if (currentUserId) {
                setCurrentUser(await CircalindApi.getGroupUserWithoutImage(currentUserId));
            }
        };

        loadUser();
    }, [currentUserId]);

    return <div className="full-page">
        <h1><strong>Willkommen {currentUser && currentUser.firstName} {currentUser && currentUser.lastName}</strong></h1>
        <h2><strong>Entscheiderbereich</strong></h2>
        <img src={circalindDeciderImage} alt=""></img>
        <div className={"navigation"}>
            <button disabled><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button onClick={() => navigate("../info")}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>;
}