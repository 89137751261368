import './App.scss';
import OlertHandler from './olert/Olert';
import { MainCircaLind } from './MainCircaLind';
import { Fragment } from 'react'
import { BrowserRouter } from 'react-router-dom';

function App() {
  const loc = window.location.pathname;

  const renderMain = () => {
    if (loc.match(".*/circalind.*") || loc.match("/")) {
      return <MainCircaLind />
    }
  }

  return (<Fragment>
    <BrowserRouter>
      {renderMain()}
    </BrowserRouter>
    <OlertHandler />
  </Fragment>

  );
}

export default App;
