import { ReactElement, useEffect, useState } from 'react';
import Select from 'react-select';
import { HybridBase64Upload } from '../basicComponents/hybridBase64Upload/HybridBase64Upload';
import './SubAreaEditor.scss';

export type Props = {
    initialData?: SubAreaData,
    onSave: (data: SubAreaData) => void;
    onCancel: () => void;
}
export const SubAreaEditor = (props: Props): ReactElement => {
    const [name, setName] = useState("");
    const [color, setColor] = useState("");
    const [imageSource, setImageSource] = useState<ImageSource>('none');
    const [imageBase64, setImageBase64] = useState<string>("");
    const [imageURL, setImageURL] = useState<string>("");

    const initialData = props.initialData;
    useEffect(() => {
        if (initialData) {
            setName(initialData.name);
            setColor(initialData.color);
            if (initialData.image.startsWith("data:")) {
                setImageSource('base64');
                setImageBase64(initialData.image);
            } else if (initialData.image.startsWith("http")) {
                setImageSource('url');
                setImageURL(initialData.image);
            } else {
                setImageSource('none');
            }
        }
    }, [initialData]);

    const uploadAreaClassExtra = imageSource === 'base64' ? 'visible' : 'invisible';
    const uploadURLClassExtra = imageSource === 'url' ? 'visible' : 'invisible';

    const onSave = () => {
        props.onSave({
            name,
            color,
            image: imageSource === 'base64' ? imageBase64 : imageSource === 'url' ? imageURL : ""
        });
    }

    return <div className='sub-area-editor'>
        <label>Name:</label>
        <input type="text" value={name} onChange={e => setName(e.target.value)} />
        <label>Farbe:</label>
        <input type="color" value={color} onChange={e => setColor(e.target.value)} />
        <label>Bild:</label>

        <div className="image-upload">
            <Select
                className="image-source-select"
                value={imageSourceOptions.find(iso => iso.value === imageSource)}
                options={imageSourceOptions}
                onChange={option => { setImageSource(option?.value || "none") }}
            />
            <div className={"image-upload-area " + uploadAreaClassExtra}>
                <HybridBase64Upload onUpload={b => { setImageBase64(b); }} />
            </div>
            <input className={"image-upload-url " + uploadURLClassExtra} type="text" onChange={ev => setImageURL(ev.target.value)} />
            <div className={"image-preview " + uploadAreaClassExtra}>
                {renderImage(imageBase64)}
            </div>
            <div className={"image-preview " + uploadURLClassExtra}>
                {renderImage(imageURL)}
            </div>
        </div>

        <div className="buttons">
            <button onClick={props.onCancel}>Abbrechen</button>
            <button onClick={onSave}>Speichern</button>
        </div>

    </div>;
}

type ImageSource = "url" | "base64" | "none";
type ImageSourceOption = { value: ImageSource, label: string };

const imageSourceOptions: Array<ImageSourceOption> = [
    { value: "none", label: "Kein Bild" },
    { value: "base64", label: "Hochladen" },
    { value: "url", label: "Verlinken" },
];

const renderImage = (src: string): ReactElement =>
    <img alt="preview" src={src} style={src.trim() === "" ? ({ display: "none" }) : ({})} />

export type SubAreaData = {
    name: string,
    image: string,
    color: string
};
