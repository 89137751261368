import { Card } from "../competence/Card";

import phasesImage from "../../../resources/circa/projects_welcome.svg";
import circalindPersonalImage from "../../../resources/circa/personal_project.svg";
import strategyImage_back from "../../../resources/circa/strategy_project.svg"
import impulseImage from "../../../resources/circa/impulse.png"

import "./PhaseOverview.scss";

export const PhaseOverview = () => {
    const navigation = "../start";
    return <div className="phase-overview">
        <h3>In 5 Phasen vorankommen, um Vorhaben voranzubringen!</h3>
        <h3>Hier gibt es:</h3>
        <div className="cards">
            <Card title={"Werkstatt-Räume: 5 Phasen zum Spezifizieren"} subtitle={""} images={[{ path: phasesImage, title: "kompakt plus" }, { path: phasesImage, title: "Assistenz-System" }]} navigation={navigation} back={false} />
            <Card title={"Räume für Notizen & links"} subtitle={""} images={[{ path: circalindPersonalImage, title: "" }, { path: strategyImage_back, title: "" }]} navigation={navigation} back={false} />
            <Card title={"Räume zum Vertiefen je Phase"} subtitle={""} images={[{ path: impulseImage, title: "Zusammenarbeit mit Partnern" }]} navigation={navigation} back={false} />
        </div>
    </div>
};