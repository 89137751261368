import { ChangeEvent, ReactElement, useCallback, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";

import './HybridBase64Upload.scss';

export interface Props {
    onUpload: (base64: string, filename?: string) => void;
}

export const HybridBase64Upload = ({ onUpload }: Props): ReactElement => {
    const inputRef = useRef<HTMLInputElement>(null);
    const onFile = useCallback((file: File) => {
        convertFileToBase64(file).then(base64 => {
            onUpload(base64, file.name);
        });
    }, [onUpload]);

    return <div className="hybrid-base64-upload">
        <div
            onDragOver={ev => { ev.preventDefault() }}
            onDrop={dropHandler(onFile)}
            className="hybrid-base64-upload-drop-area"
            onClick={() => inputRef.current!.click()}
        >
            <div className="hybrid-base64-upload-symbol" >
                <FontAwesomeIcon icon={faCloudUploadAlt} />
            </div>
        </div>
        <input
            ref={inputRef}
            className="hybrid-base64-upload-input"
            type="file"
            onChange={fileChangeHandler(onFile)}
        />
    </div>
}

const dropHandler = (onFile: (file: File) => void) => (ev: React.DragEvent) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    if (!ev.dataTransfer) return;

    if (ev.dataTransfer && ev.dataTransfer.items) {
        [...ev.dataTransfer.items].forEach(item => {
            if (item.kind === "file") {
                const file = item.getAsFile()!;
                onFile(file)
            }
        });
    } else {
        const files = [...ev.dataTransfer.files];
        if (files.length > 0) {
            onFile(files[0]);
        }
    }
}

const fileChangeHandler = (onFile: (file: File) => void) => (ev: ChangeEvent<HTMLInputElement>): void => {
    if (ev.target.files && ev.target.files.length > 0) {
        onFile(ev.target.files[0]);
    }
}

const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
    });
}
