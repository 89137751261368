import { forwardRef, Fragment, ReactElement, useCallback, useEffect, useState } from "react";
import { GraphValues, Question as Q, TableData } from "../dataTypes/generated";
import { TableAnswerCell, useAnswer, QuestionAnswer } from "../hooks/useAnswer";
import { Graphs } from "../projects/compass/graphs/Graphs";
import { CompassTable } from "../projects/compass/table/CompassTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { usePassword } from "../hooks/usePassword";

import '../projects/compass/question/compassQuestion.scss'
import './question.scss'

export interface QuestionProps {
    question: Q;
    editMode: boolean;
    index: number;
    projectId: number;
    updateQuestion?: (question: Q, index: number) => void;
    preventUnsetProjectId?: boolean;
    onNext: () => Promise<void>;
    onBack: () => Promise<void>;
}

export const Question = forwardRef((props: QuestionProps, ref): ReactElement => {

    const password = usePassword(props.editMode);
    const [answer, setAnswer, saveAnswer] = useAnswer(props.question.id, props.question.phase, props.projectId, password);
    const [changed, setChanged] = useState<boolean>(false);

    const next = () => {
        if (!props.editMode) {
            saveAnswer(password);
        }
        props.onNext();
    }

    const back = () => {
        if (!props.editMode) {
            saveAnswer(password);
        }
        props.onBack();
    }

    const setAnswerCallback = useCallback((q: QuestionAnswer) => {
        setAnswer(q);
    }, [setAnswer])

    useEffect(() => {
        setAnswerCallback({ cells: [], comments: { decision: "", development: "", events: "" }, graphData: [] });
    }, [props.question, setAnswerCallback])

    const onTableCellsAnswerd = (newCells: TableAnswerCell[]) => {
        setChanged(true);
        setAnswerCallback({ ...answer, cells: newCells })
    }

    useEffect(() => {
        if (changed) {
            saveAnswer(password);
            setChanged(false);
        }
    }, [changed, password, props.index, saveAnswer])

    const onTableUpdate = (data: TableData) => {
        if (props.updateQuestion) {
            let q = { ...props.question };
            q.table = data;
            props.updateQuestion(q, props.index);
        }
    }

    const onGraphChange = (values: GraphValues[]) => {
        if (props.updateQuestion) {
            let q = { ...props.question };
            if (q.graph) {
                q.graph.values = values;
                props.updateQuestion(q, props.index);
            }
        }
    }

    const onChangeQuestion = (question: Q) => {
        if (props.updateQuestion) {
            props.updateQuestion(question, props.index);
        }
    }

    const renderTitle = () => {
        if (props.editMode) {
            return <input value={props.question.title} onChange={(e) => onChangeQuestion({ ...props.question, title: e.target.value })} />
        } else {
            return <span>{props.question.title}</span>
        }
    }

    const renderQuestionWithOptionalGraph = () => {
        if (props.question.graph && props.question.graph.type !== "NONE") {
            return <div className="question-table with-graph">
                <Graphs showLegend={true} edit={props.editMode} graph={props.question.graph} nrOfPoints={5} onGraphUpdate={onGraphChange} />
                <CompassTable edit={props.editMode} onUpdate={onTableUpdate} data={props.question.table} answers={answer.cells} onCellAnswerd={onTableCellsAnswerd} />
            </div>
        } else {
            return <div className="question-table">
                <CompassTable edit={props.editMode} onUpdate={onTableUpdate} data={props.question.table} answers={answer.cells} onCellAnswerd={onTableCellsAnswerd} />
            </div>
        }
    }

    return <Fragment>
        <div className="phase-question-box">
            <div className="phase">
                <div className="question">
                    <div className="question-header">
                        {renderTitle()}
                    </div>
                    {renderQuestionWithOptionalGraph()}
                </div>
            </div>
        </div>
        <div className={"navigation" + (props.editMode ? " edit" : "")}>
            <button onClick={() => back()}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button onClick={() => next()}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </Fragment>
});
