import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, ReactElement } from "react";
import './dialog.scss'

export interface DialogProps {
    className: string;
    component: ReactElement;
    show: boolean;
    toogle: () => void;
}

export interface DialogCompProps {
    toggle: () => void;
}

export const Dialog = (props: DialogProps): ReactElement => {


    return props.show ?
        <Fragment>
        <div className="overlay"/>
        <div className={"dialog " + props.className} id="dialog-modal">
            <div className="dialog-top">
                <FontAwesomeIcon className="dialog-close-fa" icon={faXmark} onClick={() => props.toogle()} />
            </div>
            {props.component}
        </div>
        </Fragment>
        :
        <Fragment/>
    
}