import { useEffect, useState } from "react";
import { DeskItem, DeskType, Project, TableCell, TableData } from "../dataTypes/generated";
import { TableAnswerCell } from "../hooks/useAnswer";
import { CompassTable } from "../projects/compass/table/CompassTable";
import { CircalindApi } from "../circalindApi";
import { sendOlert } from "../../olert/Olert";
import { useSelectedProject } from "../../utils/Hooks";
import { convertAndDownload } from "../../invoice/utils/Util";
import { TabView } from "../contentManager/TabView";
import { entries } from "./ProjectHistory";
import { Dialog } from "../basicComponents/dialogs/Dialog";
import { NewDeskItem } from "../projects/desk/NewDeskItem";

export enum DocumentationDeskType {
    BEST_PRACTISE,
    PRESENTATION_SHOP,
    MY_COLLECTION
}

interface DocumentationDeskProps {
    deskType: DocumentationDeskType
}

export const DocumentationDesk = ({ deskType }: DocumentationDeskProps) => {
    const [projectId,] = useSelectedProject();
    const [items, setItems] = useState<DeskItem[]>([]);
    const [type, setType] = useState<DeskType>("DOCUMENTS");
    const [, setProject] = useState<Project>();
    const [searchTags, ] = useState<Array<string>>([]);
    const [, setShowTagDialog] = useState<boolean>(false);
    const [, setSelectedItem] = useState<DeskItem>();
    const [newDialog, setNewDialog] = useState<boolean>(false);

    const updateItems = async () => {
        const res = await CircalindApi.getAllDeskItemsForProject(projectId);
        if (res) {
            setItems(res);
        }
    }

    useEffect(() => {
        const loadProject = async (id: number) => {
            const res = await CircalindApi.getProject(id);
            if (res) {
                setProject(res);
            }
        }

        loadProject(projectId);

        const internalUpdateItems = async () => {
            const res = await CircalindApi.getAllDeskItemsForProject(projectId);
            if (res) {
                setItems(res);
            }
        }

        internalUpdateItems();
    }, [projectId])

    const onUpdate = (data: TableData) => {
    }

    const onAnswer = (data: TableAnswerCell[]) => {
    }

    const onDelete = async (id: number) => {
        const res = await CircalindApi.deleteDeskItem(id);
        if (res) {
            sendOlert("Erfolgreich", "gelöscht", "Success");
        } else {
            sendOlert("Fehler", "Objekt konnte nicht gelöscht werden", "Error");
        }
        updateItems();
    }

    const getItemAndDownload = async (id: number) => {
        const res = await CircalindApi.getDeskItem(id);
        if (res) {
            convertAndDownload(res.base64Data.split('base64,')[1], res.date!.split("T")[0] + "_" + res.filename);
        }
    }

    const onItemClicked = async (id: number, action: "new" | "delete" | "open" | "tags" | "source", row: number, col: number) => {
        if (action === "new") {
            switch (row) {
                case 1: setType("SCREENSHOTS"); break;
                case 2: setType("DOCUMENTS"); break;
                case 3: setType("QUOTE"); break;
                case 4: setType("PHOTOS"); break;
                case 5: setType("PRESENTATION"); break;
                default: setType("DOCUMENTS"); break;
            }
            setNewDialog(!newDialog);
        } else if (action === "tags") {
            setShowTagDialog(true);
            setSelectedItem(items.find(i => i.id === id));
        } else if (action === "delete") {
            onDelete(id);
        } else if (action === "open") {
            getItemAndDownload(id);
        } else if (action === "source") {
            const item = items.find(i => i.id === id);
            if (item && item.source) {
                window.location.href = item.source;
            }
        }
    }

    const getItemsForPhaseAndType = (type: DeskType): DeskItem[] => {
        return items.filter(i => i.type === type).filter(i => {
            if (searchTags.length > 0) {
                return searchTags.some(st => i.tags.includes(st));
            } else {
                return true;
            }
        });
    }

    const generateData = (): TableData => {
        const generateContentRow = (row: number, type: DeskType): Array<TableCell> => {
            const columns = 2;

            const rowTitle = (() => {
                switch (type) {
                    case "DOCUMENTS":
                        return "Dokumente";
                    case "PHOTOS":
                        return "Fotos";
                    case "QUOTE":
                        return "Zitate";
                    case "PRESENTATION":
                        return "Präsentationen";
                    case "SCREENSHOTS":
                        return "Circalind-Seiten";
                    case "PROTOCOLS":
                        return "Protokolle";
                    case "BRAINSTORMING":
                        return "Brain-Storming";
                }
            })();

            return [...Array(columns).keys()].map(column => {
                return {
                    row: row,
                    column: column,
                    height: 1,
                    width: 3,
                    type: column === 0 ? "INFO" : "MULTIFILE",
                    id: (row + 1) * 10 + column + 1,
                    value: column === 0 ? rowTitle : JSON.stringify(getItemsForPhaseAndType(type)),
                    placeholder: column === 0 ? rowTitle : "file",
                    style: null
                }
            });
        }

        const generateHeaderRow = (): Array<TableCell> => {
            const columns = 2;

            return [...Array(columns).keys()].map(column => {
                const placeholder = "";

                return {
                    row: 0,
                    column: column,
                    height: 1,
                    width: 1,
                    type: "INFO",
                    id: 10 + column + 1,
                    value: "",
                    placeholder: placeholder,
                    style: null
                }
            });
        }

        const headerRow = generateHeaderRow();
        const contentRowTypes: Array<DeskType> = ["SCREENSHOTS", "DOCUMENTS", "QUOTE", "PHOTOS", "PRESENTATION"];

        return {
            tableCells: [...headerRow, ...contentRowTypes.map((ct, index) => generateContentRow(index + 1, ct)).flat()],
            cols: 6,
            rows: 6,
            title: "Information",
            style: null
        };
    };

    return <TabView entries={entries}>
        <div className="documentation-desk">
            <CompassTable
                answers={[]}
                data={generateData()}
                edit={false}
                onCellAnswerd={onAnswer}
                onUpdate={onUpdate}
                onCellItemClicked={onItemClicked}
            />
        </div>
        <Dialog className="detailProject" show={newDialog} toogle={() => setNewDialog(false)} component={<NewDeskItem doUpdate={updateItems} onclose={() => setNewDialog(false)} phase={0} phaseName={"Im Projekt"} projectId={projectId} groupId={-1} type={type} />} />
    </TabView>
};