import { ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { sendOlert } from "../../olert/Olert";
import { PasswordChange } from "../basicComponents/password/PasswordChange";
import { CircalindApi } from "../circalindApi";
import { useHeaderContext } from "../page/header/Header";

import './login.scss'

export const UpdatePass = (): ReactElement => {

    const { updateHeader } = useHeaderContext();
    const navigate = useNavigate();
    const { token } = useParams<{ token: string }>();
    const [valid, setValid] = useState<boolean>(false);

    useEffect(() => {
        updateHeader([])
    }, [updateHeader])

    useEffect(() => {
        const check = async (token: string) => {
            let res = await CircalindApi.validPassToken(token);
            setValid(res);
        }
        if (token) {
            check(token);
        }
    }, [token])

    const delayedNavigate = () => {
        setTimeout(function() {
            navigate("/circalind/login");
        }, 5000);
    }

    const reset = async (mail: string) => {
        if (token && valid) {
            let res = await CircalindApi.changePassword(token, mail);
            if (res) {
                sendOlert("Erfolgreich", "Password wurde geändert, Sie werden gleich zum Login weitergeleitet");
                delayedNavigate();
            } else {
                sendOlert("Error", "Password konnte nicht geändert werden", "Error")
            }
        }
    }

    return <div className="update">
        {valid ?
            <div className="reset-password">
                <PasswordChange onOk={reset} />
            </div>
            :
            <div className="warning">
                <h2>Dieser link ist nicht mehr gültig</h2>
                <span className="invalid">Um ihr Password zu ändern bitte lassen Sie sich eine Neue E-mail über "Password vergessen" beim Login zukommen</span>
                <Link to={"/circalind/login"}>Zum Login</Link>
            </div>
        }
    </div>
}
