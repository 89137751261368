import { PhaseDiagramsCompare } from "../projects/competence/PhaseDiagramsCompare";
import { useSelectedProject } from "../../utils/Hooks";

export const MatchingProjectPhase = () => {
    const [selectedProject,] = useSelectedProject();

    return <div>
        <h2>Projekt-Profil (Soll-/Ist)</h2>
        <PhaseDiagramsCompare projectId={selectedProject} graphForChoice={"PROJECT_PHASE_COMPETENCE"} />
    </div>
};