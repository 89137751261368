import { useEffect, useState } from "react"
import { CircalindApi } from "../circalind/circalindApi"
import { Area, SubArea } from "../circalind/dataTypes/generated"
import { readFromLocalStorage, writeToLocalStorage } from "./LocalStorage"

export const useGroupWideSubArea = (area: Area, subAreaName: string): SubArea | null => {
    const [subArea, setSubArea] = useState<SubArea | null>(null)

    useEffect(() => {
        CircalindApi.getSubAreas({
            groupId: -1,
            area,
            targetProjectId: null,
            targetUserId: null,
            targetInternalGroupId: null,
        }).then(subAreas => {
            const sa = subAreas.find(subArea => subArea.name === subAreaName) || null;
            setSubArea(sa)
        })
    }, [area, subAreaName]);

    return subArea;
}

const makeLocalStorageHook = <T>(
    key: string,
    defaultValue: T,
) => {
    const listeners = new Set<(value: T) => void>();

    const read = () => {
        const value = readFromLocalStorage(key);
        if (value == null) {
            return defaultValue;
        } else {
            return JSON.parse(value);
        }
    }

    const write = (value: T) => writeToLocalStorage(key, JSON.stringify(value))
    const notify = (value: T) => listeners.forEach(listener => listener(value))

    const set = (newValue: T) => {
        write(newValue);
        notify(newValue);
    }

    return () => {
        const [value, setValue] = useState<T>(() => read());

        useEffect(() => {
            const listener = (newValue: T) => setValue(newValue);
            listeners.add(listener);
            return () => {
                listeners.delete(listener);
            }
        }, []);

        return [value, set] as const;

    };
}

export const useSelectedProject = makeLocalStorageHook<number>("selected-project", -1);
