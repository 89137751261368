import { faBars, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createContext, ReactElement, useContext, useEffect, useState } from "react";
import { getCurrentUserId, getUserRoles } from "../../../utils/Auth";
import { useLocation } from "react-router";
import { ProjectSelect } from "../../../leading/dialogs/ProjectSelect";
import { AreaAndRoleInfo, Group, Project, RoleType, User } from "../../dataTypes/generated";
import { CircalindApi } from "../../circalindApi";
import { validateToken } from "../../../utils/Auth";

import Logo from '../../../resources/Logo-single.svg';
import circalindLeadershipImage from "../../../resources/circa/leadership_teamwork.svg";

import './header.scss'
import { useSelectedProject } from "../../../utils/Hooks";
import { Dialog } from "../../basicComponents/dialogs/Dialog";
import { RoleDisplay } from "../../../leading/role";

export const defaultHeaders: HeaderMenuItem[] = [
    { title: "Impulse", route: "/regular/impulse" },
    { title: "Services", route: "/regular/services" },
    { title: "CheckUp", route: "/regular/checkups/content" },
    { title: "Logout", route: "/logout" },
];

export const serviceHeaders: HeaderMenuItem[] = [
    { title: "Logout", route: "/logout" },
];

export const leadingHeaders: HeaderMenuItem[] = [
    { title: "Assistenzbereich", route: "/leading/content" },
    { title: "Logout", route: "/logout" },
];

export const LandingPageHeaders: HeaderMenuItem[] = [
    { scrolTo: "landing-page1", title: "Intention" },
    { scrolTo: "landing-page2", title: "Nachhaltigkeits-Philosophie" },
    { scrolTo: "landing-page3", title: "Wofür circalind" },
    { route: "/login", title: "Login (in Kürze)", disabled: true },
];

export const LandingPageNavHeaders: HeaderMenuItem[] = [
    { route: "/", title: "Hauptseite" },
];

export interface HeaderMenuItem {
    title: string;
    disabled?: boolean;
    route?: string;
    scrolTo?: string;
    icon?: IconDefinition;
    image?: string;
    allowedRoles?: RoleType[];
}

type ContextType = {
    headers: HeaderMenuItem[];
    updateHeader: (newHeaders: HeaderMenuItem[]) => void;
}

export const HeaderContext = createContext<ContextType>({
    headers: defaultHeaders,
    updateHeader: () => { },
});

export const useHeaderContext = () => useContext(HeaderContext)

const loadUserProjects = async () => {
    return await CircalindApi.getProjects();
}

export const Header = (): ReactElement => {
    const roles = getUserRoles("token-circalind");

    const [selectedProject, setSelectedProject] = useSelectedProject();
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo | null>(null);
    const [showAssistanceDialog, setShowAssistanceDialog] = useState<boolean>(false);
    const [group, setGroup] = useState<Group | null>(null);
    const [responsive, setResponsive] = useState<boolean>(false);
    const [projects, setProjects] = useState<Project[]>([]);
    const { headers } = useHeaderContext()
    const location = useLocation();
    const siteIsRegularOrLeading = location.pathname.startsWith("/circalind")
    const isInteraction = location.pathname.startsWith("/circalind/interaction")

    const userId = getCurrentUserId("token-circalind");

    useEffect(() => {
        const load = async () => {
            const userProjects = await loadUserProjects();
            if (userProjects) {
                const filtered = userProjects.filter(p => {
                    if (isInteraction && userId) {
                        return p.ownerId === userId || p.members.map(m => m.user.id).includes(userId);
                    } else {
                        return p.ownerId === userId;
                    }
                });
                setProjects(filtered);
                if (!filtered.find(p => p.id === selectedProject)) {
                    setSelectedProject(-1);
                }
            }

            if (roles.length > 0) {
                setGroup(await CircalindApi.getMyGroup());
            }

            setAreaAndRoleInfo(await CircalindApi.getAreaAndRoleInfo());
        }

        if (siteIsRegularOrLeading && validateToken("token-circalind") && location.pathname !== "/circalind/logout") {
            load();
        } else {
            setProjects([]);
            setSelectedProject(-1);
        }

    }, [isInteraction, location, roles.length, selectedProject, setSelectedProject, siteIsRegularOrLeading, userId])

    const onProjectSelection = (p: Project | undefined) => {
        if (p) {
            setSelectedProject(p.id);
        } else {
            setSelectedProject(-1);
        }
    }

    const renderIcon = (item: HeaderMenuItem) => {
        if (item.icon) {
            return <FontAwesomeIcon icon={item.icon} />
        }
    }

    const renderImage = (item: HeaderMenuItem) => {
        if (item.image) {
            return <img src={item.image} alt={"error"} />
        }
    }

    const renderIconOrImage = (item: HeaderMenuItem) => {
        if (item.icon) {
            return renderIcon(item);
        }
        if (item.image) {
            return renderImage(item);
        }
    }

    const scrollTo = (id: string | undefined) => {
        if (id) {
            let el = document.getElementById(id);
            if (el) {
                el.scrollIntoView({ behavior: "smooth" });
            }
        }
    }

    const onItemScroll = (item: HeaderMenuItem) => {
        if (responsive) {
            setResponsive(!responsive)
        }
        scrollTo(item.scrolTo);
    }

    const renderMenuItem = (item: HeaderMenuItem, key: number) => {
        if (item.route) {
            return <a className={"item" + (responsive ? " mobile" : "") + (item.disabled ? " disabled" : "")} key={key} href={"/circalind" + item.route}>{item.title}{renderIconOrImage(item)}</a>
        } else if (item.scrolTo) {
            return <span className={"item" + (responsive ? " mobile" : "")} key={key} onClick={() => onItemScroll(item)}>{item.title}{renderIconOrImage(item)}</span>
        } else {
            return <span className={"item" + (responsive ? " mobile" : "")} key={key} >{item.title}{renderIconOrImage(item)}</span>
        }
    }

    const renderProject = () => {
        if (validateToken("token-circalind")) {
            const user = getCurrentUserId("token-circalind");

            const projectSelectLocations = ["/circalind/regular", "/circalind/interaction", "/circalind/tools", "/circalind/managing"];

            if (siteIsRegularOrLeading) {
                if (user && window.location.pathname.startsWith("/circalind") && window.location.pathname !== "/circalind/dataprivacy" && window.location.pathname !== "/circalind/impressum") {
                    return <div className={"project" + (responsive ? " mobile" : "")}>
                        {window.location.pathname.startsWith("/circalind/leading") ? <img src={circalindLeadershipImage} alt="Projekttyp" /> : undefined}
                        {projectSelectLocations.some((l) => window.location.pathname.startsWith(l)) && <ProjectSelect projects={projects} onSelect={(e) => onProjectSelection(e)} valueProjectId={selectedProject} />}
                        {!window.location.pathname.startsWith("/circalind/leading") && selectedProject > -1 && <span className="assistance"><img onClick={() => setShowAssistanceDialog(true)} src={circalindLeadershipImage} alt="Projekt-Assistenz" /></span>}
                    </div>
                }
            }
        }
    };

    const renderMenu = () => {
        return <div className={"items" + (responsive ? " mobile" : "")}>
            <div className="resp-icon" onClick={() => setResponsive(!responsive)}><FontAwesomeIcon icon={faBars} /></div>
            {renderProject()}
            {
                headers.map((i, k) => {
                    if (i.allowedRoles && i.allowedRoles.length > 0) {
                        if (i.allowedRoles.some(r => roles.includes(r))) {
                            return renderMenuItem(i, k);
                        } else {
                            return null;
                        }
                    } else {
                        return renderMenuItem(i, k);
                    }
                })
            }
        </div>
    }

    const logo = group?.logo || Logo;

    const project = projects.find(p => p.id === selectedProject);

    return <header className="circalind-app-header">
        <div className="menu">
            {renderMenu()}
        </div>
        <div className="logo-div">
            <img src={logo} alt="logo" />
        </div>
        {project && areaAndRoleInfo && <Dialog className={""} component={<ProjectAssistanceDialog areaAndRoleInfo={areaAndRoleInfo} project={project} />} show={showAssistanceDialog} toogle={() => setShowAssistanceDialog(false)} />}
    </header>
}

const ProjectAssistanceDialog = ({ project, areaAndRoleInfo }: { project: Project, areaAndRoleInfo: AreaAndRoleInfo }) => {
    const [users, setUsers] = useState<Array<User>>([]);

    useEffect(() => {
        const loadUsers = async () => {
            setUsers(await CircalindApi.getAllUsersOfMyGroup());
        };

        loadUsers();
    }, []);

    return <div className="assistance-dialog">
        <h2>Projekt-Assistenz</h2>
        <ul>
            {project.members.map(m => {
                const user = users.find(u => u.id === m.user.id);
                if (user) {
                    return <li className="assistant"><RoleDisplay role={m.role} areaAndRoleInfo={areaAndRoleInfo} /><span className="name">{user.firstName} {user.lastName}</span></li>;
                } else {
                    return <li></li>;
                }
            })}
        </ul>
    </div>
};