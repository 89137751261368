import { faArrowLeft, faArrowRight, faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useEffect, useState } from "react";
import { Dialog } from "../../circalind/basicComponents/dialogs/Dialog";
import { CircalindApi } from "../../circalind/circalindApi";
import { AreaAndRoleInfo, CircaPackage, Group, InternalGroup, LicenseMetaData, LicenseTypes, Project, RoleType, TotalLicenses, TypeProject, User } from "../../circalind/dataTypes/generated";
import { sendOlert } from "../../olert/Olert";
import { getCurrentUserId, getUserRoles } from "../../utils/Auth";
import { EditStaffingGroup } from "../dialogs/EditStaffingGroup";
import { EditStaffingProject } from "../dialogs/EditStaffingProject";
import Select from 'react-select';
import { UserSelect } from "../dialogs/UserSelect";
import { useNavigate } from "react-router";

import './leading.scss'
import { SubAreaEdit } from "./SubAreaOverview";

export const Staffing = (): ReactElement => {
    const navigate = useNavigate();

    const [dialogs, setDialog] = useState<{ project: boolean, user: boolean, permission: boolean, groups: boolean }>({ groups: false, permission: false, project: false, user: false })
    const [selected, setSelected] = useState<Project | User | InternalGroup | undefined>(undefined);
    const [projects, setProjects] = useState<Project[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [license, setLicense] = useState<CircaPackage | null>(null);
    const [licenseMetaData, setLicenseMetaData] = useState<LicenseMetaData | null>(null);
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo | null>(null);
    const [totalLicenses, setTotalLicenes] = useState<TotalLicenses | null>(null);

    const toggleDialog = () => {
        setDialog({ groups: false, permission: false, project: false, user: false })
    }

    const openDialog = (kind: listType) => {
        let newDialogs = { groups: false, permission: false, project: false, user: false };
        switch (kind) {
            case "Project": newDialogs.project = true; break;
            case "InternalGroups": newDialogs.groups = true; break;
            case "Permission": newDialogs.permission = true; break;
            case "User": newDialogs.user = true; break;
        }
        setDialog(newDialogs);
    }

    useEffect(() => {
        const getData = async () => {
            const res = await loadData();
            setProjects(res.projects);
            setUsers(res.users);
            setAreaAndRoleInfo(res.areaAndRoleInfo);
            setLicense(res.license);
            setLicenseMetaData(res.licenseMetaData);
            setTotalLicenes(res.totalLicenses);
        }
        getData();
    }, [])

    const onSelect = (item: Project | User | InternalGroup | undefined) => {
        setSelected(item);
    }

    const onUpdate = async () => {
        window.location.reload();
    }

    const onCancel = () => {
        setDialog({ groups: false, permission: false, project: false, user: false })
    }

    return <div className="staffing">
        <h2>Setting & Staffing</h2>
        <div className="list-cards">
            <ProjectOverview totalLicenses={totalLicenses} license={license} licenseMetaData={licenseMetaData} users={users} areaAndRoleInfo={areaAndRoleInfo} onOpenDialog={openDialog} onDelete={onUpdate} onSelect={onSelect} title="Entscheiderprojekte und Projektassistenz" kind="Project" items={projects} />
            <SubAreaEdit />
        </div>
        <Dialog className="editStaffing" show={dialogs.project} toogle={toggleDialog} component={<EditStaffingProject showAllDeciders={true} showData={true} showRoles={true} selected={selected} users={users} onUpdate={onUpdate} onCancel={onCancel} licenseType={license?.type || null} />} />
        <Dialog className="editStaffing" show={dialogs.groups} toogle={toggleDialog} component={<EditStaffingGroup selected={selected} users={users} onUpdate={onUpdate} onCancel={onCancel} licenseType={license?.type || null} />} />
        <div className={"navigation"}>
            <button onClick={() => navigate("../users/new")}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button onClick={() => navigate("../tools")}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>
}

export const loadData = async (): Promise<{ totalLicenses: TotalLicenses | null, projects: Project[], users: User[], intGroups: InternalGroup[], currentGroup: Group | null, areaAndRoleInfo: AreaAndRoleInfo, license: CircaPackage | null, licenseMetaData: LicenseMetaData | null }> => {
    const gr = await CircalindApi.getMyGroup();
    const pr = await CircalindApi.getGroupProjects();
    const us = await CircalindApi.getAllUsersOfMyGroup();
    const ig = await CircalindApi.getInternalGroups();
    const license = await CircalindApi.getLicense();
    const areaAndRoleInfo = await CircalindApi.getAreaAndRoleInfo();
    const licenseMetaData = await CircalindApi.getLicenseMetaData();
    const totalLicenses = await CircalindApi.getTotalLicenses();
    return { totalLicenses: totalLicenses, projects: pr, users: us, intGroups: ig, currentGroup: gr, areaAndRoleInfo, license, licenseMetaData }
}

type listType = "Project" | "User" | "Permission" | "InternalGroups" | "UserCount";

export interface ListCardProps<T> {
    title: string;
    areaAndRoleInfo: AreaAndRoleInfo | null;
    allowedRoles?: Array<RoleType>;
    license: CircaPackage | null;
    licenseMetaData: LicenseMetaData | null;
    totalLicenses: TotalLicenses | null;
    kind: listType;
    items: (T)[];
    users: Array<User>;
    onSelect?: (item: T | undefined) => void;
    onOpenDialog?: (kind: listType) => void;
    onDelete: () => void;
}

export interface StaffingDialogProps {
    preDefinedType?: TypeProject;
    selected: Project | User | InternalGroup | undefined;
    users?: User[];
    projects?: Project[];
    groups?: InternalGroup[];
    licenseType: LicenseTypes | null;
    onUpdate: () => void;
    onCancel: () => void;
}

const ProjectOverview = (props: ListCardProps<Project>): ReactElement => {
    const currentUserId = getCurrentUserId("token-circalind");
    const userRoles = getUserRoles("token-circalind");
    const isPriviledUser = (userRoles.includes("CIRCALINDMANAGER") || userRoles.includes("COORDINATOR") || userRoles.includes("CO_COORDINATOR"));

    const [filterRole, setFilterRole] = useState<RoleType | undefined>();
    const [filterUser, setFilterUser] = useState<User | undefined>();

    useEffect(() => {
        let limitedUser: User | undefined = undefined;
        if (!isPriviledUser) {
            limitedUser = props.users.find(u => u.id === currentUserId);
        }
        setFilterUser(limitedUser);
    }, [props.users, currentUserId, isPriviledUser]);

    const deleteItem = async (item: Project) => {
        let res = false;
        res = await CircalindApi.deleteProject(item.id);
        if (res === true) {
            sendOlert("Erfolgreich", "gelöscht", "Success");
        } else {
            sendOlert("Fehler", "Objekt konnte nicht gelöscht werden", "Error");
        }
        props.onDelete();
    }

    const onItemSelect = (item: Project) => {
        if (props.onSelect && props.onOpenDialog) {
            props.onSelect(item);
            props.onOpenDialog(props.kind);
        }
    };

    const onOpenNewDialog = () => {
        if (props.onSelect && props.onOpenDialog) {
            props.onSelect(undefined);
            props.onOpenDialog(props.kind);
        }
    };


    const renderProjectItem = (p: Project, index: number) => {
        const decider = props.users.find(u => u.id === p.ownerId);
        const coordinator = props.users.find(u => u.id === p.members.find(m => m.role === "COORDINATOR")?.user.id);

        return <div key={index} className="project-entry" onClick={() => onItemSelect(p)}>
            <div>
                <span><strong>Nr.: {p.id}</strong></span>
                <br />
                <label>{p.date}</label>
            </div>
            <span><span>Entscheider: </span><br /> <strong>{decider?.firstName} {decider?.lastName}</strong></span>
            <span><span>Koordinator: </span><br /> <strong>{coordinator?.firstName} {coordinator?.lastName}</strong></span>
        </div>
    }

    const renderItem = (item: Project, index: number) => {
        return <div key={index} className="list-entry">
            {renderProjectItem(item, index)}
            {isPriviledUser ? <FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteItem(item)} /> : undefined}
        </div>
    }

    const renderUserSelect = () => {
        return <div>
            <label>Nutzer filtern</label>
            <UserSelect disabled={!isPriviledUser} useName={true} valueUserId={filterUser?.id} users={props.users} onSelect={(user) => setFilterUser(user)}></UserSelect>
        </div>;
    };

    const renderRoleSelect = () => {
        if (props.areaAndRoleInfo) {

            const projectRoles = Object.entries(props.areaAndRoleInfo.roleTitles);
            projectRoles.push(["DECIDER" as RoleType, props.areaAndRoleInfo.roleTitles["DECIDER"]!]);
            const options = projectRoles.map(entry => { return { label: entry[1], value: entry[0] }; });

            return <div>
                <label>Rolle filtern</label>
                <Select value={options.find(o => filterRole === o.value)} isClearable={true} className="add-role" onChange={(option) => setFilterRole(option?.value as RoleType)} options={options}></Select>
            </div>;
        }
    };

    const filterElements = (i: Project) => {
        if (filterUser && filterRole) {
            if (filterRole === "DECIDER") {
                return i.ownerId === filterUser.id;
            } else {
                return i.members.find(m => m.user.id === filterUser.id && m.role === filterRole);
            }
        } else if (filterUser && !filterRole) {
            return i.ownerId === filterUser.id || i.members.find(m => m.user.id === filterUser.id);
        } else {
            return true;
        }
    };

    return <div className="list-card">
        <div className="title">
            <h3>{props.title}</h3>
            <div className="split-select">
                {renderUserSelect()}
                {renderRoleSelect()}
            </div>
        </div>
        <div className="list">
            {props.items.filter(filterElements).map((p, i) => renderItem(p, i))}
        </div>
        <button className="add" onClick={() => onOpenNewDialog()}><FontAwesomeIcon icon={faPlusCircle} /></button>
    </div>
}
