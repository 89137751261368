import { ReactElement } from "react";
import { AreaAndRoleInfo, RoleType } from "../circalind/dataTypes/generated";

import impulseImage from "../resources/circa/impulse.png";
import circalindDeciderImage from "../resources/circa/compass/Persönlichkeit.png";

import "./role.scss";

export const RoleDisplay = ({ role, areaAndRoleInfo }: { role: RoleType, areaAndRoleInfo?: AreaAndRoleInfo }) => {
    const title = areaAndRoleInfo?.roleTitles[role] || role;

    return <span className="role-display"><RoleCircle role={role} /> {title}</span>
};

const Circle = ({ color, full }: { color: string, full: boolean }) => {
    if (full) {
        return <svg className="role-circle" viewBox="0 0 100 100"><circle cx="50" cy="50" r="40" fill={color} /></svg>
    } else {
        return <svg className="role-circle" viewBox="0 0 100 100"><circle cx="50" cy="50" r="30" stroke={color} strokeWidth={20} fill="none" /></svg>
    }
};

export const RoleCircle = ({ role }: { role: RoleType }) => {
    let circle: ReactElement | null = null;

    switch (role) {
        case "DECIDER":
            circle = <img className="role-circle" src={circalindDeciderImage} alt="" />
            break;
        case "CIRCALINDMANAGER":
            circle = <Circle color={"#eee"} full={false} />
            break;
        case "COORDINATOR":
            circle = <Circle color={"#598b58"} full={false} />
            break;
        case "CO_COORDINATOR":
            circle = <Circle color={"#8dd63d"} full={false} />
            break;
        case "IT_COORDINATOR":
            circle = <Circle color={"#18668b"} full={false} />
            break;
        case "QM_COORDINATOR":
            circle = <Circle color={"#c28b37"} full={false} />
            break;
        case "CONTENT_MANAGER":
            circle = <Circle color={"#c0bccf"} full={false} />
            break;
        case "IMPULSE_PARTNER":
            circle = <Circle color={"#afc95d"} full={false} />
            break;
        case "SUPPORT_PARTNER":
            circle = <img className="role-circle" src={impulseImage} alt="" />
            break;
        case "CO_MANAGER_PROJECT":
            circle = <Circle color={"#3f9fc3"} full={false} />
            break;
        case "CO_MANAGER_DRIVERS":
            circle = <Circle color={"#09558c"} full={false} />
            break;
    }

    return circle;
};