import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import { SubAreaContent } from "../../contentManager/SubAreaContent";

export const DriverNotes = ({ showButtons }: { showButtons: boolean }) => {
    const navigate = useNavigate();

    return <div className="driver-notes">
        <h3>Notizen zu Treibern und Herausforderungen</h3>
        <SubAreaContent isProjectSpecific={true} notes={true} area={"practical"} />
        {showButtons ?
            <div className={"navigation"}>
                <button onClick={() => navigate("../sync")}><FontAwesomeIcon icon={faArrowLeft} /></button>
                <button onClick={() => navigate("/circalind/regular/phase/decide")}><FontAwesomeIcon icon={faArrowRight} /></button>
            </div>
            :
            undefined}
    </div>;
};