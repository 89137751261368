import { useEffect, useState } from "react";
import { sendOlert } from "../../olert/Olert";
import { decrypt, encrypt } from "../../utils/Encryption";
import { CircalindApi } from "../circalindApi";
import { Answer, GraphValues } from "../dataTypes/generated";

export interface QuestionEndAnswer {
    driver: string;
    comments: string;
    graphData: GraphValues[];
    title: string;
}

export interface TableAnswerCell {
    column: number;
    row: number;
    answer: string;
}

const createEmptyAnswer = (): QuestionEndAnswer => {
    return { driver: "", graphData: [], comments: "", title: "" };
}

export const useEndAnswer = (phase: number, projectid: number, password: string | null) => {
    const emptyAnswer: QuestionEndAnswer = createEmptyAnswer();

    const [rawAnswer, setRawAnswer] = useState<Answer>({ answer: "", phase: phase, questionId: -1, userId: -1, projectId: projectid, timestamp: "" })
    const [endAnswer, setEndAnswer] = useState<QuestionEndAnswer>(emptyAnswer)

    const onSaveEndAnswer = async (pass: string | null) => {
        if (projectid && phase >= 0) {
            if (pass !== null) {
                let toSave = rawAnswer;
                //encrypt HERE
                toSave.answer = encrypt(JSON.stringify(endAnswer), pass);
                await CircalindApi.saveEndAnswer(toSave);
            } else {
                sendOlert("Hinweis", "Es konnten keine Daten gespeichert werden da kein Password vergeben wurde", "Warning")
            }
        }
    }

    useEffect(() => {
        const decryptEndAnswer = (password: string | null) => {
            if (rawAnswer && rawAnswer.userId !== -1 && password) {
                try {
                    setEndAnswer(JSON.parse(decrypt(rawAnswer.answer, password)));
                } catch {
                    setEndAnswer(createEmptyAnswer());
                }
            }
        }

        decryptEndAnswer(password)
    }, [password, rawAnswer])

    useEffect(() => {
        const getAndSetEndAnswer = async () => {
            if (phase >= 0) {
                let res = await CircalindApi.getEndAnswer(phase, projectid);
                if (res) {
                    setRawAnswer(res);
                } else {
                    setRawAnswer({ answer: "", questionId: -1, phase: phase, userId: -1, projectId: projectid, timestamp: "" });
                }
            }
        }
        getAndSetEndAnswer();
    }, [projectid, phase])

    return [endAnswer, setEndAnswer, onSaveEndAnswer] as const
}