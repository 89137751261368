import { ReactElement, useEffect, useState } from "react";
import { sendOlert } from "../../../../olert/Olert";
import { CircalindApi } from "../../../circalindApi";
import { CompassType, Question } from "../../../dataTypes/generated";
import { QuestionTable, QuestionTablePage } from "../../../questions/QuestionTable";
import { createEmptyQuestion, getDefaultByType, mapPages } from "./CockpitUtils";

import './DeepIntro.scss';

export interface CockpitCompassProps {
    type: CompassType;
    index: number;
    edit: boolean;
    onBack: () => void;
    projectId: number;
}

export const CockpitCompass = (props: CockpitCompassProps): ReactElement => {

    const [selected, setSelected] = useState<number>(0);
    const [questions, setQuestions] = useState<Question[]>();
    const [pages, setPages] = useState<QuestionTablePage[]>([]);

    const loadCompassQuestions = async () => {
        const subAreas = await CircalindApi.getSubAreas({
            groupId: -1,
            area: "deepCompass",
            targetProjectId: props.projectId,
            targetUserId: null,
            targetInternalGroupId: null
        });

        const subArea = subAreas.find(sa => sa.name === getSubAreaNameFromType(props.type));

        if (subArea) {
            return CircalindApi.getCompassQuestions({ "type": props.type, subAreaId: subArea.id });
        } else {
            return [];
        }
    };

    useEffect(() => {
        const internalLoadCompassQuestions = async () => {
            const subAreas = await CircalindApi.getSubAreas({
                groupId: -1,
                area: "deepCompass",
                targetProjectId: props.projectId,
                targetUserId: null,
                targetInternalGroupId: null
            });
    
            const subArea = subAreas.find(sa => sa.name === getSubAreaNameFromType(props.type));
    
            if (subArea) {
                return CircalindApi.getCompassQuestions({ "type": props.type, subAreaId: subArea.id });
            } else {
                return [];
            }
        };

        const defaultPages = getDefaultByType(props.type);
        const createEmptyQuestionsAfterLoad = (existing: Question[]): Question[] => {
            let res = [];
            if (defaultPages) {
                for (let i = 0; i < defaultPages.length; i++) {
                    const ex = existing.find(q => q.order === i);
                    if (ex) {
                        res.push(ex);
                    } else {
                        res.push(createEmptyQuestion(i, props.type));
                    }
                }
            }
            return res;
        }
        const loadCompass = async () => {
            const res = await internalLoadCompassQuestions();
            if (res) {
                if (res.length === 0 && props.edit === true) {
                    let ques = createEmptyQuestionsAfterLoad(res);
                    setQuestions(ques);
                    setPages(mapPages(ques, getDefaultByType(props.type)));
                } else {
                    if (res.length >= 0) {
                        setQuestions(res);
                        setPages(mapPages(res, getDefaultByType(props.type)));
                    }
                }
            } else {
                if (props.edit === true) {
                    let ques = createEmptyQuestionsAfterLoad(res);
                    setQuestions(ques);
                    setPages(mapPages(ques, getDefaultByType(props.type)));
                }
            }
        }
        loadCompass();
    }, [props.type, props.edit, props.projectId])

    useEffect(() => {
        setSelected(props.index)
    }, [props.index])

    const onSave = async () => {
        const loadCompass = async () => {
            const res = await loadCompassQuestions();
            if (res) {
                setQuestions(res);
            }
        }
        if (questions) {
            let res = await CircalindApi.SaveCompassQuestions(questions);
            if (res) {
                sendOlert("Gespeichert", "", "Success");
                loadCompass();
            } else {
                sendOlert("ERROR", "Konnte änderung nicht speichern", "Error")
            }
        }
    }

    const getSubAreaNameFromType = (t: CompassType) => {
        switch (t) {
            case "COMPSELFGUIDE":
                return "Selbstführung"
            case "COMPMANAGEMENT":
                return "Management"
            case "COMPLANECHANGE":
                return "Spur-Wechsel"
        }
    };

    const onBack = async () => {
        if (selected === 0) {
            props.onBack();
        } else {
            setSelected(selected - 1);
        }

    }

    const onNext = async () => {
        if (selected < getDefaultByType(props.type).length) {
            setSelected(selected + 1);
        }
    }

    const onSelected = (atIndex: number) => {
        setSelected(atIndex);
    }

    const onUpate = (q: Question, atIndex: number) => {
        if (questions) {
            let newQuestions = [...questions];
            newQuestions[atIndex] = q;
            setQuestions(newQuestions);
            let newPages = [...pages];
            newPages[atIndex].question = q;
            setPages(newPages);
        }
    }

    return <div className="transformation-compass">
        {pages.length > 0 ?
            <QuestionTable projectId={props.projectId} edit={props.edit} onUpdate={onUpate} pages={pages} selected={selected} onSelected={onSelected} onSave={onSave} onNext={onNext} onBack={onBack} />
            : <span className="warning">Es gibt noch keine Fragen</span>}
    </div>
}