import { ReactElement, useEffect, useRef, useState } from "react";
import { CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import { SubGraphProps } from "./Graphs";
import { EditingState, registerMouseAndTouchEvents } from "./RadarGraph";


export const LineGraph = (props: SubGraphProps): ReactElement => {
    const [editingState, setEditingState] = useState<EditingState>({ kind: "idle" });
    const ref = useRef<any>(null);

    useEffect(() => {
        if (ref.current.current) {
            registerMouseAndTouchEvents(ref.current.current, setEditingState);
        }
    }, [ref]);

    const lineData = () => {
        let res: any[] = [];
        for (let i = 0; i < props.nrOfPoints; i++) {
            let t = props.data.flatMap(l => l.points).filter(k => k.index === i);
            let point: any = { index: i };
            t.forEach(p => {
                point["description"] = p.description;
                let varr = p.name;
                point[varr] = p.value;
            })
            res.push(point);
        }
        return res;
    }

    const onPointMove = (e: CategoricalChartState) => {
        if (ref.current && ref.current.current && e.chartY !== undefined && e.chartY !== null) {
            // use ref to look for element
            const height = ref.current.current.querySelector(".recharts-cartesian-grid").getBoundingClientRect().height;

            let newPointVal = 100 + ((e.chartY * (-100)) / height);
            if (newPointVal > 100) {
                newPointVal = 100;
            }
            if (newPointVal < 0) {
                newPointVal = 0;
            }

            const getEditState = (): EditingState => {
                const pointIndex = props.data[0].points.findIndex(v => v.description === e.activeLabel);
                console.log(props.data);
                console.log(e.activeLabel);
                console.log(pointIndex)
                let nearestValueIndex = -1;
                for (let i = 0; i < props.data.length; i++) {
                    if (nearestValueIndex === -1) {
                        nearestValueIndex = i;
                    } else {
                        const oldDelta = Math.abs(props.data[nearestValueIndex].points[pointIndex].value - newPointVal);
                        const newDelta = Math.abs(props.data[i].points[pointIndex].value - newPointVal);
                        if (newDelta < oldDelta) {
                            nearestValueIndex = i;
                        }
                    }
                }

                return {
                    kind: "editing",
                    valueIndex: nearestValueIndex,
                    pointIndex: pointIndex
                }
            };

            switch (editingState.kind) {
                case "idle":
                    return;
                case "waitingForEdit":
                    const editState = getEditState();
                    setEditingState(editState);
                    return;
                case "editing":
                    let newData = [...props.data]
                    newData[editingState.valueIndex].points[editingState.pointIndex].value = newPointVal;
                    props.updateData(newData);
            }
        }
    }

    const renderTooltip = () => {
        return undefined;
    }

    const renderLine = () => {
        return props.data.map((l, i) => <Line key={i} dataKey={l.uuid} stroke={l.color} strokeWidth={2}
            activeDot={{ r: 10 }} />
        )
    }

    return <ResponsiveContainer ref={ref} width="100%" height="100%">
        <LineChart
            ref={props.innerRef}
            onMouseMove={(e) => onPointMove(e)}
            width={800}
            height={300}
            data={lineData()}
            margin={{
                top: 5,
                right: 30,
                left: 0,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="description" />
            <YAxis type="number" domain={[0, 100]} />
            <Tooltip content={renderTooltip} />
            <ReferenceLine />

            {renderLine()}
        </LineChart>
    </ResponsiveContainer>

}