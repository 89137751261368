import Select from 'react-select';

import "./Customer.scss";
import { Customer, TypeCustomer, User } from "../dataTypes/generated";

interface CustomerProps {
    customer: Customer;
    setCustomer: (c: Customer) => void;
    user: User;
    setUser: (u: User) => void;
    isAdmin: boolean;
}

export const CustomerView = ({ customer, setCustomer, user, setUser, isAdmin }: CustomerProps) => {
    const customerTypeOptions: Array<{ label: string, value: TypeCustomer }> = [
        { label: "Privatkunde", value: "PRIVATE" },
        { label: "Privatkunde B2B-Partner", value: "PRIVATE_B2B" },
        { label: "Privatkunde Vertriebs-Partner", value: "PRIVATE_SALES" },
        { label: "Unternehmenskunde", value: "COMPANY" },
        { label: "Unternehmenskunde B2B-Partner", value: "COMPANY_B2B" },
        { label: "Unternehmenskunde Vertriebs-Partner", value: "COMPANY_SALES" },
    ];

    return <div className="customer-container">
        {(!(!isAdmin && customer.discount === 0)) && <div className="form-entry">
            <label htmlFor="discount">Kundenspezifscher Rabatt in %</label>
            <input min="0" max="100" id="discount" type="number" disabled={!isAdmin} value={customer.discount} onChange={(e) => setCustomer({ ...customer, discount: +e.target.value })} />
        </div>}
        {(!(!isAdmin && customer.basicDiscount === 0)) && <div className="form-entry">
            <label htmlFor="basicDiscount">Basisvertrags Rabatt in %</label>
            <input min="0" max="100" id="basicDiscount" type="number" disabled={!isAdmin} value={customer.basicDiscount} onChange={(e) => setCustomer({ ...customer, basicDiscount: +e.target.value })} />
        </div>}
        <div className="form-entry">
            <label>Kundentyp</label>
            <Select isDisabled={customer.id > -1} value={customerTypeOptions.find(o => o.value === customer.type)} options={customerTypeOptions} onChange={(o) => setCustomer({ ...customer, type: o!.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="name">Name Unternehmen</label>
            <input id="name" value={customer.name} onChange={(e) => setCustomer({ ...customer, name: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="taxNr">Steuernummer Kunde</label>
            <input id="taxNr" value={customer.taxNr} onChange={(e) => setCustomer({ ...customer, taxNr: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="taxId">Umsatzsteuer-ID Unternehmen</label>
            <input id="taxId" value={customer.taxId} onChange={(e) => setCustomer({ ...customer, taxId: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="specialContractConditions">Spez. Vertragsbedingungen</label>
            <input id="specialContractConditions" value={customer.specialContractConditions} onChange={(e) => setCustomer({ ...customer, specialContractConditions: e.target.value })} />
        </div>
        <h3>Anschrift Unternehmen</h3>
        <div className="form-entry">
            <label htmlFor="companyStreet">Straße</label>
            <input id="companyStreet" value={customer.companyStreet} onChange={(e) => setCustomer({ ...customer, companyStreet: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="companyStreetNumber">Hausnummer</label>
            <input id="companyStreetNumber" value={customer.companyStreetNumber} onChange={(e) => setCustomer({ ...customer, companyStreetNumber: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="companyPostbox">Postfach</label>
            <input id="companyPostbox" value={customer.companyPostbox} onChange={(e) => setCustomer({ ...customer, companyPostbox: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="companyZipCode">Postleitzahl</label>
            <input id="companyZipCode" value={customer.companyZipCode} onChange={(e) => setCustomer({ ...customer, companyZipCode: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="companyCity">Stadt</label>
            <input id="companyCity" value={customer.companyCity} onChange={(e) => setCustomer({ ...customer, companyCity: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="companyCountry">Land</label>
            <input id="companyCountry" value={customer.companyCountry} onChange={(e) => setCustomer({ ...customer, companyCountry: e.target.value })} />
        </div>
        <h3>Ansprechpartner Kunde</h3>
        <div className="form-entry">
            <label htmlFor="managerEmail">E-Mail</label>
            <input type="email" id="managerEmail" value={user.mail} onChange={(e) => setUser({ ...user, mail: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="userFirstName">Vorname</label>
            <input id="userFirstName" value={user.firstName} onChange={(e) => setUser({ ...user, firstName: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="userLastName">Nachname</label>
            <input id="userLastName" value={user.lastName} onChange={(e) => setUser({ ...user, lastName: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="managerDivision">Abteilung</label>
            <input id="managerDivision" value={customer.managerDivision} onChange={(e) => setCustomer({ ...customer, managerDivision: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="managerStreet">Straße</label>
            <input id="managerStreet" value={customer.managerStreet} onChange={(e) => setCustomer({ ...customer, managerStreet: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="managerStreetNumber">Hausnummer</label>
            <input id="managerStreetNumber" value={customer.managerStreetNumber} onChange={(e) => setCustomer({ ...customer, managerStreetNumber: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="managerPostbox">Postfach</label>
            <input id="managerPostbox" value={customer.managerPostbox} onChange={(e) => setCustomer({ ...customer, managerPostbox: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="managerZipCode">Postleitzahl</label>
            <input id="managerZipCode" value={customer.managerZipCode} onChange={(e) => setCustomer({ ...customer, managerZipCode: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="managerCity">Stadt</label>
            <input id="managerCity" value={customer.managerCity} onChange={(e) => setCustomer({ ...customer, managerCity: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="managerCountry">Land</label>
            <input id="managerCountry" value={customer.managerCountry} onChange={(e) => setCustomer({ ...customer, managerCountry: e.target.value })} />
        </div>
        <h3>Rechnungsanschrift</h3>
        <div className="form-entry">
            <label htmlFor="invoiceCompany">Unternehmen</label>
            <input id="invoiceCompany" value={customer.invoiceCompany} onChange={(e) => setCustomer({ ...customer, invoiceCompany: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="invoiceEmail">E-Mail</label>
            <input type="email" id="invoiceEmail" value={customer.invoiceEmail} onChange={(e) => setCustomer({ ...customer, invoiceEmail: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="invoiceFirstName">Vorname</label>
            <input id="invoiceFirstName" value={customer.invoiceFirstName} onChange={(e) => setCustomer({ ...customer, invoiceFirstName: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="invoiceLastName">Nachname</label>
            <input id="invoiceLastName" value={customer.invoiceLastName} onChange={(e) => setCustomer({ ...customer, invoiceLastName: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="invoiceDivision">Abteilung</label>
            <input id="invoiceDivision" value={customer.invoiceDivision} onChange={(e) => setCustomer({ ...customer, invoiceDivision: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="invoiceStreet">Straße</label>
            <input id="invoiceStreet" value={customer.invoiceStreet} onChange={(e) => setCustomer({ ...customer, invoiceStreet: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="invoiceStreetNumber">Hausnummer</label>
            <input id="invoiceStreetNumber" value={customer.invoiceStreetNumber} onChange={(e) => setCustomer({ ...customer, invoiceStreetNumber: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="invoicePostbox">Postfach</label>
            <input id="invoicePostbox" value={customer.invoicePostbox} onChange={(e) => setCustomer({ ...customer, invoicePostbox: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="invoiceZipCode">Postleitzahl</label>
            <input id="invoiceZipCode" value={customer.invoiceZipCode} onChange={(e) => setCustomer({ ...customer, invoiceZipCode: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="invoiceCity">Stadt</label>
            <input id="invoiceCity" value={customer.invoiceCity} onChange={(e) => setCustomer({ ...customer, invoiceCity: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="invoiceCountry">Land</label>
            <input id="invoiceCountry" value={customer.invoiceCountry} onChange={(e) => setCustomer({ ...customer, invoiceCountry: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="invoiceIban">IBAN</label>
            <input id="invoiceIban" value={customer.invoiceIban} onChange={(e) => setCustomer({ ...customer, invoiceIban: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="invoicePaymentGoal">Zahlungsziel (in Tagen)</label>
            <input id="invoicePaymentGoal" type="number" min="1" value={customer.invoicePaymentGoal} onChange={(e) => setCustomer({ ...customer, invoicePaymentGoal: +e.target.value })} />
        </div>
        <h3>Anschrift Einkauf</h3>
        <div className="form-entry">
            <label htmlFor="purchaseCompany">Unternehmen</label>
            <input id="purchaseCompany" value={customer.purchaseCompany} onChange={(e) => setCustomer({ ...customer, purchaseCompany: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="purchaseDivision">Abteilung</label>
            <input id="purchaseDivision" value={customer.purchaseDivision} onChange={(e) => setCustomer({ ...customer, purchaseDivision: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="purchaseStreet">Straße</label>
            <input id="purchaseStreet" value={customer.purchaseStreet} onChange={(e) => setCustomer({ ...customer, purchaseStreet: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="purchaseStreetNumber">Hausnummer</label>
            <input id="purchaseStreetNumber" value={customer.purchaseStreetNumber} onChange={(e) => setCustomer({ ...customer, purchaseStreetNumber: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="purchasePostbox">Postfach</label>
            <input id="purchasePostbox" value={customer.purchasePostbox} onChange={(e) => setCustomer({ ...customer, purchasePostbox: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="purchaseZipCode">Postleitzahl</label>
            <input id="purchaseZipCode" value={customer.purchaseZipCode} onChange={(e) => setCustomer({ ...customer, purchaseZipCode: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="purchaseCity">Stadt</label>
            <input id="purchaseCity" value={customer.purchaseCity} onChange={(e) => setCustomer({ ...customer, purchaseCity: e.target.value })} />
        </div>
        <div className="form-entry">
            <label htmlFor="purchaseCountry">Land</label>
            <input id="purchaseCountry" value={customer.purchaseCountry} onChange={(e) => setCustomer({ ...customer, purchaseCountry: e.target.value })} />
        </div>
    </div>
}