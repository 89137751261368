import { useEffect, useState } from "react";
import { GraphImage, Project } from "../../../dataTypes/generated";
import { CircalindApi } from "../../../circalindApi";
import { useSelectedProject } from "../../../../utils/Hooks";
import { useNavigate } from "react-router";
import { renderPhaseStateSVG } from "../../Projects";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faArrowRight, faCopy, faHouse, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import strategyImage_back from "../../../../resources/circa/strategy_project.svg"
import circalindPersonalImage from "../../../../resources/circa/personal_project.svg";
import EmptyProjectImage from "../../../../resources/circa/cockpit/project-empty.png";

import "./OneView.scss";
import { SynchroCircle } from "../../phases/prePhase/synchro/SynchroCircle";
import { defaultConfig } from "../../phases/prePhase/synchro/Config";
import { renderStatusCircle } from "./ProjectFamilyOverview";
import { getTodoColor } from "../../../practical/transfer/NewTodo";
import { PhaseDiagram } from "../../competence/PhaseDiagram";
import { getCurrentUserId } from "../../../../utils/Auth";
import { Dialog } from "../../../basicComponents/dialogs/Dialog";
import { sendOlert } from "../../../../olert/Olert";

export interface ProjectAction {
    icon: IconDefinition;
    tooltip: string;
    action: (project: Project) => Promise<void>;
}


export const OneView = () => {
    const [projects, setProjects] = useState<Array<Project>>([]);
    const [, setProject] = useSelectedProject();
    const [projectId, setProjectId] = useState<number>(-1);
    const [showCloneDialog, setShowCloneDialog] = useState<boolean>(false);
    const [currentProjectGraph, setCurrentProjectGraph] = useState<GraphImage | null>(null);
    const userId = getCurrentUserId("token-circalind");
    const navigate = useNavigate();

    const loadProjects = async () => {
        setProjects((await CircalindApi.getProjects()).filter(p => p.ownerId === getCurrentUserId("token-circalind")));
    };

    useEffect(() => {
        loadProjects();
    }, []);

    useEffect(() => {
        const loadGraph = async () => {
            if (userId) {
                setCurrentProjectGraph(await CircalindApi.getGraphImage(userId, projectId, userId, 1, "", "PHASE"));
            }
        }

        loadGraph();
    }, [projectId, userId]);

    const createDefaultActions = (reload: () => Promise<void>): Array<ProjectAction> => {
        return [
            { icon: faArrowRight, tooltip: "Schnellzugriff", action: async (project) => { setProject(project.id); navigate("../dashboard") } },
            { icon: faHouse, tooltip: "Basis", action: async (project) => { setProject(project.id); navigate("../base/start") } },
            { icon: faCopy, tooltip: "Klonen", action: async (project) => { setProjectId(project.id); setShowCloneDialog(true); } },
            { icon: faTrashAlt, tooltip: "Löschen", action: async (project) => { await CircalindApi.deleteProject(project.id); await reload(); } },
        ];
    };

    const actualProject = projects.find(p => p.id === projectId)

    return <div className="one-view">
        <div className="projects">
            <h2>Projektübersicht</h2>
            <div onClick={() => navigate("/circalind/regular/dashboard/base/start")} className="card clickable base"><img src={EmptyProjectImage} alt="" />Projekt-Basis aktualisieren</div>
            <ProjectOverview setProjectId={setProjectId} projects={projects} showFilter={true} projectActions={createDefaultActions(loadProjects)} />
        </div>
        <div className="personal-competences">
            <h2>Synchronimeter</h2>
            <div className="content">
                <div className="synchro">
                    <h3>SOLL</h3>
                    <h3>IST</h3>
                    <svg viewBox="-15 -15 30 30" width="200" height="200">
                        {actualProject && <SynchroCircle showIs={false} project={actualProject} config={{ ...defaultConfig, showText: false, valueWidth: 1.5, projectRadius: 8 }} onClick={() => { }} onDragStart={() => { }} onDragEnd={() => { }} onSetValue={() => { }} />}
                    </svg>
                    <svg viewBox="-15 -15 30 30" width="200" height="200">
                        {actualProject && <SynchroCircle showIs={true} project={actualProject} config={{ ...defaultConfig, showText: false, valueWidth: 1.5, projectRadius: 8 }} onClick={() => { }} onDragStart={() => { }} onDragEnd={() => { }} onSetValue={() => { }} />}
                    </svg>
                </div>
            </div>
        </div>
        <div className="project-competences">
            <h2>Projekt-Profil</h2>
            <div className="content">
                {currentProjectGraph && <PhaseDiagram showTitle={false} edit={false} value={currentProjectGraph} updateGraphImage={() => { }} />}
            </div>
        </div>
        {projectId && <Dialog className={""} component={<CloneProjectDialog givenProject={projects.find(p => p.id === projectId)!} reload={async () => { setShowCloneDialog(false); loadProjects() }} />} show={showCloneDialog} toogle={() => setShowCloneDialog(false)} />}
    </div>
};

export const CloneProjectDialog = ({ givenProject, reload }: { givenProject: Project, reload: () => void }) => {
    const [project, setProject] = useState<Project>({ ...givenProject, name: "" });

    const cloneProject = async () => {
        const res = await CircalindApi.cloneProject(project);

        if (res > 0) {
            sendOlert("Erfolgreich geklont", "Projekt erfolgreich geklont", "Success");
        } else {
            sendOlert("Fehler beim Klonen", "Fehler beim Klonen des Projekts", "Error");
        }

        reload();
    };

    return <div className="clone-project-dialog">
        <label htmlFor="name">Neuer Name: </label>
        <input id="name" value={project.name} onChange={(e) => setProject({ ...project, name: e.target.value })} />
        <button onClick={cloneProject}>Erstellen</button>
    </div>;
};

export const ProjectOverview = ({ projects, projectActions, showFilter, setProjectId }: { projects: Array<Project>, projectActions: Array<ProjectAction>, showFilter: boolean, setProjectId: (id: number) => void }) => {
    const temporal = [{ label: "Aktuell", value: 0 }, { label: "Mittelfristig", value: 1 }, { label: "Langfristig", value: 2 }];
    const importance = ["A", "B", "C"];

    const [selectedTemporal, setSelectedTemporal] = useState<Array<number>>(temporal.map(t => t.value));
    const [selectedImportance, setSelectedImportance] = useState<Array<string>>([...importance]);

    const renderProject = (p: Project) => {
        const image = p.type === "PERSONAL" ? circalindPersonalImage : strategyImage_back;
        return <div onClick={() => setProjectId(p.id)} key={p.id} className="project">
            <img src={image} alt="" />
            <span className="name"><strong>{p.importance}</strong>{renderStatusCircle(getTodoColor(p.status))} {p.name}</span>
            <span style={{ width: "250px" }}>{renderPhaseStateSVG(p.phase)}</span>
            <span className="icons">
                {projectActions.map((pa, index) => <FontAwesomeIcon key={index} title={pa.tooltip} icon={pa.icon} onClick={() => pa.action(p)} />)}
            </span>
        </div>;
    };

    return <div className="project-overview">
        {showFilter &&
            <>
                <h3>Zeithorizont</h3>
                <div className="selection">
                    {temporal.map((t) => <span
                        key={t.value}
                        onClick={() => setSelectedTemporal(!selectedTemporal.includes(t.value) ? [...selectedTemporal, t.value] : selectedTemporal.filter((v) => v !== t.value))}
                        className={selectedTemporal.includes(t.value) ? "active" : ""}>
                        {t.label}
                    </span>)}
                </div>
                <h3>Priorität</h3>
                <div className="selection">
                    {importance.map((i) => <span
                        key={i}
                        onClick={() => setSelectedImportance(!selectedImportance.includes(i) ? [...selectedImportance, i] : selectedImportance.filter((v) => v !== i))}
                        className={selectedImportance.includes(i) ? "active" : ""}>
                        {i}
                    </span>)}
                </div>
            </>
        }
        <div className="project-list">
            {temporal.filter(t => selectedTemporal.includes(t.value)).map(t => {
                return <div key={t.value}>
                    <h3>{t.label}</h3>
                    {projects.filter(p => p.temporal === t.value && selectedTemporal.includes(p.temporal) && selectedImportance.includes(p.importance)).map(renderProject)}
                </div>;
            })}
        </div>
    </div>
};