import { useNavigate } from "react-router";
import { OrderOverview } from "../services/OrderOverview";

import "./AdminOrderOverview.scss";

export const AdminOrderOverview = ({showTypeSelection}: {showTypeSelection: boolean}) => {
    const navigate = useNavigate();

    return <div className="admin-order-overview">
        <h2>Auswertungen Bestellungen {showTypeSelection ? "pro Kunden-Typ" : "pro Kunde"}</h2>
        <div className="right">
            <button onClick={() => navigate("../overview")}>Zurück</button>
        </div>
        <OrderOverview isAdmin={true} showTypeSelection={showTypeSelection} />
    </div>
};