import { CircalindApi } from "../../circalind/circalindApi";
import { convertAndDownload } from "../../invoice/utils/Util";
import { sendOlert } from "../../olert/Olert";
import { useEffect, useState } from "react";
import { flipArrayEntry } from "../../circalind/projects/compass/cockpit/CockpitOverview";
import { useNavigate } from "react-router";

import toolboxImage from "../../resources/circa/toolbox.svg";
import qualityManagementImage from "../../resources/circa/quality.png";
import leadingDocumentationImage from "../../resources/circa/leadingDocumentation.png";
import faqImage from "../../resources/circa/faq.png";
import { ProjectSelect } from "../dialogs/ProjectSelect";
import { Group, Project, User } from "../../circalind/dataTypes/generated";

import "./LeadingDocumentation.scss";
import { getCurrentUserId } from "../../utils/Auth";
import { UserSelect } from "../dialogs/UserSelect";

export const LeadingDocumentation = () => {
    const navigate = useNavigate();
    const [showFront, setShowFront] = useState<Array<boolean>>([true, true, true, true]);

    return <div className="leading-documentation">
        <div onClick={() => setShowFront(flipArrayEntry(0, showFront))} className={`card content-card ${showFront[0] ? 'show-front' : 'show-back'}`}>
            <div className="front">
                <h3>Dokumentation</h3>
                <div onClick={() => navigate("../excel")} className="image-wrapper card clickable">
                    <img src={leadingDocumentationImage} alt="" />
                </div>
            </div>
            <div className="back">
            </div>
        </div>
        <div onClick={() => setShowFront(flipArrayEntry(1, showFront))} className={`card content-card ${showFront[1] ? 'show-front' : 'show-back'}`}>
            <div className="front">
                <h3>FAQ-Liste</h3>
                <div onClick={() => navigate("../faq")} className="image-wrapper card clickable">
                    <img src={faqImage} alt="" />
                </div>
            </div>
            <div className="back">
            </div>
        </div>
        <div onClick={() => setShowFront(flipArrayEntry(2, showFront))} className={`card content-card ${showFront[2] ? 'show-front' : 'show-back'}`}>
            <div className="front">
                <h3>IT-Koordination</h3>
                <div onClick={() => navigate("../../toolbox")} className="image-wrapper card clickable">
                    <img src={toolboxImage} alt="" />
                </div>
            </div>
            <div className="back">
            </div>
        </div>
        <div onClick={() => setShowFront(flipArrayEntry(3, showFront))} className={`card content-card ${showFront[3] ? 'show-front' : 'show-back'}`}>
            <div className="front">
                <h3>QM-Koordination</h3>
                <div onClick={() => navigate("../../quality")} className="image-wrapper card clickable">
                    <img src={qualityManagementImage} alt="" />
                </div>
            </div>
            <div className="back">
            </div>
        </div>
    </div>;
}

export const ExcelList = () => {
    const [projectId, setProjectId] = useState<number>(-1);
    const [userId, setUserId] = useState<number>(-1);
    const [projects, setProjects] = useState<Array<Project>>([]);
    const [users, setUsers] = useState<Array<User>>([]);
    const [group, setGroup] = useState<Group | null>(null);
    const currentUserId = getCurrentUserId("token-circalind");

    useEffect(() => {
        const loadProjects = async () => {
            setProjects(await CircalindApi.getProjects())
        };

        const loadUsers = async () => {
            setUsers((await CircalindApi.getAllUsersOfMyGroup()).filter(u => u.roles.includes("DECIDER")));
        };

        const loadGroup = async () => {
            setGroup(await CircalindApi.getMyGroup());
        };

        loadUsers();
        loadProjects();
        loadGroup();
    }, []);

    useEffect(() => {
        setProjectId(-1);
    }, [userId]);

    const downloadExcelProject = async () => {
        let res = await CircalindApi.getProjectExcel(projectId);
        if (res) {
            convertAndDownload(res.data, res.name);
        } else {
            sendOlert("Error", "Es konnte keine Excel-tabelle erstellt werden für dieses Projekt", "Error");
        }
    };

    const downloadExcelGroup = async () => {
        let res = await CircalindApi.getMyGroupExcel();
        if (res) {
            convertAndDownload(res.data, res.name);
        } else {
            sendOlert("Error", "Es konnte keine Excel-tabelle erstellt werden", "Error");
        }
    };

    const uploadToDesk = async () => {
        let res = await CircalindApi.getMyGroupExcel();
        if (res && group && currentUserId) {
            const result = await CircalindApi.addDeskItem({
                id: -1,
                userId: currentUserId,
                projectId: -1,
                groupId: group.id,
                phase: group.managementPhase,
                type: "DOCUMENTS",
                name: res.name,
                filename: res.name,
                base64Data: "base64," + res.data,
                tags: [],
                date: null,
                source: window.location.href
            });

            if (result) {
                sendOlert("Excel", "Excel-Tabelle in Schreibtisch übertragen", "Success");
            }
        } else {
            sendOlert("Fehler", "Es konnte keine Excel-Tabelle erstellt werden", "Error");
        }
    };

    return <div className="card">
        <h2>Export der Nutzer und Berechtigungen</h2>
        <div className="description">
            Laden Sie sich hier eine projektspezifische Excel-Übersicht aller
            Nutzer des Projekts inklusive Rollen und Berechtigungen herunter.
        </div>
        <div className="download-container">
            <div className="selection">
                <strong>Entscheider</strong>
                <strong>Projekt</strong>
                <UserSelect useName={true} valueUserId={userId} users={users} onSelect={u => setUserId(u ? u.id : -1)} />
                <ProjectSelect projects={projects.filter(p => p.ownerId === userId)} onSelect={(e) => setProjectId(e ? e.id : -1)} valueProjectId={projectId} />
            </div>
            <button disabled={projectId === -1} onClick={downloadExcelProject}>Download für Projekt</button>
            <button onClick={downloadExcelGroup}>Download Übergreifend</button>
            <button onClick={uploadToDesk}>Daten an Schreibtisch senden</button>
        </div>
    </div>
}