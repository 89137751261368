import { ReactElement, useEffect, useState } from "react";

import './impressum.scss'
import { LandingPageNavHeaders, useHeaderContext } from "./circalind/page/header/Header";
import hamburgLogo from './resources/circa/hamburg.png';
import fingerImage from "./resources/circa/fingerprint.svg";
import lindnerTeamLogo from "./resources/circa/lindnerteam.webp";
import hummingbirdLogo from "./resources/circa/hummingbird_logo.png";
import { SettingsInvoice } from "./circalind/dataTypes/generated";
import { CircalindApi } from "./circalind/circalindApi";


export const Impressum = (): ReactElement => {
    const { updateHeader } = useHeaderContext();
    const [invoiceSettings, setInvoiceSettings] = useState<SettingsInvoice | null>(null);

    const [logo, setLogo] = useState<string>();
    const [servicesLogo, setServicesLogo] = useState<string>();

    useEffect(() => {
        const loadLogo = async () => {
            setServicesLogo(await (await fetch(process.env.REACT_APP_API_URL + "/invoice/settings/logo/services/get")).text());
            setLogo(await (await fetch(process.env.REACT_APP_API_URL + "/invoice/settings/logo/get")).text());
        };

        loadLogo();
    }, []);

    useEffect(() => {
        updateHeader(LandingPageNavHeaders)
    }, [updateHeader])

    useEffect(() => {
        const loadSettings = async () => {
            setInvoiceSettings(await CircalindApi.getInvoiceSettings());
        }

        loadSettings();
    }, []);

    return <div className="impressum">

        <h1>Impressum</h1>
        {invoiceSettings && <div className="general-info">
            <div>
                <img style={{ width: "300px" }} src={logo} alt="circalind" />

                <ul className="info">
                    <li><strong>circalind ist ein digitales tool-set</strong></li>
                    <li><strong>{invoiceSettings.company}</strong></li>
                    <li><strong>{invoiceSettings.fullName}</strong></li>
                </ul>
                <ul className="info">
                    <li>Alle Urheberrechte und Produktrechte sind Eigentum und obliegen:</li>
                    <li>{invoiceSettings.fullName}</li>
                    <li>{invoiceSettings.company}</li>
                    <li>{invoiceSettings.address}</li>
                    <li>{invoiceSettings.zipCode} {invoiceSettings.city}</li>
                </ul>
                <ul className="info">
                    <li>Telefon: {invoiceSettings.phone}</li>
                    <li>E-Mail: <a href={`mailto:${invoiceSettings.email}`}>{invoiceSettings.email}</a></li>
                </ul>
            </div>
            <div className="links">
                <ul className="info">
                    <li>
                        <span className="fingerprint">
                            <img src={fingerImage} alt="fingerprint" />
                            <span>Gender-Hinweis</span>
                        </span>
                    </li>
                    <li><a className="link" href="/circalind/dataprivacy">Datenschutz</a></li>
                    <li><a className="link" href="/circalind/impressum">Impressum</a></li>
                    <li>AGBs</li>
                    <li><a href="https://www.linkedin.com/in/doris-lindner-00426168/">LinkedIn</a></li>
                    <li>Änderungen vorbehalten</li>
                </ul>
            </div>
        </div>}
        <div className="realization">
            <div><h4>Corporate Design, Icons und Grafiken gestaltet <br /> von Frau Carolin Düngen</h4><a href="https://www.bowandhummingbird.com/"><img src={hummingbirdLogo} alt="Bow and Hummingbird" /></a></div>
        </div>
        {invoiceSettings && <div className="services">
            <img style={{ width: "200px" }} src={servicesLogo} alt="circalind services" />

            <ul className="info">
                <li><strong>circalind services ist der digitale provider</strong></li>
                <li>für login und  Nutzung von circalind</li>
                <li>im Kontext von Leistungen: {invoiceSettings?.fullName} -  lindnerteam</li>
                <li>Beratung - Coaching - Projekt-Partner</li>
            </ul>

            <div className="lindnerteam">
                <div><strong>lindnerteam ist: </strong></div>
                <div className="lindnerteam-list">
                    <img className="hamburg-logo" src={hamburgLogo} alt="Hamburg" />
                    <div className="lindnerteam-content-list">
                        <ul>
                            <li>zertifiziertes Umweltpartner-Unternehmen der Freien und Hansestadt Hamburg</li>
                            <li>unterstützt im eigenen unternehmerischen Handel Klima- &amp; Umweltziele</li>
                            <li>geprüft von der Behörde für Umwelt, Klima, Energie und Agrarwirtschaft</li>
                            <li>in Zusammenarbeit mit der Handelskammer Hamburg</li>
                        </ul>
                        <img className="lindnerteam-logo" src={lindnerTeamLogo} alt="Lindnerteam" />
                    </div>
                </div>
            </div>
        </div>}
    </div>
}
