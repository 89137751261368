import Logo from '../../resources/Logo-single.svg';
import puzzleImage from "../../resources/circa/info/puzzle.png";
import circalindManagementImage from "../../resources/circa/circalind_managing.svg";
import circalindLeadershipImage from "../../resources/circa/leadership_teamwork.svg";

import "./RoomChange.scss";
import { getUserRoles } from '../../utils/Auth';
import { RoleType } from '../dataTypes/generated';


export const RoomChange = () => {
    return <div className="change-room-card">
        <div />
        <ChangeRoomCard />
    </div>;
};

const ChangeRoomCard = () => {
    const roles = getUserRoles("token-circalind");

    const leadingRoles: Array<RoleType> = ["COORDINATOR", "CO_COORDINATOR", "IT_COORDINATOR", "QM_COORDINATOR", "CO_MANAGER_DRIVERS", "CO_MANAGER_PROJECT", "IMPULSE_PARTNER", "SUPPORT_PARTNER", "CONTENT_MANAGER"];

    const activeDecider = roles.includes("DECIDER");
    const activeLeading = roles.filter(r => leadingRoles.includes(r)).length > 0;

    return <div className="card">
        <h2>Raum wechseln</h2>
        <div className="card-grid">
            <div onClick={() => window.location.href = "/circalind/services/start"} className="card with-image">
                <img src={puzzleImage} alt="Notizen / Dokumente" />
                <div className="text-center">Circalind Services</div>
            </div>
            {activeDecider && <div onClick={() => window.location.href = "/circalind/regular/start"} className="card with-image">
                <img src={Logo} alt="Entscheiderbereich" />
                <div className="text-center">Entscheiderbereich</div>
            </div>}
            {activeLeading && <div onClick={() => window.location.href = "/circalind/leading/start"} className="card with-image">
                <img src={circalindLeadershipImage} alt="Führung Und Zusammenarbeit" />
                <div className="text-center">Führung Und Zusammenarbeit</div>
            </div>}
            {activeDecider && <div onClick={() => window.location.href = "/circalind/managing"} className="card with-image">
                <img src={circalindManagementImage} alt="circalind managen" />
                <div className="text-center">circalind managen</div>
            </div>}
        </div>
    </div>;
}
