import { useCallback, useRef, useState } from "react";

export const useRelativeMousePos = (transformX: (x: number) => number, transformY: (x: number) => number): [[number, number], React.RefObject<any>, (ev: React.MouseEvent) => void] => {
    const ref = useRef<any>(null);
    const [pos, setPos] = useState<[number, number]>([0, 0]);
    const onMouseMove = useCallback((ev: React.MouseEvent) => {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            setPos([transformX((ev.clientX - rect.left) / rect.width), transformY((ev.clientY - rect.top) / rect.height)]);
        }
    }, [transformX, transformY]);

    return [pos, ref, onMouseMove];
}
