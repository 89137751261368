import { OrderOverview } from "./OrderOverview"

import "./CustomerOrderView.scss";
import { useNavigate } from "react-router";

export const CustomerOrderOverview = () => {
    const navigate = useNavigate();

    return <div className="customer-order-overview">
        <h2>Auswertungen Bestellungen</h2>
        <div className="right">
            <button onClick={() => navigate("/circalind/services/choose-license")}>Zurück</button>
        </div>
        <OrderOverview isAdmin={false} showTypeSelection={false} />
    </div>;
}