import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./CompetencePage.scss";
import { CompetenceIndividualCard, CompetenceTeamCard } from "../competence/CompetenceOverview";

export const CompetencePage = ({ onNext, onBack }: { onNext: () => Promise<void>, onBack: () => Promise<void> }) => {
    return <div className="competence-interjection-page">
        <h2><strong>Instrumente zum Spezifizieren & Navigieren
            für Laufendes Lernen & Vorankommen</strong></h2>
        <div className="competence-links">
            <CompetenceIndividualCard navigation="../../competence/individual" back={false} />
            <CompetenceTeamCard navigation="../../competence/team" back={false} />
        </div>
        <div className="navigation">
            <button onClick={() => onBack!()}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button onClick={() => onNext!()}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>;
};