import "./ProjectDashboard.scss";

import managementImage from "../../../../resources/circa/compass/ManagementCompass.png";
import spurImage from "../../../../resources/circa/compass/SpurWechsel.png";
import teamImage from "../../../../resources/circa/competence/team.png";
import syncImage from "../../../../resources/circa/synchro.png";
import radarImage from "../../../../resources/circa/radar.jpg";
import timeHorizonImage from "../../../../resources/circa/time-horizon.png";
import resumeImage from "../../../../resources/circa/resume.png";
import { useNavigate } from "react-router";

export const ProjectDashboard = () => {
    const navigate = useNavigate();

    return <div className="project-dashboard">
        <div>
            <div className="card header-card">
                <h3>Projekt-Steuerung</h3>
                <div>
                    <div onClick={() => navigate("/circalind/regular/phase/compass/manage")} className="card clickable">
                        <div className="img-card card">
                            <img src={managementImage} alt="Management"></img>
                        </div>
                        <h4>Kompass: Management</h4>
                    </div>
                    <div onClick={() => navigate("/circalind/regular/phase/compass/lane")} className="card clickable">
                        <div className="img-card card">
                            <img src={spurImage} alt="Spur-Wechsel"></img>
                        </div>
                        <h4>Kompass: Spur-Wechsel / Veränderung</h4>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div className="card header-card">
                <h3>Steuerungsinstrumente</h3>
                <div>
                    <div onClick={() => navigate("/circalind/regular/dashboard/base/synchro")} className="card clickable">
                        <div className="img-card card">
                            <img src={syncImage} alt="Synchronimeter"></img>
                        </div>
                        <h4>Projekt-Profil & Synchron-Status</h4>
                    </div>
                    <div onClick={() => navigate("/circalind/regular/dashboard/radar")} className="card clickable">
                        <div className="img-card card">
                            <img src={radarImage} alt="Radar"></img>
                        </div>
                        <h4>Projekt-Radar <br /> Treiber & Herausforderungen</h4>
                    </div>
                    <div onClick={() => navigate("/circalind/regular/postPhase")} className="card clickable">
                        <div className="img-card card">
                            <img src={resumeImage} alt="Resümee"></img>
                        </div>
                        <h4>Resümee</h4>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div className="card header-card">
                <h3>Steuerungsinstrumente</h3>
                <div>
                    <div onClick={() => navigate("/circalind/regular/dashboard/base/time")} className="card clickable">
                        <div className="img-card card">
                            <img src={timeHorizonImage} alt="Synchronimeter"></img>
                        </div>
                        <h4>Zeit-Horizont definieren</h4>
                    </div>
                    <div onClick={() => navigate("/circalind/regular/checkups/matching/project/phases")} className="card clickable">
                        <div className="img-card card">
                            <img src={teamImage} alt="Projekt-Expertise"></img>
                        </div>
                        <h4>Projekt-Entwicklung</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
};