import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useState } from "react";
import { Question, TableData } from "../dataTypes/generated";
import { TableAnswerCell, useAnswer } from "../hooks/useAnswer";
import { CompassTable } from "../projects/compass/table/CompassTable";

import './questionTable.scss'
import { usePassword } from "../hooks/usePassword";

export interface QuestionTableProps {
    pages: QuestionTablePage[];
    edit: boolean;
    onUpdate: (q: Question, atIndex: number) => void;
    selected: number;
    onSelected: (atIndex: number) => void;
    onSave: () => void;
    projectId: number;
    onNext: () => Promise<void>;
    onBack: () => Promise<void>;
}

export interface QuestionTablePage {
    logo?: string;
    title: string;
    question: Question;
}

export const QuestionTable = (props: QuestionTableProps): ReactElement => {

    const [editMode, ] = useState<boolean>(props.edit);
    const [, setCellTypeChanged] = useState<boolean>(false);


    const password = usePassword();
    const [answer, setAnswer, saveAnswer] = useAnswer(props.pages[props.selected].question.id, props.pages[props.selected].question.phase, props.projectId, password);

    const onTableUpdate = (data: TableData, cellTypeChanged?: boolean) => {
        let newQ = { ...props.pages[props.selected].question };
        newQ.table = data;
        props.onUpdate(newQ, props.selected);
        if (cellTypeChanged) {
            setCellTypeChanged(true);
        }
    }

    const onTableCellsAnswerd = (newCells: TableAnswerCell[]) => {
        setAnswer({ ...answer, cells: newCells })
    }

    const renderNavItems = () => {
        return props.pages.map((p, i) => {
            return <div key={i}
                className={"nav-item " + ((props.selected === i) ? "active" : "")}
                onClick={() => { saveAnswer(password); props.onSelected(i) }}
            >
                {p.logo ? <img src={p.logo} alt="" /> : <div className="empty" />}
            </div>
        })
    }

    const onNavNext = async () => {
        await saveAnswer(password);
        props.onNext()
    }

    const onNavBack = async () => {
        await saveAnswer(password);
        props.onBack()
    }

    return <div className="question-table">
        <div className="title">
            <h2>{props.pages[props.selected].title}</h2>
        </div>
        <div className="main">
            <div className="question-nav">
                {renderNavItems()}
            </div>
            <div className={"question-table" + (editMode ? "-edit" : "")}>
                <CompassTable edit={editMode} onUpdate={onTableUpdate} data={props.pages[props.selected].question.table} answers={answer.cells} onCellAnswerd={onTableCellsAnswerd} />
            </div>
        </div>
        <div className={"navigation"}>
            <button onClick={() => onNavBack()}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button onClick={() => props.onSave()}>Aktuelle Seite Speichern</button>
            <button onClick={() => onNavNext()}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>
}