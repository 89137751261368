import { ReactElement } from "react";
import Select from 'react-select'
import { Customer } from "../../../circalind/dataTypes/generated";

interface SelectCustomerItem {
    value: Customer,
    label: string
}

export interface SelectCustomerProps {
    customerId: number;
    onChange: (customer: Customer) => void;
    isDissabled?: boolean;
    customers: Array<Customer>;
}

export const SelectCustomer = (props: SelectCustomerProps): ReactElement => {
    const createOption = (c: Customer): SelectCustomerItem => {
        return { value: c, label: `K-${c.id} ${c.id} ${c.name} ${c.name}` };
    }
    const options: SelectCustomerItem[] = props.customers.map(c => createOption(c));

    const selectedText = (): SelectCustomerItem | null => {
        const found = props.customers.find(c => c.id === props.customerId);
        if (found) {
            return createOption(found);
        }
        return null;
    }

    return <Select value={selectedText()}
        isDisabled={props.isDissabled}
        isSearchable={true}
        isClearable={true}
        placeholder={"Bitte Kunde wählen"}
        className={"reaxt-select-container" + (props.customerId === -1 ? " invalid" : "")}
        classNamePrefix={"react-select"}
        noOptionsMessage={({ inputValue }) => !inputValue ? "" : "No results found"}
        options={options}
        onChange={
            (e) => {
                if (e) {
                    props.onChange(e.value);
                }
            }
        } />
}