import { ReactElement } from "react";

export type InfoSubArea = "settings_and_staffing"
    | "it_management"
    | "quality_management"
    | "documentation"
    | "information_and_tips"
    | "project_desk"
    | "communication_and_interaction"
    | "change_room"
    | "planning"
    | "construction"
    | "simulation"
    | "governance"
    | "practice_room"
    | "cockpit"
    | "phases"
    | "skills"
    | "communication"
    | "impulses"
    | "services"
    | "checkups"
    ;

export const subAreaInfoPathMatchers: { [key in InfoSubArea]: (path: string) => boolean } = {
    settings_and_staffing: (path: string) => path.startsWith("/circalind/leading/staffing"),
    it_management: (path: string) => path.startsWith("/circalind/leading/toolbox"),
    quality_management: (path: string) => path.startsWith("/circalind/leading/quality"),
    documentation: (path: string) => path.startsWith("/circalind/leading/documentation"),
    information_and_tips: () => false,
    project_desk: () => false,
    communication_and_interaction: () => false,
    change_room: (path: string) => path.startsWith("/circalind/regular/functional") || path.startsWith("/circalind/leading/functional"),
    planning: (path: string) => path.startsWith("/circalind/leading/collaboration/planning"),
    construction: (path: string) => path.startsWith("/circalind/leading/collaboration/construction"),
    simulation: (path: string) => path.startsWith("/circalind/leading/collaboration/simulation"),
    governance: (path: string) => path.startsWith("/circalind/regular/governance"),
    practice_room: () => false,
    cockpit: () => false,
    phases: () => false,
    skills: () => false,
    communication: () => false,
    impulses: () => false,
    services: () => false,
    checkups: () => false,
};

const ThreeColumn = ({ planning, construction, simulation }: { planning: string, construction: string, simulation: string }) => {
    return <div className="three-column-layout">
        <div className="planning">{planning}</div>
        <div className="construction">{construction}</div>
        <div className="simulation">{simulation}</div>
    </div>
}
export const infoSubAreaFallback: { [key in InfoSubArea]: ReactElement } = {
    settings_and_staffing: <ThreeColumn
        planning={"Zum Orchestrieren des passgenauen Rollen-Zusammenwirkens für ein individualisiertes leadership-support-system"}
        construction={"Zur Unterstützung der Governance insbesondere im flexiblen vielfältigen Projekt-Kontext"}
        simulation={"Die Einsatzsteuerung von circalind ist in 3 Level aufgebaut:\n\n 1. Für das setting & staffing  der Einsatzverantwortung & Steuerung = Rollenvergabe von circalind\n 2. Für das Setting & Staffing (= wer mit welchem Projekt welche Räume als „circalind-Konsument“ nutzen\n 3. Für das „Setting & Staffing“ wer gibt wem zu welchem seiner Projekte Content-Input-Geber sein bzw. Prüfer\n"} />,

    it_management: <ThreeColumn
        planning={"In Zustimmung mit \n- IT-Schnittstellenverantwortlichen/ -Koordinatoren \n- Verantwortliche für IT-Sicherheit und Datenschutz\n\nPassgenau zur\nIT-Umgebung"}
        construction={"Als ein Beitrag zur Governance insbesondere im digitalen Webapp-Kontext"}
        simulation={"circalind mit weiteren Tools ergänzen"} />,

    quality_management: <ThreeColumn
        planning={"Dokumentation sichert Transparenz über Einsatz von circalind und Rollen-Vergabe, mit den jeweilen Berechtigungen"}
        construction={"Als ein Beitrag zur Governance insbesondere im digitalen webapp  Kontext"}
        simulation={"Stets den Überblick in einem modularen digitalen-toolset haben"} />,

    documentation: <ThreeColumn
        planning={"Dokumentation sichert Transparenz über Einsatz von circalind und Rollen-Vergabe, mit den jeweilen Berechtigungen"}
        construction={"Als ein Beitrag zur Governance insbesondere im digitalen webapp  Kontext"}
        simulation={"Stets den Überblick in einem modularen digitalen-toolset haben"} />,

    information_and_tips: <div>Fallback Content für Information and Tips</div>,

    project_desk: <ThreeColumn
        planning={"Hier kann der Projekt-Schreibtisch zur Verlinkung zu Dokumenten genutzt werden, so dass Wissensicherung je Einsatz von circalind praxisbezogen unterstützt wird."}
        construction={"Freigabe: Personen, mit diesen Rollen können den Projekt-Schreibtisch für sich nutzen."}
        simulation={"Für eigene Präsentationen können hier Dokumente per Link zugeordnet werden, so dass sie einsatzbezogen parallel erstellt und wieder aufgerufen werden."} />,

    communication_and_interaction: <ThreeColumn
        planning={"Hier kann mit seinen Beratern, Coaches und Trainern direkt prozessbegleitend kommuniziert werden. \n\nFeedback kann gegenseitig angefragt und gegeben werden."}
        construction={"Hinweis:"}
        simulation={"Wissenstransfer kann gezielt angefragt und gegeben werden: hier anfragen und über die Impuls-Funktion erhalten. Zur Wissenssicherung kann der Projektschreibtisch genutzt Verlinkung genutzt werden."} />,

    change_room: <ThreeColumn
        planning={"Zum Autorisieren von Personen und ihrerer Rollen hier direkt zu circalind services wechseln"}
        construction={"Zum Manager - Ergänzen des Portfolios: hier direkt zu circalind services wechseln"}
        simulation={"Zum Autorisieren für individualiserte Nutzung: hier direkt zu circalind services wechseln"} />,

    planning: <ThreeColumn
        planning={"circalind Einsatz-Planung"}
        construction={"Planungs-Prozess erfolgt in 5 Stufen"}
        simulation={"Beratung begleitet unternehmensspezifischen Einsatz und Planungs-Prozess"} />,

    construction: <ThreeColumn
        planning={"circalind Einsatz-Ziele, Rahmenbedingungen und Ressourcen  werden hier im Beratungs-Prozess konkret aufeinander abgestimmt"}
        construction={"Projekt-Verantwortliche & weitere Akteure werden durch Support unterstützt: \nKern-Team & Fach-Verantwortliche \nContent-Input-Geber\n Prüfer"}
        simulation={"Ein strukturiertes Vorgehen von 5 Stufen unterstützt effizienten Aufbau"} />,

    simulation: <ThreeColumn
        planning={"Mit dem Modus: RealLabor können „Setting & Staffing-Rollen-Konstellationen“ simuliert und reflektiert werden.\n\n Interessant im Rahmen:\n Aufbau neuer Geschäftszweige/ New Business\n Management -& Organization Development OrganisationsentwicklungLeadership-trainings-Programmen"}
        construction={"Das 5 stufige Vorgehen und einem Beratungsprozess mit Schwerpunkten auf:\n Kontinuierliches Qualitätsmanagement und „Laufendes Lernen“ unterstützt Einsatzqualität und Wirksamkeit von circalind."}
        simulation={"Der Modus vom RealLabor bietet Möglichkeiten speziell zum Erproben und Simulieren, indem er entsprechend der geltenden  IT-Sicherheitsvorschriften und Datenschutzverordnung im extra Bereich verortet wird."} />,

    governance: <ThreeColumn
        planning={"circalind Entscheider erhalten auf diesem WEege stets aktuelle Informationen, die passgenau für deren Rolle/Funktion bedeutsam sind."}
        construction={"Hier können individualisiert Personen und Zielgruppen den jeweiligen Unternehmenskontext aus Ihrer Rolle in der Governance-Funktion Informationen eingeben und pflegen."}
        simulation={"Willkommen im Raum: Unternehmenskontext"} />,

    practice_room: <div>Keine Daten hinterlegt</div>,

    cockpit: <div>Keine Daten hinterlegt</div>,

    phases: <div>Keine Daten hinterlegt</div>,

    skills: <div>Keine Daten hinterlegt</div>,

    communication: <div>Keine Daten hinterlegt</div>,

    impulses: <div>Keine Daten hinterlegt</div>,

    services: <div>Keine Daten hinterlegt</div>,

    checkups: <div>Keine Daten hinterlegt</div>,
}

export const nameOfInfoSubArea: { [key in InfoSubArea]: String } = {
    settings_and_staffing: "Settings and Staffing",
    it_management: "IT Management",
    quality_management: "Quality Management",
    documentation: "Dokumentation",
    information_and_tips: "Information and Tips",
    project_desk: "Projekt-Schreibtisch",
    communication_and_interaction: "Kommunikation und Interaktion",
    change_room: "Raum wechseln",
    planning: "Planung",
    construction: "Aufbau",
    simulation: "RealLabor",
    governance: "Unternehmens-Info",
    practice_room: "Praxis-Räume",
    cockpit: "Persönliches Cockpit",
    phases: "5 Phasen",
    skills: "Kompetenzen",
    communication: "Kommunikation",
    impulses: "Impuls",
    services: "Services",
    checkups: "CheckUps",
}
