import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, Tooltip } from "recharts";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import { SubGraphProps } from "./Graphs";
import { assertNever } from "../../phases/prePhase/synchro/util";

export type EditingState = {
    kind: "idle"
} | {
    kind: "waitingForEdit",
} | {
    kind: "editing",
    valueIndex: number,
    pointIndex: number,
}

export const registerMouseAndTouchEvents = (ref: any, setEditingState: (state: EditingState) => void) => {
    ref.addEventListener("mouseup", () => {
        setEditingState({ kind: "idle" });
    });
    ref.addEventListener("mousedown", () => {
        setEditingState({ kind: "waitingForEdit" });
    });
    ref.addEventListener("touchstart", () => {
        setEditingState({ kind: "waitingForEdit" });
    });
    ref.addEventListener("touchend", () => {
        setEditingState({ kind: "idle" });
    });
}

export const RadarGraph = (props: SubGraphProps): ReactElement => {
    const [editingState, setEditingState] = useState<EditingState>({ kind: "idle" });
    const ref = useRef<any>(null);

    useEffect(() => {
        if (ref.current.current) {
            registerMouseAndTouchEvents(ref.current.current, setEditingState);
        }
    }, [ref]);

    const lineData = () => {
        let res: any[] = [];
        for (let i = 0; i < props.nrOfPoints; i++) {
            let t = props.data.flatMap(l => l.points).filter(k => k.index === i);
            let point: any = { index: i };
            t.forEach(p => {
                point["description"] = p.description;
                let varr = p.name;
                point[varr] = p.value;
            })
            res.push(point);
        }
        return res;
    }

    const onPointMove = (e: CategoricalChartState) => {
        if (
            e.activeCoordinate !== undefined
            && e.activeCoordinate.radius !== undefined
            && e.activeCoordinate.outerRadius !== undefined
            && e.activeCoordinate.angle !== undefined
        ) {
            const indexToAngles = [90, 18, -54, -126, -198];
            const r = Math.min(e.activeCoordinate.outerRadius, e.activeCoordinate.radius);
            const cur = r / e.activeCoordinate.outerRadius;
            const newPointVal = Math.min(100, cur * 100);
            const getEditState = (): EditingState => {
                const pointIndex = indexToAngles.indexOf(e.activeCoordinate!.angle!);
                let nearestValueIndex = -1;
                for (let i = 0; i < props.data.length; i++) {
                    if (nearestValueIndex === -1) {
                        nearestValueIndex = i;
                    } else {
                        const oldDelta = Math.abs(props.data[nearestValueIndex].points[pointIndex].value - newPointVal);
                        const newDelta = Math.abs(props.data[i].points[pointIndex].value - newPointVal);
                        if (newDelta < oldDelta) {
                            nearestValueIndex = i;
                        }
                    }
                }

                return {
                    kind: "editing",
                    valueIndex: nearestValueIndex,
                    pointIndex: pointIndex
                }
            };

            switch(editingState.kind) {
                case "idle":
                    return;
                case "waitingForEdit":
                    const editState = getEditState();
                    setEditingState(editState);
                    return;
                case "editing":
                    let data = [...props.data]
                    const validAngles = [-1, 0, 1].map(offset => indexToAngles[(editingState.pointIndex + offset + indexToAngles.length) % indexToAngles.length]);
                    const tooFarOff = !validAngles.includes(e.activeCoordinate.angle);
                    data[editingState.valueIndex].points[editingState.pointIndex].value = tooFarOff ? 0 : newPointVal;
                    props.updateData(data);
                    return;
                default:
                    assertNever(editingState);
                    return

            }
        }
    }

    const renderRadar = () => {
        return props.data.map((l, i) => <Radar key={i}
            activeDot={{ r: 10 }}
            dot={true}
            name="Mike"
            dataKey={l.uuid}
            stroke="#8884d8"
            fill={l.color}
            fillOpacity={0.7}
            isAnimationActive={false}
        />
        )
    };

    // stops flickering of tooltips
    // see: https://github.com/recharts/recharts/issues/300
    // It is not really clear why it is happening or why the issues was closed
    // People seem to still experience it
    const data = lineData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const cachedData = useMemo(() => data, [JSON.stringify(data)]);

    return <ResponsiveContainer ref={ref} width="100%" height="100%">
        <RadarChart id="radar-chart-id-comp" cx="50%" cy="50%" outerRadius="90%" data={cachedData} ref={props.innerRef}
            onMouseMove={(e) => onPointMove(e)}
        >
            <PolarGrid />
            <Tooltip content={renderTooltip} />
            <PolarAngleAxis dataKey="description" />
            <PolarRadiusAxis domain={[0, 100]} />
            {renderRadar()}
        </RadarChart>
    </ResponsiveContainer>
}
const renderTooltip = () => {
    return undefined;
}

