import { ReactElement } from "react";
import Select, { createFilter, SingleValue } from 'react-select';
import { Project } from "../../circalind/dataTypes/generated";
import circalindPersonalImage from "../../resources/circa/personal_project.svg";
import strategyImage_back from "../../resources/circa/strategy_project.svg"

interface ProjectOption {
    value: Project,
    label: any
}

interface ProjectSelectProps {
    projects: Project[] | undefined;
    onSelect: (user: Project | undefined) => void;
    valueProjectId?: number;
}

export const ProjectSelect = (props: ProjectSelectProps): ReactElement => {
    const getOptions = (): ProjectOption[] => {
        if (props.projects) {
            return props.projects.map(u => { return { value: u, label: <div style={{ display: "inline-flex", alignItems: "center" }}><img style={{ height: "1.3em" }} src={u.type === "PERSONAL" ? circalindPersonalImage : strategyImage_back} alt={u.name} />{u.name}</div> } });
        }

        return [];
    }

    const filterConfig = {
        ignoreCase: true,
        trim: true,
        matchFrom: ('start' as const)
    };

    const onUserSelect = (opt: SingleValue<ProjectOption> | undefined) => {
        if (opt) {
            props.onSelect(opt.value)
        } else {
            props.onSelect(undefined);
        }
    }

    const getSelectedDefault = (sel: number | undefined): ProjectOption | null => {
        if (sel && sel > -1) {
            return getOptions().find(u => u.value.id === sel) || null;
        }
        return null
    }

    return <div className="add-user">
        <Select
            placeholder={"Projektwahl"}
            value={getSelectedDefault(props.valueProjectId)}
            isSearchable
            isClearable
            options={getOptions()}
            filterOption={createFilter(filterConfig)}
            onChange={(opt) => onUserSelect(opt)}
        />
    </div>
}
