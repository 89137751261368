import { FormEvent, useEffect, useState } from "react";
import { User } from "../dataTypes/generated";
import { CircalindApi } from "../circalindApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import "./AdminAdminPage.scss";
import { sendOlert } from "../../olert/Olert";
import { getCurrentUserId } from "../../utils/Auth";
import { useNavigate } from "react-router";

const createNewUser = (): User => {
    const newUser: User = {
        id: -1,
        mail: "",
        secret: "",
        firstName: "",
        lastName: "",
        roles: ["ADMIN"]
    };

    return newUser;
};

export const AdminAdminPage = () => {
    const [users, setUsers] = useState<Array<User>>([]);
    const [newUser, setNewUser] = useState<User>(createNewUser());

    const currentUserId = getCurrentUserId("token-circalind");

    const navigate = useNavigate();

    const loadUsers = async () => {
        setUsers((await CircalindApi.getUsers()).filter(u => u.roles.includes("ADMIN")));
    };

    useEffect(() => {
        loadUsers();
    }, []);

    const addUser = async(e: FormEvent<HTMLFormElement>) => {
        const res = await CircalindApi.addNewAdminUser(newUser);

        e.preventDefault();

        if (res > -1) {
            sendOlert("Erfolgreich hinzugefügt", "Admin erfolgreich hinzugefügt.", "Success");
            loadUsers();
            setNewUser(createNewUser());
        } else {
            sendOlert("Fehler beim Hinzufügen", "Admin konnte nicht hinzugefügt werden.", "Error");
        }
    };

    const deleteUser = async(id: number) => {
        const res = await CircalindApi.deleteUser(id);

        if (res) {
            sendOlert("Erfolgreich gelöscht", "Admin erfolgreich gelöscht.", "Success");
            loadUsers();
        } else {
            sendOlert("Fehler beim Löschen", "Admin konnte nicht gelöscht werden.", "Error");
        }
    };

    return <div className="admin-admin-page">
        <h2>Verwaltung der Administratoren</h2>
        <div className="form-element">
            <label>E-Mail</label>
            <label>Vorname</label>
            <label>Nachname</label>
            <label>Aktion</label>
        </div>
        {users.map(u => 
        <div key={u.id} className="form-element">
            <input disabled value={u.mail} type="email"></input>
            <input disabled value={u.firstName} type="text"></input>
            <input disabled value={u.lastName} type="text"></input>
            <button disabled={u.id === currentUserId} onClick={() => deleteUser(u.id)}><FontAwesomeIcon icon={faTrashAlt} /></button>
        </div>)
        }
        <hr />
        <form onSubmit={addUser}>
            <div className="form-element">
                <input required onChange={e => setNewUser({...newUser, mail: e.target.value})} value={newUser.mail} type="email"></input>
                <input onChange={e => setNewUser({...newUser, firstName: e.target.value})} value={newUser.firstName} type="text"></input>
                <input onChange={e => setNewUser({...newUser, lastName: e.target.value})} value={newUser.lastName} type="text"></input>
                <button type="submit">Hinzufügen</button>
            </div>
        </form>
        <div className="right">
            <button onClick={() => navigate("../overview")}>Zurück</button>
        </div>
    </div>;
};