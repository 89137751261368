import { Fragment, ReactElement, useEffect, useState } from "react";

import './transfer.scss'

import Umsetzen from '../../../resources/circa/transfehr/umsetzen.svg';
import Neu from '../../../resources/circa/transfehr/new.svg';
import Kommunikation from '../../../resources/circa/transfehr/kommuniziern.svg';
import Kompetenz from '../../../resources/circa/transfehr/kompetenz.svg';
import { ToDo, TodoType } from "../../dataTypes/generated";
import { CircalindApi } from "../../circalindApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Dialog } from "../../basicComponents/dialogs/Dialog";
import { useDialog } from "../../hooks/useDialog";
import { getTodoColor, NewTodo } from "./NewTodo";
import { sendOlert } from "../../../olert/Olert";

export const Transfer = (): ReactElement => {

    const [todos, setTodos] = useState<ToDo[]>([]);
    const [selected, setSelected] = useState<ToDo>({ id: -1, date: "", state: "NONE", text: "", type: "NONE", userId: -1 });
    const { show, toggle } = useDialog();

    useEffect(() => {
        const getUserTodos = async () => {
            const res = await CircalindApi.getTodos();
            if (res) {
                setTodos(res);
            }
        }
        getUserTodos();
    }, [])

    const onNewTodo = (type: TodoType, id: number) => {
        let res: ToDo = { id: -1, date: "", state: "NONE", text: "", type: type, userId: -1 };
        setSelected(res);
        toggle();
    }

    const deleteTodo = async (todo: ToDo) => {
        let res = await CircalindApi.deleteTodo(todo.id);
        const getUserTodos = async () => {
            const res = await CircalindApi.getTodos();
            if (res) {
                setTodos(res);
            }
        }
        if (res) {
            sendOlert("Erfolgreich", "Aufgabe abeschlossen", "Success");
        } else {
            sendOlert("ERROR", "Aufgabe konnte nicht geschlossen werde", "Error");
        }
        getUserTodos();
        toggle();
    }

    const saveTodo = async (todo: ToDo) => {
        let res = false;
        if (todo.id === -1) {
            res = await CircalindApi.addTodo(todo);
        } else {
            res = await CircalindApi.updateTodo(todo);
        }
        const getUserTodos = async () => {
            const res = await CircalindApi.getTodos();
            if (res) {
                setTodos(res);
            }
        }
        if (res) {
            sendOlert("Erfolgreich", "Aufgabe erstellt", "Success");
        } else {
            sendOlert("ERROR", "Aufgabe konnte erstellt werde", "Error");
        }
        getUserTodos();
        toggle();
    }

    const openSelected = (todo: ToDo) => {
        setSelected(todo)
        toggle();
    }

    const renderTodo = (key: number, todo: ToDo) => {
        return <div key={key} className={"todoItem" + (selected.id === todo.id ? " selected" : "")} onClick={() => openSelected(todo)} onMouseOver={() => setSelected(todo)}>
            <div className="state" style={{ backgroundColor: getTodoColor(todo.state) }}>

            </div>
            <span>{todo.text}</span>
        </div>
    }

    const renderList = (type: TodoType) => {
        const todoItems = todos.filter(t => t.type === type);
        return <div className="list">
            <div className="todos">
                {todoItems.length === 0 ?
                    <span className="empty">To do - Liste ….</span>
                    :
                    todoItems.map((t, i) => renderTodo(i, t))
                }
            </div>
            <div className="tools" onClick={() => onNewTodo(type, -1)}>
                <FontAwesomeIcon icon={faPlus} />
            </div>
        </div>
    }

    return <Fragment>
        <div className="transfer">
            <div>
                <div className="main">
                    <div className="header">
                        <span>In die Praxis transferieren - in der Praxis aktualisieren</span>
                    </div>
                    <div className="table">
                        <div className="row top">
                            <div className="col-header">
                                <span>Umsetzen</span>
                                <img src={Umsetzen} alt="error" />
                            </div>
                            <div className="col-header">
                                <span>Organisieren</span>
                                <img src={Neu} alt="error" />
                            </div>
                            <div className="col-header">
                                <span>Kommunizieren</span>
                                <img src={Kommunikation} alt="error" />
                            </div>
                            <div className="col-header">
                                <span>Lernen</span>
                                <img src={Kompetenz} alt="error" />
                            </div>
                        </div>
                        <div className="row">
                            {renderList("IMPLEMENT")}
                            {renderList("NEW")}
                            {renderList("COMMUNICATE")}
                            {renderList("COMPETENCE")}
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <Dialog className="new-todo" show={show} toogle={toggle} component={<NewTodo onDelete={deleteTodo} toggle={toggle} onSave={saveTodo} todo={selected} />} />
    </Fragment>
}