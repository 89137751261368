import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import practicalImage from "../../resources/circa/practical.svg";

import "../../leading/Start.scss";


export const WelcomePractical = () => {
    return <div className="full-page">
        <h1><strong>Praxisbereich</strong></h1>
        <img src={practicalImage} alt="Praxisbereich" />
        <div onClick={() => window.location.href = "content/general"} className="lower-arrow"><FontAwesomeIcon icon={faArrowRight} /></div>
    </div>;
}