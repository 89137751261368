import { ReactElement } from "react"
import { InfoBubble, InfoHull } from "./InfoHull"
import leadership_teamwork from '../../resources/circa/leadership_teamwork.svg';

import './LeadingInfoOverview.scss'

export const LeadingInfoOverview = ({ userId, edit }: { userId?: number, edit: boolean }): ReactElement => {
    return <div className="leading-info-overview">
        <InfoHull edit={edit} userId={userId}>
            <div className="leading-info-overview-grid">
                <InfoBubble subArea="settings_and_staffing" />
                <InfoBubble subArea="it_management" />
                <InfoBubble subArea="quality_management" />
                <InfoBubble subArea="documentation" />
                <div>
                    <hr></hr>
                    <InfoBubble subArea="planning" />
                </div>
                <InfoBubble subArea="construction" />
                <InfoBubble subArea="simulation" />

                <InfoBubble subArea="information_and_tips" />
                <InfoBubble subArea="project_desk" />
                <InfoBubble subArea="communication_and_interaction" />
                <InfoBubble subArea="change_room" />

                <div className="leading-info-center">
                    <div><span>Führung & Zusammen-arbeit</span> <img alt="Führung und Zusammenarbeit" src={leadership_teamwork} /></div>
                </div>

            </div>
        </InfoHull>
    </div>
}
