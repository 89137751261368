import { faArrowLeft, faArrowRight, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useEffect, useState } from "react";
import planningImage from "../../resources/circa/planning.svg";
import { LeadingInfoOverview } from "../info/LeadingInfoOverview";


import { ManagementPhase, ManagementPhaseContent } from "../dataTypes/generated";
import { CircalindApi } from "../circalindApi";
import { getActiveGroup } from "../../utils/Auth";
import step1 from "./images/stufe1.png";
import step2 from "./images/stufe2.png";
import step3 from "./images/stufe3.png";
import step4 from "./images/stufe4.png";
import step5 from "./images/stufe5.png";

import Editor from 'react-simple-wysiwyg';

import './collaberation.scss'
import { Dialog } from "../basicComponents/dialogs/Dialog";


export const Planning = (): ReactElement => {
    const [page, setPage] = useState<number>(0);

    const back = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    }

    const next = () => {
        if (page < 2) {
            setPage(page + 1)
        }
    }

    const renderWelcome = () => {
        return <div className="collab-welcome">
            <h1>Willkommen in der</h1>
            <h1>Planungs-Phase!</h1>
            <figure>
                <img className="welcome-logo" src={planningImage} alt="Planung" />
                <span className="image-text">Planung</span>
            </figure>
            <div><span>für Koordinator</span></div>
        </div>
    }

    const renderItem = () => {
        switch (page) {
            case 0: return renderWelcome();
            case 1: return <Stairs toShow="PLANNING" />
            case 2: return <LeadingInfoOverview edit={false} />
        }
    }

    return <div className="collaberation">

        <div className="info-page">
            {renderItem()}
        </div>
        <div className={"navigation"}>
            <button onClick={() => back()}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button disabled={page === 2} onClick={() => next()}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>
}

export const Infos = (): ReactElement => {

    const renderInfoButton = (title: string, subTitle?: string, textItems?: string[]) => {
        return <div className="info">
            <button className="info-button">
                <span className="title">{title}</span>
                <span className="intro">Intro</span>
            </button>
            <div className="button-info">
                {subTitle ? <h3>{subTitle}</h3> : null}
                {textItems ?
                    <ul>
                        {textItems.map((v, i) => <li key={i}>{v}</li>)}
                    </ul>
                    : null
                }
            </div>
        </div>
    }

    return <div className="collab-infos">
        <div className="intro-col left">
            {renderInfoButton("Settings & Staffing",
                "für Zusammenspiel: Nutzer & Inpout-Geber",
                ["Rollen-Struktur aufstellen & auf Ziel-Setzung ausrichten",
                    "orchestrieren",
                    "Community building"])}
            {renderInfoButton("IT-Management")}
            {renderInfoButton("Nachhaltigkeits-Management")}
            {renderInfoButton("Dokumentation", "Aufbau-Dokumentation", [
                "Projekt-Listen - Funktionen",
                "Entscheidungen - Termine/ Fristen",
                "Aktivitäten / Maßnamen"
            ])}
            {renderInfoButton("Support", undefined, [
                "Projekt-Planung",
                "Aufbau-Phase",
                "Betriebs-Phase"
            ])}
        </div>
        <div className="intro-col right">
            {renderInfoButton("Information & Tipps")}
            {renderInfoButton("Projekt-Schreibtisch")}
            {renderInfoButton("Kommunikation & Interaktion", "team-direkt", [
                "Team-Kommunikation managen",
                "team-direkt für Mitwirkung nutzen",
                "team-direkt stärkt Community!"
            ])}
            {renderInfoButton("Raum wechseln für", undefined, [
                "für Portfolio",
                "Zugangsberechtigungen für Projekt und Akteure sowie Nutzer"
            ])}
        </div>
    </div>
}

export const Stairs = (props: { toShow: ManagementPhase }): ReactElement => {
    const [managementPhaseContent, setManagementPhaseContent] = useState<ManagementPhaseContent | null>(null);
    const [edit, setEdit] = useState(false);
    const [updateColumn, setUpdateColumn] = useState<number>();
    const groupId = getActiveGroup("token-circalind");

    const loadContent = async () => {
        if (groupId) {
            setManagementPhaseContent(await CircalindApi.getManagmentContent(groupId, props.toShow))
        }
    };

    const saveContent = async () => {
        if (managementPhaseContent) {
            await CircalindApi.saveManagmentContent(managementPhaseContent);
        }
    };

    useEffect(() => {
        const internalLoadContent = async () => {
            if (groupId) {
                setManagementPhaseContent(await CircalindApi.getManagmentContent(groupId, props.toShow))
            }
        };
        internalLoadContent();
    }, [groupId, props.toShow]);


    const getImage = (step: number) => {
        switch (step) {
            case 2: return step2;
            case 3: return step3;
            case 4: return step4;
            case 5: return step5;
            default: return step1;
        }
    }

    const getClassName = (step: number) => {
        switch (step) {
            case 2: return "two";
            case 3: return "three";
            case 4: return "four";
            case 5: return "five";
            default: return "one";
        }
    }

    const renderColumn = (step: number) => {
        if (managementPhaseContent) {
            return <div className={"step-col " + getClassName(step)}>
                <img className="step-logo" src={getImage(step)} alt={"Stufe " + step} />
                {edit && <button onClick={() => setUpdateColumn(step)}><FontAwesomeIcon icon={faPen} /></button>}
                <div dangerouslySetInnerHTML={{ __html: (managementPhaseContent as any)["phase" + step] }}>
                </div>
            </div>
        }
    }

    const renderButtons = () => {
        if (!edit) {
            return <button onClick={() => setEdit(true)}>Eingeben</button>;
        } else {
            return <>
                <button onClick={async () => { await loadContent(); setEdit(false) }}>Abbrechen</button>
                <button onClick={async () => { await saveContent(); setEdit(false) }}>Speichern</button>
            </>
        }
    };

    const getHtmlForColumn = () => {
        if (updateColumn) {
            return (managementPhaseContent as any)["phase" + updateColumn];
        } else {
            return "";
        }
    };

    const updateHtmlColumn = (html: string) => {
        if (updateColumn && managementPhaseContent) {
            setManagementPhaseContent({ ...managementPhaseContent, ["phase" + updateColumn]: html });
        }
    };

    return <div className="steps-info">
        <div className="actions">{renderButtons()}</div>
        <div className="title">
            {edit && managementPhaseContent ? <input value={managementPhaseContent.title} onChange={e => setManagementPhaseContent({ ...managementPhaseContent, title: e.target.value })} /> : <h2>{managementPhaseContent?.title}</h2>}
        </div>
        <div className="steps">
            {renderColumn(1)}
            {renderColumn(2)}
            {renderColumn(3)}
            {renderColumn(4)}
            {renderColumn(5)}
        </div>
        {<Dialog className={""} component={<MyTextEditor html={getHtmlForColumn()} updateHtml={updateHtmlColumn} />} show={!!updateColumn} toogle={() => setUpdateColumn(undefined)} />}
    </div>
}

const MyTextEditor = ({ html, updateHtml }: { html: string, updateHtml: (html: string) => void }) => {
    return <div className="my-editor">
        <Editor value={html} onChange={e => updateHtml(e.target.value)} />
    </div>
};
