import { faEnvelope, faEuroSign, faExclamationTriangle, faTasks, faVial } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GroupUser, TypeCustomer, User } from "../../circalind/dataTypes/generated";

export interface FilterParams {
    offset: string;
    limit: string;
    column: string;
    direction: string;
}

export const formatNumber = (value: number): string => {
    const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
    });
    return formatter.format(value);
}

export const roundPrice = (value: number): number => {
    return Math.round(value * 100) / 100;
}

export const convertAndDownload = (base64: string, filename: string) => {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    let blob = new Blob([bytes]);
    let link = document.createElement('a');
    let href = window.URL.createObjectURL(blob);
    link.href = href;
    link.download = filename;
    document.body.appendChild(link);
    link.setAttribute("type", "hidden");
    link.click();
}

export const getStateString = (id: number) => {
    switch (id) {
        case 1: return "Test"
        case 2: return "Ungültig"
        case 3: return "In Bearbeitung"
        case 4: return "Verschickt"
        case 5: return "Bezahlt"
        default: return "UNBEKANNT"
    }
}

export const getCustomerTypeString = (t: TypeCustomer) => {
    switch (t) {
        case "PRIVATE": return "Privatperson"
        case "COMPANY": return "Unternehmen"
        default: return "UNBEKANNT"
    }
}

export const getStateIcon = (id: number) => {
    switch (id) {
        case 1: return <FontAwesomeIcon icon={faVial} />
        case 2: return <FontAwesomeIcon icon={faExclamationTriangle} />
        case 3: return <FontAwesomeIcon icon={faTasks} />
        case 4: return <FontAwesomeIcon icon={faEnvelope} />
        case 5: return <FontAwesomeIcon icon={faEuroSign} />
    }
}

export const groupUserToUser = (gu: GroupUser): User => {
    return {
        firstName: gu.firstName,
        lastName: gu.lastName,
        roles: gu.roles,
        secret: "",
        id: gu.id,
        mail: gu.email
    };
};

export const blobToBase64Url = async (blob: Blob, asDataUrl: boolean): Promise<string> => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    await new Promise<void>((resolve, reject) => {
        reader.onload = () => resolve();
        reader.onerror = reject;
    });
    const base64Url = reader.result?.toString()?.split(',')[1];
    if (!base64Url) {
        throw new Error("Failed to convert blob to base64 URL.");
    }

    if (asDataUrl) {
        return `data:${blob.type};base64,${base64Url}`;
    } else {
        return base64Url;
    }
}


export const refToHtml = async (myRef: React.RefObject<any>): Promise<string> => {
    let cssText = "@page { size: A4 landscape; margin: 0; } .App-Circalind { -webkit-print-color-adjust:exact; -webkit-filter:opacity(1); }";
    let exportHTML = "";

    for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
            cssText += rule.cssText;
        }
    }

    const blackList = ["file-upload", "navigation"];

    const iterateOver = async (elem: Element) => {
        if (blackList.some(item => elem.classList.contains(item))) {
            elem.remove();
            return;
        }

        if (elem.tagName === "IMG") {
            const image = elem as HTMLImageElement;
            if (!image.src.includes("base64")) {
                const response = await fetch(image.src);
                const blob = await response.blob();
                image.src = await blobToBase64Url(blob, true);
            }
        }

        for (let child of elem.children) {
            await iterateOver(child);
        }
    };


    if (myRef.current) {
        const copy = myRef.current.cloneNode(true) as Element;
        await iterateOver(copy as Element);
        exportHTML = copy.outerHTML;
    }

    return `<!DOCTYPE html><html><head><style>${cssText}</style><title>circalind</title></head><body class="App-Circalind">${exportHTML}</body></html>`;
};