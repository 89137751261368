import { getUserRoles } from "../../utils/Auth";

import { CircalindApi } from "../../circalind/circalindApi";
import { sendOlert } from "../../olert/Olert";
import { ReactElement, useEffect, useState } from "react";
import { AreaAndRoleInfo, CircaPackage, InternalGroup, LicenseMetaData, RoleType, TotalLicenses, User } from "../../circalind/dataTypes/generated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Dialog } from "../../circalind/basicComponents/dialogs/Dialog";
import { EditStaffingUser } from "../dialogs/EditStaffingUser";
import { EditStaffingGroup } from "../dialogs/EditStaffingGroup";
import Select from 'react-select';

import "../../leading/staffing/leading.scss";
import { useNavigate } from "react-router";
import { ListCardProps } from "./Staffing";

export type UserCount = {
    user: User,
    count: number
}

export const UserOverview = (): ReactElement => {
    const allowedRoles: Array<RoleType> = ["CIRCALINDMANAGER", "COORDINATOR", "CO_COORDINATOR"];
    const userRoles = getUserRoles("token-circalind");
    const [users, setUsers] = useState<Array<User>>([]);
    const [userCount, setUserCount] = useState<Array<UserCount>>([]);
    const [license, setLicense] = useState<CircaPackage | null>(null);
    const [licenseMetaData, setLicenseMetaData] = useState<LicenseMetaData | null>(null);
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo | null>(null);
    const [totalLicenses, setTotalLicenses] = useState<TotalLicenses | null>(null);
    const [showInteralGroupDialog, setShowInternalGroupDialog] = useState<boolean>(false);
    const [selectedInternalGroup, setSelectedInternalGroup] = useState<InternalGroup | undefined>(undefined);
    const [internalGroups, setInternalGroups] = useState<InternalGroup[]>([]);
    const internalGroupsWithOwner = calcInternalGroupsWithOwner(internalGroups, users);

    const navigate = useNavigate();

    const loadData = async () => {
        setLicense(await CircalindApi.getLicense());
        setAreaAndRoleInfo(await CircalindApi.getAreaAndRoleInfo());
        const us = await CircalindApi.getAllUsersOfMyGroup();
        setUsers(us);
        setInternalGroups(await CircalindApi.getInternalGroups());
        setLicenseMetaData(await CircalindApi.getLicenseMetaData());
        setTotalLicenses(await CircalindApi.getTotalLicenses());
        const pr = await CircalindApi.getGroupProjects();

        let uc: Array<UserCount> = [];

        for (let u of us.filter(u => u.roles.includes("DECIDER"))) {
            uc.push({
                user: u, count: pr.filter(p => p.ownerId === u.id).map(p => p.members.filter(pm => pm.user.id !== u.id).length).reduce((a, b) => {
                    return a + b;
                }, 0)
            });
        }

        setUserCount(uc);
    };

    useEffect(() => {

        loadData();
    }, []);

    const deleteInternalGroup = async (item: InternalGroup) => {
        let res = false;
        res = await CircalindApi.deleteInternalGroup(item.id);
        if (res === true) {
            sendOlert("Erfolgreich", "gelöscht", "Success");
        } else {
            sendOlert("Fehler", "Objekt konnte nicht gelöscht werden", "Error");
        }
        await loadData();
    }

    const onGroupItemSelect = (item: InternalGroup) => {
        setSelectedInternalGroup(item);
        setShowInternalGroupDialog(true);
    }

    const onOpenNewInteralGroupDialog = () => {
        setSelectedInternalGroup(undefined);
        setShowInternalGroupDialog(true);
    }

    const renderGroupItem = (item: InternalGroupsWithOwner) => {
        return <div className="list-entry">
            <div className="group-entry" onClick={() => onGroupItemSelect(item.internalGroup)}>
                <span>{item.internalGroup.name}</span>
                <label>{item.owner?.mail || "Kein Besitzer"}</label>
            </div>
            <FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteInternalGroup(item.internalGroup)} />
        </div>
    }

    const onUpdate = async () => {
        loadData();
        setShowInternalGroupDialog(false);
    };

    return <div className="staffing">
        <div className="list-cards">
            <DeciderAssistenceView totalLicenses={totalLicenses} license={license} licenseMetaData={licenseMetaData} users={users} areaAndRoleInfo={areaAndRoleInfo} onOpenDialog={() => { }} onDelete={() => { }} title="" kind="UserCount" items={userCount}></DeciderAssistenceView>
            <div className="list-card">
                <div className="title">
                    <h3>Personenkreise</h3>
                </div>

                {(allowedRoles && allowedRoles.some(r => userRoles.includes(r))) || !allowedRoles ?
                    <>
                        <div className="list">
                            {internalGroupsWithOwner.map(g => renderGroupItem(g))}
                        </div>
                        <button className="add" onClick={() => onOpenNewInteralGroupDialog()}><FontAwesomeIcon icon={faPlusCircle} /></button>
                    </>
                    : <span>Nur für die Rolle {areaAndRoleInfo?.roleTitles["CIRCALINDMANAGER"]}</span>}
                <Dialog className="editStaffing" show={showInteralGroupDialog} toogle={() => setShowInternalGroupDialog(false)} component={
                    <EditStaffingGroup
                        licenseType={license?.type || null}
                        selected={selectedInternalGroup}
                        onUpdate={onUpdate}
                        users={users}
                        onCancel={() => setShowInternalGroupDialog(false)}
                    />}
                />
            </div>
        </div>
        <div className={"navigation"}>
            <button onClick={() => navigate("../tools")}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button disabled><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>
}

interface InternalGroupsWithOwner {
    internalGroup: InternalGroup,
    owner?: User
}

const calcInternalGroupsWithOwner = (
    internalGroups: InternalGroup[],
    users: User[]
): InternalGroupsWithOwner[] => {
    return internalGroups.map(ig =>
    ({
        internalGroup: ig,
        owner: users.find(u => u.id === ig.ownerId)
    })
    )
}

export const ModuleUserOverview = () => {
    const allowedRoles: Array<RoleType> = ["COORDINATOR", "CO_COORDINATOR"];
    const [filterRole, setFilterRole] = useState<RoleType>();
    const userRoles = getUserRoles("token-circalind");
    const [users, setUsers] = useState<Array<User>>([]);
    const [license, setLicense] = useState<CircaPackage | null>(null);
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo | null>(null);
    const [showUserDialog, setShowUserDialog] = useState<boolean>(false);
    const [selected, setSelected] = useState<User | undefined>(undefined);


    const loadData = async () => {
        setLicense(await CircalindApi.getLicense());
        setAreaAndRoleInfo(await CircalindApi.getAreaAndRoleInfo());
        setUsers(await CircalindApi.getAllUsersOfMyGroup());
    };

    useEffect(() => {

        loadData();
    }, []);

    const deleteItem = async (item: User) => {
        let res = false;
        res = await CircalindApi.deleteUser((item as User).id);
        if (res === true) {
            sendOlert("Erfolgreich", "gelöscht", "Success");
        } else {
            sendOlert("Fehler", "Objekt konnte nicht gelöscht werden", "Error");
        }
        await loadData();
    }


    const onItemSelect = (item: User) => {
        setSelected(item);
        setShowUserDialog(true);
    }

    const onOpenNewDialog = () => {
        setSelected(undefined);
        setShowUserDialog(true);
    }

    const renderUserItem = (p: User, index: number) => {
        return <div key={index} className="user-entry" onClick={() => onItemSelect(p)}>
            <span>{p.firstName + " " + p.lastName}</span>
            <label>{p.mail}</label>
        </div>
    }

    const renderItem = (item: User, index: number) => {
        return <div key={index} className="list-entry">
            {renderUserItem(item as User, index)}
            <FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteItem(item)} />
        </div>
    }

    const renderRoleSelect = () => {
        if (areaAndRoleInfo) {
            const options = Object.entries(areaAndRoleInfo.roleTitles).map(entry => {
                return { label: entry[1], value: entry[0] };
            });

            return <>
                <label>Rolle filtern</label>
                <Select value={options.find(o => filterRole === o.value)} isClearable={true} className="add-role" onChange={(option) => setFilterRole(option?.value as RoleType)} options={options}></Select>
            </>;
        }
    };

    const onUpdate = async () => {
        loadData();
        setShowUserDialog(false);
    };

    return <div className="list-card">
        <div className="title">
            <h3>Nutzerverwaltung</h3>
            {renderRoleSelect()}
        </div>

        {(allowedRoles && allowedRoles.some(r => userRoles.includes(r))) || !allowedRoles ?
            <>
                <div className="list">
                    {users.filter(item => !filterRole || item.roles.find(r => filterRole === r)).map((p, i) => renderItem(p, i))}
                </div>
                <button className="add" onClick={() => onOpenNewDialog()}><FontAwesomeIcon icon={faPlusCircle} /></button>
            </>
            : <span>Nur für die Rolle {areaAndRoleInfo?.roleTitles["COORDINATOR"]}</span>}
        <Dialog className="editStaffing" show={showUserDialog} toogle={() => setShowUserDialog(false)} component={
            <EditStaffingUser
                licenseType={license?.type || null}
                selected={selected}
                onUpdate={onUpdate}
                onCancel={() => setShowUserDialog(false)}
            />
        }
        />
    </div>
};

const DeciderAssistenceView = (props: ListCardProps<UserCount>) => {
    return <div className="list-card">
        <h3>Entscheider-Assistenz-Übersicht</h3>
        <div className="user-counts">
            <div className="user-count"><strong>Entscheider</strong><strong>Assistenzen</strong></div>
            {props.items.map(i =>
                <div className="user-count" key={i.user.id}><span>{i.user.firstName} {i.user.lastName}</span>{i.count}<span></span></div>
            )}
        </div>
    </div>;
};

export const UsersNewPage = () => {
    const navigate = useNavigate();

    return <div className="staffing">
        <div className="list-cards">
            <ModuleUserOverview />
        </div>
        <div className={"navigation"}>
            <button onClick={() => navigate("../overview")}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button onClick={() => navigate("../projects")}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>
};