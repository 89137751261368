import { faBorderStyle, faDownload, faEllipsisVertical, faExternalLink, faLeftRight, faMinusSquare, faPenToSquare, faPlusCircle, faPlusSquare, faTags, faTrashAlt, faUpDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, Fragment, ReactElement, useState } from "react";
import { DeskItem, DeskType, TableCell, TableCellType, TableData } from "../../../dataTypes/generated";
import DateTimePicker from 'react-datetime-picker';
import ICalendarLink from "react-icalendar-link";
import {
    Menu,
    MenuItem,
    MenuButton,
    MenuDivider,
    SubMenu
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';

import '../../compass/question/compassQuestion.scss'

import { ICalEvent } from "react-icalendar-link/dist/utils";
import { Base64Upload } from "../../../basicComponents/base64Upload/Base64Upload";
import { convertAndDownload } from "../../../../invoice/utils/Util";
import { Dialog } from "../../../basicComponents/dialogs/Dialog";

import documentImage from "../../../../resources/circa/desk/document.png"
import brainImage from "../../../../resources/circa/desk/brain.png"
import presentationImage from "../../../../resources/circa/desk/presentation.png"
import protocolImage from "../../../../resources/circa/desk/protocol.png"
import { Bar, BarChart, Cell, Legend, Pie, PieChart, Tooltip, XAxis, YAxis } from "recharts";
import { getPhaseImage, getPhaseTitle } from "../../phases/PhaseHeader";

export interface compassTableProps {
    edit: boolean;
    data: TableData;
    onUpdate: (data: TableData, changedCellType?: boolean) => void;
    allowedCellTypes?: TableCellType[];
    multipleAnswers?: userAnswers[];
    answers: TableAnswerCell[];
    onCellAnswerd: (newCells: TableAnswerCell[]) => void;
    onCellItemClicked?: (id: number, action: "new" | "delete" | "open" | "tags" | "source", row: number, col: number) => Promise<void>;
}

export interface userAnswers {
    userId: number;
    userName: string;
    userMail: string;
    answer: TableAnswerCell;
}

interface QuestionLink {
    url: string;
    title: string;
}

interface QuestionQuote {
    quote: string;
    author: string;
}

interface QuestionYoutube {
    url: string;
    title: string;
}

interface TableAnswerCell {
    column: number;
    row: number;
    answer: string;
}

const pollColors = ["red", "blue", "green", "deeppink", "yellow", "orange", "purple"];

export const CompassTable = (props: compassTableProps): ReactElement => {
    const [userInput, setUserInput] = useState<Map<number, string>>(new Map<number, string>());
    const [showExpandedDialog, setShowExpandedDialog] = useState<boolean>(false);
    const [selectedCell, setSelectedCell] = useState<number | null>(null);
    const [showStylingDialog, setShowStylingDialog] = useState<boolean>(false);

    const addToInputs = (val: string, key: number) => {
        let newMap: Map<number, string> = new Map(userInput);
        newMap.set(key, val);
        setUserInput(newMap);
    }

    const getOptions = (cell: TableCell): string[] => {
        try {
            return JSON.parse(cell.placeholder);
        } catch (e) {
            return [];
        }
    }

    const renderUserAnswers = (cell: TableCell) => {

        const getAnswersForCell = () => {
            if (props.multipleAnswers) {
                return props.multipleAnswers.filter(a => a.answer.row === cell.row && a.answer.column === cell.column);
            }
            return []
        }

        const evalSingleChoice = () => {
            const resMap = new Map<string, number>();
            const answers = getAnswersForCell();
            getOptions(cell).forEach(o => resMap.set(o, 0));

            let sum = 0;

            answers.forEach(a => {
                const selected: string[] = JSON.parse(a.answer.answer);
                selected.forEach(s => {
                    const oldVal = resMap.get(s)
                    if (oldVal !== undefined) {
                        resMap.set(s, (oldVal + 1));
                        sum++;
                    }
                })
            });

            const pieData: Array<{ name: string, value: number }> = [];

            resMap.forEach((v, k) => pieData.push({ name: k, value: (v / sum) * 100 }));

            return <PieChart width={500} height={250}>
                <Legend verticalAlign="top" align="center" />
                <Pie label={(entry: any) => `${entry.name} (${entry.value} %)`} data={pieData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50}>
                    {pieData.map((_, index) => <Cell fill={pollColors[index % pollColors.length]} />)}
                </Pie>
            </PieChart>
        }

        const evalMultiChoice = () => {
            const resMap = new Map<string, number>();
            const answers = getAnswersForCell();
            getOptions(cell).forEach(o => resMap.set(o, 0));

            answers.forEach(a => {
                const selected: string[] = JSON.parse(a.answer.answer);
                selected.forEach(s => {
                    const oldVal = resMap.get(s)
                    if (oldVal !== undefined) {
                        resMap.set(s, (oldVal + 1));
                    }
                })
            })

            const barData: Array<{ name: string, value: number }> = [];
            resMap.forEach((v, k) => barData.push({ name: k, value: v }));
            return <BarChart data={barData} width={500} height={250}>
                <XAxis dataKey="name" />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Bar dataKey="value">
                    {barData.map((_, index) => <Cell fill={pollColors[index % pollColors.length]} />)}
                </Bar>
            </BarChart>
        }

        const evalBoolean = () => {
            const resMap = new Map<string, number>();
            const answers = getAnswersForCell();
            resMap.set("Ja", 0);
            resMap.set("Nein", 0);

            let sum = 0;

            answers.forEach(a => {
                const selected: boolean = JSON.parse(a.answer.answer);
                const oldVal = resMap.get(selected ? "Ja" : "Nein")
                if (oldVal !== undefined) {
                    resMap.set(selected ? "Ja" : "Nein", (oldVal + 1));
                    sum++;
                }
            })

            const pieData = [
                {
                    name: "Ja",
                    value: (resMap.get("Ja")! / sum) * 100,
                },
                {
                    name: "Nein",
                    value: (resMap.get("Nein")! / sum) * 100,
                }
            ];

            return <PieChart width={340} height={250}>
                <Legend verticalAlign="top" align="center" />
                <Pie label={(entry: any) => `${entry.name} (${entry.value} %)`} data={pieData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50}>
                    <Cell fill="red"></Cell>
                    <Cell fill="green"></Cell>
                </Pie>
            </PieChart>
        }

        const getAnswerString = (answer: TableAnswerCell, celltype: TableCellType): string => {
            switch (celltype) {
                case "BOOLEAN": return ((JSON.parse(answer.answer) as boolean[]) ? "Ja" : "Nein");
                case "TEXT": return answer.answer;
                default: return "keine Antworten";
            }
        }

        const renderByType = () => {
            switch (cell.type) {
                case "MULTICHOICE": return evalMultiChoice();
                case "SINGLECHOICE": return evalSingleChoice();
                case "BOOLEAN": return evalBoolean();
                default: return <Fragment>{getAnswersForCell().map((a, i) => <Fragment key={i}>
                    <span className="user">{a.userName}</span>
                    <span className="answer">{getAnswerString(a.answer, cell.type)}</span>
                </Fragment>)}</Fragment>
            }
        }

        return <div className="answers">
            {renderByType()}
        </div>
    }

    const getAnswerString = (cell: TableCell): string => {
        if (props.answers) {
            let found = props.answers.find(c => c.column === cell.column && c.row === cell.row);
            if (found) {
                return found.answer;
            }
            return "";
        } else
            return "";
    }

    const onAnswerUpdate = (answerString: string, cell: TableCell) => {
        if (props.answers) {
            let newCellAnswers = [...props.answers];
            if (props.answers.length > 0) {
                const index = props.answers.findIndex(c => c.column === cell.column && c.row === cell.row);
                if (index >= 0) {
                    newCellAnswers[index].answer = answerString;
                } else {
                    newCellAnswers.push({ column: cell.column, row: cell.row, answer: answerString });
                }
            } else {
                newCellAnswers.push({ column: cell.column, row: cell.row, answer: answerString });
            }
            props.onCellAnswerd(newCellAnswers);
        }
    }

    const isCellAlreadyAtPos = (row: number, col: number) => {
        //is inefficent but tables should not be very large
        const largeCells = props.data.tableCells.filter(c => c.height > 1 || c.width > 1);
        let res = largeCells.findIndex(c => (row >= c.row && row <= (c.row + c.height - 1) && (col >= c.column && col <= (c.column + c.width - 1))))
        return res > -1;
    }

    const changeCellType = (cell: TableCell, type: TableCellType) => {
        let newData = { ...props.data };
        const index = newData.tableCells.findIndex(c => c.id === cell.id);
        if (index >= 0) {
            newData.tableCells[index].type = type;
            newData.tableCells[index].placeholder = "";
            newData.tableCells[index].value = "";
            props.onUpdate(newData, true);
        }
    }

    const addColumn = () => {
        let newData = { ...props.data };
        newData.cols += 1;
        props.onUpdate(newData);
    }

    const addRow = () => {
        let newData = { ...props.data };
        newData.rows += 1;
        props.onUpdate(newData);
    }

    const addCellToData = (row: number, col: number) => {
        let newData = { ...props.data };
        newData.tableCells.push({ id: newData.tableCells.length + 1, row: row, column: col, height: 1, width: 1, placeholder: "", type: props.allowedCellTypes ? props.allowedCellTypes[0] : "INFO", value: "", style: null })
        props.onUpdate(newData);
    }

    const updatePlaceHolderinCell = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, cell: TableCell) => {
        let newData = { ...props.data };
        const index = newData.tableCells.findIndex(c => c.id === cell.id);
        if (index >= 0) {
            newData.tableCells[index].placeholder = e.target.value;
            props.onUpdate(newData);
        }
    }

    const updateStringPlaceHolderinCell = (placeholder: string, cell: TableCell) => {
        let newData = { ...props.data };
        const index = newData.tableCells.findIndex(c => c.id === cell.id);
        if (index >= 0) {
            newData.tableCells[index].placeholder = placeholder;
            props.onUpdate(newData);
        }
    }

    const deleteCell = (cell: TableCell) => {
        let newData = { ...props.data };
        const index = newData.tableCells.findIndex(c => c.id === cell.id);
        if (index >= 0) {
            newData.tableCells.splice(index, 1);
            props.onUpdate(newData);
        }
    }

    const reOrderRows = (start: number, deletedRows: number, cells: TableCell[]): TableCell[] => {
        cells.forEach(r => {
            if (r.row >= start) {
                r.row = r.row - deletedRows;
            }
        })
        return cells;
    }

    const deleteRow = (row: number) => {
        let newData = { ...props.data };
        let newCells = newData.tableCells.filter(c => c.row !== row);
        newData.tableCells = reOrderRows(row, 1, newCells);
        newData.rows -= 1;
        if (newData.rows === 0) {
            newData.rows = 1;
        }
        if (newData.tableCells.length === 0) {
            newData.cols = 1;
        }
        props.onUpdate(newData);
    }

    const reOrderColumns = (start: number, deletedColums: number, cells: TableCell[]): TableCell[] => {
        cells.forEach(c => {
            if (c.column >= start) {
                c.column = c.column - deletedColums;
            }
        })
        return cells;
    }

    const deleteCol = (col: number) => {
        let newData = { ...props.data };
        let newCells = newData.tableCells.filter(c => c.column !== col);
        newData.tableCells = reOrderColumns(col, 1, newCells);
        newData.cols -= 1;
        if (newData.cols === 0) {
            newData.cols = 1;
        }
        if (newData.tableCells.length === 0) {
            newData.rows = 1;
        }
        props.onUpdate(newData);
    }

    const expandCell = (cell: TableCell, row: boolean) => {
        let newData = { ...props.data };
        const index = newData.tableCells.findIndex(c => c.id === cell.id);
        if (index >= 0) {
            if (row) {
                if (getCell(cell.row + 1, cell.column) === undefined) {
                    newData.tableCells[index].height += 1;
                }
            } else {
                if (getCell(cell.row, cell.column + 1) === undefined) {
                    newData.tableCells[index].width += 1;
                }
            }
            props.onUpdate(newData);
        }
    }

    const getCell = (row: number, col: number): TableCell | undefined => {
        return props.data.tableCells.find(c => c.row === row && c.column === col);
    }

    const renderInfo = (cell: TableCell, edit: boolean) => {
        if (edit) {
            return <textarea value={cell.placeholder} readOnly={!edit} onChange={(e) => updatePlaceHolderinCell(e, cell)} />
        } else {
            return <p>{cell.placeholder}</p>;
        }
    }

    const renderText = (cell: TableCell, edit: boolean) => {
        return <Fragment>{edit ? <textarea value={cell.placeholder} onChange={(e) => updatePlaceHolderinCell(e, cell)} />
            : <textarea placeholder={cell.placeholder} value={getAnswerString(cell)} onChange={(e) => onAnswerUpdate(e.target.value, cell)} />
        }
            {edit ? undefined : <FontAwesomeIcon onClick={() => { setSelectedCell(cell.id); setShowExpandedDialog(true) }} icon={faPenToSquare} />}
        </Fragment>
    }

    const renderBoolean = (cell: TableCell, edit: boolean) => {
        const getBoolean = (cell: TableCell): boolean => {
            return (getAnswerString(cell).toLowerCase() === 'true')
        }

        return <div className="yes-no">
            <input type="radio" checked={getBoolean(cell)} onChange={() => onAnswerUpdate("true", cell)} />
            <label>Ja</label>
            <input type="radio" checked={!getBoolean(cell)} onChange={() => onAnswerUpdate("false", cell)} />
            <label>Nein</label>
        </div>
    }

    const renderLink = (cell: TableCell, edit: boolean) => {
        const getQuestionLink = (): QuestionLink => {
            try {
                return JSON.parse(cell.placeholder);
            } catch (e) {
                return { title: "", url: "" };
            }
        }
        const onChange = (l: QuestionLink) => {
            updateStringPlaceHolderinCell(JSON.stringify(l), cell);
        }
        return <div className="link">
            {edit ?
                <Fragment>
                    <input type="text" placeholder="URL" value={getQuestionLink().url} onChange={(e) => onChange({ ...getQuestionLink(), url: e.target.value })} />
                    <input type="text" placeholder="Name" value={getQuestionLink().title} onChange={(e) => onChange({ ...getQuestionLink(), title: e.target.value })} />
                </Fragment>
                :
                <a href={getQuestionLink().url}>{getQuestionLink().title}</a>
            }
        </div>
    }

    const renderQuote = (cell: TableCell, edit: boolean) => {
        const getQuestionQuote = (): QuestionQuote => {
            try {
                return JSON.parse(cell.placeholder);
            } catch (e) {
                return { quote: "", author: "" };
            }
        }
        const onChange = (l: QuestionQuote) => {
            updateStringPlaceHolderinCell(JSON.stringify(l), cell);
        }
        return <div className="quote">
            {edit ?
                <Fragment>
                    <textarea placeholder="Zitat" value={getQuestionQuote().quote} onChange={(e) => onChange({ ...getQuestionQuote(), quote: e.target.value })} />
                    <input type="text" placeholder="Autor" value={getQuestionQuote().author} onChange={(e) => onChange({ ...getQuestionQuote(), author: e.target.value })} />
                </Fragment>
                :
                <Fragment>
                    <q>{getQuestionQuote().quote}</q>
                    <span>&mdash; {getQuestionQuote().author}</span>
                </Fragment>
            }
        </div>
    }

    const renderYouTube = (cell: TableCell, edit: boolean) => {
        const getQuestionYoutube = (): QuestionYoutube => {
            try {
                return JSON.parse(cell.placeholder);
            } catch (e) {
                return { url: "", title: "" };
            }
        }
        const onChange = (l: QuestionYoutube) => {
            updateStringPlaceHolderinCell(JSON.stringify(l), cell);
        }
        const getIdFromYoutubeUrl = (url: string): string => {
            var video_id = url.split('v=')[1];
            var ampersandPosition = video_id.indexOf('&');
            if (ampersandPosition !== -1) {
                return video_id.substring(0, ampersandPosition);
            }
            return video_id;
        }

        return <div className="youtube">
            {edit ?
                <Fragment>
                    <input type="text" placeholder="Titel" value={getQuestionYoutube().title} onChange={(e) => onChange({ ...getQuestionYoutube(), title: e.target.value })} />
                    <input type="text" placeholder="Youtube link" value={getQuestionYoutube().url} onChange={(e) => onChange({ ...getQuestionYoutube(), url: e.target.value })} />
                </Fragment>
                :
                (getQuestionYoutube().url && <iframe title="youtube Link" id="ytplayer" width="640" height="360" src={`http://www.youtube.com/embed/${getIdFromYoutubeUrl(getQuestionYoutube().url)}`}></iframe>)
            }
        </div>
    }

    const renderCalendar = (cell: TableCell, edit: boolean) => {

        const getCalEvent = (): ICalEvent => {
            try {
                return JSON.parse(cell.placeholder);
            } catch (e) {
                let start = new Date();
                let end = new Date(start.getTime() + 1 * 3600000);
                return { title: "", location: "", description: "", startTime: start.toISOString(), endTime: end.toISOString() }
            }
        }

        const onChange = (newEvent: ICalEvent) => {
            updateStringPlaceHolderinCell(JSON.stringify(newEvent), cell);
        }

        const renderCalendarButton = () => {
            //@ts-ignore
            return <ICalendarLink event={getCalEvent()}>Dem Kalender hinzufügen</ICalendarLink>;
        }

        return <div className="calendar">
            {edit ?
                <Fragment>
                    <input type="text" placeholder="Titel" value={getCalEvent().title} onChange={(e) => onChange({ ...getCalEvent(), title: e.target.value })} />
                    <input type="text" placeholder="Beschreibung" value={getCalEvent().description} onChange={(e) => onChange({ ...getCalEvent(), description: e.target.value })} />
                    <input type="text" placeholder="Ort" value={getCalEvent().location} onChange={(e) => onChange({ ...getCalEvent(), location: e.target.value })} />
                    <DateTimePicker className={"date-time-picker"} disableClock locale="de-DE" value={new Date(getCalEvent().startTime)} onChange={(e: any) => onChange({ ...getCalEvent(), startTime: e.toISOString() })} />
                    <DateTimePicker className={"date-time-picker"} disableClock locale="de-DE" value={new Date(getCalEvent().endTime!)} onChange={(e: any) => onChange({ ...getCalEvent(), endTime: e.toISOString() })} />
                </Fragment>
                :
                <>
                    <p>{getCalEvent().title} am {new Date(getCalEvent().startTime).toLocaleString()}</p>
                    {renderCalendarButton()}
                </>
            }
        </div>
    }

    const renderStaticImage = (cell: TableCell, edit: boolean) => {
        const onImageUpload = (base64: string) => {
            updateStringPlaceHolderinCell(base64, cell);
        }
        return <div className="static-image">
            {edit ?
                <Base64Upload type="IMAGE" onUpload={onImageUpload} maxHeight={1200} maxWidth={1200} maxFileSize={20000000} />
                :
                <img className="static-Image" src={cell.placeholder} alt="Error no Logo" />
            }
        </div>
    }

    const renderUserImage = (cell: TableCell, edit: boolean) => {
        const onImageUpload = (base64: string) => {
            onAnswerUpdate(base64, cell);
        }
        const imageData = getAnswerString(cell);
        return <div className="user-image">
            <label>{cell.placeholder}</label>
            <div className="image-wrapper">
                {(imageData.length > 0) ?
                    <img className="static-Image" src={imageData} alt="" /> : null
                }
            </div>
            <Base64Upload type="IMAGE" onUpload={onImageUpload} maxHeight={1200} maxWidth={1200} />
        </div>
    }

    const renderUserFile = (cell: TableCell, edit: boolean) => {
        const onFileUpload = (base64: string, filename?: string) => {
            onAnswerUpdate(base64, cell);
            if (filename) {
                updateStringPlaceHolderinCell(filename, cell);
            }
        }
        return <div className="user-file">
            {(cell.value && cell.value.length > 0) ?
                <span className="download" onClick={() => convertAndDownload(cell.value[0].split('base64,')[1], cell.placeholder ? cell.placeholder : "datei")}>Hochgeladene</span> : null
            }
            <Base64Upload type="FILE" onUpload={onFileUpload} maxFileSize={25 * 1024 * 1024} />
        </div>
    }

    const renderPhaseIcon = (cell: TableCell) => {
        return <div className="phase-icon">
            <img src={getPhaseImage(Number(cell.value))} alt={getPhaseTitle(Number(cell.value))} />
        </div>
    }

    const renderMultiFile = (cell: TableCell) => {
        const getItems = (): DeskItem[] => {
            try {
                return JSON.parse(cell.value);
            } catch (e) {
                return [];
            }
        }

        const renderIcon = (itemType: DeskType) => {
            switch (itemType) {
                case "DOCUMENTS":
                    return documentImage;
                case "PRESENTATION":
                    return presentationImage;
                case "BRAINSTORMING":
                    return brainImage;
                case "PROTOCOLS":
                    return protocolImage;
            }
        };

        const renderItems = (items: DeskItem[]) => {
            if (items.length > 0) {
                return items.map((d, i) => <div key={i} className="desk-item">
                    <span className="desk-item-title">
                        {renderIcon(d.type) && <img src={renderIcon(d.type)} alt={d.name} />}
                        {d.name}
                    </span>
                    <span className="actions">
                        {d.source && <FontAwesomeIcon className="action" icon={faExternalLink} onClick={(e) => { e.stopPropagation(); if (props.onCellItemClicked) props.onCellItemClicked(d.id, "source", cell.row, cell.column) }} />}
                        <FontAwesomeIcon className="action" icon={faTags} onClick={(e) => { e.stopPropagation(); if (props.onCellItemClicked) props.onCellItemClicked(d.id, "tags", cell.row, cell.column) }} />
                        <FontAwesomeIcon className="action" icon={faDownload} onClick={(e) => { e.stopPropagation(); if (props.onCellItemClicked) props.onCellItemClicked(d.id, "open", cell.row, cell.column) }} />
                        <FontAwesomeIcon className="action" icon={faTrashAlt} onClick={(e) => { e.stopPropagation(); if (props.onCellItemClicked) props.onCellItemClicked(d.id, "delete", cell.row, cell.column) }} />
                    </span>
                </div>)
            }
            return <div className="new-desk-item">
                <span>Noch keine Dateien</span>
            </div>
        }

        return <div className="multi-files">
            {renderItems(getItems())}
            <div className="add">
                <FontAwesomeIcon icon={faPlusCircle} onClick={() => props.onCellItemClicked ? props.onCellItemClicked(-1, "new", cell.row, cell.column) : null} />
            </div>
        </div>
    }

    const renderMultiChoice = (cell: TableCell, edit: boolean, multiSelect: boolean) => {
        const getOptions = (cell: TableCell): string[] => {
            try {
                return JSON.parse(cell.placeholder);
            } catch (e) {
                return [];
            }
        }
        const getAnswers = (cell: TableCell): string[] => {
            try {
                return (JSON.parse(getAnswerString(cell)))
            } catch (e) {
                return [];
            }
        }
        const onAddOption = (key: number) => {
            const opt = userInput.get(key);
            if (opt) {
                let c = getOptions(cell);
                c.push(opt);
                updateStringPlaceHolderinCell(JSON.stringify(c), cell);
                let t = new Map(userInput);
                setUserInput(t);
            }
        }
        const onAnswered = (val: string) => {
            let answer = "";
            if (multiSelect) {
                let an = getAnswers(cell);
                const ind = an.indexOf(val);
                if (ind >= 0) {
                    an.splice(ind, 1);
                } else {
                    an.push(val);
                }
                answer = JSON.stringify(an);
            } else {
                answer = JSON.stringify([val])
            }
            onAnswerUpdate(answer, cell);
        }
        return <div className="multi-choice">
            {getOptions(cell).map((o, i) => <div key={i} className="option" onClick={() => onAnswered(o)}><input readOnly={true} type={"checkbox"} value={o} checked={getAnswers(cell).indexOf(o) > -1} />{o}</div>)}
            {edit ?
                <div key={cell.id}>
                    <input type={"text"} value={userInput.get(cell.id) || ""} placeholder="Option" onChange={(e) => addToInputs(e.target.value, cell.id)} />
                    <FontAwesomeIcon icon={faPlusCircle} onClick={() => onAddOption(cell.id)} />
                </div>
                : null
            }
        </div>
    }

    const renderCellItem = (cell: TableCell) => {
        if (props.multipleAnswers) {
            switch (cell.type) {
                case "INFO": return renderInfo(cell, props.edit);
                case "TEXT": return renderUserAnswers(cell);
                case "BOOLEAN": return renderUserAnswers(cell);
                case "LINK": return renderLink(cell, props.edit);
                case "QUOTE": return renderQuote(cell, props.edit);
                case "YOUTUBE": return renderYouTube(cell, props.edit);
                case "CALENDAR": return renderCalendar(cell, props.edit);
                case "STATICIMAGE": return renderStaticImage(cell, props.edit);
                case "IMAGEUPLOAD": return renderUserImage(cell, props.edit);
                case "FILEUPLOAD": return renderUserFile(cell, props.edit);
                case "MULTIFILE": return renderMultiFile(cell);
                case "PHASEICON": return renderPhaseIcon(cell);
                case "MULTICHOICE": return renderUserAnswers(cell);
                case "SINGLECHOICE": return renderUserAnswers(cell);
                default: return <span>MISSING DEFINITION</span>
            }
        } else {
            switch (cell.type) {
                case "INFO": return renderInfo(cell, props.edit);
                case "TEXT": return renderText(cell, props.edit);
                case "BOOLEAN": return renderBoolean(cell, props.edit);
                case "LINK": return renderLink(cell, props.edit);
                case "QUOTE": return renderQuote(cell, props.edit);
                case "YOUTUBE": return renderYouTube(cell, props.edit);
                case "CALENDAR": return renderCalendar(cell, props.edit);
                case "STATICIMAGE": return renderStaticImage(cell, props.edit);
                case "IMAGEUPLOAD": return renderUserImage(cell, props.edit);
                case "FILEUPLOAD": return renderUserFile(cell, props.edit);
                case "MULTIFILE": return renderMultiFile(cell);
                case "PHASEICON": return renderPhaseIcon(cell);
                case "MULTICHOICE": return renderMultiChoice(cell, props.edit, true);
                case "SINGLECHOICE": return renderMultiChoice(cell, props.edit, false);
                default: return <span>MISSING DEFINITION</span>
            }
        }
    }

    const renderCellMenuEntry = (cell: TableCell, type: TableCellType, title: string) => {
        if (props.allowedCellTypes && props.allowedCellTypes.length > 0) {
            if (props.allowedCellTypes.indexOf(type) > -1) {
                return <MenuItem onClick={() => changeCellType(cell, type)}>{title}</MenuItem>
            } else {
                return null;
            }
        }
        return <MenuItem onClick={() => changeCellType(cell, type)}>{title}</MenuItem>
    }

    const renderCellMenu = (cell: TableCell) => {
        if (props.edit) {
            return <Menu key={cell.id} menuButton={<MenuButton><FontAwesomeIcon className="cell-menu" icon={faEllipsisVertical} /></MenuButton>}>
                <SubMenu label="Typ">
                    {renderCellMenuEntry(cell, "INFO", "Info")}
                    {renderCellMenuEntry(cell, "TEXT", "Text")}
                    {renderCellMenuEntry(cell, "MULTICHOICE", "Multi-Choice")}
                    {renderCellMenuEntry(cell, "SINGLECHOICE", "Single-Choice")}
                    {renderCellMenuEntry(cell, "STATICIMAGE", "Bild")}
                    {renderCellMenuEntry(cell, "BOOLEAN", "Ja/Nein")}
                    {renderCellMenuEntry(cell, "LINK", "Link")}
                    {renderCellMenuEntry(cell, "QUOTE", "Zitat")}
                    {renderCellMenuEntry(cell, "YOUTUBE", "YouTube")}
                    {renderCellMenuEntry(cell, "CALENDAR", "Kalender")}
                    {renderCellMenuEntry(cell, "IMAGEUPLOAD", "Bild Hochladen")}
                    {renderCellMenuEntry(cell, "FILEUPLOAD", "Datei Hochladen")}
                </SubMenu>
                <MenuDivider />
                <MenuItem onClick={() => { setShowStylingDialog(true); setSelectedCell(cell.id) }}><FontAwesomeIcon icon={faBorderStyle} /><span>Stil</span></MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => expandCell(cell, false)}><FontAwesomeIcon icon={faLeftRight} /><span>Erweitern</span></MenuItem>
                <MenuItem onClick={() => expandCell(cell, true)}><FontAwesomeIcon icon={faUpDown} /><span>Erweitern</span></MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => deleteCell(cell)}><FontAwesomeIcon icon={faTrashAlt} /><span>Löschen</span></MenuItem>
            </Menu>
        }
    }

    const renderCell = (cell: TableCell | undefined, row: number, col: number) => {
        const basicStyle = { width: (100 / props.data.cols) * (cell?.width || 1) + "%" };
        if (cell) {
            return <td key={cell + "-" + col} colSpan={cell.width} rowSpan={cell.height} style={cell.style ? { ...JSON.parse(cell.style), ...basicStyle } : basicStyle} >
                <div className={"cell-item" + (props.edit ? " edit" : "") + " " + cell.type.toLowerCase()}>
                    <div className="cell-wrapper">
                        {renderCellItem(cell)}
                        {renderCellMenu(cell)}
                    </div>
                </div>
            </td>
        } else {
            if (props.edit && !isCellAlreadyAtPos(row, col)) {
                return <td className="empty-cell" key={row + "-" + col} onClick={() => addCellToData(row, col)}>
                    <FontAwesomeIcon icon={faPlusSquare} size="lg" />
                </td>
            } else if (!isCellAlreadyAtPos(row, col)) {
                return <td key={row + "-" + col} >
                </td>
            }
        }
    }

    const renderCols = (data: TableData, row: number) => {
        let res = [];
        if (props.edit) {
            res.push(<td key={"del-" + row} className="edit-row">
                {renderRowDelete(row)}
            </td>)
        }
        for (let j = 0; j < data.cols; j++) {
            res.push(renderCell(getCell(row, j), row, j))
        }
        return res;
    }

    const renderRowDelete = (row: number) => {
        if (props.edit) {
            return <FontAwesomeIcon icon={faMinusSquare} onClick={() => deleteRow(row)} size="lg" />
        }
    }

    const renderEditColumnsDelete = () => {
        let res = [];
        res.push(<td key={"col-e0"}></td>)
        for (let i = 0; i < props.data.cols; i++) {
            res.push(<td className="edit-col" key={"col-" + i}><FontAwesomeIcon icon={faMinusSquare} onClick={() => deleteCol(i)} size="lg" /></td>)
        }
        return res;
    }

    const renderRows = (data: TableData) => {
        let res = [];
        for (let i = 0; i < data.rows; i++) {
            res.push(<tr key={"row-" + i}>
                {renderCols(data, i)}
            </tr>)
        }
        if (props.edit) {
            res.push(<tr className="edit-row-menu" key={"edit-row"}>
                {renderEditColumnsDelete()}
            </tr>)
        }
        return res;
    }

    const updateCell = (cell: TableCell) => {
        const copied = props.data.tableCells.slice();
        const targetIndex = copied.findIndex(tc => tc.id === cell.id);
        copied[targetIndex] = { ...cell };
        props.onUpdate({ ...props.data, tableCells: copied });
    };

    const actualCell = props.data.tableCells.find(tc => tc.id === selectedCell);

    return <Fragment>
        <div>
            {props.edit ? <div className="add-col">
                <FontAwesomeIcon icon={faPlusSquare} onClick={() => addColumn()} size="lg" />
            </div> : null}
            <table style={props.data.style ? JSON.parse(props.data.style) : {}} className={"compass-table" + (props.edit ? " editmode" : "")}>
                <tbody>
                    {renderRows(props.data)}
                </tbody>
            </table>
            {props.edit ? <div className="add-row">
                <FontAwesomeIcon icon={faPlusSquare} onClick={() => addRow()} size="lg" />
            </div> : null}
            {actualCell &&
                <Dialog className={"expanded-dialog"} component={<textarea onChange={(e) => onAnswerUpdate(e.target.value, actualCell)} value={getAnswerString(actualCell)} />} show={showExpandedDialog} toogle={() => setShowExpandedDialog(false)} />
            }
            {showStylingDialog && actualCell &&
                <Dialog className={"styling-dialog"} component={<StylingDialog cell={actualCell} updateCell={updateCell} />} show={showStylingDialog} toogle={() => setShowStylingDialog(false)} />
            }
        </div>
    </Fragment>
}

const StylingDialog = ({ cell, updateCell }: { cell: TableCell, updateCell: (cell: TableCell) => void }) => {
    const parseBorder = () => {
        if (cell.style) {
            const parsed = JSON.parse(cell.style);
            if (parsed.border) {
                const parts = parsed.border.split(" ");
                return {
                    width: parts[0].replace(/[^0-9]+/g, ''),
                    style: parts[1],
                    color: parts[2]
                }
            }
        }

        return {
            width: "0",
            style: "dashed",
            color: "black"
        }
    }

    const border = parseBorder();

    const setBorder = ({
        width,
        style,
        color
    }: {
        width: string,
        style: string,
        color: string
    }) => {
        const border = `${width}px ${style} ${color}`;

        if (cell.style) {
            updateCell({ ...cell, style: JSON.stringify({ ...JSON.parse(cell.style), border: border }) });
        } else {
            updateCell({ ...cell, style: JSON.stringify({ border: border }) });
        }
    }


    return <div className="style-dialog">
        <h2>Stil</h2>
        <h3>Rahmen</h3>
        <div className="form-element">
            <label>Dicke</label>
            <input value={border.width} onChange={(e) => setBorder({ ...border, width: e.target.value })} type="number" />
        </div>

        <div className="form-element">
            <label>Stil</label>
            <select onChange={(e) => setBorder({ ...border, style: e.target.value })} value={border.style}>
                <option value="dashed">
                    Gestrichelt
                </option>
                <option value="dotted">
                    Gepunktet
                </option>
                <option value="solid">
                    Durchgängig
                </option>
            </select>
        </div>

        <div className="form-element">
            <label>Farbe</label>
            <input value={border.color} onChange={(e) => setBorder({ ...border, color: e.target.value })} type="color" />
        </div>
    </div>;
}