import { ReactElement, useEffect, useState } from "react";
import { sendOlert } from "../../olert/Olert";
import { CircalindApi } from "../circalindApi";
import { validMail } from "../../utils/CircaUtils";
import { useHeaderContext } from "../page/header/Header";

import './login.scss'

export const ResetPass = (): ReactElement => {

    const { updateHeader } = useHeaderContext();
    const [mail, setMail] = useState<string>("");

    useEffect(() => {
        updateHeader([])
    }, [updateHeader])

    const resetPass = async () => {
        if (validMail(mail)) {
            await CircalindApi.requestPassReset(mail.trim());
            sendOlert("Hinweis", "Erfolgreich");
        } else {
            sendOlert("Hinweis", "Bitte geben Sie eine gültige E-mail addresse ein");
        }
    }

    return <div className="login">
        <h2>Passwort Zurücksetzen</h2>
        <div className="login-form">
            <input className={"mail" + (validMail(mail) ? " valid" : " invalid")} type="text" placeholder="E-mail" value={mail} onChange={(e) => setMail(e.target.value)} />
            <label>Wir werden Anweisungen zum zurücksetzen des Passwortes an die angegebene E‑Mail-Adresse senden</label>
            <button onClick={() => resetPass()}>Zurücksetzen</button>
        </div>
    </div>

}
