import competenceTeamImage from "../../../resources/circa/competence/team.png";
import competenceIndividualImage from "../../../resources/circa/competence/erkentnisse1.png";
import { Card } from "./Card";

import "./CompetenceOverview.scss";
import { useSelectedProject } from "../../../utils/Hooks";
import { useEffect, useState } from "react";
import { CircalindApi } from "../../circalindApi";

export const CompetenceOverview = () => {
    return <div className="competence-overview">
        <div></div>
        <CompetenceIndividualCard navigation="../individual" back={false} />
        <CompetenceTeamCard navigation="../team" back={false} />
        <div></div>
    </div>
};

const individualTitle = "Persönliches Profil";
const individualSubtitle = "Kompetenzen & Talente";

const teamTitle = "Projekt-Kompetenz-Profil";
const teamSubtitle = "Soll-/Ist";

export const CompetenceIndividualCard = ({ back, navigation }: { back: boolean, navigation: string }) => {
    return <Card title={individualTitle} subtitle={individualSubtitle} images={[{ path: competenceIndividualImage, title: "" }]} navigation={navigation} back={back} />
}

export const CompetenceTeamCard = ({ back, navigation }: { back: boolean, navigation: string }) => {
    const [currentProject,] = useSelectedProject();
    const [phase, setPhase] = useState<number>();

    useEffect(() => {
        const loadProject = async () => {
            const project = await CircalindApi.getProject(currentProject);

            if (project) {
                setPhase(project.phase);
            }
        };

        loadProject();
    }, [currentProject]);


    return <Card title={teamTitle} subtitle={`${teamSubtitle} (${phase})`} images={[{ path: competenceTeamImage, title: "" }]} navigation={navigation} back={back} />
}