import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useState } from "react";
import Select, { StylesConfig } from 'react-select'
import { DialogCompProps } from "../../basicComponents/dialogs/Dialog";
import { ToDo, TodoState } from "../../dataTypes/generated";
import { DateTime } from 'luxon'
import ICalendarLink from "react-icalendar-link";
import { ICalEvent } from "react-icalendar-link/dist/utils";

export interface newTodoProps extends DialogCompProps {
    todo: ToDo;
    onSave: (todo: ToDo) => Promise<void>;
    onDelete: (todo: ToDo) => Promise<void>;
}

interface SelectItem {
    value: TodoState;
}

export const getTodoColor = (val: string) => {
    switch (val) {
        case "GREEN": return "#18c42f";
        case "YELLOW": return "#f5f100";
        case "RED": return "#c92f14";
        default: return "#cccccc"
    }
}

const getCalEvent = (todo: ToDo): ICalEvent | undefined => {
    if (todo) {
        let start = DateTime.fromJSDate(new Date(todo.date));
        let end = start.plus({ hours: 1 });
        let startIso = start.toISO();
        let endIso = end.toISO();

        if (startIso && endIso) {
            return { title: todo.text, location: "", description: "", startTime: startIso, endTime: endIso }
        }
    }
}

export const NewTodo = (props: newTodoProps): ReactElement => {

    const [todo, setTodo] = useState<ToDo>(props.todo);

    const defaultValue: TodoState = "NONE";

    const options: SelectItem[] = [
        { value: "NONE" },
        { value: "GREEN" },
        { value: "YELLOW" },
        { value: "RED" },
    ];

    const getColor = (item: SelectItem) => {
        return getTodoColor(item.value);
    }

    const square = (color: string) => ({
        alignItems: 'center',
        display: 'flex',

        ':before': {
            backgroundColor: color,
            content: '" "',
            display: 'block',
            marginRight: 8,
            height: 20,
            width: 50,
        },
    });

    const colourStyles: StylesConfig<any> = {
        control: (styles) => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                ...square(getColor(data)),
                backgroundColor: isSelected ? 'lightgray' : isFocused ? 'lightblue' : 'white',
                color: 'black',
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: 'gray'
                },
            };
        },
        input: (styles) => ({ ...styles, ...square('#18c42f') }),
        placeholder: (styles) => ({ ...styles, ...square('#18c42f') }),
        singleValue: (styles, { data }) => ({ ...styles, ...square(getColor(data)) }),
    };

    return <div className="newTodo">
        <h4>Bearbeiten oder Hinzufügen</h4>
        <div className="todo">
            <div className="selection">
                <div className="input-group">
                    <label>Priorität:</label>
                    <Select
                        value={{ value: todo.state.toString() }}
                        isSearchable={false}
                        defaultValue={{ value: defaultValue }}
                        className={"react-select-container"}
                        classNamePrefix={"react-select"}
                        options={options}
                        styles={colourStyles}
                        onChange={
                            (e) => {
                                if (e) {
                                    setTodo({ ...todo, state: (e.value as TodoState) });
                                }
                            }
                        }
                    />
                </div>
                {todo.id !== -1 ? <div className="delete" onClick={() => props.onDelete(todo)}>Erledigt<FontAwesomeIcon icon={faCheck} /></div> : null}
            </div>
            <div>
                <label htmlFor="date">Termin:</label>
                <input value={DateTime.fromJSDate(new Date(todo.date)).toFormat("yyyy-MM-dd'T'HH:mm")} id="date" type="datetime-local" onChange={e => setTodo({ ...todo, date: new Date(e.target.value).toISOString() })} />
                <div>
                    {/* 
                     // @ts-ignore */}
                    <ICalendarLink event={getCalEvent(todo)}>Dem Kalender hinzufügen</ICalendarLink>;
                </div>
            </div>
            <textarea className={todo.text.length === 0 ? "invalid" : ""} placeholder="Beschreibung" value={todo.text} onChange={(e) => setTodo({ ...todo, text: e.target.value })} />
        </div>
        <div className="new-navigation">
            <div className="left">
                <button onClick={() => props.toggle()}>Abbrechen</button>
            </div>
            <div className="right">
                <button disabled={todo.text.length === 0} onClick={() => props.onSave(todo)}>Speichern</button>
            </div>
        </div>
    </div>
}