import { useEffect, useState } from "react";
import { Dialog } from "../basicComponents/dialogs/Dialog";
import { CircalindApi } from "../circalindApi";
import { GeneralContent } from "../contentManager/GeneralContent";
import { Area, CircaPackage, Question, SubArea } from "../dataTypes/generated";
import { getPhaseImage, getPhaseTitle } from "../projects/phases/PhaseHeader";

import "./SubAreaContentByPhase.scss";
import { useSelectedProject } from "../../utils/Hooks";

export const SubAreaContentByPhase = ({ area }: { area: Area }) => {
    const [license, setLicense] = useState<CircaPackage | null>(null);
    const [selectedProject,] = useSelectedProject();
    const [question, setQuestion] = useState<Question | null>();
    const [selectedSubarea, setSelectedSubarea] = useState<SubArea | null>(null);
    const [subAreas, setSubAreas] = useState<Array<SubArea>>([]);
    const [showDialog, setShowDialog] = useState<boolean>(false);

    useEffect(() => {
        const loadData = async () => {
            setLicense(await CircalindApi.getLicense());
        }

        loadData();
    }, []);

    useEffect(() => {
        const loadSubAreas = async () => {
            const response = await CircalindApi.getSubAreas({ groupId: -1, area: area, targetProjectId: selectedProject, targetInternalGroupId: null, targetUserId: null });
            setSubAreas(response);
            if (response.length > 1) {
                setSelectedSubarea(response[0]);
            }
        };

        loadSubAreas();
    }, [selectedProject, area]);

    const renderSubarea = (subarea: SubArea) => {
        const img = subarea.image && <img src={subarea.image} alt={subarea.name} />;
        if (selectedSubarea === subarea) {
            return <div key={subarea.id} onClick={() => setSelectedSubarea(subarea)} className="card">{img}<strong>{subarea.name}</strong></div>;
        } else {
            return <div key={subarea.id} onClick={() => setSelectedSubarea(subarea)} className="card">{img}{subarea.name}</div>;
        }
    };

    const renderPhases = () => {
        return (license && license.type === "UK1" ? [1] : [1,2,3,4,5]).map(i => {
            const callback = async () => {
                if (selectedSubarea) {
                    const response = await CircalindApi.getContentQuestionByFilterForSubArea({
                        subArea: selectedSubarea.id, phase: i,
                        notes: false,
                        userId: -1,
                        order: -1
                    });
                    if (response) {
                        setQuestion(response);
                    } else {
                        const response = await CircalindApi.getContentQuestionByFilterForSubArea({
                            subArea: selectedSubarea.id, phase: -1,
                            notes: false,
                            userId: -1,
                            order: -1
                        });
                        setQuestion(response);
                    }
                    setShowDialog(true);
                }
            };

            return <div onClick={callback} className="card" key={i}><img src={getPhaseImage(i)} alt={getPhaseTitle(i)} /><strong>{getPhaseTitle(i)}</strong></div>
        });
    };

    return <>
        <div className="area-header">
            {subAreas.map(renderSubarea)}
        </div>
        <div className="phases">
            {renderPhases()}
        </div>
        <Dialog className={"with-padding"} component={question ? <GeneralContent question={question} canEdit={false} canReview={false} showControls={false} /> : <div style={{ padding: "30px" }}>Kein Inhalt verfügbar</div>} show={showDialog} toogle={() => setShowDialog(false)} />
    </>;
};
