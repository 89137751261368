import Select from 'react-select';
import { useEffect, useState } from "react";
import { UserSelect } from "../../leading/dialogs/UserSelect";
import { User } from "../dataTypes/generated";
import { CircalindApi } from "../circalindApi";

import "./SendMessage.scss";
import { SendMessageType, communicationTitles } from './InteractionOverview';

export const SendMessage = ({ type, user }: { type: SendMessageType | null, user: User | undefined }) => {
    const [selectedTopic, setSelectedTopic] = useState<SendMessageType | null>(type);
    const [selectedUser, setSelectedUser] = useState<User | undefined>(user);
    const [users, setUsers] = useState<Array<User>>([]);

    useEffect(() => {
        const getData = async () => {
            const users = await CircalindApi.getAllUsersOfMyGroup();
            setUsers(users);
        };

        getData();
    }, []);

    const options = Object.entries(communicationTitles).map(entry => {
        return {
            label: entry[1],
            value: (+entry[0]) as SendMessageType
        };
    });

    const draftEmail = () => {
        window.location.href = `mailto:${selectedUser!.mail}?subject=${encodeURIComponent(communicationTitles[selectedTopic!])}`;
    };

    return <div className="send-message">
        <h2>Nachricht schicken</h2>
        <div className="block">
            <label>Thema:</label>
            <Select value={options.find(o => o.value === selectedTopic)} onChange={(o) => { setSelectedTopic(o!.value) }} options={options} />
        </div>
        <div className="block">
            <label>An Nutzer:</label>
            <UserSelect useName={true} valueUserId={selectedUser?.id} users={users} onSelect={(u => setSelectedUser(u))} />
        </div>
        <div className="button-container">
            <button onClick={draftEmail} disabled={!(selectedTopic !== null && selectedUser !== undefined)}>E-Mail formulieren</button>
        </div>
    </div>;
};