import { useNavigate } from "react-router"

import erkenntnisseImage from "../../../../resources/circa/competence/erkentnisse1.png";
import selbstImage from "../../../../resources/circa/compass/Selbstführung.png";
import DoorImg from "../../../../resources/circa/door.png";

import "./PersonalDashboard.scss"

export const PersonalDashboard = () => {
    const navigate = useNavigate();

    return <div className="personal-dashboard">
        <div className="card main">
            <h2>Persönliche Weiterentwicklung</h2>
            <div onClick={() => navigate("/circalind/regular/competence/individual")} className="img-card card clickable">
                <img src={erkenntnisseImage} alt="Persönliche Kompetenzen"></img>
            </div>
        </div>
        <div className="card main">
            <h2>Kompass: Selbstführung</h2>
            <div onClick={() => navigate("/circalind/regular/phase/compass/self")} className="img-card card clickable">
                <img src={selbstImage} alt="Selbstführung"></img>
            </div>
        </div>
        <div className="card main">
            <h2>Nächste Schritte</h2>
            <div onClick={() => { navigate("/circalind/managing/transfer") }} className="img-card card clickable">
                <img src={DoorImg} alt="" />
            </div>
        </div>
    </div>
}