import { decodeJwt } from "jose";
import { RoleType } from "../circalind/dataTypes/generated";

export interface Token {
    iss: string;
    user: string;
    id: number;
    roles: string;
    activeGroup: number;
    nbf: number;
    exp: number;
}

export type storageKey = "token-circalind";

export const getToken = (storage: storageKey): string | null => {
    const item = localStorage.getItem(storage);
    if (item) {
        try {
            decodeJwt(item);
            return item;
        } catch (e) {
            console.error("Invalid saved token.");
        }
    }

    return null;
}

export const setToken = (storage: storageKey, token: string | null) => {
    if (token) {
        localStorage.setItem(storage, token);
    } else {
        localStorage.removeItem(storage);
    }
}

export const clearToken = (storage: storageKey) => {
    localStorage.removeItem(storage);
}

export const validateToken = (storage: storageKey): boolean => {
    const token = getToken(storage);
    if (token != null && token.length > 0) {
        let tokenPayload: Token = decodeJwt<Token>(token);

        const currentTimestamp = Math.floor(Date.now() / 1000);

        if (tokenPayload.exp && currentTimestamp >= tokenPayload.exp) {
            return false;
        }

        if (!tokenPayload.user && tokenPayload.user.length === 0) {
            return false;
        }

        return true;
    }
    return false;
}

export const getCurrentUser = (storage: storageKey): string | null => {
    const token = getToken(storage);
    if (token) {
        let tokenPayload: Token = decodeJwt<Token>(token);
        return tokenPayload.user;
    }
    return null;
}

export const getCurrentUserId = (storage: storageKey): number | null => {
    const token = getToken(storage);
    if (token) {
        let tokenPayload: Token = decodeJwt<Token>(token);
        return tokenPayload.id;
    }
    return null;
}

export const getActiveGroup = (storage: storageKey): number | null => {
    const token = getToken(storage);
    if (token) {
        let tokenPayload: Token = decodeJwt<Token>(token);
        return tokenPayload.activeGroup;
    }
    return null;
}

export const getUserRoles = (storage: storageKey): RoleType[] => {
    const token = getToken(storage);
    if (token) {
        let tokenPayload: Token = decodeJwt<Token>(token);
        if (tokenPayload) {
            let res: RoleType[] = [];
            let role: string[] = tokenPayload.roles.split(";");
            role.forEach(r => res.push(r as RoleType))
            return res;
        }
    }
    return [];
}