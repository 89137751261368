import "./CustomerOrderView.scss";
import { useNavigate } from "react-router";
import { PackageOverview } from "./PackageOverview";

export const CustomerPackageOverview = () => {
    const navigate = useNavigate();

    return <div className="customer-order-overview">
        <h2>Auswertung Laufzeiten</h2>
        <div className="right">
            <button onClick={() => navigate("/circalind/services/choose-license")}>Zurück</button>
        </div>
        <PackageOverview isAdmin={false} showTypeSelection={false} />
    </div>;
}