import { useNavigate } from "react-router";
import { SubAreaContent } from "../../../contentManager/SubAreaContent";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelectedProject } from "../../../../utils/Hooks";
import { useEffect, useRef, useState } from "react";
import { CircalindApi } from "../../../circalindApi";
import { CircaPackage, Project, ProjectImage } from "../../../dataTypes/generated";
import { Base64Upload } from "../../../basicComponents/base64Upload/Base64Upload";
import Select from 'react-select';
import { Dialog } from "../../../basicComponents/dialogs/Dialog";
import { SynchronimeterAndControls } from "../../phases/prePhase/synchro/SynchronimeterAndControls";
import { PrePhase } from "../../phases/prePhase/PrePhase";
import timeHorizonImage from "../../../../resources/circa/time-horizon.png";
import interjectionImage from '../../../../resources/circa/interjection.png'

import "./ProjectBase.scss";
import { refToHtml } from "../../../../invoice/utils/Util";
import { getTodoColor } from "../../../practical/transfer/NewTodo";


// Start
// Zeithorizont
// Priorität
// Synchro
// Persönliche Entwicklung
// Projekt Entwicklung
// Selbstführung
// Management
// Spurwechsel
// Link zu Phase 1

export const ProjectBaseStart = () => {
    const navigate = useNavigate();
    const [projectId,] = useSelectedProject()
    const [project, setProject] = useState<Project | null>(null);
    const [projectImage, setProjectImage] = useState<ProjectImage | null>(null);
    const myRef = useRef<HTMLDivElement>(null);

    const loadProject = async () => {
        setProject(await CircalindApi.getProject(projectId))
        setProjectImage(await CircalindApi.getProjectImage(projectId));
    };

    useEffect(() => {
        const internalLoadProject = async () => {
            setProject(await CircalindApi.getProject(projectId))
            setProjectImage(await CircalindApi.getProjectImage(projectId));
        };

        internalLoadProject();
    }, [projectId]);


    const saveToDocumentation = async () => {
        const html = await refToHtml(myRef);
        const type = "start";
        await CircalindApi.saveToWay({ projectId: projectId, phase: 0, index: 0, type: type, html: html });
    };

    return <div ref={myRef} className="project-base">
        <div className="card start">
            <h2>{project && project.phase > 0 ? "Projektbasis aktualisieren" : "Projektbasis eingeben"}</h2>
            <h3>{project?.name}</h3>
            {projectImage?.image && <img src={projectImage?.image} alt={project?.name} />}
            <Base64Upload onUpload={async (d, f) => { await CircalindApi.setProjectImage({ projectId: projectId, image: d }); await loadProject(); }} type={"IMAGE"} />
        </div>
        <div className={"navigation"}>
            <button disabled><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button onClick={async () => { await saveToDocumentation(); navigate("../time"); }}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>;
};

const EditImportance = ({ project, updateProject }: { project: Project, updateProject: (project: Project) => Promise<void> }) => {
    const importanceOptions = ["A", "B", "C"].map(p => { return { label: p, value: p } });
    const [importance, setImportance] = useState<string>(project.importance);
    const [status, setStatus] = useState<string>(project.status);

    const colorOptions = ["GREEN", "YELLOW", "RED"].map(p => { return { label: <span style={{ display: "block", width: "20px", height: "20px", backgroundColor: getTodoColor(p) }}></span>, value: p } });

    return <div className="edit-dialog">
        <label>Priorität</label>
        <Select value={importanceOptions.find(po => po.value === importance)} options={importanceOptions} onChange={(opt) => setImportance(opt!.value)} />
        <label>Status: </label>
        <Select value={colorOptions.find(po => po.value === status)} options={colorOptions} onChange={(opt) => setStatus(opt!.value)} />
        <button onClick={() => updateProject({ ...project, importance, status })}>Speichern</button>
    </div>
}

const EditTemporal = ({ project, updateProjectTemporal }: { project: Project, updateProjectTemporal: (temporal: number) => Promise<void> }) => {
    const temporalOptions = [{ label: "Aktuell", value: 0 }, { label: "Mittelfristig", value: 1 }, { label: "Langfristig", value: 2 }];
    const [temporal, setTemporal] = useState<number>(project.temporal);

    return <div className="edit-dialog">
        <label>Zeithorizont</label>
        <Select value={temporalOptions.find(po => po.value === temporal)} options={temporalOptions} onChange={(opt) => setTemporal(opt!.value)} />
        <button onClick={() => updateProjectTemporal(temporal)}>Speichern</button>
    </div>
}


export const ProjectBaseTime = () => {
    const navigate = useNavigate();
    const [projectId,] = useSelectedProject()
    const [project, setProject] = useState<Project | null>(null);
    const [showTemporalDialog, setShowTemporalDialog] = useState(false);

    const updateProjectTemporal = async (temporal: number) => {
        if (project) {
            const updatedProject = { ...project, temporal: temporal };
            await CircalindApi.addNewProject(updatedProject);
            setShowTemporalDialog(false);
            loadProject();
        }
    };


    const loadProject = async () => {
        setProject(await CircalindApi.getProject(projectId))
    };

    useEffect(() => {
        const internalLoadProject = async () => {
            setProject(await CircalindApi.getProject(projectId))
        };

        internalLoadProject();
    }, [projectId]);

    return <div className="project-base">
        <h2>Zeithorizont</h2>
        <div className="button-wrapper">
            <p>Auf welchen Zeit-Horizont richten die Treiber / Beweggründe das Projekt aus? </p>
            <div className="card clickable small">
                <img onClick={() => setShowTemporalDialog(true)} src={timeHorizonImage} alt="" />
            </div>
        </div>
        <div className={"navigation"}>
            <button onClick={() => navigate("../start")}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button onClick={() => navigate("../importance")}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
        {project && <Dialog className={"importance-dialog"} component={<EditTemporal project={project} updateProjectTemporal={updateProjectTemporal} />} show={showTemporalDialog} toogle={() => setShowTemporalDialog(false)} />}
    </div>
};

export const ProjectBaseImportance = () => {
    const navigate = useNavigate();
    const [projectId,] = useSelectedProject()
    const [project, setProject] = useState<Project | null>(null);
    const [showImportanceDialog, setShowImportanceDialog] = useState(false);

    const updateProject = async (project: Project) => {
        if (project) {
            await CircalindApi.addNewProject(project);
            setShowImportanceDialog(false);
            loadProject();
        }
    };

    const loadProject = async () => {
        setProject(await CircalindApi.getProject(projectId))
    };

    useEffect(() => {
        const internalLoadProject = async () => {
            setProject(await CircalindApi.getProject(projectId))
        };

        internalLoadProject();
    }, [projectId]);

    return <div className="project-base">
        <h2>Priorität</h2>
        <div className="button-wrapper">
            <p>Welche Priorität hat das Projekt in Bezug auf welchen Zeit-Horizont?</p>
            <div onClick={() => setShowImportanceDialog(true)} className="card clickable small">
                <span className="importance"><span className="A">A,</span> <span className="B">B,</span> <span className="C">C</span></span>
            </div>
        </div>
        <div className={"navigation"}>
            <button onClick={() => navigate("../time")}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button onClick={() => navigate("../drivers")}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
        {project && <Dialog className={"importance-dialog"} component={<EditImportance project={project} updateProject={updateProject} />} show={showImportanceDialog} toogle={() => setShowImportanceDialog(false)} />}
    </div>

};

export const ProjectBaseDrivers = () => {
    const navigate = useNavigate();

    return <div className="project-base">
        <h2>Treiber & Herausforderungen</h2>
        <SubAreaContent isProjectSpecific={true} notes={true} area={"practical"} />
        <div className={"navigation"}>
            <button onClick={() => navigate("../importance")}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button onClick={() => navigate("../interjection")}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>
};

export const ProjectBaseInterjection = () => {
    const navigate = useNavigate();

    return <div className="phase-interjection">
        <h1><strong>Einstieg in die Reflektion</strong></h1>
        <div className="img-container">
            <img src={interjectionImage} alt="Spezifizieren & Navigieren" />
        </div>
        <div className="navigation">
            <button onClick={() => navigate("../drivers")}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button onClick={() => navigate("../pre/manage")}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>;
};

export const ProjectBaseManage = () => {
    const navigate = useNavigate();
    const myRef = useRef<HTMLDivElement>(null);
    const [projectId,] = useSelectedProject()

    const saveToDocumentation = async () => {
        const html = await refToHtml(myRef);
        const type = "manage";
        await CircalindApi.saveToWay({ projectId: projectId, phase: 0, index: 2, type: type, html: html });
    };

    return <div ref={myRef} className="project-base">
        <PrePhase onPrev={async () => { await saveToDocumentation(); navigate("../interjection"); }} onNext={async () => { await saveToDocumentation(); navigate("../pre/lane") }} phaseType={"Management"} />
    </div>
};

export const ProjectBaseLaneChange = () => {
    const navigate = useNavigate();
    const myRef = useRef<HTMLDivElement>(null);
    const [projectId,] = useSelectedProject()
    const [license, setLicense] = useState<CircaPackage | null>(null);

    useEffect(() => {
        const loadLicense = async () => {
            setLicense(await CircalindApi.getLicense());
        }

        loadLicense();
    }, []);

    const saveToDocumentation = async () => {
        const html = await refToHtml(myRef);
        const type = "lane";
        await CircalindApi.saveToWay({ projectId: projectId, phase: 0, index: 3, type: type, html: html });
    };

    return <div ref={myRef} className="project-base">
        <PrePhase onPrev={async () => { await saveToDocumentation(); navigate("../pre/manage") }} onNext={license && license.type === "UK1" ? undefined : async () => { await saveToDocumentation(); navigate("../../../phase") }} phaseType={"Spur-Wechsel"} />
    </div>
};

export const OneViewSynchronimeter = () => {
    const navigate = useNavigate();
    return <SynchronimeterAndControls onPrev={() => navigate("../../dashboard")} />;
};