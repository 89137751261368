import NavigateImage from "../../../../resources/circa/cockpit/navigate.png";
import EmptyProjectImage from "../../../../resources/circa/cockpit/project-empty.png";
import OneViewImage from "../../../../resources/circa/cockpit/one-view.png";
import DeepImage from "../../../../resources/circa/cockpit/deep.png";
import PersonalNavigationImage from "../../../../resources/circa/cockpit/personal_navigation.png";
import { useNavigate } from "react-router";
import { useState } from "react";

import "./CockpitOverview.scss";

export const flipArrayEntry = (index: number, array: Array<boolean>) => {
    const copy = [...array];
    copy[index] = !copy[index];
    return copy;
};

export const CockpitOverview = () => {
    const navigate = useNavigate();

    const [showFront, setShowFront] = useState<Array<boolean>>([true, true, true, true]);

    return <div className="cockpit-overview">
        <div onClick={() => setShowFront(flipArrayEntry(0, showFront))} className={`card content-card ${showFront[0] ? 'show-front' : 'show-back'}`}>
            <div className="front">
                <h3>Persönliches Navigations-System</h3>
                <div onClick={() => navigate("../personal")} className="image-wrapper card clickable">
                    <img src={PersonalNavigationImage} alt="" />
                </div>
            </div>
            <div className="back">
                <p>
                </p>
            </div>
        </div>
        <div onClick={() => setShowFront(flipArrayEntry(2, showFront))} className={`card content-card ${showFront[2] ? 'show-front' : 'show-back'}`}>
            <div className="front">
                <h3>Steuerung aller Projekte</h3>
                <div onClick={() => navigate("../one")} className="image-wrapper card clickable">
                    <img src={OneViewImage} alt="" />
                </div>
                <h3>Schnellzugriff</h3>
                <div onClick={() => navigate("../dashboard")} className="clickable image-wrapper card">
                    <img src={NavigateImage} alt="" />
                </div>
            </div>
            <div className="back">
                <p>
                    <strong>alle Projekte</strong>
                    <ul>
                        <li>Status aller Projekte</li>
                    </ul>
                    <strong>je Projekt:</strong>
                    <ul>
                        <li>Projekt-spezifisch Treiber, Projekt-Kontext &amp; Projekt-Kontext & Herausforderungen</li>
                        <li>Projekt-Verlauf</li>
                    </ul>
                </p>
            </div>
        </div>
        <div onClick={() => setShowFront(flipArrayEntry(1, showFront))} className={`card content-card ${showFront[1] ? 'show-front' : 'show-back'}`}>
            <div className="front">
                <h3>Projekt-Basis je Projekt</h3>
                <div onClick={() => navigate("../base/start")} className="image-wrapper card clickable">
                    <img src={EmptyProjectImage} alt="" />
                </div>
            </div>
            <div className="back">
                <p>
                    <strong>
                        Projekt-Basis<br />
                    </strong>
                    Treiber, Projekt-Kontext &amp; Herausforderungen<br />
                    <strong>
                        Projekt-Expertise & Potenziale
                    </strong>

                    <hr />
                    Zum Projekt-Beginn <br />
                    hier loslegen
                </p>
            </div>
        </div>
        <div onClick={() => setShowFront(flipArrayEntry(3, showFront))} className={`card content-card ${showFront[3] ? 'show-front' : 'show-back'}`}>
            <div className="front">
                <h3>Räume zum Vertiefen</h3>
                <div onClick={() => navigate("../deep")} className="image-wrapper card clickable">
                    <img src={DeepImage} alt="" />
                </div>
            </div>
            <div className="back">
                <p>
                    <ul>
                        <li><strong>Selbstführung</strong></li>
                        <li><strong>Management</strong><br />Projekt-Spezifika</li>
                        <li><strong>Spur-Wechsel</strong><br />Innovation & Veränderung</li>
                    </ul>
                </p>
            </div>
        </div>
    </div>;
};