import { ReactElement, useEffect, useState } from "react";
import { useSelectedProject } from "../../utils/Hooks";
import { Project, ProjectWayEntry } from "../dataTypes/generated";
import { CircalindApi } from "../circalindApi";

import mywayImage from "../../resources/circa/documentation.png";

import './wayBooklet.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";


export const WayBooklet = (): ReactElement => {
    const [projectId,] = useSelectedProject();
    const [project, setProject] = useState<Project | null>(null);
    const [selectedPage, setSelectedPage] = useState<number>(-1);
    const [pages, setPages] = useState<Array<ProjectWayEntry>>([]);
    const [html, setHtml] = useState<string>("");

    const navigate = useNavigate();

    const getEntries = async (projectId: number) => {
        const entries = await CircalindApi.getProjectWay(projectId);
        setPages(entries);
    };

    useEffect(() => {
        getEntries(projectId);
    }, [projectId]);

    useEffect(() => {
        if (selectedPage >= 0 && pages.length > selectedPage) {
            setHtml(pages[selectedPage].html);
        } else {
            setHtml("");
        }
    }, [selectedPage, pages]);

    const loadProject = async () => {
        const project = await CircalindApi.getProject(projectId);
        setProject(project);
    }

    useEffect(() => {
        loadProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    const getBorderClass = (phase: number) => {
        if (project && project.phase >= phase) {
            return "border-green";
        } else {
            return "";
        }
    };

    const renderFrontPage = () => {
        return <>
            <h2>{project && project.compact ? "Projekt-Weg" : "Mein-Weg"} </h2>
            <div className="row">
                <div className="image-wrapper">
                    <img alt="Mein Weg Icon" src={mywayImage} />
                </div>
                <div className={getBorderClass(0)}></div>
                <div>
                    <h3>zu Beginn: Projekt-Basis</h3>
                    <ul>
                        <li>Meine Perspektive auf das Projekt: „Ich & das Projekt“</li>
                        <li>Treiber, Projekt-Kontext & Herausforderungen</li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="image-wrapper">
                    <img alt="Mein Weg Icon" src={mywayImage} />
                </div>
                <div>
                    <div style={{ height: "20%" }} className={getBorderClass(0)}></div>
                    <div style={{ height: "20%" }} className={getBorderClass(2)}></div>
                    <div style={{ height: "20%" }} className={getBorderClass(3)}></div>
                    <div style={{ height: "20%" }} className={getBorderClass(4)}></div>
                    <div style={{ height: "20%" }} className={getBorderClass(5)}></div>
                </div>
                <div>
                    <h3>5 Phasen während des Projektes</h3>
                    <ol>
                        <li> Phase: Anliegen - Ziel formulieren</li>
                        <li> Phase: Lage beschreiben</li>
                        <li> Phase: Lage bewerten</li>
                        <li> Phase: Entscheidungen treffen</li>
                        <li> Phase: Vorhaben umsetzen</li>
                    </ol>
                </div>
            </div>
            <div className="row">
                <div className="image-wrapper">
                    <img alt="Mein Weg Icon" src={mywayImage} />
                </div>
                <div>
                    <div className={getBorderClass(5)}></div>
                </div>
                <div>
                    <h3>zum Abschluss</h3>
                    <p>
                        Erkenntnisse zusammenfassen<br />
                        Wissen sichern
                    </p>
                </div>
            </div>
        </>;
    };

    const renderDocumentation = () => {
        return <iframe title="documentation" sandbox="allow-same-origin" srcDoc={html}></iframe>
    }

    const renderSelectedPage = () => {
        if (selectedPage === -1) {
            return renderFrontPage();
        } else {
            return renderDocumentation();
        }
    }

    return <div className="my-way-container">
        {renderSelectedPage()}
        <div className={"navigation"}>
            <button onClick={() => selectedPage - 1 === -2 ? navigate("../desk") : setSelectedPage(Math.max(-1, selectedPage - 1))}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button onClick={() => setSelectedPage(Math.min(pages.length - 1, selectedPage + 1))}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>;
}



