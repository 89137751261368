import { useEffect, useState } from "react";
import { LeadingInfoOverview } from "../circalind/info/LeadingInfoOverview";
import { useNavigate } from "react-router";
import { CircalindApi } from "../circalind/circalindApi";
import { CircaPackage, Group } from "../circalind/dataTypes/generated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import "./Start.scss";
import "../circalind/page/menus/Menu.scss";

import { LeadingModeHeader } from "../circalind/page/menus/Menu";

export const LeadingInfo = () => {
    const [circaPackage, setCircaPackage] = useState<CircaPackage | null>();
    const [group, setGroup] = useState<Group | null>();

    const navigate = useNavigate();

    const getGroup = async () => {
        setGroup(await CircalindApi.getMyGroup())
    };

    useEffect(() => {
        const loadPackage = async () => {
            setCircaPackage(await CircalindApi.getLicense());
        };

        loadPackage();
        getGroup();
    }, []);

    return <div className="full-page">
        <h1><strong>Führung und Zusammenarbeit</strong></h1>
        {group && <LeadingModeHeader group={group} updateGroup={() => getGroup()} edit={true} />}
        <h3>{circaPackage && circaPackage.type}</h3>
        <LeadingInfoOverview edit={false} />
        <div className={"navigation"}>
            <button onClick={() => navigate("../start")}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button disabled><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>;
};