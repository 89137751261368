import { faArrowAltCircleLeft, faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, ReactElement, useEffect, useState } from "react";
import { CircalindApi } from "../../circalindApi";

import phase1Image from '../../../resources/circa/phasen/phase_1.png';
import phase2Image from '../../../resources/circa/phasen/phase_2.png';
import phase3Image from '../../../resources/circa/phasen/phase_3.png';
import phase4Image from '../../../resources/circa/phasen/phase_4.png';
import phase5Image from '../../../resources/circa/phasen/phase_5.png';

import './phase.scss'
import { useNavigate } from "react-router";
import { useSelectedProject } from "../../../utils/Hooks";

export const PhaseStart = (): ReactElement => {

    const [selectedProject,] = useSelectedProject();
    const [projectId, setProjectId] = useState<number>(-1);

    const [phase, setPhase] = useState<number>(1);
    const navigate = useNavigate();

    useEffect(() => {
        setProjectId(selectedProject);
    }, [selectedProject])

    useEffect(() => {
        const loadPhaseID = async () => {
            const p = await CircalindApi.getProject(projectId);
            if (p !== null) {
                setPhase(p.phase);
            }
        }
        if (projectId !== -1) {
            loadPhaseID();
        }
    }, [projectId])

    const isActive = (index: number) => {
        if (index === phase) {
            return " active";
        }
        return "";
    }

    const goToPhases = () => {
        if (phase === 0) {
            navigate("/circalind/regular/phase/decide");
        } else {
            navigate("/circalind/regular/phase/" + phase);
        }
    }

    return <div className="phase-start">
        <div>
            <div className="title">
                <h2>5 Phasen zum Vorankommen</h2>
                <h2>Strategie & Vorgehen reflektieren</h2>
            </div>
            <div className="phases">
                <div className="phase-image">
                    <img src={phase1Image} alt={"error"} />
                </div>
                <div className="phase-image">
                    <img src={phase2Image} alt={"error"} />
                </div>
                <div className="phase-image">
                    <img src={phase3Image} alt={"error"} />
                </div>
                <div className="phase-image">
                    <img src={phase4Image} alt={"error"} />
                </div>
                <div className="phase-image">
                    <img src={phase5Image} alt={"error"} />
                </div>
            </div>
            <div className="texts">
                <div className={"description" + isActive(1)}>
                    <span>1.Phase</span>
                    <span>Anliegen - Ziel formulieren</span>
                </div>
                <div className={"description" + isActive(2)}>
                    <span>2.Phase</span>
                    <span>Lage beschreiben</span>
                </div>
                <div className={"description" + isActive(3)}>
                    <span>3.Phase</span>
                    <span>Lage bewerten</span>
                </div>
                <div className={"description" + isActive(4)}>
                    <span>4.Phase</span>
                    <span>Entscheidungen treffen</span>
                </div>
                <div className={"description" + isActive(5)}>
                    <span>5.Phase</span>
                    <span>Vorhaben umsetzen</span>
                </div>
            </div>
        </div>
        <div className="navigation">
            {projectId <= -1 ?
                <span>Kein Projekt gewählt. Bitte wählen sie eins</span> :
                <Fragment>
                    <button disabled={true}><FontAwesomeIcon icon={faArrowAltCircleLeft} /></button>
                    <button disabled={projectId <= -1} onClick={() => goToPhases()}><FontAwesomeIcon icon={faArrowAltCircleRight} /></button>
                </Fragment>
            }
        </div>
    </div>
}
