export const defaultConfig: Config = {
    projectRadiusStrokeWidth: 1,
    showText: true,
    innerRadius: 10,
    sectionMargin: 3,
    sectionGap: 3,
    projectRadius: 10,
    valueWidth: 3,
    sections: [
        { name: "Kurzfristig", color: "lightgray", highlightColor: "lightblue" },
        { name: "Mittelfristig", color: "lightgray", highlightColor: "lightblue" },
        { name: "Langfristig", color: "lightgray", highlightColor: "lightblue" }
    ]
}

export type Config = {
    showText: boolean;
    innerRadius: number,
    projectRadiusStrokeWidth: number;
    sectionMargin: number,
    sectionGap: number,
    sections: Section[],
    projectRadius: number
    valueWidth: number
}

export type Section = {
    name: string,
    color: string,
    highlightColor: string
}
