import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { Bar, BarChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { SubGraphProps } from "./Graphs";
import { EditingState } from "./RadarGraph";


export const ArrowGraph = (props: SubGraphProps): ReactElement => {
    const componentProps = props;
    const ref = useRef<any>(null);
    const [editingState, setEditingState] = useState<EditingState>({ kind: "idle" });
    useEffect(() => {
        if (ref.current && ref.current.current) {
            ref.current.current.addEventListener("pointerup", () => {
                setEditingState({ kind: "idle" });
            });
        }
    }, [ref]);


    const barData = () => {
        let res: any[] = [];
        let bar1: any = { name: "-" }
        for (let i = 0; i < props.data.length; i++) {
            let varr1 = props.data[i].uuid;
            bar1[varr1] = props.data[i].points[0].value;
            bar1["desc"] = props.data[i].title;
        }
        res.push(bar1);
        return res;
    }

    const onPointMove = useCallback((e: PointerEvent) => {
        if (editingState.kind === "editing") {
            const axisLine = ref.current.current.querySelector(".recharts-xAxis").querySelector(".recharts-cartesian-axis-line")
            const chartX = e.clientX - axisLine.getBoundingClientRect().x;
            const width = axisLine.getBoundingClientRect().width;

            let newData = [...props.data]
            let newLineIndex = editingState.valueIndex;
            let newPointVal = 200 * (chartX / width) - 100;
            newPointVal = Math.max(-100, Math.min(100, newPointVal));
            if (newLineIndex >= 0) {
                newData[newLineIndex].points[editingState.pointIndex].value = newPointVal;
                props.updateData(newData);
            }
        }
    }, [editingState, props]);

    useEffect(() => {
        const copiedRef = ref?.current?.current;
        if (copiedRef) {
            copiedRef.addEventListener("pointermove", onPointMove);
            return () => {
                copiedRef.removeEventListener("pointermove", onPointMove);
            }
        }
    }, [ref, onPointMove]);

    const renderArrow = (props: any, id: string) => {
        const { fill, x, y, width, index, value } = props;
        const valueIndex = componentProps.data.findIndex(v => v.uuid === id);
        const pointIndex = index as number;

        const editState = {
            kind: "editing" as const,
            valueIndex, pointIndex
        }

        //                         C
        //                         |\
        //     A                  B| \
        //     =====================  \
        //     |                       >D
        //     =====================  /
        //     G                  F| /
        //                         |/
        //                         E
        //

        const xFactor = value > 0 ? 1 : -1;

        const dx = width + x;
        const dy = y;

        const arrowWidth = 20;
        const arrowHeight = 20;
        const lineHeight = 10;

        const cx = dx - arrowWidth * xFactor
        const cy = dy - arrowHeight / 2;

        const ex = dx - arrowWidth * xFactor
        const ey = dy + arrowHeight / 2;

        const fx = dx - arrowWidth * xFactor;
        const fy = dy + lineHeight / 2;

        const bx = dx - arrowWidth * xFactor;
        const by = dy - lineHeight / 2;

        const gx = x;
        const gy = dy + lineHeight / 2;

        const ax = x;
        const ay = dy - lineHeight / 2;

        const points = `
            ${ax} ${ay},
            ${bx} ${by},
            ${cx} ${cy},
            ${dx} ${dy},
            ${ex} ${ey},
            ${fx} ${fy},
            ${gx} ${gy},
            ${ax} ${ay}
        `;

        return <g>
            <line x1={x} y1={y} x2={x + width + (value > 0 ? -20 : 20)} y2={y} stroke={fill}
                strokeWidth="8" />
            <polygon
                fill={fill}
                points={points}
                onPointerDown={() => setEditingState(editState)} />
        </g>
    }

    const renderBar = () => {
        return props.data.map((l, i) => <Bar
            key={l.uuid}
            dataKey={l.uuid}
            shape={(p: any) => renderArrow(p, l.uuid)}
            fill={l.color}>
        </Bar>
        )
    }

    return <ResponsiveContainer ref={ref}>
        <BarChart
            ref={props.innerRef}
            data={barData()}
            layout="vertical"
        >
            <XAxis type="number" interval={0} domain={[-100, 100]} />
            <YAxis type="category" dataKey={"name"} />
            <ReferenceLine x={0} stroke="#000" />
            {renderBar()}
        </BarChart>
    </ResponsiveContainer>

}
