import { ReactElement, useEffect } from "react";
import { clearToken } from "../../utils/Auth";
import { useHeaderContext } from "../page/header/Header";

import "./Logout.scss";

export const Logout = (): ReactElement => {
    const { updateHeader } = useHeaderContext();

    useEffect(() => {
        clearToken("token-circalind");
        updateHeader([{ route: "/login", title: "Login" }]);
    }, [updateHeader])

    return <div>
        <h2>Sie haben sich Ausgeloggt</h2>
    </div>

}
