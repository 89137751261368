import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import qualityImage from "../../resources/circa/quality.png";

import "../Start.scss";


export const WelcomeQuality = () => {
    return <div className="full-page">
        <h1><strong>Nachhaltigkeits-Management</strong></h1>
        <img src={qualityImage} alt="Nachhaltigkeits-Management" />
        <div onClick={() => window.location.href = "main"} className="lower-arrow"><FontAwesomeIcon icon={faArrowRight} /></div>
    </div>;
}