import { TabView } from "../contentManager/TabView";
import projectHistoryImage from "../../resources/circa/documentation/project-history.png";
import bestPracticeImage from "../../resources/circa/documentation/best-practice.png";
import myCollectionImage from "../../resources/circa/documentation/my-collection.png";
import circalindPersonalImage from "../../resources/circa/personal_project.svg";
import circalindLeadershipImage from "../../resources/circa/leadership_teamwork.svg";
import strategyImage_back from "../../resources/circa/strategy_project.svg"
import { useEffect, useState } from "react";
import { CircalindApi } from "../circalindApi";
import { useSelectedProject } from "../../utils/Hooks";
import { DeskItem, Project } from "../dataTypes/generated";
import { ProjectAction, ProjectOverview } from "../projects/compass/cockpit/OneView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Dialog } from "../basicComponents/dialogs/Dialog";
import { ProjectSelect } from "../../leading/dialogs/ProjectSelect";
import { sendOlert } from "../../olert/Olert";

import "../projects/compass/cockpit/OneView.scss";
import "./ProjectHistory.scss";
import { convertAndDownload } from "../../invoice/utils/Util";

export const entries: Array<{ path: string, images: Array<string> }> = [
    { path: "/circalind/regular/documentation/practises", images: [bestPracticeImage] },
    { path: "/circalind/regular/documentation/presentations", images: [circalindPersonalImage, circalindLeadershipImage, strategyImage_back] },
    { path: "/circalind/regular/documentation/collection", images: [myCollectionImage] },
    { path: "/circalind/regular/documentation/history", images: [projectHistoryImage] },
];

export const ProjectHistory = () => {
    const [projectId,] = useSelectedProject();
    const [childProjects, setChildProjects] = useState<Array<Project>>([]);
    const [projects, setProjects] = useState<Array<Project>>([]);
    const [items, setItems] = useState<Array<DeskItem>>([]);
    const [filter, setFilter] = useState<string>("");
    const [showLinkingDialog, setShowLinkingDialog] = useState<boolean>(false);

    const loadChildProjects = async () => {
        const projects = (await CircalindApi.getChildProjects(projectId))
        setChildProjects(projects);

        const deskItems: Array<DeskItem> = [];

        for (let project of projects) {
            deskItems.push(...(await CircalindApi.getAllDeskItemsForProject(project.id)));
        }

        setItems(deskItems);
    };

    const loadProjects = async () => {
        setProjects((await CircalindApi.getProjects()));
    };

    useEffect(() => {
        loadProjects();
    }, []);

    useEffect(() => {
        const internalLoadChildProjects = async () => {
            const projects = (await CircalindApi.getChildProjects(projectId))
            setChildProjects(projects);

            const deskItems: Array<DeskItem> = [];

            for (let project of projects) {
                deskItems.push(...(await CircalindApi.getAllDeskItemsForProject(project.id)));
            }

            setItems(deskItems);
        };
        internalLoadChildProjects();
    }, [projectId]);

    const projectActions: Array<ProjectAction> = [
        { icon: faTrashAlt, tooltip: "Aus Historie Löschen", action: async (project) => { await CircalindApi.unlinkChildProject(projectId, project.id); await loadChildProjects() } }
    ];


    const getItemAndDownload = async (id: number) => {
        const res = await CircalindApi.getDeskItem(id);
        if (res) {
            convertAndDownload(res.base64Data.split('base64,')[1], res.date!.split("T")[0] + "_" + res.filename);
        }
    }

    const renderItems = () => {
        return items
            .filter(i => i.name.toLowerCase().includes(filter.toLowerCase()) || i.tags.some(t => t.toLowerCase().includes(filter.toLowerCase())))
            .map(i => <li key={i.id}>{i.name} <FontAwesomeIcon onClick={() => getItemAndDownload(i.id)} icon={faDownload} /></li>);
    };

    return <TabView entries={entries}>
        <div className="project-history">
            <h2>Projekthistorie <FontAwesomeIcon onClick={() => setShowLinkingDialog(true)} icon={faPlusCircle} /></h2>
            <ProjectOverview setProjectId={() => { }} showFilter={false} projects={childProjects} projectActions={projectActions} />
            <h2>Historie des Projektschreibtisch</h2>
            <input type="search" value={filter} onChange={(e) => setFilter(e.target.value)} placeholder="Suche" />
            <ul>
                {renderItems()}
            </ul>
            <Dialog className={""} component={<LinkProjectDialog projects={projects} projectId={projectId} close={async () => { setShowLinkingDialog(false); await loadChildProjects() }} />} show={showLinkingDialog} toogle={() => setShowLinkingDialog(false)} />
        </div>
    </TabView>
};

const LinkProjectDialog = ({ projectId, projects, close }: { projectId: number, projects: Array<Project>, close: () => Promise<void> }) => {
    const [child, setChild] = useState<Project | undefined>(undefined);

    return <div className="link-project">
        <h3>Projekt der Historie hinzufügen</h3>
        <div className="project-selection">
            <ProjectSelect valueProjectId={child?.id} projects={projects} onSelect={(p) => setChild(p)} />
            <button onClick={async () => {
                if (projectId > -1 && child) {
                    const result = await CircalindApi.linkChildProject(projectId, child.id);
                    if (!result) {
                        sendOlert("Fehler beim Hinzufügen", "Projekt ist bereits in der Historie.", "Error");
                    } else {
                        await close();
                    }
                }
            }
            }>Hinzufügen</button>
        </div>
    </div>
};