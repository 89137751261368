import { useState } from "react";
import { useEffect } from "react"
import { CircalindApi } from "../../circalind/circalindApi";
import { GeneralContent } from "../../circalind/contentManager/GeneralContent";
import { Question, SubArea } from "../../circalind/dataTypes/generated";
import { useGroupWideSubArea } from "../../utils/Hooks";

const createEmptyQuestion = (subArea: SubArea): Question => {
    return {
        compass: "NONE",
        graph: { description: "", title: "", type: "NONE", values: [] },
        id: -1,
        kind: "Question",
        order: -1,
        phase: -1,
        group: -1,
        title: "",
        subArea: subArea.id,
        notes: false,
        editedOn: "",
        owner: -1,
        reviewedBy: -1,
        reviewedOn: "",
        version: "1.0",
        table: { cols: 1, rows: 1, tableCells: [], title: "", style: null }
    }
}


export const FAQ = () => {
    const subArea = useGroupWideSubArea("faq", "faq");
    const [generalContent, setGeneralContent] = useState<Question | null>(null);

    useEffect(() => {
        const getContent = async (subArea: SubArea) => {

            const result = await CircalindApi.getContentQuestionByFilterForSubArea({
                subArea: subArea.id,
                notes: false,
                phase: - 1,
                userId: -1,
                order: -1,
            });
            if (result) {
                setGeneralContent(result);
            } else {
                setGeneralContent(createEmptyQuestion(subArea));
            }
        };

        if (subArea) {
            getContent(subArea);
        }
    }, [subArea]);

    return <div>
        <h2>FAQ</h2>
        {
            generalContent
                ? <GeneralContent
                    onUpdate={(d) => setGeneralContent({ ...generalContent, table: d })}
                    canEdit={true}
                    canReview={true}
                    question={generalContent}
                    showControls={true}
                ></GeneralContent>
                : null
        }
    </div>;
}
