import { ReactElement, useEffect, useState } from "react";
import { sendOlert } from "../../olert/Olert";
import { PasswordChange } from "../basicComponents/password/PasswordChange";
import { CircalindApi } from "../circalindApi";
import { GroupUser } from "../dataTypes/generated";
import './profile.scss'
import { useNavigate } from "react-router";

export const Profile = (): ReactElement => {
    const [groupUser, setGroupUser] = useState<GroupUser | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const getUser = async () => {
            const user = await CircalindApi.getGroupUserWithImage();
            setGroupUser(user);
        };

        getUser();
    }, []);

    const changePassword = async (pass: string) => {
        let res = await CircalindApi.userChangePassword(pass);
        if (res) {
            sendOlert("Erfolgreich", "Ihr Passwort wurde geändert");
        } else {
            sendOlert("Error", "Passwort konnte nicht geändert werden", "Error");
        }
    }

    const updateProfile = async () => {
        if (groupUser) {
            const result = await CircalindApi.updateGroupUser(groupUser);

            if (result) {
                navigate(-1);
            }
        }
    };

    return <div className="profile">
        <div className="profile-group">
            <div className="profile-group-header">
                <h2>Profildaten</h2>
            </div>
            <div className="profile-block">
                <label>E-mail / Login</label>
                <input value={groupUser?.email || ""} disabled type="email" />
                <label>Vorname</label>
                <input value={groupUser?.firstName || ""} onChange={(e) => setGroupUser({ ...groupUser!, firstName: e.target.value })} type="text" />
                <label>Nachname</label>
                <input value={groupUser?.lastName || ""} onChange={(e) => setGroupUser({ ...groupUser!, lastName: e.target.value })} type="text" />
                <label>Schwerpunkt</label>
                <input value={groupUser?.description || ""} onChange={(e) => setGroupUser({ ...groupUser!, description: e.target.value })} type="text" />
                <label>Firma</label>
                <input value={groupUser?.company || ""} onChange={(e) => setGroupUser({ ...groupUser!, company: e.target.value })} type="text" />
                <label>Funktion</label>
                <input value={groupUser?.position || ""} onChange={(e) => setGroupUser({ ...groupUser!, position: e.target.value })} type="text" />
                <button onClick={updateProfile}>Speichern</button>
            </div>
        </div>
        <hr />
        <div className="profile-group">
            <div className="profile-group-header">
                <h2>Passwort</h2>
            </div>
            <div className="profile-block">
                <PasswordChange onOk={changePassword} />
            </div>
        </div>
    </div>

}