import { FunctionComponent } from "react";
import { useLocation, useNavigate } from "react-router";

import "./TabView.scss";

interface TabViewProps {
    entries: Array<{ path: string, images: Array<string> }>
};

export const TabView: FunctionComponent<React.PropsWithChildren<TabViewProps>> = (props: React.PropsWithChildren<TabViewProps>) => {
    const navigate = useNavigate();
    const location = useLocation();

    return <div className="tab-view">
        <div className="tab-header">
            {props.entries.map(entry => <div className={location.pathname.startsWith(entry.path) ? "active tab card" : "tab card"} onClick={() => navigate(entry.path)} key={entry.path}>{entry.images.map(img => <img key={img} src={img} alt="" />)}</div>)}
        </div>
        {props.children}
    </div>;
};