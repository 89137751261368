import { v4 as uuidv4 } from 'uuid';
import { GraphImage, GraphType, GraphValues } from "../../dataTypes/generated";
import { Graphs, createEmptyPointsForGraphValuesWithDesc } from "../compass/graphs/Graphs";

import "./PhaseDiagram.scss"

interface PhaseDiagramProps {
    edit: boolean;
    value: GraphImage
    showTitle: boolean
    updateGraphImage: (graphImage: GraphImage) => void
    innerRef?: React.MutableRefObject<any>
}

export const PhaseDiagram = (props: PhaseDiagramProps) => {
    const getValueOrDefault = () => {
        if (props.value.json) {
            return JSON.parse(props.value.json);
        } else {
            return getDefaultDataForType(props.value.graphType);
        }
    }

    const renderGraph = () => {
        return <Graphs innerRef={props.innerRef} showLegend={props.edit} edit={props.edit} nrOfPoints={1} onGraphUpdate={(v) => props.updateGraphImage({ ...props.value, json: JSON.stringify(v) })} graph={
            { description: "", title: props.value.name, type: props.value.graphType, values: getValueOrDefault() }
        } onTitleChange={ props.showTitle ? title => props.updateGraphImage({ ...props.value, name: title }) : undefined} updateGraphPNG={async base64 => props.updateGraphImage({ ...props.value, base64 })} />
    }

    return <div className="phase-diagram">
        {renderGraph()}
    </div>
};

export const getTypeForPhase = (phase: number): GraphType => {
    switch (phase) {
        case 1:
            return "RADAR"
        case 2:
            return "LINE"
        case 3:
            return "BAR"
        case 4:
            return "ARROWS"
        case 5:
            return "SQARES"
        default:
            throw new Error("Unknown phase");
    }
}

const getDefaultDataForType = (type: string): Array<GraphValues> => {
    switch (type) {
        case "RADAR": {
            let newSoll: GraphValues = {
                uuid: uuidv4(),
                title: "Soll",
                color: "#33cc33",
                numberOfPoints: 5,
                points: []
            }
            let newIst: GraphValues = {
                uuid: uuidv4(),
                title: "Ist",
                color: "#175583",
                numberOfPoints: 5,
                points: []
            }
            createEmptyPointsForGraphValuesWithDesc(newSoll, ["Punkt1", "Punkt2", "Punkt3", "Punkt4", "Punkt5"], 50);
            createEmptyPointsForGraphValuesWithDesc(newIst, ["Punkt1", "Punkt2", "Punkt3", "Punkt4", "Punkt5"], 25);
            return [newSoll, newIst];
        }
        case "LINE": {
            let newSoll: GraphValues = {
                uuid: uuidv4(),
                title: "Daten 1",
                color: "#33cc33",
                numberOfPoints: 5,
                points: []
            }
            let newIst: GraphValues = {
                uuid: uuidv4(),
                title: "Daten 2",
                color: "#175583",
                numberOfPoints: 5,
                points: []
            }
            createEmptyPointsForGraphValuesWithDesc(newSoll, ["Punkt1", "Punkt2", "Punkt3", "Punkt4", "Punkt5"], 50);
            createEmptyPointsForGraphValuesWithDesc(newIst, ["Punkt1", "Punkt2", "Punkt3", "Punkt4", "Punkt5"], 25);
            return [newSoll, newIst];
        }
        case "BAR": {
            let newSoll: GraphValues = {
                uuid: uuidv4(),
                title: "Daten 1",
                color: "#33cc33",
                numberOfPoints: 5,
                points: []
            }
            createEmptyPointsForGraphValuesWithDesc(newSoll, ["Punkt1", "Punkt2", "Punkt3", "Punkt4", "Punkt5"], 50);
            return [newSoll];
        }
        case "ARROWS": {
            let newSoll: GraphValues = {
                uuid: uuidv4(),
                title: "Punkt 1",
                color: "#33cc33",
                numberOfPoints: 5,
                points: []
            }
            let newIst: GraphValues = {
                uuid: uuidv4(),
                title: "Punkt 2",
                color: "#175583",
                numberOfPoints: 5,
                points: []
            }
            createEmptyPointsForGraphValuesWithDesc(newSoll, ["Punkt1", "Punkt2", "Punkt3", "Punkt4", "Punkt5"], 50);
            createEmptyPointsForGraphValuesWithDesc(newIst, ["Punkt1", "Punkt2", "Punkt3", "Punkt4", "Punkt5"], -50);
            return [newSoll, newIst];
        }
        case "SQARES": {
            let newSoll: GraphValues = {
                uuid: uuidv4(),
                title: "Daten 1",
                color: "#33cc33",
                numberOfPoints: 5,
                points: []
            }
            let newIst: GraphValues = {
                uuid: uuidv4(),
                title: "Daten 2",
                color: "#175583",
                numberOfPoints: 5,
                points: []
            }
            createEmptyPointsForGraphValuesWithDesc(newSoll, ["Punkt1", "Punkt2", "Punkt3", "Punkt4", "Punkt5"], 0);
            createEmptyPointsForGraphValuesWithDesc(newIst, ["Punkt1", "Punkt2", "Punkt3", "Punkt4", "Punkt5"], 0);
            return [newSoll, newIst];
        }
        default:
            throw new Error("Unknown type");
    }
}