import { CompassType, Question, TableCell } from "../../../dataTypes/generated";
import { QuestionTablePage } from "../../../questions/QuestionTable";

import transform1 from '../../../../resources/circa/compass/transform1.png';
import transform2 from '../../../../resources/circa/compass/transform2.png';
import transform3 from '../../../../resources/circa/compass/transform3.png';
import transform4 from '../../../../resources/circa/compass/transform4.png';
import transform5 from '../../../../resources/circa/compass/transform5.png';
import transform6 from '../../../../resources/circa/compass/transform6.png';

import selfguide1 from '../../../../resources/circa/compass/selfguide1.png';
import selfguide2 from '../../../../resources/circa/compass/selfguide2.png';
import selfguide3 from '../../../../resources/circa/compass/selfguide3.png';
import selfguide4 from '../../../../resources/circa/compass/selfguide4.png';
import selfguide5 from '../../../../resources/circa/compass/selfguide5.png';
import selfguide6 from '../../../../resources/circa/compass/selfguide6.png';

export const logos = [transform1, transform2, transform3, transform4, transform5, transform6];

export interface CockpitObject {
    title: string;
    items: CockpitItem[];
}

export interface CockpitItem {
    logo?: string;
    text: string;
}

export const LANECHANGE: CockpitObject = {
    title: `Hier im Bereich Selbstführung können Sie Innehalten, um diese
    Aspekte in Bezug zu Kontext und Treibern zu reflektieren`,
    items: [
        { logo: transform1, text: "Szenarien entwickeln- Modelle erproben" },
        { logo: transform2, text: "Neue Prioritäten setzen" },
        { logo: transform3, text: "Auf-, Umbau-, Abbau gestalten" },
        { logo: transform4, text: "Stabilisieren" },
        { logo: transform5, text: "Loslassen" },
        { logo: transform6, text: "Kontext wechseln" }
    ]
}

export const SELFGUIDE: CockpitObject = {
    title: `Hier im Bereich Selbstfürhung können Sie Inehalten, um diese Aspekte im Bezug zu Kontext und Treibern zu reflektieren`,
    items: [
        { logo: selfguide1, text: "Persönlichkeit" },
        { logo: selfguide2, text: "Meine Motivation" },
        { logo: selfguide3, text: "Meine engen Beziehungen- sozial Verbundenheit" },
        { logo: selfguide4, text: "Mein Wohlbefinden" },
        { logo: selfguide5, text: "Meine Kopetenzen und Talente" },
        { logo: selfguide6, text: "Meine Zeit-Horizonte - mein Umgang mit Zeit" }
    ]
}

export const MANAGEMENT: CockpitObject = {
    title: `Der Bereich Management-Kompass unterstützt Spedifikationen von Vorhaben zu erkennen, um Gestaltungschancen zu nutzen und weiter auszugestalten.`,
    items: [
        { text: "Meine Verantwortung" },
        { text: "Ziele/Zeit-Verainbarung" },
        { text: "Zahlen, Daten, Fakten" },
        { text: "Struktur Organisation" },
        { text: "Resourcen" },
        { text: "Herausforderungen" }
    ]
}

export const createEmptyHeaderCells = (row: number): TableCell[] => {
    let res: TableCell[] = [];
    for (let i = 0; i < 3; i++) {
        res.push({ id: (row * 3) + res.length + 1, row: row, column: i, height: 1, width: 1, placeholder: "Hier steht was", type: "INFO", value: "TITEL", style: null })
    }
    return res;
}

const createEmptyQuestionCells = (row: number): TableCell[] => {
    let res: TableCell[] = [];
    for (let i = 0; i < 3; i++) {
        res.push({ id: (row * 3) + res.length + 1, row: row, column: i, height: 1, width: 1, placeholder: "...", type: "TEXT", value: "", style: null })
    }
    return res;
}

export const createDefaultCells = (): TableCell[] => {
    let res: TableCell[] = [];
    res = res.concat(createEmptyHeaderCells(0));
    res = res.concat(createEmptyQuestionCells(1));
    res = res.concat(createEmptyHeaderCells(2));
    res = res.concat(createEmptyQuestionCells(3));
    res = res.concat(createEmptyHeaderCells(4));
    res = res.concat(createEmptyQuestionCells(5));
    return res;
}

export const createEmptyQuestion = (i: number, type: CompassType): Question => {
    return {
        compass: type,
        graph: { description: "", title: "", values: [], type: "NONE" },
        id: -1,
        subArea: -1,
        notes: false,
        kind: "Question",
        order: i,
        phase: 0,
        group: -1,
        title: "",
        editedOn: "",
        owner: -1,
        reviewedBy: -1,
        reviewedOn: "",
        version: "",
        table: { cols: 3, rows: 6, tableCells: createDefaultCells(), title: "", style: null },
    }
}

export const defaultLaneChange: QuestionTablePage[] = [
    { logo: LANECHANGE.items[0].logo, title: LANECHANGE.items[0].text, question: createEmptyQuestion(0, "COMPLANECHANGE") },
    { logo: LANECHANGE.items[1].logo, title: LANECHANGE.items[1].text, question: createEmptyQuestion(1, "COMPLANECHANGE") },
    { logo: LANECHANGE.items[2].logo, title: LANECHANGE.items[2].text, question: createEmptyQuestion(2, "COMPLANECHANGE") },
    { logo: LANECHANGE.items[3].logo, title: LANECHANGE.items[3].text, question: createEmptyQuestion(3, "COMPLANECHANGE") },
    { logo: LANECHANGE.items[4].logo, title: LANECHANGE.items[4].text, question: createEmptyQuestion(4, "COMPLANECHANGE") },
    { logo: LANECHANGE.items[5].logo, title: LANECHANGE.items[5].text, question: createEmptyQuestion(5, "COMPLANECHANGE") },
]

export const defaultManagement: QuestionTablePage[] = [
    { title: MANAGEMENT.items[0].text, question: createEmptyQuestion(0, "COMPMANAGEMENT") },
    { title: MANAGEMENT.items[1].text, question: createEmptyQuestion(1, "COMPMANAGEMENT") },
    { title: MANAGEMENT.items[2].text, question: createEmptyQuestion(2, "COMPMANAGEMENT") },
    { title: MANAGEMENT.items[3].text, question: createEmptyQuestion(3, "COMPMANAGEMENT") },
    { title: MANAGEMENT.items[4].text, question: createEmptyQuestion(4, "COMPMANAGEMENT") },
    { title: MANAGEMENT.items[5].text, question: createEmptyQuestion(5, "COMPMANAGEMENT") },
]

export const defaultSelfGuide: QuestionTablePage[] = [
    { logo: SELFGUIDE.items[0].logo, title: SELFGUIDE.items[0].text, question: createEmptyQuestion(0, "COMPSELFGUIDE") },
    { logo: SELFGUIDE.items[1].logo, title: SELFGUIDE.items[1].text, question: createEmptyQuestion(1, "COMPSELFGUIDE") },
    { logo: SELFGUIDE.items[2].logo, title: SELFGUIDE.items[2].text, question: createEmptyQuestion(2, "COMPSELFGUIDE") },
    { logo: SELFGUIDE.items[3].logo, title: SELFGUIDE.items[3].text, question: createEmptyQuestion(3, "COMPSELFGUIDE") },
    { logo: SELFGUIDE.items[4].logo, title: SELFGUIDE.items[4].text, question: createEmptyQuestion(4, "COMPSELFGUIDE") },
    { logo: SELFGUIDE.items[5].logo, title: SELFGUIDE.items[5].text, question: createEmptyQuestion(5, "COMPSELFGUIDE") },
]

export const getDefaultByType = (type: CompassType) => {
    switch (type) {
        case "COMPLANECHANGE": return defaultLaneChange;
        case "COMPMANAGEMENT": return defaultManagement;
        case "COMPSELFGUIDE": return defaultSelfGuide;
        default: return [];
    }
}

export const mapPages = (questions: Question[] | undefined, defaultPages: QuestionTablePage[]): QuestionTablePage[] => {
    if (questions && questions.length > 0) {
        let res = defaultPages;
        res.forEach((p, i) => {
            const ques = questions.find(q => q.order === i);
            if (ques) {
                res[i] = { ...res[i], question: ques };
            }
        })
        return res;
    } else {
        return defaultPages
    }
}