import { useEffect, useState } from "react";
import { AreaAndRoleInfo, CircaPackage, LicenseMetaData, TotalLicenses, User } from "../../circalind/dataTypes/generated";
import { AvailableAreaDetails, AvailableRoleDetails } from "../../circalind/services/Contract";
import { ListCardProps } from "./Staffing";
import { CircalindApi } from "../../circalind/circalindApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import './leading.scss'

export const ModuleOverview = () => {
    const navigate = useNavigate();

    const [users, setUsers] = useState<User[]>([]);
    const [license, setLicense] = useState<CircaPackage | null>(null);
    const [licenseMetaData, setLicenseMetaData] = useState<LicenseMetaData | null>(null);
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo | null>(null);
    const [totalLicenses, setTotalLicenes] = useState<TotalLicenses | null>(null);

    useEffect(() => {
        const loadData = async () => {
            const us = await CircalindApi.getAllUsersOfMyGroup();
            const license = await CircalindApi.getLicense();
            const areaAndRoleInfo = await CircalindApi.getAreaAndRoleInfo();
            const licenseMetaData = await CircalindApi.getLicenseMetaData();
            setUsers(us);
            setLicense(license);
            setAreaAndRoleInfo(areaAndRoleInfo)
            setLicenseMetaData(licenseMetaData);
            setTotalLicenes(await CircalindApi.getTotalLicenses());
        };

        loadData();
    }, []);

    return <div className="staffing">
        <div className="list-cards">
            <ContractDetails totalLicenses={totalLicenses} license={license} licenseMetaData={licenseMetaData} users={users} areaAndRoleInfo={areaAndRoleInfo} onOpenDialog={() => { }} onDelete={() => { }} title="" kind="Project" items={[]} />
        </div>
        <div className={"navigation"}>
            <button onClick={() => navigate("../overview")}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button disabled><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>
};

const ContractDetails = (props: ListCardProps<void>) => {
    const isLicenseDataAvailable = props.totalLicenses != null && props.license !== null && props.licenseMetaData !== null && props.areaAndRoleInfo !== null;

    return <div className="list-card">
        {isLicenseDataAvailable && <AvailableRoleDetails showRoles={true} totalLicenses={props.totalLicenses!} licenseMetaData={props.licenseMetaData!} license={props.license!} areaAndRoleInfo={props.areaAndRoleInfo!} users={props.users} />}
        {isLicenseDataAvailable && <AvailableAreaDetails circaPackage={props.license!} areaAndRoleInfo={props.areaAndRoleInfo!} />}
    </div>;
};
