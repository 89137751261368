export const polarToCartesian = (r: number, theta: number): [number, number] => {
    const asRads = theta * Math.PI / 180;
    return [r * Math.cos(asRads), r * Math.sin(asRads)];
}

export const cartesianToPolar = (x: number, y: number): [number, number] => {
    const r = Math.sqrt(x * x + y * y);
    const theta = Math.atan2(y, x) * 180 / Math.PI;
    return [r, theta];
}

export const angleDistance = (a: number, b: number): number => {
    const d = Math.abs(a - b);
    return Math.min(d, 360 - d);
}

export const assertNever = (x: never): never => { console.error(x); throw new Error("should be unreachable!"); }
