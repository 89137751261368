import { ReactElement, useEffect, useMemo, useState } from "react";
import { Area, AreaAndRoleInfo, CompassType, GraphImageFor, Permissions, Project, Question, RoleType, SubArea, User } from "../dataTypes/generated";
import { GeneralContent } from "./GeneralContent";
import { areaImages } from "../../leading/areas";

import "./GeneralContentEditor.scss";
import "../projects/compass/question/compassQuestion.scss";
import { getCurrentUserId } from "../../utils/Auth";
import { CircalindApi } from "../circalindApi";
import { SubAreaLine } from "./SubAreaLine";
import { UserSelect } from "../../leading/dialogs/UserSelect";
import { ProjectSelect } from "../../leading/dialogs/ProjectSelect";
import { CompactId } from "../id/Id";
import { RoleDisplay } from "../../leading/role";

import competenceTeamImage from "../../resources/circa/competence/team.png";
import competenceIndividualImage from "../../resources/circa/competence/erkentnisse1.png";
import competenceLearningImage from "../../resources/circa/competence/learning.png";
import selbstImage from "../../resources/circa/compass/Selbstführung.png";
import managementImage from "../../resources/circa/compass/ManagementCompass.png";
import spurImage from "../../resources/circa/compass/SpurWechsel.png";
import circalindManagementImage from "../../resources/circa/circalind_managing.svg";
import circalindLeadershipImage from "../../resources/circa/leadership_teamwork.svg";
import { LeadingInfoOverview } from "../info/LeadingInfoOverview";
import { Dialog } from "../basicComponents/dialogs/Dialog";
import { PhaseDiagrams } from "../projects/competence/PhaseDiagrams";
import { Phase } from "../projects/phases/Phase";
import { CockpitCompass } from "../projects/compass/cockpit/CockpitCompass";
import { DeciderInfoOverview } from "../info/DeciderInfoOverview";

export const GeneralContentEditor = (): ReactElement => {
    const [selectedProject, setSelectedProject] = useState<number>(-1);
    const [selectedArea, setSelectedArea] = useState<Area | null>(null);
    const [selectedSubarea, setSelectedSubarea] = useState<SubArea | null>(null);
    const [selectedPhase, setSelectedPhase] = useState<number>(-1);
    const [selectedUser, setSelectedUser] = useState<number>(-1);
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [filteredAreas, setFilteredAreas] = useState<Array<Area>>([]);
    const [permissions, setPermissions] = useState<Array<Permissions>>([]);
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo | null>(null);
    const [users, setUsers] = useState<Array<User>>([]);
    const [projects, setProjects] = useState<Array<Project>>([]);
    const [showReviewers, setShowReviewers] = useState<boolean>(false);

    type InfoType = "LEADING" | "DECIDER"

    const [activeRole, setActiveRole] = useState<RoleType>();
    const [selectedToolboxSubArea, setSelectedToolboxSubArea] = useState<GraphImageFor>();
    const [selectedDeepCompassType, setSelectedDeepCompassType] = useState<CompassType>();
    const [selectedInfoType, setSelectedInfoType] = useState<InfoType>("LEADING");


    const userId = getCurrentUserId("token-circalind");

    useEffect(() => {
        const loadData = async () => {
            setUsers(await CircalindApi.getAllUsersOfMyGroup());
            setProjects(await CircalindApi.getProjects());
        };

        loadData();
    }, []);

    const subAreaFilter = useMemo(() => selectedArea ? {
        groupId: -1,
        area: selectedArea,
        targetProjectId: selectedProject === -1 ? null : selectedProject,
        targetUserId: (selectedProject !== -1 || selectedUser === -1) ? null : selectedUser,
        targetInternalGroupId: null
    } : null, [selectedArea, selectedProject, selectedUser]);

    useEffect(() => {
        setSelectedArea(null);
        setCollapsed(false)
    }, [activeRole, selectedUser, selectedProject]);

    useEffect(() => {
        setSelectedProject(-1);
        setSelectedUser(-1);
    }, [activeRole]);

    useEffect(() => {
        setSelectedProject(-1);
    }, [selectedUser]);

    useEffect(() => {
        const filterAreas = async (areas: Array<Area>) => {
            if (selectedProject !== -1) {
                const projectId = selectedProject;
                const project = projects.find(p => p.id === projectId);
                const filteredAreas: Array<Area> = [];

                if (areas.includes("practical") && project && activeRole === "CO_MANAGER_DRIVERS") {
                    if (project.members.find(m => m.user.id === userId && m.role === "CO_MANAGER_DRIVERS")) {
                        filteredAreas.push("practical");
                    }
                }

                if (areas.includes("practicalRoom") && (selectedUser || project) && activeRole === "CO_MANAGER_PROJECT") {
                    filteredAreas.push("practicalRoom");
                }

                if (project && activeRole === "IMPULSE_PARTNER") {
                    if (project.members.find(m => m.user.id === userId && m.role === "IMPULSE_PARTNER")) {
                        filteredAreas.push("toolbox");
                        filteredAreas.push("impulse");
                    }
                }

                if (activeRole === "SUPPORT_PARTNER") {
                    filteredAreas.push("compass");
                }

                if (activeRole === "CONTENT_MANAGER") {
                    filteredAreas.push("checkups", "service", "governance");
                }

                return filteredAreas;
            } else if (selectedUser !== -1) {
                if (activeRole === "SUPPORT_PARTNER") {
                    return ["info"] as Array<Area>;
                } else {
                    return [];
                }
            } else {
                return [];
            }
        };

        const getPermissions = async () => {
            const areaAndRoleInfo = await CircalindApi.getAreaAndRoleInfo();
            setAreaAndRoleInfo(areaAndRoleInfo);
            const contentAreas = (areaAndRoleInfo).contentAreas

            if (userId !== null) {
                const perms = await CircalindApi.getGroupPermissions();
                const filtered = await filterAreas(contentAreas);
                setFilteredAreas(filtered);
                setPermissions(perms);
            }
        };

        getPermissions();
    }, [selectedProject, selectedUser, activeRole, userId, projects]);

    const createEmptyQuestion = (subArea: SubArea, ownerId: number): Question => {
        return {
            compass: "NONE",
            graph: { description: "", title: "", type: "NONE", values: [] },
            id: -1,
            kind: "Question",
            order: -1,
            phase: selectedPhase,
            group: -1,
            title: "",
            subArea: subArea.id,
            notes: false,
            editedOn: "",
            owner: ownerId,
            reviewedBy: -1,
            reviewedOn: "",
            version: "1.0",
            table: { cols: 1, rows: 1, tableCells: [], title: "", style: null }
        }
    }

    const [question, setQuestion] = useState<Question | null>();

    const selectCritera = async () => {
        if (selectedSubarea) {
            const question = await CircalindApi.getContentQuestionByFilterForSubArea({
                subArea: selectedSubarea.id,
                notes: false,
                phase: selectedPhase,
                userId: -1,
                order: -1
            })

            if (question) {
                setQuestion(question);
            } else {
                setQuestion(createEmptyQuestion(selectedSubarea, userId === null ? -1 : userId));
            }

        }

        setCollapsed(!collapsed);
    };

    const renderPhasesHeader = () => {
        const phases = [];

        phases.push(<span key={0} onClick={() => setSelectedPhase(-1)} className={`phase-element ${selectedPhase === -1 ? "selected" : ""}`}>Phasenübergreifend</span>);

        for (let i = 1; i <= 5; i++) {
            phases.push(<span key={i} onClick={() => setSelectedPhase(i)} className={`phase-element ${i === selectedPhase ? "selected" : ""}`}>Phase {i}</span>);

            phases.push(<span key={i + "|"} className={`phase-separator ${i === selectedPhase ? "selected" : ""}`} />);
        }

        if (
            areaAndRoleInfo &&
            selectedArea &&
            areaAndRoleInfo.contentAreas.includes(selectedArea) &&
            selectedArea !== "governance" &&
            selectedArea !== "competence" &&
            selectedArea !== "communication" &&
            selectedProject !== -1
        ) {
            return <  div className="phases-header">
                {phases}
            </div>
        }
    }

    const isStartDisabled = () => {
        return areaAndRoleInfo === null || selectedArea === null || (areaAndRoleInfo.contentAreas.includes(selectedArea) && (selectedSubarea === null)) || (selectedArea === "toolbox" && !selectedToolboxSubArea);
    }

    const renderUserAndProject = () => {
        return <div className="user-project-selection">
            <div className="selection-column">
                <label>Nutzer wählen</label>
                <UserSelect valueUserId={selectedUser === -1 ? undefined : selectedUser} users={users} useName={true} onSelect={u => setSelectedUser(u ? u.id : -1)} />
            </div>
            <div className="selection-column">
                <label>Projekt wählen</label>
                <ProjectSelect projects={projects.filter(p => p.ownerId === selectedUser)} onSelect={(e) => setSelectedProject(e ? e.id : -1)} valueProjectId={selectedProject} />
            </div>
        </div>;
    };

    const renderArea = (area: Area) => {
        const title = areaAndRoleInfo ? areaAndRoleInfo.areaTitles[area] : area;
        const updateSelectedArea = () => {
            setSelectedArea(area);

            if (selectedArea !== area) {
                setSelectedSubarea(null);
            }

            setSelectedPhase(-1);
        };

        return <div key={area} className={`header-element ${selectedArea === area ? "selected" : ""}`}>
            <div className="header-image" onClick={updateSelectedArea}>
                {areaImages[area] ?
                    <img src={areaImages[area]} alt={title} />
                    : <span>{title}</span>
                }
            </div>
            <div className="header-title">{title}</div>
        </div>
    };

    const renderAreas = () => {
        const topRow: Array<Area> = ["compass", "governance", "competence", "impulse", "service", "checkups"];
        const lowerRow: Array<Area> = ["practicalRoom", "practical", "toolbox", "info"];

        return <>
            <div className="area">
                {filteredAreas.filter(fa => topRow.includes(fa)).map(renderArea)}
            </div>
            <div className="area">
                {filteredAreas.filter(fa => lowerRow.includes(fa)).map(renderArea)}
            </div>
        </>
    };

    const renderToolboxSubAreas = () => {
        return <div className="area">
            <div className={`header-element ${selectedToolboxSubArea === "PERSONAL_COMPETENCE" ? "selected" : ""}`}>
                <div className="header-image" onClick={() => setSelectedToolboxSubArea("PERSONAL_COMPETENCE")}>
                    <img src={competenceIndividualImage} alt={"Persönliche Weiterentwicklung"} />
                </div>
                <div className="header-title">Persönliche Weiterentwicklung</div>
            </div>
            <div className={`header-element ${selectedToolboxSubArea === "PROJECT_COMPETENCE" ? "selected" : ""}`}>
                <div className="header-image" onClick={() => setSelectedToolboxSubArea("PROJECT_COMPETENCE")}>
                    <img src={competenceLearningImage} alt={"Projekt-Erfolgsfelder"} />
                </div>
                <div className="header-title">Projekt-Erfolgsfelder</div>
            </div>
            <div className={`header-element ${selectedToolboxSubArea === "PROJECT_PHASE_COMPETENCE" ? "selected" : ""}`}>
                <div className="header-image" onClick={() => setSelectedToolboxSubArea("PROJECT_PHASE_COMPETENCE")}>
                    <img src={competenceTeamImage} alt={"Projekt-Profil (Soll-/Ist)"} />
                </div>
                <div className="header-title">Projekt-Profil (Soll-/Ist)</div>
            </div>
        </div>;
    };

    const renderGroupAreas = () => {
        return <div className="area">
            {renderArea("phase")}
        </div>
    };

    const renderDeepCompassArea = () => {
        return <div className="area">
            {renderArea("deepCompass")}
        </div>
    };

    const renderDeepCompassSubAreas = () => {
        return <div className="area">
            <div className={`header-element ${selectedDeepCompassType === "COMPSELFGUIDE" ? "selected" : ""}`}>
                <div className="header-image" onClick={() => setSelectedDeepCompassType("COMPSELFGUIDE")}>
                    <img src={selbstImage} alt={"Selbstführung"} />
                </div>
                <div className="header-title">Selbstführung</div>
            </div>
            <div className={`header-element ${selectedDeepCompassType === "COMPMANAGEMENT" ? "selected" : ""}`}>
                <div className="header-image" onClick={() => setSelectedDeepCompassType("COMPMANAGEMENT")}>
                    <img src={managementImage} alt={"Management"} />
                </div>
                <div className="header-title">Management</div>
            </div>
            <div className={`header-element ${selectedDeepCompassType === "COMPLANECHANGE" ? "selected" : ""}`}>
                <div className="header-image" onClick={() => setSelectedDeepCompassType("COMPLANECHANGE")}>
                    <img src={spurImage} alt={"Spur-Wechsel"} />
                </div>
                <div className="header-title">Spur-Wechsel</div>
            </div>
        </div>;
    };

    const renderInfoSubAreas = () => {
        return <div className="area">
            <div className={`header-element ${selectedInfoType === "LEADING" ? "selected" : ""}`}>
                <div className="header-image" onClick={() => setSelectedInfoType("LEADING")}>
                    <img src={circalindLeadershipImage} alt={"Führung und Zusammenarbeit"} />
                </div>
                <div className="header-title">Führung und Zusammenarbeit</div>
            </div>
            <div className={`header-element ${selectedInfoType === "DECIDER" ? "selected" : ""}`}>
                <div className="header-image" onClick={() => setSelectedInfoType("DECIDER")}>
                    <img src={circalindManagementImage} alt={"Entscheider"} />
                </div>
                <div className="header-title">Entscheider</div>
            </div>
        </div>;
    };

    const renderHeader = (): ReactElement => {
        const isContentAreas = areaAndRoleInfo && selectedArea && areaAndRoleInfo.contentAreas.includes(selectedArea);
        const isToolbox = selectedArea === "toolbox";
        const isGroup = activeRole === "SUPPORT_PARTNER" && selectedUser === -1;
        const isSupportPartnerAndProject = activeRole === "SUPPORT_PARTNER" && selectedProject !== -1;
        const isDeepCompass = selectedArea === "deepCompass";
        const isInfo = selectedArea === "info";

        return <div className="content-editor-header">
            <div className="two-column">
                <div className="left-column">
                    <strong>Aktive Rolle</strong>
                    <strong>{areaAndRoleInfo && activeRole && <RoleDisplay areaAndRoleInfo={areaAndRoleInfo} role={activeRole} />}</strong>
                    <CompactId allowedRoles={["IMPULSE_PARTNER", "CO_MANAGER_DRIVERS", "CO_MANAGER_PROJECT", "SUPPORT_PARTNER", "CONTENT_MANAGER"]} activeRole={activeRole} selectRole={setActiveRole} expandedStart={!collapsed} projectId={selectedProject === -1 ? undefined : selectedProject} targetUserId={selectedUser === -1 ? undefined : selectedUser} />
                </div>
                <div>
                    {renderUserAndProject()}
                    {!collapsed && <>
                        {renderAreas()}
                        {isSupportPartnerAndProject && renderDeepCompassArea()}
                        {!subAreaFilter || selectedProject === -1 || !isContentAreas ? null :
                            <SubAreaLine edit={false} permissions={selectedArea === "compass" ? null : permissions} selected={selectedSubarea ? selectedSubarea.id : -1} filter={subAreaFilter} onSelected={setSelectedSubarea} />
                        }
                        {isDeepCompass && renderDeepCompassSubAreas()}
                        {isInfo && renderInfoSubAreas()}
                        {
                            isGroup && renderGroupAreas()
                        }
                        {
                            isToolbox && <><hr />{renderToolboxSubAreas()}</>
                        }
                        {!collapsed && renderPhasesHeader()}
                    </>}
                    {collapsed && renderCollapsedInfo()}
                </div>
            </div>
            <div className="start-button">
                <button disabled={isStartDisabled()} onClick={selectCritera}>{collapsed ? "Auswahl" : "Start"}</button>
            </div>
        </div >;
    };

    const renderCollapsedInfo = () => {
        const renderSubArea = () => {
            if (selectedSubarea) {
                return <>
                    <div className="card">
                        {selectedSubarea.image ? <img alt={selectedSubarea.name} src={selectedSubarea.image} /> : <span>{selectedSubarea.name}</span>}
                    </div>
                    <div className="card">
                        <img src={areaImages[selectedSubarea.area]} alt={selectedSubarea.area} />
                    </div>
                </>
            } else if (selectedArea !== null) {
                return <>
                    <div className="card">
                        <img src={areaImages[selectedArea]} alt={selectedArea} />
                    </div>
                </>
            }
        };

        return <div className="collapsed-info">
            {renderSubArea()}
        </div>;
    };

    const renderEditorContent = () => {
        if (question) {
            return <GeneralContent
                allowedCellTypes={["INFO", "LINK", "YOUTUBE", "CALENDAR"]}
                canEdit={true}
                canReview={question.id > -1}
                changeVersion={(version) => { setQuestion({ ...question, version }) }}
                showControls={true}
                question={question}
                onUpdate={(d) => setQuestion({ ...question, table: d })}
                onUpdateId={(id) => setQuestion({ ...question, id })}
                onShowReviewers={() => setShowReviewers(true)}
            />
        }
    };

    const renderToolbox = () => {
        switch (selectedToolboxSubArea) {
            case "PERSONAL_COMPETENCE":
                if (userId)
                    return <PhaseDiagrams graphForChoices={["PERSONAL_COMPETENCE"]} chooseProject={false} userId={selectedUser} authorId={userId} />;
                break;
            case "PROJECT_COMPETENCE":
                if (userId)
                    return <PhaseDiagrams graphForChoices={["PROJECT_COMPETENCE"]} projectId={selectedProject} chooseProject={false} userId={selectedUser} authorId={userId} />;
                break;
            case "PROJECT_PHASE_COMPETENCE":
                if (userId)
                    return <PhaseDiagrams graphForChoices={["PROJECT_PHASE_COMPETENCE"]} projectId={selectedProject} chooseProject={false} userId={selectedUser} authorId={userId} />;
                break;
        }
    };

    const renderInfo = () => {
        if (selectedInfoType === "LEADING") {
            return <LeadingInfoOverview edit={true} userId={selectedUser} />
        } else {
            return <DeciderInfoOverview edit={true} userId={selectedUser} />
        }
    };

    const renderPhaseEditor = () => {
        return <Phase editMode={true} />
    };

    const renderDeepCompassContent = () => {
        if (selectedProject && selectedDeepCompassType) {
            return <CockpitCompass projectId={selectedProject} onBack={() => { }} type={selectedDeepCompassType} index={0} edit={true} />
        }
    };

    const renderContent = () => {
        if (areaAndRoleInfo && selectedArea && areaAndRoleInfo.contentAreas.includes(selectedArea)) {
            return renderEditorContent();
        } else if (selectedArea === "toolbox" && selectedToolboxSubArea) {
            return renderToolbox();
        } else if (selectedArea === "info") {
            return renderInfo();
        } else if (selectedArea === "phase") {
            return renderPhaseEditor();
        } else if (selectedArea === "deepCompass" && selectedDeepCompassType) {
            return renderDeepCompassContent();
        }
    };

    return <>
        {renderHeader()}
        {collapsed && renderContent()}
        {areaAndRoleInfo && selectedUser && selectedArea && selectedSubarea && <Dialog className={""} component={<PickReviewer selectedTargetUser={users.find(u => u.id === selectedUser)!} selectedProject={projects.find(p => p.id === selectedProject)} users={projects.find(p => p.id === selectedProject)?.members.filter(m => m.role === "CONTENT_MANAGER").map(m => m.user) || []} areaTitle={areaAndRoleInfo.areaTitles[selectedArea]!} subAreaTitle={selectedSubarea.name} closeDialog={() => setShowReviewers(false)} />} show={showReviewers} toogle={() => setShowReviewers(false)} />}
    </>;
}

const PickReviewer = ({ users, selectedProject, selectedTargetUser, subAreaTitle, areaTitle, closeDialog }: { users: Array<User>, selectedProject?: Project, selectedTargetUser: User, areaTitle: string, subAreaTitle: string, closeDialog: () => void }) => {
    const [user, setUser] = useState<User>();

    const sendRequest = () => {
        if (user) {
            const target = selectedProject ? `im Projekt ${selectedProject.name}` : `für den Nutzer ${selectedTargetUser!.lastName}`;
            const template = `Hallo ${user.firstName} ${user.lastName},\n\n im Bereich ${areaTitle}(${subAreaTitle}) ${target} wird Ihre Freigabe angefragt.\n`;

            window.location.href = `mailto:${selectedTargetUser.mail}?subject=${encodeURIComponent('Anfrage Freigabe')}&body=${encodeURIComponent(template)}`;
            closeDialog();
        }
    };

    return <div className="request-review-dialog">
        <label>Freigabe anfragen</label>
        <UserSelect useName={true} valueUserId={user?.id} users={users} onSelect={u => setUser(u)} />
        <button onClick={() => sendRequest()}>Anfragen</button>
    </div>;
};