import { useNavigate } from "react-router";

import "../../leading/toolbox/CircaToolbox.scss";

import competenceTeamImage from "../../resources/circa/competence/team.png";
import competenceIndividualImage from "../../resources/circa/competence/erkentnisse1.png";
import competenceLearningImage from "../../resources/circa/competence/learning.png";

export const MatchingOverview = () => {
    const navigate = useNavigate();

    return <div className="circa-toolbox">
        <div className="columns">
            <div></div>
            <div className="card content-card">
                <h3>Entscheider-Welt</h3>
                <div onClick={() => { navigate("./decider") }} className="image-wrapper card clickable">
                    <img src={competenceIndividualImage} alt="" />
                </div>
                <div></div>
            </div>
            <div className="card content-card">
                <h3>Projekt-Welt</h3>
                <div onClick={() => { navigate("./project/complete") }} className="image-wrapper card clickable">
                    <img src={competenceLearningImage} alt="" />
                </div>
                <div onClick={() => { navigate("./project/phases") }} className="image-wrapper card clickable">
                    <img src={competenceTeamImage} alt="" />
                </div>
                <div></div>
            </div>
        </div>
    </div>;
}