import governanceImage from "../resources/circa/governance.svg";
import supportImage from "../resources/circa/other_tools.svg";
import impulseImage from "../resources/circa/impulse.png";
import checkupsImage from "../resources/circa/risk_management.svg";
import competenceImage from "../resources/circa/competence.svg";
import communicationImage from "../resources/circa/kommunication.svg";
import practicalImage from "../resources/circa/practical.svg";
import driverImage from "../resources/circa/drivers.png";
import toolsImage from "../resources/circa/toolbox.svg";
import cockpitImage from "../resources/circa/user/left-menu-2.svg";
import compassImage from "../resources/circa/compass/compass.png";
import phasesImage from "../resources/circa/projects_welcome.svg";
import informationImage from "../resources/circa/information.png";
import deskImage from "../resources/circa/desk.png";
import interactImage from "../resources/circa/interact.png";
import myWayImage from "../resources/circa/personal_nav.svg";
import perspectiveImage from '../resources/circa/perspective.png'
import { Area } from "../circalind/dataTypes/generated";

export const contentAreas: Array<Area> = ["governance", "competence", "impulse", "support", "checkups", "communication", "practical"];

export const areaImages: Record<Area, string> = {
    "governance": governanceImage,
    "practical": driverImage,
    "practicalRoom": practicalImage,
    "communication": communicationImage,
    "impulse": impulseImage,
    "service": supportImage,
    "support": impulseImage,
    "checkups": checkupsImage,
    "competence": competenceImage,
    "tools": toolsImage,
    "toolbox": perspectiveImage,
    compass: compassImage,
    deepCompass: compassImage,
    competenceTalents: "",
    competencePersonalFuture: "",
    competenceProjectAction: "",
    competenceProjectExpertise: "",
    communicationPrepost: "",
    communicationProject: "",
    faq: "",
    quality: "",
    phase: phasesImage,
    prePhase: "",
    postPhase: "",
    perspective: "",
    context: "",
    projectDesk: deskImage,
    documentation: myWayImage,
    interaction: interactImage,
    polls: "",
    info: informationImage,
    cockpit: cockpitImage
}
