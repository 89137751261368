import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cockpitImage from "../../../../resources/circa/user/left-menu-2.svg";

import "../../../../leading/Start.scss";


export const WelcomeCockpit = () => {
    return <div className="full-page">
        <h1><strong>Persönliches Cockpit</strong></h1>
        <img src={cockpitImage} alt="persönliches Cockpit" />
        <div onClick={() => window.location.href = "main"} className="lower-arrow"><FontAwesomeIcon icon={faArrowRight} /></div>
    </div>;
}