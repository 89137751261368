import { useEffect, useState } from "react";
import { CircalindApi } from "../circalindApi";
import { AreaAndRoleInfo, CircaPackage, LicenseMetaData, RoleType, TotalLicenses, User } from "../dataTypes/generated";

import "./Contract.scss";
import { RoleDisplay } from "../../leading/role";
import { areaImages } from "../../leading/areas";

export const ContractView = () => {
    const [users, setUsers] = useState<Array<User>>([]);
    const [license, setLicense] = useState<CircaPackage | null>(null);
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo | null>(null);
    const [licenseMetaData, setLicenseMetaData] = useState<LicenseMetaData | null>(null);
    const [totalLicenses, setTotalLicenes] = useState<TotalLicenses | null>(null);

    useEffect(() => {
        const loadData = async () => {
            setLicense(await CircalindApi.getLicense());
            setAreaAndRoleInfo(await CircalindApi.getAreaAndRoleInfo());
            setLicenseMetaData(await CircalindApi.getLicenseMetaData());
            setUsers(await CircalindApi.getAllUsersOfMyGroup());
            setTotalLicenes(await CircalindApi.getTotalLicenses());
        };

        loadData();
    }, []);

    const renderRoles = () => {
        if (licenseMetaData && license && areaAndRoleInfo && totalLicenses) {
            return <AvailableRoleDetails showRoles={false} totalLicenses={totalLicenses} users={users} license={license} licenseMetaData={licenseMetaData} areaAndRoleInfo={areaAndRoleInfo} />
        }
    };

    return <div className="contract-container">
        {renderRoles()}
    </div>;
};


interface AvailableRoleDetailsProps {
    licenseMetaData: LicenseMetaData;
    license: CircaPackage;
    areaAndRoleInfo: AreaAndRoleInfo;
    users: Array<User>;
    totalLicenses: TotalLicenses;
    showRoles?: boolean;
}

export const AvailableRoleDetails = ({ licenseMetaData, license, areaAndRoleInfo, users, totalLicenses, showRoles }: AvailableRoleDetailsProps) => {
    const countRoles = (roles: Array<RoleType>) => {
        return users.map(u => u.roles.filter(r => roles.includes(r)).length).reduce((a, b) => a + b, 0);
    };

    const myRoles = areaAndRoleInfo.availableRoles[license.type]!;
    const myAssistanceRoles = myRoles.filter(r => r !== "DECIDER");

    const renderRoles = () => {
        return <>
            {
                myAssistanceRoles.map(r => {
                    return <div key={r} className="role-entry">
                        <div><RoleDisplay role={r} areaAndRoleInfo={areaAndRoleInfo} /></div>
                        <div><strong>max. {totalLicenses.totalAssistance}</strong></div>
                        <div><strong>{countRoles([r])}</strong></div>
                        <div><strong>{totalLicenses.totalAssistance - countRoles(myAssistanceRoles)}</strong></div>
                    </div>
                })
            }
            <div className="role-entry">
                <div><RoleDisplay role={"DECIDER"} areaAndRoleInfo={areaAndRoleInfo} /></div>
                <div><strong>max. {totalLicenses.totalDeciders}</strong></div>
                <div><strong>{countRoles(["DECIDER"])}</strong></div>
                <div><strong>{totalLicenses.totalDeciders - countRoles(["DECIDER"])}</strong></div>
            </div>
        </>
    };

    return <div className="role-overview">
        <h3>Steckbrief Modulpaket {licenseMetaData.licenseTitles[license.type]}</h3>
        <h4>Paket-Nr. P-{license?.id}</h4>
        <h4>Laufzeit: {license.startMonth}.{license.startYear} bis {license.endMonth}.{license.endYear}</h4>
        {showRoles
            &&
            <details>
                <summary>Rollen</summary>
                <div className="role-entry">
                    <div>Rolle</div>
                    <div>Soll</div>
                    <div>Ist</div>
                    <div>Verfügbar</div>
                </div>
                <hr />
                {renderRoles()}
                <hr />
                <strong>Gesamtzahl verfügbarer Lizenzen: {users.flatMap(u => u.roles.filter(r => myRoles.includes(r))).length} von {totalLicenses.totalAssistance + totalLicenses.totalDeciders} ausgeschöpft</strong>
            </details>
        }
    </div>
};

interface AvailableAreaDetailsProps {
    circaPackage: CircaPackage;
    areaAndRoleInfo: AreaAndRoleInfo;
}

export const AvailableAreaDetails = ({ circaPackage, areaAndRoleInfo }: AvailableAreaDetailsProps) => {
    return <div className="area-overview">
        <h3>Übersicht über alle verfügbaren Räume</h3>
        <details>
            <summary>Räume</summary>
            <div className="rooms">
                {areaAndRoleInfo.availableAreas[circaPackage.type]!.map(area => {
                    return <div className="room">
                        <img src={areaImages[area]} alt={areaAndRoleInfo.areaTitles[area]} />
                        <span>{areaAndRoleInfo.areaTitles[area]}</span>
                    </div>;
                })}
            </div>
        </details>
    </div>
};