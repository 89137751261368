import { useEffect, useState } from "react";
import { Customer, User } from "../dataTypes/generated";
import { useNavigate } from "react-router";
import { CircalindApi } from "../circalindApi";
import { sendOlert } from "../../olert/Olert";
import { CustomerView } from "../services/Customer";

import "./MyCustomerView.scss";

export const MyCustomerView = () => {
    const [customer, setCustomer] = useState<Customer>();
    const [user, setUser] = useState<User>();

    const navigate = useNavigate();

    useEffect(() => {
        const loadData = async () => {
            const c = await CircalindApi.getMyCustomer();
            if (c) {
                const u = await CircalindApi.getMyUser();

                if (u && c) {
                    setUser(u);
                    setCustomer(c);
                }
            }
        };

        loadData();
    }, []);

    const saveCustomer = async () => {
        if (customer && user) {
            const res = await CircalindApi.updateCustomer({
                customer: customer,
                manager: user,
            });

            if (res) {
                sendOlert("Erfolg", "Daten erfolgreich gespeichert.", "Success");
            } else {
                sendOlert("Fehler", "Daten konnten nicht gespeichert werden.", "Error");
            }
        }
    };

    return <div className="manager-customer">
        {customer && user && <CustomerView customer={customer} setCustomer={setCustomer} user={user} setUser={setUser} isAdmin={false} />}
        <div className="right">
            <button onClick={() => navigate("/circalind/services/choose-license")}>Zurück</button>
            <button onClick={saveCustomer}>Speichern</button>
        </div>
    </div>
};