import { ReactElement, useEffect, useState } from "react";
import { sendOlert } from "../../olert/Olert";
import { Dialog } from "../basicComponents/dialogs/Dialog";
import { CircalindApi } from "../circalindApi";
import { SubArea, SubAreaFilter, Permissions } from "../dataTypes/generated";
import { SubAreaData, SubAreaEditor } from "./SubAreaEditor";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import './SubAreaLine.scss';
import { getCurrentUserId, getUserRoles } from "../../utils/Auth";

export type Props = {
    filter: SubAreaFilter,
    edit: boolean,
    permissions: Array<Permissions> | null,
    selected: number,
    onSelected: (subArea: SubArea | null) => void,
}

export const SubAreaLine = (props: Props): ReactElement => {
    const userRoles = getUserRoles("token-circalind");
    const userId = getCurrentUserId("token-circalind");
    const { filter } = props;
    const [editMode, setEditMode] = useState(userRoles.includes("COORDINATOR") || userRoles.includes("CO_COORDINATOR"));
    const [subAreas, setSubAreas] = useState<Array<SubArea>>([]);
    const [showEditor, setShowEditor] = useState<boolean>(false);

    useEffect(() => {
        const filterSubAreas = (sas: Array<SubArea>) => {
            return props.edit ? sas : sas.filter(sa => props.permissions === null || props.permissions.find(p => sa.id === p.subArea && p.userId === userId));
        };

        CircalindApi.getSubAreas(filter).then(sas => setSubAreas(filterSubAreas(sas)));
    }, [filter, props.edit, props.permissions, userId]);

    const closeDialog = () => setShowEditor(false);
    const saveDialog = (data: SubAreaData) => {
        setShowEditor(false);

        const ord = subAreas.reduce((acc, sa) => Math.max(acc, sa.ord), 0) + 1;
        const old = subAreas.find(sa => sa.id === props.selected);
        const newData: SubArea = old ? {
            ...old,
            ...data
        } : {
            id: -1,
            ...props.filter,
            groupId: props.filter.groupId ?? -1,
            area: props.filter.area!,
            targetProjectId: props.filter.targetProjectId!,
            targetUserId: props.filter.targetUserId!,
            targetInternalGroupId: -1,
            ord,
            ...data
        };

        (async () => {
            await CircalindApi.addOrUpdateSubArea(newData);
            await CircalindApi.getSubAreas(filter).then(sas => setSubAreas(sas));
            sendOlert("Erfolgreich", "Gespeichert", "Success");
        })();
    }

    const openAddNewDialog = () => {
        props.onSelected(null);
        setShowEditor(true);
    }

    const openEditDialog = (sa: SubArea) => {
        props.onSelected(sa);
        setShowEditor(true);
    }

    const deleteSubArea = async (id: number) => {
        await CircalindApi.deleteSubArea(id);
        await CircalindApi.getSubAreas(filter).then(sas => setSubAreas(sas));
        sendOlert("Erfolgreich", "Gelöscht", "Success");
    }

    return <div className="sub-area-line">
        <div className="sub-area-list">
            {subAreas.map(sa => {
                return <div
                    className={`sub-area ${sa.id === props.selected ? " selected" : ""}`}
                    onClick={() => props.onSelected(sa)}
                    key={sa.id}
                >
                    {sa.image ? <img alt="icon" src={sa.image} /> : null}
                    <span style={{ color: sa.color }} >{sa.name}</span>
                    <div className={"sub-area-edit-button" + (!editMode ? " hidden" : "")} onClick={() => openEditDialog(sa)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </div>
                    <div className={"sub-area-delete-button" + (!editMode ? " hidden" : "")} onClick={() => deleteSubArea(sa.id)}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </div>
                </div>
            })}
            {(userRoles.includes("COORDINATOR") || userRoles.includes("CO_COORDINATOR")) &&
                <>
                    <div className={"add-sub-area-button" + (!editMode ? " hidden" : "")} onClick={openAddNewDialog}>
                        <FontAwesomeIcon icon={faPlusCircle} />
                    </div>
                </>
            }
        </div>
        <Dialog
            show={showEditor}
            component={<SubAreaEditor
                onSave={saveDialog}
                onCancel={closeDialog}
                initialData={subAreas.find(sa => sa.id === props.selected)}
            />}
            toogle={() => { setShowEditor(e => !e) }}
            className="What"
        />
    </div >
}
