import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useEffect, useState } from "react";

import selbstImage from "../../../../resources/circa/compass/Selbstführung.png";
import managementImage from "../../../../resources/circa/compass/ManagementCompass.png";
import spurImage from "../../../../resources/circa/compass/SpurWechsel.png";

import './prePhase.scss'
import { useNavigate } from "react-router";
import { useSelectedProject } from "../../../../utils/Hooks";

export const PrePhaseSelection = ({ onNext, onBack }: { onNext: () => Promise<void>, onBack: () => Promise<void> }): ReactElement => {

    const [selectedProject,] = useSelectedProject();
    const [projectId, setProjectId] = useState<number>(-1);
    const navigate = useNavigate();

    useEffect(() => {
        const selProjId = selectedProject;

        setProjectId(selProjId);
    }, [selectedProject])

    const gotToPrePhase = (nav: string) => {
        if (projectId !== -1) {
            navigate("../compass/" + nav);
        }
    }

    return <div className="pre-phase-selection">
        {projectId === -1 ? <span className="warning">Bitte wählen Sie ein Projekt aus</span> : null}
        <div className="pre-phases">
            <div></div>
            <div className="pre-phase-card">
                <h2>Selbstführung</h2>
                <span></span>
                <div className="round">
                    <img onClick={() => gotToPrePhase("self")} src={selbstImage} alt="selbstfürhung" />
                </div>
            </div>
            <div className="pre-phase-card">
                <h2>Management-Kompass</h2>
                <span></span>
                <img onClick={() => gotToPrePhase("manage")} className="round" src={managementImage} alt="management" />
            </div>
            <div className="pre-phase-card">
                <h2>Spur-Wechsel</h2>
                <span></span>
                <img onClick={() => gotToPrePhase("lane")} className="round" src={spurImage} alt="spurwechsel" />
            </div>
        </div>
        <div className="navigation">
            <button onClick={() => onBack!()}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button onClick={() => onNext!()}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>
}
