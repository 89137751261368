import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Customer, TypeCustomer } from "../dataTypes/generated";
import { CircalindApi } from "../circalindApi";
import Select from 'react-select';

import "./BasicTable.scss";
import "./AdminCustomerOverview.scss";

export const AdminCustomerOverview = () => {
    const navigate = useNavigate();
    const [filterType, setFilterType] = useState<TypeCustomer | null>(null);
    const [customers, setCustomers] = useState<Array<Customer>>([])

    useEffect(() => {
        const loadCustomers = async () => {
            setCustomers(await CircalindApi.getCustomers());
        };

        loadCustomers();
    }, []);

    const customerTypeOptions: Array<{ label: string, value: TypeCustomer }> = [
        { label: "Privatkunde", value: "PRIVATE" },
        { label: "Privatkunde B2B-Partner", value: "PRIVATE_B2B" },
        { label: "Privatkunde Vertriebs-Partner", value: "PRIVATE_SALES" },
        { label: "Unternehmenskunde", value: "COMPANY" },
        { label: "Unternehmenskunde B2B-Partner", value: "COMPANY_B2B" },
        { label: "Unternehmenskunde Vertriebs-Partner", value: "COMPANY_SALES" },
    ];

    return <div className="admin-customer-overview">
        <h2>Kundenstammdaten</h2>
        <div className="buttons">
            <Select placeholder="Kunden-Typ-Filter" isClearable={true} value={customerTypeOptions.find(o => o.value === filterType)} options={customerTypeOptions} onChange={(o) => setFilterType(o ? o.value : o)} />
            <div></div>
            <button onClick={() => navigate("../overview")}>Zurück</button>
            <button onClick={() => navigate("../customer?id=-1")}>Kunden anlegen</button>
        </div>
        <div className="table-wrapper">
            <table className="basic-table">
                <thead>
                    <tr>
                        <th>Kundennummer</th>
                        <th>Unternehmen</th>
                        <th>Kunden-Typ</th>
                        <th>Spez. Vertragsbedinungen</th>
                        <th>Aktion</th>
                    </tr>
                </thead>
                <tbody>
                    {customers.filter(c => !filterType || c.type === filterType).map(c =>
                        <tr key={c.id}>
                            <td>K-{c.id}</td>
                            <td>{c.name}</td>
                            <td>{customerTypeOptions.find(o => o.value === c.type)?.label}</td>
                            <td>{c.specialContractConditions}</td>
                            <td className="right"><button onClick={() => navigate("../customer?id=" + c.id)}>Bearbeiten</button></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    </div>;
}
