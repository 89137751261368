import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import myWayImage from "../../resources/circa/personal_nav.svg";

import "../../leading/Start.scss";


export const WelcomeRegularDocumentation = () => {
    return <div className="full-page">
        <h1><strong>Dokumentation</strong></h1>
        <img src={myWayImage} alt="Dokumentation" />
        <div onClick={() => window.location.href = "main"} className="lower-arrow"><FontAwesomeIcon icon={faArrowRight} /></div>
    </div>;
}