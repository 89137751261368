import { ReactElement, useState } from "react";
import { sendOlert } from "../../../olert/Olert";
import { Base64Upload } from "../../basicComponents/base64Upload/Base64Upload";
import { CircalindApi } from "../../circalindApi";
import { DeskItem, DeskType } from "../../dataTypes/generated";
import { TagList } from "./ProjectDesk";

export interface NewDeskItemProps {
    onclose: () => void;
    doUpdate: () => Promise<void>;
    phase: number;
    phaseName: string;
    projectId: number;
    groupId: number;
    type: DeskType;
}

export const NewDeskItem = (props: NewDeskItemProps): ReactElement => {

    const [newItem, setNewItem] = useState<DeskItem>({ id: -1, name: "", phase: props.phase, projectId: props.projectId, groupId: props.groupId, type: props.type, userId: -1, base64Data: "", filename: "", tags: [], date: null, source: null })

    const onFileUpload = (base64: string, filename?: string) => {
        let newI = { ...newItem };
        newI.base64Data = base64;
        if (filename) {
            newI.filename = filename;
        }
        if (newI.name.length > 0) {
            save(newI);
        } else {
            sendOlert("ACHTUNG", "Bitte einen Namen eingeben für die Datei", "Info");
        }
    }

    const save = async (toUpload: DeskItem) => {
        const res = await CircalindApi.addDeskItem(toUpload);
        if (res) {
            sendOlert("Erfolgreich", "gespeichert", "Success");
            props.doUpdate();
            props.onclose();
        } else {
            sendOlert("Fehler", "Objekt konnte nicht zum Projektschreibtisch hinzugefügt werden", "Error");
        }
    }

    return <div className="new-desk-item">
        <h2>{"Hinzufügen zu Phase " + props.phaseName}</h2>
        <input type={"text"} value={newItem.name} onChange={(e) => setNewItem({ ...newItem, name: e.target.value })} />
        <TagList tags={newItem.tags} onChange={(tags: Array<string>) => setNewItem({ ...newItem, tags })} />
        <Base64Upload type="FILE" onUpload={onFileUpload} maxFileSize={25 * 1024 * 1024} />
        <div className="abort">
            <button onClick={() => props.onclose()}>Abbrechen</button>
        </div>
    </div>
}