import { ReactElement } from "react"
import { InfoBubble, InfoHull } from "./InfoHull"
import personal from '../../resources/circa/personal_project.svg';
import strategy from '../../resources/circa/strategy_project.svg';

import './DeciderInfoOverview.scss'

export const DeciderInfoOverview = ({edit, userId}: {edit: boolean, userId: number}): ReactElement => {
    return <div className="decider-info-overview">
        <InfoHull edit={edit} userId={userId}>
            <div className="decider-info-overview-grid">
                <InfoBubble subArea="governance" />
                <InfoBubble subArea="practice_room" />
                <InfoBubble subArea="cockpit" />
                <InfoBubble subArea="phases" />
                <InfoBubble subArea="documentation" />
                <InfoBubble subArea="skills" />
                <InfoBubble subArea="communication" />

                <InfoBubble subArea="impulses" />
                <InfoBubble subArea="services" />
                <InfoBubble subArea="checkups" />

                <InfoBubble subArea="information_and_tips" />
                <InfoBubble subArea="project_desk" />
                <InfoBubble subArea="communication_and_interaction" />
                <InfoBubble subArea="change_room" />

                <div className="decider-info-center">
                    <div><span>Persönliche Projekte</span> <img alt="Persönlich" src={personal} /></div>
                    <div><span>Strategie-Projekte</span> <img alt="Strategie" src={strategy} /></div>
                </div>

            </div>
        </InfoHull>
    </div>
}
