import { ReactElement, useState } from "react";

import './collaberation.scss'

import constructionImage from "../../resources/circa/construction.svg";
import { Infos, Stairs } from "./Planning";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

export const Construction = (): ReactElement => {
    const [page, setPage] = useState<number>(0);

    const back = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    }

    const next = () => {
        if (page < 2) {
            setPage(page + 1)
        }
    }

    const renderWelcome = () => {
        return <div className="collab-welcome">
            <h1>Willkommen in der</h1>
            <h1>Aufbau-Phase!</h1>
            <figure>
                <img className="welcome-logo" src={constructionImage} alt="Aufbau" />
                <span className="image-text">Aufbau</span>
            </figure>
            <div><span>für Koordiantor</span></div>
        </div>
    }

    const renderItem = () => {
        switch (page) {
            case 0: return renderWelcome();
            case 1: return <Stairs toShow="CONSTRUCTION" />
            case 2: return <Infos />
        }
    }

    return <div className="collaberation">
        <div className="info-page">
            {renderItem()}
        </div>
        <div className={"navigation"}>
            <button onClick={() => back()}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button disabled={page === 2} onClick={() => next()}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>
}