import { Fragment, ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router";
import { CockpitObject, LANECHANGE, MANAGEMENT, SELFGUIDE } from "./CockpitUtils";
import { CompassType } from "../../../dataTypes/generated";
import { CockpitCompass } from "./CockpitCompass";
import { useSelectedProject } from "../../../../utils/Hooks";

import './DeepIntro.scss'

export interface LaneChangeProps {
    edit: boolean;
}

export const DeepIntro = (props: LaneChangeProps): ReactElement => {

    const { route } = useParams<{ route: string }>();
    const [data, setData] = useState<CockpitObject>();
    const [type, setType] = useState<CompassType>();
    const [selected, setSelected] = useState<number>(0);
    const [projectId,] = useSelectedProject();


    useEffect(() => {
        switch (route) {
            case "self1": setData(LANECHANGE); break;
            case "self3": setData(SELFGUIDE); break;
            case "self2": setData(MANAGEMENT); break;
            default: setData(undefined);
        }
    }, [route])

    const navigateTo = (atIndex: number) => {
        setSelected(atIndex)
        switch (route) {
            case "self1": setType("COMPLANECHANGE"); break;
            case "self2": setType("COMPMANAGEMENT"); break;
            case "self3": setType("COMPSELFGUIDE"); break;
        }
    }

    const renderItems = () => {
        return data?.items.map((m, i) => {
            return <div key={i} className="item" onClick={() => (props.edit === true || projectId >= 0) ? navigateTo(i) : null}>
                <div className="image"> {m.logo ? <img src={m.logo} alt="" /> : null}</div>
                <span>{m.text}</span>
            </div>
        })
    }

    const onBackNavigate = () => {
        setSelected(0);
        setType(undefined);
    }

    const renderBasedOnType = () => {
        if (type !== undefined) {
            return <CockpitCompass projectId={projectId} onBack={onBackNavigate} type={type} index={selected} edit={props.edit} />
        } else {
            return <div className="lanechange">
                {(props.edit === false && projectId === -1) ? <h2 className="warning">Bitte Wählen Sie zuerst ein Projekt aus</h2> : null}
                <div>
                    <div className="main">
                        <div>
                            <div className="title">
                                <span>{data?.title}</span>
                            </div>
                            <div className="items">
                                {renderItems()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    }


    return <Fragment>
        {renderBasedOnType()}
    </Fragment>
}
