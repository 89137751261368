import { useEffect, useState } from "react";
import { CircalindApi } from "../../circalindApi";
import { GeneralContent } from "../../contentManager/GeneralContent";
import { GraphImageFor, Question, SubArea } from "../../dataTypes/generated";
import { CompetenceIndividualCard, CompetenceTeamCard } from "./CompetenceOverview";
import { useAnswer } from "../../hooks/useAnswer";
import { useSelectedProject } from "../../../utils/Hooks";

import "./CompetenceQuestion.scss";
import { usePassword } from "../../hooks/usePassword";

const createEmptyQuestion = (subareaId: number, notes: boolean) => {
  const empty: Question = {
    id: -1,
    subArea: subareaId,
    notes: notes,
    table: { tableCells: [], cols: 0, rows: 0, title: "", style: null },
    title: "",
    phase: -1,
    order: -1,
    compass: "NONE",
    graph: { description: "", title: "", type: "AREA", values: [] },
    group: -1,
    owner: -1,
    reviewedBy: 0,
    version: "",
    editedOn: "",
    reviewedOn: "",
    kind: ""
  };

  return empty;
};

export const CompetenceQuestion = ({ notes, name }: { notes: boolean, name: GraphImageFor }) => {
  const [selectedProject,] = useSelectedProject();
  const [question, setQuestion] = useState<Question>(createEmptyQuestion(-1, notes));
  const [subAreas, setSubAreas] = useState<Array<SubArea>>([]);
  const password = usePassword();
  const [answer, setAnswer, saveAnswer] = useAnswer(question.id, -1, selectedProject, password);

  useEffect(() => {
    const loadQuestion = async () => {
      const subArea = subAreas.find(sa => sa.name === name);
      if (subArea) {
        const response = await CircalindApi.getContentQuestionByFilterForSubArea({ subArea: subArea.id, notes: notes, phase: null, order: -1, userId: null });
        if (response) {
          setQuestion(response);
        } else {
          setQuestion(createEmptyQuestion(subArea.id, notes));
        }
      }
    }

    loadQuestion();
  }, [name, notes, subAreas]);

  useEffect(() => {
    const loadSubAreas = async () => {
      const response = await CircalindApi.getSubAreas({ groupId: -1, area: "competence", targetProjectId: selectedProject, targetInternalGroupId: null, targetUserId: null });
      setSubAreas(response);
    };

    loadSubAreas();
  }, [selectedProject]);

  const renderCard = () => {
    switch (name) {
      case "PERSONAL_COMPETENCE":
        return <CompetenceIndividualCard navigation="../" back={true} />;
      case "PROJECT_PHASE_COMPETENCE":
        return <CompetenceTeamCard navigation="../" back={true} />;
    }
  }

  return <div className="competence-question">
    {renderCard()}
    <div className="content">
      <GeneralContent onUpdate={d => setQuestion({ ...question, table: d })} canEdit={notes} canReview={false} showControls={notes} answers={answer.cells} onCellAnswerd={(data) => setAnswer({ ...answer, cells: data })} question={question}></GeneralContent>
      <div className="button-container">
        <button onClick={() => saveAnswer(password)}>Speichern</button>
      </div>
    </div>
  </div>;
};
