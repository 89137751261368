import { ReactElement, useEffect, useRef, useState } from "react";
import { Question, TableData } from "../../../dataTypes/generated";
import { CompassTable } from "../../compass/table/CompassTable";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableAnswerCell, useAnswer } from "../../../hooks/useAnswer";
import { CircalindApi } from "../../../circalindApi";
import { createEmptyQuestion } from "../../compass/cockpit/CockpitUtils";
import { useSelectedProject } from "../../../../utils/Hooks";
import { usePassword } from "../../../hooks/usePassword";
import { useNavigate } from "react-router";

import selbstImage from "../../../../resources/circa/compass/Selbstführung.png";
import managementImage from "../../../../resources/circa/compass/ManagementCompass.png";
import spurImage from "../../../../resources/circa/compass/SpurWechsel.png";

import './prePhase.scss';
import '../../compass/question/compassQuestion.scss';

export type prePhaseType = "Selbstführung" | "Management" | "Spur-Wechsel";

export interface PrePhaseProps {
    phaseType: prePhaseType;
    edit?: boolean;
    onNext?: () => void;
    onPrev?: () => void;
}

export const PrePhase = (props: PrePhaseProps): ReactElement => {

    const [selectedProject,] = useSelectedProject();
    const [question, setQuestions] = useState<Question>(createEmptyQuestion(-1, "NONE"));
    const [questionId, setQuestionId] = useState<number>(-1);
    const [projectId, setProjectId] = useState<number>(-1);
    const [preview, setPreview] = useState<boolean>(props.edit ? props.edit : false);

    const navigate = useNavigate();

    const password = usePassword()
    const [answer, setAnswer, saveAnswer] = useAnswer(questionId, -1, projectId, password);

    const myRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setProjectId(selectedProject);
    }, [selectedProject])

    useEffect(() => {
        const loadQuestion = async () => {
            const subAreas = await CircalindApi.getSubAreas({
                groupId: -1,
                area: "compass",
                targetProjectId: selectedProject,
                targetUserId: null,
                targetInternalGroupId: null
            });

            const subArea = subAreas.find(sa => sa.name === props.phaseType);

            if (subArea) {
                const res = await CircalindApi.getContentQuestionByFilterForSubArea({
                    subArea: subArea.id,
                    notes: false,
                    phase: null,
                    userId: null,
                    order: -1
                })
                if (res) {
                    setQuestions(res);
                    setQuestionId(res.id);
                } else {
                    if (props.edit === true) {
                        setQuestions(createEmptyQuestion(-1, "NONE"))
                        setQuestionId(-1);
                    }
                }
            }

        }

        loadQuestion();
    }, [props.edit, props.phaseType, selectedProject])

    const canEdit = (): boolean => {
        if (props.edit) {
            return props.edit;
        }
        return false;
    }

    const save = async () => {
    }

    const getImage = () => {
        switch (props.phaseType) {
            case "Management": return managementImage;
            case "Selbstführung": return selbstImage;
            case "Spur-Wechsel": return spurImage;
            default: return "ERROR";
        }
    }

    const onUpdate = (data: TableData) => {
        setQuestions({ ...question, table: data });
    }

    const onAnswer = (data: TableAnswerCell[]) => {
        setAnswer({ ...answer, cells: data })
    }

    const saveAnswers = async () => {
        await saveAnswer(password);
    }

    return <div ref={myRef} className="pre-Phase">
        <div className="pre-Phase-body">
            <div className="pre-phase-card">
                <h2>{props.phaseType}</h2>
                <span></span>
                <div className="round">
                    <img src={getImage()} alt="kompass" />
                </div>
            </div>
            <div className="questions">
                <h3>{props.phaseType}</h3>
                {(props.edit === undefined && (question === undefined || question.table.tableCells.length === 0)) ?
                    <span>Es wurden noch keine Fragen Hinterlegt</span> :
                    <CompassTable
                        edit={preview}
                        answers={answer.cells}
                        data={question.table}
                        onCellAnswerd={onAnswer}
                        onUpdate={onUpdate}
                    />
                }
            </div>
        </div>
        {canEdit() ? <div className="edit-tool-bar">
            <button onClick={() => save()}>Speichern</button>
            <button onClick={() => setPreview(!preview)}>{preview ? "Vorschau" : "Editiern"}</button>
        </div> : null}
        <div className={"navigation"}>
            {props.onPrev ? <button onClick={async () => { await saveAnswers(); props.onPrev!() }}><FontAwesomeIcon icon={faArrowLeft} /></button> : <button onClick={async () => { await saveAnswers(); navigate(-1) }}><FontAwesomeIcon icon={faArrowLeft} /></button>}
            {props.onNext && <button onClick={async () => { await saveAnswers(); props.onNext!() }}><FontAwesomeIcon icon={faArrowRight} /></button>}
        </div>
    </div>
}
