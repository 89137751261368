import { ReactElement, useEffect, useState } from "react";
import { clearPassphrase, clearTempPassphrase, decrypt, encrypt, getPassphrase, getTempPassphrase, setPassphrase, setTempPassphrase } from "../../utils/Encryption";
import * as GDC from "../basicComponents/dialogs/GlobalDialogContainer";
import { CircalindApi } from "../circalindApi";
import { PasswordCheck } from "../dataTypes/generated";
import { assertNever } from "../projects/phases/prePhase/synchro/util";

import './usePassword.scss';

export const usePassword = (supressPrompt?: boolean): string | null => {
  const [state, setState] = useState<HookState>(getInitialHookState());

  useEffect(() => {
    let cancelled = false;
    if (!supressPrompt && state.kind === "verifying") {
      CircalindApi.getPasswordCheck().then(res => {
        if (!cancelled) {
          if (!res) {
            setState({ kind: "none" });
          } else {
            if (verify(state.password, res)) {
              setState({ kind: "verified", password: state.password });
            } else {
              setState({ kind: "none" });
            }
          }
        }
      });
    }

    return () => {
      cancelled = true;
    }
  }, [state, supressPrompt]);

  useEffect(() => {
    switch (state.kind) {
      case 'none':
        if (!supressPrompt) {
          GDC.setDialog(
            <PasswordPrompt onSetPassword={(password, trustDevice) => {
              setState({ kind: "verified", password });
              savePassword(password, trustDevice);
            }} />
          )
        }
        break;
      case 'verifying':
        GDC.setDialog(null);
        break
      case 'verified':
        GDC.setDialog(null);
        break
      default: assertNever(state);
    }
  }, [state, supressPrompt]);

  switch (state.kind) {
    case 'none': return null;
    case 'verifying': return null;
    case 'verified': return state.password;
    default: return assertNever(state);
  }

}

type HookState = { kind: "none" }
  | { kind: "verifying", password: string }
  | { kind: "verified", password: string };

const getInitialHookState = (): HookState => {
  const saved = getSavedPassword();

  if (saved === null) {
    return { kind: "none" };
  } else {
    return { kind: "verifying", password: saved };
  }
}

type Props = {
  onSetPassword: (password: string, trust: boolean) => void;
}

const PasswordPrompt = ({ onSetPassword }: Props): ReactElement => {
  const [promptState, setPromptState] = useState<PromptState>("loading");
  const [pwc, setPwc] = useState<PasswordCheck | null>(null);
  const [trustPC, ] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  const passwordProblem = getPasswordProblem(password, password2);

  useEffect(() => {
    CircalindApi.getPasswordCheck().then(res => {
      setPwc(res);
      if (res === null) {
        setPromptState("intro-text");
      } else {
        setPromptState("ask-for-password");
      }
    });
  }, []);

  const handleSetPasswordButton = async () => {
    await submitPassword(password);
    onSetPassword(password, trustPC);
  }

  const handleCheckPasswordButton = () => {
    if (verify(password, pwc!)) {
      onSetPassword(password, trustPC);
    } else {
      setPromptState("invalid-password")
    }
  }

  let content = null;
  switch (promptState) {
    case "loading":
      content = <div className="loading">Loading...</div>;
      break;
    case "intro-text":
      content = <div className="intro-text">
        <p>Einige Inhalte dieser Software werden nur verschlüsselt gespeichert um ihre persönlichen Daten zu schützen. Hierzu müssen Sie ein Passwort festlegen.</p>
        <p>Die Verschlüsselung verhindert den Zugriff aller unbefugten Personen auf diese Daten. Nicht einmal die Serverbetreiber selbst können auf die Daten zugreifen.</p>
        <p className="warning">Merken Sie sich das Passwort, das Sie nun setzen, aus diesem Grund sehr gut. Geht es verloren, sind automatisch alle damit verschlüsselten Daten verloren und können auch nicht wieder hergestellt werden.</p>
        <button onClick={() => setPromptState("set-password")}>Habe ich verstanden!</button>
      </div>
      break;
    case "ask-for-password":
      content = <div className="ask-for-password">
        <p className="text">Bitte geben Sie ihr Passwort ein um den Inhalt dieser Seite zu sehen.</p>
        <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
        <label>Passwort</label>
        <button onClick={handleCheckPasswordButton}>Ok</button>
      </div>
      break;
    case "set-password":
      content = <div className="set-new">
        <p className="text">Bitte geben Sie ein neues Passwort ein:</p>
        <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
        <label>Passwort</label>
        <input type="password" value={password2} onChange={e => setPassword2(e.target.value)} />
        <label>Passwort wiederholen</label>
        <button disabled={!!passwordProblem} onClick={handleSetPasswordButton}>Setzen</button>
        <label className="warning">{passwordProblem}</label>
        {
          pwc === null ? null :
            <div className="warning reset">
              Achtung, alle Inhalte, die mit dem alten Passwort gespeichert wurden,
              gehen beim setzen eines neuen Passwortes verloren!
            </div>
        }
      </div>
      break
    case "invalid-password":
      content = <div className="invalid-password">
        <p>
          Das Passwort war leider falsch.
          Sie können es erneut versuchen oder ein neues Passwort setzen.
          <span className="warning">
            Wenn Sie ein neues Passwort setzen, dann gehen alle mit dem alten Passwort gespeicherten
            Inhalte verloren.
          </span>
        </p>
        <button onClick={() => setPromptState("ask-for-password")}>Erneut versuchen</button>
        <button onClick={() => setPromptState("set-password")}>Neues Passwort setzen</button>
      </div>
      break;
    default: assertNever(promptState);

  }

  return <div className="use-password-new">
    <div className="header">Passwort notwendig</div>
    {content}
  </div>
}

const getPasswordProblem = (p1: string, p2: string): string | null => {
  if (p1.length < 8) return "Das Passwort muss mindestens 8 Zeichen lang sein.";
  else if (p1 !== p2) return "Die Passwörter stimmen nicht überein.";
  else return null;
}

const submitPassword = async (password: string) => {
  let salt = (new Date()).toString();
  let encrypted = encrypt(salt, password);
  return CircalindApi.setPasswordCheck({ salt, encrypted });
}

const getSavedPassword = (): string | null => {
  const tmp = getTempPassphrase();
  if (!tmp) {
    return getPassphrase();
  } else {
    return tmp;
  }
}

const savePassword = (password: string, trust: boolean): void => {
  clearPassphrase();
  clearTempPassphrase();
  if (trust) {
    setPassphrase(password);
  } else {
    setTempPassphrase(password);
  }
}

const verify = (password: string, passwordCheck: PasswordCheck): boolean => {
  try {
    return decrypt(passwordCheck.encrypted, password) === passwordCheck.salt;
  } catch (e) {
    console.error(e);
    return false;
  }
}

type PromptState = "loading" | "intro-text" | "ask-for-password" | "set-password" | "invalid-password";
