import { useEffect, useState } from "react";
import { CircalindApi } from "../circalindApi";
import { Area, Question, SubArea } from "../dataTypes/generated";
import { GeneralContent } from "./GeneralContent";

import "./SubAreaContent.scss";
import { useSelectedProject } from "../../utils/Hooks";
import { TableAnswerCell, useAnswer } from "../hooks/useAnswer";
import { usePassword } from "../hooks/usePassword";
import { getCurrentUserId } from "../../utils/Auth";


const createEmptyQuestion = (subarea: SubArea | null, notes: boolean) => {
    const empty: Question = {
        id: -1,
        subArea: subarea ? subarea.id : -1,
        notes: notes,
        table: { tableCells: [], cols: 0, rows: 0, title: "", style: null },
        title: subarea ? subarea.name : "",
        phase: -1,
        order: -1,
        compass: "NONE",
        graph: { description: "", title: "", type: "AREA", values: [] },
        group: -1,
        owner: -1,
        reviewedBy: 0,
        version: "",
        editedOn: "",
        reviewedOn: "",
        kind: ""
    };

    return empty;
};


export const SubAreaContent = ({ area, isProjectSpecific, notes = false, global = false, isUserSpecific = false }: { area: Area, isProjectSpecific: boolean, notes?: boolean, global?: boolean, isUserSpecific?: boolean }) => {
    const [selectedProject,] = useSelectedProject();
    const [question, setQuestion] = useState<Question>(createEmptyQuestion(null, notes));
    const [selectedSubarea, setSelectedSubarea] = useState<SubArea | null>(null);
    const [subAreas, setSubAreas] = useState<Array<SubArea>>([]);

    const password = usePassword();

    const [answer, setAnswer, saveAnswer] = useAnswer(question.id, -1, selectedProject, password);

    const onAnswer = (data: TableAnswerCell[]) => {
        setAnswer({ ...answer, cells: data });
        saveAnswer(password);
    }

    const renderSubarea = (subarea: SubArea) => {
        const img = subarea.image && <img src={subarea.image} alt={subarea.name} />;

        if (selectedSubarea === subarea) {
            return <div key={subarea.id} onClick={() => setSelectedSubarea(subarea)} className="card active">{img}<strong>{subarea.name}</strong></div>;
        } else {
            return <div key={subarea.id} onClick={() => setSelectedSubarea(subarea)} className="card">{img}{subarea.name}</div>;
        }
    };

    useEffect(() => {
        const loadSubAreas = async () => {
            const response = await CircalindApi.getSubAreas({ groupId: global ? null : -1, area: area, targetProjectId: isProjectSpecific && (selectedProject > -1) ? selectedProject : null, targetInternalGroupId: null, targetUserId: isUserSpecific ? getCurrentUserId("token-circalind") : null });
            setSubAreas(response);
            if (response.length > 1) {
                setSelectedSubarea(response[0]);
            }
        };

        loadSubAreas();
    }, [isProjectSpecific, isUserSpecific, area, global, selectedProject]);

    useEffect(() => {
        const loadQuestion = async () => {
            if (selectedSubarea !== null) {
                const response = await CircalindApi.getContentQuestionByFilterForSubArea({ subArea: selectedSubarea.id, notes: notes, phase: null, order: -1, userId: null });
                if (response) {
                    setQuestion(response);
                } else {
                    setQuestion(createEmptyQuestion(selectedSubarea, notes));
                }
            }
        };

        loadQuestion();
    }, [selectedSubarea, notes]);

    return <>
        <div className="area-header">
            {subAreas.map(renderSubarea)}
        </div>
        <GeneralContent onUpdateId={id => setQuestion({ ...question, id: id })} changeTitle={t => setQuestion({ ...question, title: t })} question={question} canEdit={notes && !!selectedSubarea} canReview={false} showControls={notes} onUpdate={(d) => { setQuestion({ ...question, table: d }) }} answers={answer.cells} onCellAnswerd={onAnswer} />
    </>;
};
