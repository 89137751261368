import { useEffect, useState } from "react";
import { LicenseTypes, PackagePrice } from "../dataTypes/generated";
import { CircalindApi } from "../circalindApi";
import { useNavigate } from "react-router";
import { sendOlert } from "../../olert/Olert";

import "./BusinessPriceTable.scss";

export const PriceManangementPage = () => {
    const [prices, setPrices] = useState<Array<PackagePrice>>([]);
    const loadPrices = async () => {
        setPrices(await CircalindApi.getPackagePrices());
    };

    useEffect(() => {
        loadPrices();
    }, []);

    const savePrices = async () => {
        for (let price of prices) {
            if (price.price < 0) {
                sendOlert("Ungültiger Wert", "Preis muss >= 0€ sein.", "Error");
                return;
            }
        }

        const response = await CircalindApi.savePackagePrices(prices);

        if (response) {
            sendOlert("Operation erfolgreich", "Erfolgreich gespeichert.", "Success");
        } else {
            sendOlert("Operation fehlgeschlagen", "Preise konnten nicht gespeichert werden.", "Error");
        }

        loadPrices();
    };

    const navigate = useNavigate();

    const renderPriceType = (type: LicenseTypes) => {
        if (type === "BASE_COMPANY") {
            return "Basispreis Unternehmen"
        } else if (type === "BASE_PRIVATE") {
            return "Basispreis Privat"
        } else {
            return type;
        }
    }

    return <div className="prices">
            <h2>Preise</h2>
            <div className="price-row">
                <label>Modul</label>
                <label>Nettopreis in €</label>
            </div>
            {prices.map((price, index) => {
            return <div key={price.type} className="price-row">
               <span>{renderPriceType(price.type)}</span>
               <input onChange={e => {
                const copyPrices = [...prices];
                const foundPrice = copyPrices.find(p => p.type === price.type)
                if (foundPrice) {
                    foundPrice.price = Math.round(+e.target.value * 100);
                }
                setPrices(copyPrices);
               }} step="0.01" value={price.price / 100} type="number"></input>
               €
            </div>
            })}
            <div className="right">
                <button onClick={() => navigate("../overview")}>Zurück</button>
                <button onClick={savePrices}>Speichern</button>
            </div>
        </div>
};