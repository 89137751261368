import { useEffect, useState } from "react";
import { CircalindApi } from "../circalindApi";
import { AnalysisOrder, AreaAndRoleInfo, Customer, LicenseMetaData, Order, TypeCustomer } from "../dataTypes/generated";
import { SelectCustomer } from "../../invoice/utils/selects/SelectCustomer";
import { sendOlert } from "../../olert/Olert";
import { formatNumber } from "../../invoice/utils/Util";
import { Dialog } from "../basicComponents/dialogs/Dialog";

import Select from 'react-select';
import writeXlsxFile, { SheetData } from 'write-excel-file'

import "./OrderOverview.scss";

import "../admin/BasicTable.scss";

export const OrderOverview = ({ isAdmin, showTypeSelection = false }: { isAdmin: boolean, showTypeSelection: boolean }) => {
    const [orders, setOrders] = useState<Array<AnalysisOrder>>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
    const [selectedCustomerType, setSelectedCustomerType] = useState<TypeCustomer | null>(null);
    const [licenseMetaData, setLicenseMetaData] = useState<LicenseMetaData | null>();
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo>();
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [showPaymentDialog, setShowPaymentDialog] = useState<boolean>(false);
    const [targetOrder, setTargetOrder] = useState<Order | null>(null);

    useEffect(() => {
        const loadOrders = async () => {
            if (isAdmin) {
                if (!showTypeSelection && selectedCustomer) {
                    setOrders(await CircalindApi.getOrderAnalysisForCustomer(selectedCustomer.id));
                } else if (showTypeSelection && selectedCustomerType) {
                    setOrders(await CircalindApi.getOrderAnalysisForCustomerType(selectedCustomerType));
                }
            } else if (!isAdmin) {
                setOrders(await CircalindApi.getMyOrderAnalysis());
            }
        };
        if (!isAdmin) {
            loadOrders();
        }
    }, [isAdmin, selectedCustomer, selectedCustomerType, showTypeSelection]);

    useEffect(() => {
        const loadMetaData = async () => {
            setLicenseMetaData(await CircalindApi.getLicenseMetaData());
            setAreaAndRoleInfo(await CircalindApi.getAreaAndRoleInfo());
        };

        const getMyCustomer = async () => {
            setSelectedCustomer(await CircalindApi.getMyCustomer());
        }

        const getCustomers = async () => {
            setCustomers(await CircalindApi.getCustomers());
        }

        if (!isAdmin) {
            getMyCustomer();
        } else {
            getCustomers();
        }

        loadMetaData();
    }, [isAdmin]);

    useEffect(() => {
        const loadOrders = async () => {
            if (selectedCustomer) {
                setOrders(await CircalindApi.getOrderAnalysisForCustomer(selectedCustomer.id));
            }
        };

        loadOrders();
    }, [selectedCustomer]);

    useEffect(() => {
        const loadOrders = async () => {
            if (selectedCustomerType) {
                setOrders(await CircalindApi.getOrderAnalysisForCustomerType(selectedCustomerType));
            }
        };

        loadOrders();
    }, [selectedCustomerType]);

    const exportExcel = async () => {
        const header_rows = [
            { value: 'Kundenname', type: String, fontWeight: "bold" },
            { value: 'Kundennummer', type: String, fontWeight: "bold" },
            { value: 'Bestell Nr.', type: String, fontWeight: "bold" },
            { value: 'Bestell Datum', type: String, fontWeight: "bold" },
            { value: 'Basisvertragsnummer', type: String, fontWeight: "bold" },
            { value: 'Module', type: String, fontWeight: "bold" },
            { value: 'Start-Monat der Bestellung', type: String, fontWeight: "bold" },
            { value: 'End-Monat der Bestellung', type: String, fontWeight: "bold" },
            { value: 'Kundenrabatt', type: String, fontWeight: "bold" },
            { value: 'Rechnungsbetrag der Bestellung', type: String, fontWeight: "bold" },
            { value: 'Rechnungsbetrag pro Monat', type: String, fontWeight: "bold" },
            { value: 'Rechnungsbetrag pro Jahr', type: String, fontWeight: "bold" },
            { value: 'Anzahl zusätzlicher Entscheider Lizenzen', type: String, fontWeight: "bold" },
            { value: 'Anzahl zusätzlicher Assistenz Lizenzen', type: String, fontWeight: "bold" },
            { value: 'Zahlung in Verzug', type: String, fontWeight: "bold" }
        ];

        const values = orders.map(order =>
            [
                { value: order.customer.name, type: String },
                { value: `K-${order.customer.id}`, type: String },
                { value: `B-${order.order.id}`, type: String },
                { value: new Date(order.order.date).toLocaleDateString(), type: String },
                { value: order.contractIds.map(cid => `V-${cid}`).join(", "), type: String },
                { value: order.licenseTypes.join(", "), type: String },
                { value: order.startOrderDate, type: String },
                { value: order.endOrderDate, type: String },
                { value: order.order.customerSnapshot?.discount, type: Number },
                { value: formatNumber(order.invoiceAmount / 100.0), type: String },
                { value: formatNumber(order.invoiceAmountPerMonth / 100.0), type: String },
                { value: formatNumber(order.invoiceAmountPerYear / 100.0), type: String },
                { value: order.additionalAssistance, type: Number },
                { value: order.additionalDeciders, type: Number },
                { value: order.order.notPaid ? new Date(order.order.notPaid).toLocaleDateString() : "", type: String },
            ]
        );

        const data = [header_rows, ...values];

        await writeXlsxFile(data as SheetData, {
            fileName: 'bestellungen.xlsx',
        });
    };

    const renderCustomerSelect = () => {
        const customerTypeOptions: Array<{ label: string, value: TypeCustomer }> = [
            { label: "Privatkunde", value: "PRIVATE" },
            { label: "Privatkunde B2B-Partner", value: "PRIVATE_B2B" },
            { label: "Privatkunde Vertriebs-Partner", value: "PRIVATE_SALES" },
            { label: "Unternehmenskunde", value: "COMPANY" },
            { label: "Unternehmenskunde B2B-Partner", value: "COMPANY_B2B" },
            { label: "Unternehmenskunde Vertriebs-Partner", value: "COMPANY_SALES" },
        ];

        return <div className="customer-select-wrapper">
            {isAdmin && !showTypeSelection && <SelectCustomer customers={customers} customerId={selectedCustomer?.id || -1} onChange={setSelectedCustomer} />}
            {isAdmin && showTypeSelection && <Select placeholder="Kunden-Typ-Filter" isClearable={true} value={customerTypeOptions.find(o => o.value === selectedCustomerType)} options={customerTypeOptions} onChange={(o) => setSelectedCustomerType(o ? o.value : o)} />}
            {!isAdmin && <div></div>}
            <button onClick={exportExcel}>Download Excel</button>
        </div>;
    };

    const renderOrder = (order: AnalysisOrder) => {
        return <tr key={order.order.id}>
            <td>{order.customer.name}</td>
            <td>K-{order.customer.id}</td>
            <td>B-{order.order.id}</td>
            <td>{new Date(order.order.date).toLocaleDateString()}</td>
            <td>{order.contractIds.map(cid => `V-${cid}`).join(", ")}</td>
            <td>{order.licenseTypes.map(lt => licenseMetaData!.licenseTitles[lt]).join(", ")}</td>
            <td>{order.startOrderDate}</td>
            <td>{order.endOrderDate}</td>
            <td>{order.order.customerSnapshot?.discount} %</td>
            <td>{formatNumber(order.invoiceAmount / 100.0)}</td>
            <td>{formatNumber(order.invoiceAmountPerMonth / 100.0)}</td>
            <td>{formatNumber(order.invoiceAmountPerYear / 100.0)}</td>
            <td>{order.additionalAssistance}</td>
            <td>{order.additionalDeciders}</td>
            <td>{order.order.notPaid && new Date(order.order.notPaid).toLocaleDateString()}</td>
            {isAdmin && <td><button onClick={() => { setTargetOrder(order.order); setShowPaymentDialog(true); }}>Zahlungsverzug bearbeiten</button></td>}
        </tr>
    };

    const renderOrders = () => {
        if (licenseMetaData && (!isAdmin || selectedCustomer || selectedCustomerType) && areaAndRoleInfo) {
            return <div className="table-wrapper">
                <table className="basic-table">
                    <thead>
                        <tr>
                            <th>Kundenname</th>
                            <th>Kundennummer</th>
                            <th>Bestell Nr.</th>
                            <th>Bestell Datum</th>
                            <th>Basisvertragsnummer</th>
                            <th>Module</th>
                            <th>Start-Monat der Bestellung</th>
                            <th>End-Monat der Bestellung</th>
                            <th>Kundenrabatt</th>
                            <th>Rechnungsbetrag der Bestellung</th>
                            <th>Rechnungsbetrag pro Monat</th>
                            <th>Rechnungsbetrag pro Jahr</th>
                            <th>Anzahl zusätzlicher Entscheider Lizenzen</th>
                            <th>Anzahl zusätzlicher Assistenz Lizenzen</th>
                            <th>Zahlung in Verzug</th>
                            {isAdmin && <th>Aktion</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map(renderOrder)}
                    </tbody>
                </table>
                {targetOrder && <Dialog className={""} component={<PaymentDialog
                    updatePaid={(order: Order) => {
                        const copy = [...orders];
                        const index = copy.findIndex(o => o.order.id === order.id);
                        copy[index] = { ...copy[index], order };
                        setOrders(copy);
                        setShowPaymentDialog(false);
                    }
                    }
                    order={targetOrder}
                />} show={showPaymentDialog} toogle={() => setShowPaymentDialog(false)} />}
            </div>;
        }
    };

    return <div className="order-overview-container">
        {renderCustomerSelect()}
        {renderOrders()}
    </div>;
};

const PaymentDialog = ({ order, updatePaid }: { order: Order, updatePaid: (order: Order) => void }) => {
    const [notPaid, setNotPaid] = useState<string | null>(order.notPaid);

    const savePaymentDate = async () => {
        const res = await CircalindApi.updatePaid(order.id, notPaid);

        if (res) {
            sendOlert("Erfolgreich aktualisiert", "Zahlungsverzug wurde erfolgreich aktualisiert.", "Success");
            updatePaid({ ...order, notPaid });
        } else {
            sendOlert("Fehler beim Aktualisieren", "Zahlungsverzug konnte nicht aktualisiert werden.", "Error");
        }
    };

    return <div className="order-payment-dialog">
        <div className="top-row">
            <label htmlFor="unpaid">In Verzug seit:</label>
            <input onChange={(e) => setNotPaid(e.target.value)} value={notPaid ?? ""} id="unpaid" type="date" />
            <button onClick={() => setNotPaid(null)}>Zurücksetzen</button>
        </div>
        <div className="right">
            <button onClick={savePaymentDate}>Speichern</button>
        </div>
    </div>;
};