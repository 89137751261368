import { useEffect, useState } from "react";
import { getCurrentUserId } from "../../utils/Auth";
import { CircalindApi } from "../circalindApi";
import { Project } from "../dataTypes/generated";
import { DetailAction, ProjectsDetailsView } from "../projects/ProjectsDetailsView";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { serviceHeaders, useHeaderContext } from "../page/header/Header";

export const ProjectOverview = () => {
    const [projects, setProjects] = useState<Array<Project>>([]);

    const headers = useHeaderContext();
    headers.updateHeader(serviceHeaders);


    const getProjects = async () => {
        setProjects((await CircalindApi.getProjects()).filter(p => p.ownerId === getCurrentUserId("token-circalind")));
    };

    const actions: Array<DetailAction> = [
        { icon: faTrashAlt, tooltip: "Löschen", action: async (project: Project) => { await CircalindApi.deleteProject(project.id); await getProjects() } },
    ];

    useEffect(() => {

        getProjects();
    }, []);

    return <ProjectsDetailsView heading="Projekte initiieren und kombinieren" actionHeading="Projekt managen" projects={projects} actions={actions} />
};