import { ReactElement } from "react";
import { Phase1, Phase2, Phase3, Phase4, Phase5 } from "../../../resources/Images";
import { Phases } from "../../../utils/CircaUtils";
import { renderPhaseStateSVG } from "../Projects";

import './phase.scss';
import { useNavigate } from "react-router";

export interface PhaseHeaderProps {
    current: number;
    updatePhase?: (phase: number) => void;
}

export const getPhaseImage = (phase: number) => {
    switch (phase) {
        case 1: return Phase1;
        case 2: return Phase2;
        case 3: return Phase3;
        case 4: return Phase4;
        case 5: return Phase5;
        default: return Phase1;
    }
}

export const getPhaseTitle = (phase: number) => {
    switch (phase) {
        case 1: return Phases.PHASE_1;
        case 2: return Phases.PHASE_2;
        case 3: return Phases.PHASE_3;
        case 4: return Phases.PHASE_4;
        case 5: return Phases.PHASE_5;
        default: return Phases.PHASE_1;
    }
}


export const PhaseHeader = (props: PhaseHeaderProps): ReactElement => {
    const navigate = useNavigate();

    const renderPhaseItem = (phase: number) => {
        return <div className="phase-header-item" onClick={() => props.updatePhase ? props.updatePhase(phase) : navigate("/circalind/regular/phase/" + phase)}>
            <div className="text">
                <div className={"number " + (props.current === phase ? "selected" : "")} >{"Phase " + phase}</div>
                <span>{props.current === phase ? getPhaseTitle(phase) : ""}</span>
            </div>
            {props.current === phase ?
                <div className="phase-img">
                    <img src={getPhaseImage(props.current)} alt={"Phase " + props.current} />
                </div>
                : null
            }
        </div>
    }

    return <div className="phase-header">
        <div className="phases">
            {renderPhaseItem(1)}
            {renderPhaseItem(2)}
            {renderPhaseItem(3)}
            {renderPhaseItem(4)}
            {renderPhaseItem(5)}
        </div>
        <div className="project-state">
            {renderPhaseStateSVG(props.current)}
            <span>{(new Date()).toLocaleString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
        </div>
    </div>
}
