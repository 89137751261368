import { ReactElement } from "react";

import * as C from "./Config";
import * as U from "./util";

export type SectionAngleData = {
    section: string,
    startAngle: number,
    endAngle: number
}

export const getSectionAngleData = (config: C.Config): SectionAngleData[] => {
    const a = 360 / config.sections.length;
    const angleOffset = - a / 2 - 90;

    return config.sections.map((s, i) => {
        return {
            section: s.name,
            startAngle: i * a + angleOffset,
            endAngle: (i + 1) * a + angleOffset
        };
    });
}

export const renderSection = (section: C.Section, hovered: boolean): ReactElement => {
    return <g key={section.name}>
        <use href={`#${sectionFillPathId(section)}`} fill={hovered ? section.highlightColor : section.color} />
        <text fontSize={10}>
            <textPath href={`#${sectionTextPathId(section)}`} startOffset="50%" textAnchor="middle" alignmentBaseline="middle">
                {section.name}
            </textPath>
        </text>
    </g>;
}

export const createSectionPaths = (config: C.Config, section: C.Section): ReactElement[] => {
    const ri = config.innerRadius + config.sectionMargin;
    const ro = 100 - config.sectionMargin;
    const rt = 105;

    const { startAngle, endAngle } = getSectionAngleData(config).find(s => s.section === section.name)!;

    const innerAngleGap = (config.sectionGap / (2 * Math.PI * ri)) * 360;
    const outerAngleGap = (config.sectionGap / (2 * Math.PI * ro)) * 360;
    const textAngleGap = (config.sectionGap / (2 * Math.PI * rt)) * 360;

    const innerStartAngle = startAngle + innerAngleGap / 2;
    const outerStartAngle = startAngle + outerAngleGap / 2;
    const innerEndAngle = endAngle - innerAngleGap / 2;
    const outerEndAngle = endAngle - outerAngleGap / 2;
    const textStartAngle = startAngle + textAngleGap / 2;
    const textEndAngle = endAngle - textAngleGap / 2;

    const [x1, y1] = U.polarToCartesian(ri, innerStartAngle);
    const [x2, y2] = U.polarToCartesian(ro, outerStartAngle);
    const [x3, y3] = U.polarToCartesian(ro, outerEndAngle);
    const [x4, y4] = U.polarToCartesian(ri, innerEndAngle);
    const [tx1, ty1] = U.polarToCartesian(rt, textStartAngle);
    const [tx2, ty2] = U.polarToCartesian(rt, textEndAngle);

    return [
        <path key={sectionFillPathId(section)} id={sectionFillPathId(section)} d={`
            M ${x1} ${y1}
            L ${x2} ${y2}
            A ${ro} ${ro} 0 0 1 ${x3} ${y3}
            L ${x4} ${y4}
            A ${ri} ${ri} 0 0 0 ${x1} ${y1}
        `} />,
        <path key={sectionTextPathId(section)} id={sectionTextPathId(section)} d={`
            M ${tx1} ${ty1}
            A ${rt} ${rt} 0 0 1 ${tx2} ${ty2}
        `} />
    ];
}

const sectionFillPathId = (section: C.Section): string => `${section.name}-fill`;
const sectionTextPathId = (section: C.Section): string => `${section.name}-text`;

