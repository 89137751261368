import { ReactElement } from "react";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import feedbackImage from "../../../../resources/circa/compass/Feedback2.png";
import gestaltungImage from "../../../../resources/circa/compass/Gestaltung.png";
import wandelImage from "../../../../resources/circa/compass/Wandel.png";

import './postPhase.scss';
import { PhaseDiagrams } from "../../competence/PhaseDiagrams";
import { getCurrentUserId } from "../../../../utils/Auth";
import { GraphImageFor } from "../../../dataTypes/generated";
import { useSelectedProject } from "../../../../utils/Hooks";

export type PostPhaseType = "Feedback" | "Wandel" | "Gestaltung";

export interface PostPhaseProps {
    phaseType: PostPhaseType;
    edit?: boolean;
}

export const PostPhase = (props: PostPhaseProps): ReactElement => {
    const userId = getCurrentUserId("token-circalind");
    const [project,] = useSelectedProject();

    const navigate = useNavigate();

    const getTitle = () => {
        switch (props.phaseType) {
            case "Feedback": return "Plausibilitäts-Check";
            case "Wandel": return "Wechselwirkungs-Check";
            case "Gestaltung": return "Wirksamkeits-Hebel-Check";
            default: return "ERROR UNBEKANNTE POST-PHASE";
        }
    }

    const getImage = () => {
        switch (props.phaseType) {
            case "Feedback": return feedbackImage;
            case "Wandel": return wandelImage;
            case "Gestaltung": return gestaltungImage;
            default: return "ERROR";
        }
    }


    const onBack = async () => {
        navigate("/circalind/regular/postPhase");
    }

    const renderBody = () => {
        if (props.phaseType === "Feedback") {
            return <div>
                <h3>
                Weitere Sichtweisen/ Perspektiven (aus dem Check-Up Bereich)
                </h3>
                <div className="content-box"><a href="/circalind/managing/toolbox">Graphen zum Projekt</a></div>
                <h3>
                Feedback & Resonanz
                </h3>
                <div className="content-box"><a href="/circalind/interaction/polls">Sammlung meiner Umfragen zum Projekt anzeigen</a></div>
                <h3>
                Weitere Informations-Quellen (Kommunikation: Studien/ Analysen/ Projekt-Schreibtisch
                </h3>
                <div className="content-box"><a href="/circalind/regular/desk">Sammlung im Projekt-Schreibtisch</a></div>
            </div>;
        } else {
            const graphForChoices: Array<GraphImageFor> = props.phaseType === "Wandel" ? ["POST_PHASE_INTERACTION"] : ["POST_PHASE_EFFECTIVITY"];
            return userId && <PhaseDiagrams projectId={project} chooseProject={false} graphForChoices={graphForChoices} userId={userId} authorId={userId} />;
        }
    };

    return <div className="post-Phase">
        <div className="post-phase-header">
            <span className="round"><img src={getImage()} alt={getTitle()} /></span>
            <h2>{getTitle()}</h2>
        </div>
        <div className="post-Phase-body">
            {renderBody()}
        </div>
        <div className={"navigation"}>
            <button onClick={() => onBack()}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button disabled><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>
}
