import React, { ReactElement, useEffect, useReducer } from 'react';
import './olert.scss';

type Severity = "Error" | "Warning" | "Info" | "Success";
type OlertStatus = "InGoing" | "OutGoing" | "Paused" | "CountingDown" | "Hidden" | "Update";
type OlertAction = UpdateAction | { kind: "Tick"; } | TargetedAction;

export interface OlertMessage {
    id: string;
    title: string;
    message: string;
    timeout: number;
    timeRemaining: number;
    severity: Severity;
    status: OlertStatus;
}

export interface TargetedAction {
    kind: "Delete" | "HoverEnter" | "HoverLeave" | "AnimateIn";
    targetId: string;
}

export interface UpdateAction {
    kind: "Update" | "Add";
    message: OlertMessage;
}

let idCounter = 0;

export const sendOlert = (title: string, message: string, severity: Severity = "Info", timeout: number = 1500): string => {
    const id = "olertID-" + idCounter++;
    const event = new CustomEvent("newOlert", {
        detail: as<OlertMessage>({
            id: id,
            message: message,
            title: title,
            severity: severity,
            timeout: timeout,
            timeRemaining: timeout,
            status: "InGoing"
        })
    });
    dispatchEvent(event);
    return id;
};

export const updateOlert = (id: string, title: string, message: string, severity: Severity = "Info", timeout: number = 1500): void => {
    const event = new CustomEvent("updateOlert", {
        detail: as<OlertMessage>({
            id: id,
            message: message,
            title: title,
            severity: severity,
            timeout: timeout,
            timeRemaining: timeout,
            status: "Update"
        })
    });
    dispatchEvent(event);
};

const interval_time = 100;

const reducer = (olerts: OlertMessage[], action: OlertAction): OlertMessage[] => {
    switch (action.kind) {
        case "Tick": {
            return olerts.map((o, index) => {
                if (o.status !== "Paused") {
                    const timeRemaining = o.timeRemaining - interval_time;
                    if (timeRemaining <= 0) {
                        const pauseIndex = olerts.findIndex(e => e.status === "Paused");
                        if (pauseIndex > 0 && index <= pauseIndex) {
                            return { ...o, timeRemaining: timeRemaining, status: "Hidden" };
                        } else {
                            return { ...o, timeRemaining: timeRemaining, status: "OutGoing" };
                        }
                    } else {
                        return { ...o, timeRemaining: timeRemaining };
                    }
                } else {
                    return o;
                }
            });
        }
        case "Update": {
            return olerts.map(o => o.id === action.message.id ? action.message : o);
        }
        case "Add": {
            return ([...olerts, action.message]);
        }
        case "Delete": {
            return olerts.filter(e => e.id !== action.targetId);
        }
        case "HoverEnter": {
            return olerts.map(o => {
                if (o.status === "CountingDown" || o.status === "Paused") {
                    return o.id === action.targetId ? { ...o, status: "Paused" } : { ...o, status: "CountingDown" };
                } else {
                    return o;
                }
            });
        }
        case "HoverLeave": {
            return olerts.map(o => o.id === action.targetId ? { ...o, status: "CountingDown" } : o);
        }
        case "AnimateIn": {
            return olerts.map(o => o.id === action.targetId ? { ...o, status: "CountingDown" } : o);
        }
        default:
            return assertNever(action);
    }
};

const OlertHandler = (): ReactElement => {

    const [olerts, dispatch] = useReducer(reducer, []);

    const animationEndCallback = (olert: OlertMessage) => {
        if (olert.status === "InGoing") {
            dispatch({ kind: "AnimateIn", targetId: olert.id });
        } else if (olert.status === "OutGoing") {
            dispatch({ kind: "Delete", targetId: olert.id });
        }
    };

    const getProgress = (olert: OlertMessage): number => {
        return Math.floor((olert.timeRemaining / olert.timeout) * 100);
    };

    useEffect(() => {
        const handleNewOlert = (event: any) => {
            dispatch({ kind: "Add", message: event.detail as OlertMessage });
        };
        const handleUpdate = (event: any) => {
            dispatch({ kind: "Update", message: event.detail as OlertMessage });
        };

        const interval = setInterval(() => {
            dispatch({ kind: "Tick" });
        }, interval_time);

        window.self.addEventListener("newOlert", handleNewOlert);
        window.self.addEventListener("updateOlert", handleUpdate);
        return () => {
            clearInterval(interval);
            window.self.removeEventListener("newOlert", handleNewOlert);
            window.self.addEventListener("updateOlert", handleUpdate);
        };
    }, []);

    return (
        <div className="olert-container">
            {
                olerts.map((olert: OlertMessage, i) =>
                    <div key={olert.id}
                        className={"olert-item " + olert.status + " " + olert.severity}
                        onAnimationEnd={() => animationEndCallback(olert)}
                        onMouseEnter={() => dispatch({ kind: "HoverEnter", targetId: olert.id })}
                        onMouseLeave={() => dispatch({ kind: "HoverLeave", targetId: olert.id })}>
                        <div className={"olert " + olert.severity}>
                            <button className="close" onClick={() => dispatch({ kind: "Delete", targetId: olert.id })}>X</button>
                            <div className={olert.severity} />
                            <div className="olert-title">
                                {olert.title}
                            </div>
                            <div className="olert-message">
                                {olert.message}
                            </div>
                            <div className="olert-progress" style={{ width: getProgress(olert) + "%" }}></div>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default OlertHandler;

// fixme: replace with import from util
const as = <T extends unknown>(t: T): T => t;
const assertNever = (x: never): never => { throw new Error("should be unreachable!"); }