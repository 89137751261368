import { ReactElement, useEffect, useState } from "react";
import { CircalindApi } from "../../circalind/circalindApi";
import { AreaAndRoleInfo, RoleType, User } from "../../circalind/dataTypes/generated";
import { sendOlert } from "../../olert/Olert";
import { StaffingDialogProps } from "../staffing/Staffing";

import "./EditStaffingUser.scss";
import { RoleCircle } from "../role";

export const EditStaffingUser = (props: StaffingDialogProps): ReactElement => {

    const getDefaultOrSelected = (): User => {
        if (props.selected) {
            return props.selected as User;
        }
        return { id: -1, firstName: "", lastName: "", mail: "", roles: [], secret: "" }
    }
    const [user, setUser] = useState<User>(getDefaultOrSelected)
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo | null>();

    useEffect(() => {
        const getData = async () => {
            const areaInfo = await CircalindApi.getAreaAndRoleInfo();
            setAreaAndRoleInfo(areaInfo);
        }
        getData();
    }, [])

    const onSave = async () => {
        if (!user.mail) {
            sendOlert("Fehler", "Es muss eine E-Mail-Adresse angegeben werden", "Error");
            return;
        }

        if (!user.firstName) {
            sendOlert("Fehler", "Es muss ein Vorname angegeben werden", "Error");
            return;
        }

        if (!user.lastName) {
            sendOlert("Fehler", "Es muss ein Nachname angegeben werden", "Error");
            return;
        }

        if (user.id === -1) {
            const res = await CircalindApi.addNewUser(user);
            if (res) {
                sendOlert("Erfolgreich", "Benutzer gespeichert", "Success");
            } else {
                sendOlert("Fehler", "Benutzer konnte nicht gespeichert werden", "Error");
            }
        } else {
            const res = await CircalindApi.updateUser(user);
            if (res) {
                sendOlert("Erfolgreich", "Benutzer gespeichert", "Success");
            } else {
                sendOlert("Fehler", "Benutzer konnte nicht gespeichert werden", "Error");
            }
        }

        props.onUpdate();
    }

    const hasRole = (val: RoleType): boolean => {
        return user.roles.includes(val);
    }

    const setUserRole = (role: RoleType) => {
        let toSetUser = { ...user };
        const index = toSetUser.roles.indexOf(role);
        if (index >= 0) {
            toSetUser.roles.splice(index, 1);
        } else {
            toSetUser.roles.push(role);
        }
        setUser(toSetUser)
    }

    const renderLeadingRoles = () => {
        const roleList: Array<RoleType> = ["CIRCALINDMANAGER", "COORDINATOR", "CO_COORDINATOR", "IT_COORDINATOR", "QM_COORDINATOR", "SUPPORT_PARTNER"];
        if (areaAndRoleInfo && props.licenseType) {
            return <div className="role-list">
                <h3>Führung und Zusammenarbeit</h3>
                {areaAndRoleInfo.availableRoles[props.licenseType]!.filter(r => roleList.includes(r)).map(role => {
                    return <div className="role" key={role}>
                        <RoleCircle role={role} />
                        <input id={role} type="checkbox" checked={hasRole(role)} onChange={() => setUserRole(role)} />
                        <label htmlFor={role}>
                            {areaAndRoleInfo.roleTitles[role]}
                        </label>
                    </div>
                })
                }
            </div>;
        };
    };

    const renderDeciderRoles = () => {
        if (areaAndRoleInfo && props.licenseType) {
            const roleList: Array<RoleType> = ["DECIDER", "CONTENT_MANAGER", "CO_MANAGER_PROJECT", "CO_MANAGER_DRIVERS", "IMPULSE_PARTNER"];
            return <div className="role-list">
                <h3>Entscheider</h3>
                {areaAndRoleInfo.availableRoles[props.licenseType]!.filter(r => roleList.includes(r)).map(role => {
                    return <div className="role" key={role}>
                        <RoleCircle role={role} />
                        <input id={role} type="checkbox" checked={hasRole(role)} onChange={() => setUserRole(role)} />
                        <label htmlFor={role}>
                            {areaAndRoleInfo.roleTitles[role]}
                        </label>
                    </div>
                })
                }
            </div>;
        };
    }

    const renderRoleCheckboxes = () => {
        return <div className="role-lists">
            {renderLeadingRoles()}
            {renderDeciderRoles()}
        </div>
    };

    return <div className="new-staffing-user">
        <div className="inputs">
            <div className="input">
                <label>Vorname</label>
                <input value={user.firstName} onChange={(e) => setUser({ ...user, firstName: e.target.value })} />
            </div>
            <div className="input">
                <label>Nachname</label>
                <input value={user.lastName} onChange={(e) => setUser({ ...user, lastName: e.target.value })} />
            </div>
            <div className="input">
                <label>E-mail</label>
                <input type="email" value={user.mail} onChange={(e) => setUser({ ...user, mail: e.target.value })} />
            </div>
            <div className="roles">
                <h3>Rollen</h3>
                <h4>{user.firstName} {user.lastName}</h4>
                {renderRoleCheckboxes()}
            </div>
            <span className="info">Der Nutzer muss sich neu anmelden, falls sich die Rollen geändert haben sollten.</span>
        </div>
        <div className="buttons">
            <button onClick={() => props.onCancel()}>Abbrechen</button>
            <button onClick={() => onSave()}>Speichern</button>
        </div>
    </div>
}