import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { CircalindApi } from "../circalindApi";
import { CircaPackage } from "../dataTypes/generated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { DeciderInfoOverview } from "../info/DeciderInfoOverview";

import "./Start.scss";
import { getCurrentUserId } from "../../utils/Auth";

export const DeciderInfo = () => {
    const [circaPackage, setCircaPackage] = useState<CircaPackage | null>();

    const navigate = useNavigate();

    useEffect(() => {
        const loadPackage = async () => {
            setCircaPackage(await CircalindApi.getLicense());
        };

        loadPackage();
    }, []);

    const userId = getCurrentUserId("token-circalind");

    return <div className="full-page">
        <h1><strong>Entscheider-Projektbereich</strong></h1>
        <h3>{circaPackage && `Zur Nutzung des Modul-Pakets: ${circaPackage.type}`}</h3>
        {userId && <DeciderInfoOverview edit={false} userId={userId} />}
        <div className={"navigation"}>
            <button onClick={() => navigate("../start")}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button disabled><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>;
};