import { useEffect, useState } from "react";
import { CircalindApi } from "../circalindApi";
import { CircaPackage, LicenseMetaData } from "../dataTypes/generated";
import Select from 'react-select';
import { useNavigate, useParams } from "react-router";
import { serviceHeaders, useHeaderContext } from "../page/header/Header";

import "./ChooseLicense.scss";
import { getUserRoles } from "../../utils/Auth";

export const ChooseLicense = () => {
    const [licenseMetaData, setLicenseMetaData] = useState<LicenseMetaData | null>(null);
    const [licenses, setLicenses] = useState<Array<CircaPackage>>([]);
    const [license, setLicense] = useState<CircaPackage | null>(null);
    const { target } = useParams<{ target: string }>();

    const { updateHeader } = useHeaderContext();

    const navigate = useNavigate();

    const roles = getUserRoles("token-circalind");

    useEffect(() => {
        const loadLicenses = async () => {
            setLicenses(await CircalindApi.getAllLicenses());
            setLicenseMetaData(await CircalindApi.getLicenseMetaData());
        };

        updateHeader(serviceHeaders);
        loadLicenses();
    }, [updateHeader]);

    const renderLicenseSelect = () => {
        if (licenseMetaData) {
            const options = licenses.map(l => {
                return {
                    value: l,
                    label: `${licenseMetaData.licenseTitles[l.type]} - Paketnummer: P-${l.id} - Bis ${l.endMonth}.${l.endYear} ${l.active ? "aktiv" : "inaktiv"}`
                };
            }).sort((a, b) => a.label.localeCompare(b.label));

            return <div className="select-container">
                <Select value={options.find(o => license && o.value.id === license.id)} isSearchable isClearable={true} options={options} onChange={(opt) => setLicense(opt!.value)} />
            </div>
        }
    };

    const selectLicense = async () => {
        if (license) {
            await CircalindApi.updateRoles(license.groupId);
            if (target) {
                navigate(target);
            } else {
                navigate("/circalind/services/id");
            }
        }
    };

    const checkLicenseInRange = (license: CircaPackage) => {
        const start = new Date(license.startYear, license.startMonth - 1);
        const end = new Date(license.endYear, license.endMonth, 0, 23, 59, 59);
        const today = new Date();

        return today >= start && today <= end;
    }

    return <div className="choose-license">
        <div className="actions">
            {roles.includes("CIRCALINDMANAGER") && <button onClick={() => navigate("/circalind/services/customer")}>Stammdaten anpassen</button>}
            {roles.includes("CIRCALINDMANAGER") && <button onClick={() => navigate("/circalind/services/order")}>Bestellung aufgeben</button>}
            {roles.includes("CIRCALINDMANAGER") && <button onClick={() => navigate("/circalind/services/order/overview")}>Auswertung Bestellung</button>}
            <button onClick={() => navigate("/circalind/services/package/overview")}>Auswertung Laufzeiten</button>
        </div>
        <h3>Bitte wählen Sie ein aktives Paket</h3>
        {renderLicenseSelect()}
        <button disabled={license === null || !checkLicenseInRange(license) || !license.active} onClick={() => selectLicense()}>Weiter</button>
    </div>;
};
