import { ReactElement, useMemo } from "react";
import { Project } from "../../../../dataTypes/generated";
import { emptyProjectSynchronimeterData, ProjectSynchronimeterData } from "./ProjectSynchronimeterData";

import * as ST from './State';

import './SynchronimeterValues.scss';

export type Props = {
    showIs: boolean
    project: Project | null;
    handler: (event: ST.StateEvent) => void;
}

export const SynchronimeterValues = (props: Props): ReactElement => {
    const project = props.project;
    const json = project?.synchronimeterData;
    const data = useMemo(() => json ? JSON.parse(json) : emptyProjectSynchronimeterData, [json]) as ProjectSynchronimeterData;

    if (project === null) {
        return <div className="synchronimeter-values"></div>
    } else {
        return <div className="synchronimeter-values">
            <h3>Projektstatus ({props.showIs ? "Ist" : "Soll"})</h3>
            {data.legend.map(l => {
                const changeValue = (value: number) => props.handler({ kind: 'SetSynchronimeterValue', projectId: project.id, phase: props.showIs ? Math.max(project.phase, 1) : 0, itemId: l.id, value });

                return <div key={l.id} className="values-item">
                    <input className="color-in" type={"color"} value={l.color} onChange={() => { }} />
                    <div>{l.name}</div>
                    <input type={"number"} value={(data.values[props.showIs ? Math.max(project.phase, 1) : 0] || {})[l.id]} step={1} min={0} max={100}
                        onChange={(e) => changeValue(+e.target.value)} />
                </div>
            })}
        </div>
    }
}

