import { ChangeEvent, ReactElement, useState } from "react";
import { sendOlert } from "../../../olert/Olert";

type UploadType = "IMAGE" | "FILE";

export interface UploadProps {
    onUpload:(base64: string, filename?: string) => void;
    type: UploadType;
    maxWidth?: number;
    maxHeight?: number;
    maxFileSize?: number;
}

export const Base64Upload = (props: UploadProps): ReactElement => {

    const [data, setData] = useState<string>("");
    const [filename, setFilename] = useState<string|undefined>(undefined);

    const setFile = (ev: ChangeEvent<HTMLInputElement>) => {
        if (ev.target.files) {
            const reader = new FileReader();
            const file: File = ev.target.files[0];
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (reader.result) {
                    const data = reader.result.toString();
                    if (props.type === "IMAGE") {
                        let image = new Image()
                        image.src = data;
                        if (props.maxWidth && props.maxHeight && image.width > props.maxWidth && image.height > props.maxHeight) {
                            sendOlert("Fehler", "Bilddatei ist zu groß", "Warning", 2000);
                        } else {
                            setData(reader.result ? reader.result.toString() : "")
                        } 
                    } else {
                        if (props.maxFileSize && file.size > props.maxFileSize ) {
                            sendOlert("Fehler", "Datei ist zu groß", "Warning", 2000);
                        } else {
                            setData(reader.result ? reader.result.toString() : "")
                            
                            setFilename(file.name );
                        }
                    }
                }
            };
        }
    }

    const toUpload = () => {
        if (data.length > 0) {
            props.onUpload(data, filename);
        } else {
            sendOlert("Fehler", "Es wurde keine Bilddatei ausgewählt", "Warning", 2000);
        }
    }

    return <div className="file-upload">
        <label>Datei:</label>
        <input type="file" accept={props.type === "IMAGE"? "image/png": ""} onChange={(ev) => setFile(ev)} />
        <label>{
            props.type === "IMAGE"? ((props.maxWidth && props.maxHeight)? ("Maximale Bildgröße " + props.maxWidth +"x" + props.maxHeight) : "" ): 
            (props.maxFileSize? "Maximale Dateigröße " + Math.floor(props.maxFileSize/1024/1024) + "MB" : "")
            }
        </label>
        <button onClick={() => toUpload()}>Hochladen</button>
    </div>
}