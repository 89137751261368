import "./Start.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import circalindLeadershipImage from "../resources/circa/leadership_teamwork.svg";
import { getCurrentUserId, getUserRoles } from "../utils/Auth";
import { useEffect, useState } from "react";
import { GroupUser } from "../circalind/dataTypes/generated";
import { CircalindApi } from "../circalind/circalindApi";

export const LeadingStart = () => {
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState<GroupUser | null>(null);

    const currentUserId = getCurrentUserId("token-circalind");

    useEffect(() => {
        const loadUser = async () => {
            if (currentUserId) {
                setCurrentUser(await CircalindApi.getGroupUserWithoutImage(currentUserId));
            }
        };

        loadUser();
    }, [currentUserId]);

    const userRoles = getUserRoles("token-circalind");
    const isCoordinator = userRoles.includes("COORDINATOR") || userRoles.includes("CO_COORDINATOR");

    return <div className="full-page">
        <h1><strong>Willkommen {currentUser && currentUser.firstName} {currentUser && currentUser.lastName}</strong></h1>
        <h2><strong>{isCoordinator ? "Führung und Zusammenarbeit" : "Projektassistenz"}</strong></h2>
        <img src={circalindLeadershipImage} alt=""></img>
        <div className={"navigation"}>
            <button disabled><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button onClick={() => isCoordinator ? navigate("../staffing/overview") : navigate("../content")}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>;
}