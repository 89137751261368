import { ReactElement, useEffect, useState } from "react";

import './global-dialog-container.scss';

let tentativeDialog: ReactElement | null = null;

export let setDialog: (dialog: ReactElement | null) => void = (dialog: ReactElement | null) => {
    tentativeDialog = dialog;
}

export const GlobalDialogContainer = (): ReactElement => {
    const [dialog, innerSetDialog] = useState<ReactElement | null>(null);
    useEffect(() => {
        setDialog = innerSetDialog;

        if (tentativeDialog !== null) {
            innerSetDialog(tentativeDialog);
            tentativeDialog = null;
        }

        return () => {
            setDialog = (dialog: ReactElement | null) => {
                tentativeDialog = dialog;
            };
        }
    }, []);

    return <div className={"global-dialog-container " + (dialog === null ? "hide" : "")}>
        <div className={"dialog-container"}>
            {dialog}
        </div>
        <div className="dialog-overlay">
        </div>
    </div >;
}
