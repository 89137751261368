import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { LandingPageHeaders, useHeaderContext } from "../page/header/Header";


import strategyImage_back from "../../resources/circa/strategy_project.svg"
import personalImage_back from "../../resources/circa/personal_project.svg"
import leadershipImage_back from "../../resources/circa/leadership_teamwork.svg"

import strategyImage_front from "../../resources/circa/landing_strategy.svg"
import personalImage_front from "../../resources/circa/landing_personal.svg"
import leadershipImage_front from "../../resources/circa/landing_leadership.svg"

import './landingPage.scss'
import { FormType, InfoRequest, InterestType, LicenseMetaData, TitleType } from "../dataTypes/generated";
import { CircalindApi } from "../circalindApi";
import { sendOlert } from "../../olert/Olert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export const LandingPage = (): ReactElement => {

    const { updateHeader } = useHeaderContext();

    useEffect(() => {
        updateHeader(LandingPageHeaders)
    }, [updateHeader])

    return <div className="pre-login">
        <div className="navigate">
            <div className="pages">
                <WelcomePage1 />
                <WelcomePage2 />
                <WelcomePage3 />
            </div>
        </div>
    </div>
}

const WelcomePage1 = (): ReactElement => {

    const [isFlipped, setFlipped] = useState<boolean[]>([false, false, false])
    const [headerText, setHeader] = useState(<><span>Für Entscheider &amp; Gestalter in bewegter Zeit:</span><br /><span>circalind, um Vorhaben &amp; Projekte voranzubringen!</span></>);

    const flip = (index: number) => {
        let newFlipped = [...isFlipped];
        newFlipped[index] = !isFlipped[index];
        setFlipped(newFlipped);
    }

    useEffect(() => {
        if (isFlipped.includes(true)) {
            setHeader(<span>Projekt-Charakter passend zum Vorhaben auswählen &amp; Vorgehen kombinieren</span>)
        } else {
            setHeader(<><span>Für Entscheider &amp; Gestalter in bewegter Zeit:</span><br /><span>circalind, um Vorhaben &amp; Projekte voranzubringen!</span></>)
        }
    }, [isFlipped])

    return <div className={"page pos-1"} id="landing-page1">
        <div className="header">
            <h2>{headerText}</h2>
        </div>
        <div className="items">
            <div className="scene">
                <div className={"project-card" + (isFlipped[0] ? " is-flipped" : "")} onClick={() => flip(0)}>
                    <div className="card front">
                        <h3>Persönliche Projekte</h3>
                        <img className="left lower" src={personalImage_front} alt="Persönliche Projekte" />
                        <div className="lower-arrow"><FontAwesomeIcon icon={faArrowRight} /></div>
                    </div>
                    <div className="card back" >
                        <div className="back-title">
                            <h3>Persönliche Projekte</h3>
                            <img src={personalImage_back} alt="Persönliche Projekte" />
                        </div>
                        <div className="back-content">
                            <span className="sub-header">Selbstführung</span>
                            <br />
                            <br />
                            <span>In instabilen Zeiten gute Entscheidungen treffen und sich pro-aktiv persönlich weiterentwickeln:</span>
                            <ul>
                                <li>Urteilskraft stärken</li>
                                <li>Gestaltungsfelder ausbauen</li>
                                <li>Kompetenzen aktualisieren</li>
                            </ul>
                            <br />
                            <span>Empfehlenswert im Kontext von:</span>
                            <ul>
                                <li>Persönliche Weiterentwicklung</li>
                                <li>Vorbereitung auf eine nächste Lebens- &amp; Arbeitsphase</li>
                            </ul>
                        </div>
                        <div className="back-arrow">
                            <div className="lower-arrow"><FontAwesomeIcon icon={faArrowRight} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="scene">
                <div className={"project-card" + (isFlipped[1] ? " is-flipped" : "")} onClick={() => flip(1)}>
                    <div className="card front">
                        <h3>Führung &amp; Zusammenarbeit</h3>
                        <img className="right with-bottom" src={leadershipImage_front} alt="Führung und Zusammenarbeit" />
                        <div className="lower-arrow"><FontAwesomeIcon icon={faArrowRight} /></div>
                    </div>
                    <div className="card back" >
                        <div className="back-title">
                            <h3>Führung &amp; Zusammenarbeit</h3>
                            <img className="with-bottom right" src={leadershipImage_back} alt="Führung und Zusammenarbeit" />
                        </div>
                        <div className="back-content">
                            <span className="sub-header">Unternehmerische Governance-Verantwortung</span>
                            <br />
                            <br />
                            <ul>
                                <li>Ordnungsgemäße Unternehmensführung</li>
                                <li>Organisatorische Integration von Regulatorik/ ESG/CSRD</li>
                                <li>Wandlungs-/ Transformations-Willigkeit und Fähigkeit</li>
                            </ul>
                            <span className="sub-header">PlusPunkt in der Unternehmensbewertung</span>
                            <br />
                            <br />
                            <span>Empfehlenswert im Kontext von: </span>
                            <ul>
                                <li>Betreuung von Führungskräften/ Experten</li>
                                <li>Programm-Steuerung</li>
                                <li>Risiko-Frühwarn-Mechanismen</li>
                                <li>Modernes Onboarding</li>
                                <li>RealLabor/ Einsatz neuer Technologien/ KI</li>
                                <li>Unternehmens-Nachfolge</li>
                                <li>Wissenssicherung</li>
                                <li>Flexible modulare Organisation</li>
                                <li>Kollaboration/ Allianz-Projekte</li>
                                <li>Haftungsrisiken mindern</li>
                            </ul>
                        </div>
                        <div className="back-arrow">
                            <div className="lower-arrow"><FontAwesomeIcon icon={faArrowRight} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="scene">
                <div className={"project-card" + (isFlipped[2] ? " is-flipped" : "")} onClick={() => flip(2)}>
                    <div className="card front">
                        <h3>Strategie Projekte</h3>
                        <img className="with-bottom left" src={strategyImage_front} alt="Strategie Projekte" />
                        <div className="lower-arrow"><FontAwesomeIcon icon={faArrowRight} /></div>
                    </div>
                    <div className="card back" >
                        <div className="back-title">
                            <h3>Strategie Projekte</h3>
                            <img className="with-bottom" src={strategyImage_back} alt="Strategie Projekte" />
                        </div>
                        <div className="back-content">
                            <span className="sub-header">Werkzeug, RealLabor & Katalysator</span>
                            <br />
                            <br />
                            <span>Wettbewerbsfähigkeit stärken</span>
                            <ul>
                                <li>Reputation wahren und ausbauen</li>
                                <li>Transformations- & Kompetenz-Management</li>
                                <li>Digitalisierung/ KI nutzen</li>
                                <li>Steuerung von Transformations-Plänen</li>
                            </ul>
                            <span>Empfehlenswert im Kontext:</span>
                            <ul>
                                <li>Unternehmens-/ Organisationsentwicklung</li>
                                <li>Aufbau neuer Geschäftsmodelle</li>
                                <li>Vielfalt managen - Überblick behalten</li>
                                <li>Ressourcen effizient und flexibel einsetzen</li>
                                <li>Kompetenzen aktualisieren</li>
                                <li>Geschäftsprozesse effizienter umstellen</li>
                                <li>Innovative Herangehensweisen</li>
                                <li>Haftungs-Risiken mindern</li>
                            </ul>
                        </div>
                        <div className="back-arrow">
                            <div className="lower-arrow"><FontAwesomeIcon icon={faArrowRight} /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const WelcomePage2 = (): ReactElement => {

    const [collapsed, setCollapsed] = useState<boolean[]>([true, true, true])
    const [impulses,] = useState<boolean>(false);

    const expand = (index: number) => {
        let newColl = [...collapsed];
        newColl[index] = !newColl[index];
        setCollapsed(newColl);
    }

    const renderImpulses = () => {
        //schould only render if there are impulses from backend available
        if (impulses) {
            return <div className="impulses">
                <div className="impuls-text">
                    <span>Impulse für soziales gesellschaftliches Engagement:</span>
                </div>
                <div className="impuls">
                    <span>Engagement:</span>
                    <svg height="50" width="50">
                        <circle cx="25" cy="25" r="20" stroke="green" strokeWidth="3" fill="white" />
                    </svg>
                </div>
                <div className="impuls">
                    <span>Engagement:</span>
                    <svg height="50" width="50">
                        <circle cx="25" cy="25" r="20" stroke="green" strokeWidth="3" fill="white" />
                    </svg>
                </div>
                <div className="impuls">
                    <span>Engagement:</span>
                    <svg height="50" width="50">
                        <circle cx="25" cy="25" r="20" stroke="green" strokeWidth="3" fill="white" />
                    </svg>
                </div>
                <div className="impuls">
                    <span>Engagement:</span>
                    <svg height="50" width="50">
                        <circle cx="25" cy="25" r="20" stroke="green" strokeWidth="3" fill="white" />
                    </svg>
                </div>
            </div>
        }
    }

    return <div className={"page pos-2"} id="landing-page2">
        <div className="header">
            <h3>circalind Managementsystem „Transformation gestalten, Risiken Mindern, Geschäftsprozesse effizienter umstellen“</h3>
        </div>
        <div className="sub-header">
            <span>circalind entspricht einem Integrativen Transformations- & Kompetenz-Managementsystem (ITKM)</span>
            <span>für nachhaltige Unternehmensführung & Stärkung von Wettbewerbsfähigkeit</span>
            <span>In der Unternehmensbewertung: ESG/ CSRD mit Schwerpunkt auf Governance-Indikatoren</span>
        </div>
        <div className="items">
            <div className="philosophy">
                <div className="header light" onClick={() => expand(0)}>
                    <span>Klima- &amp; Energie-Ziele <br />Umweltschutz</span>
                </div>
                <div className={"info light" + (collapsed[0] ? " collapsed" : " expanded")}>
                    <div>
                        Umweltbeitrag zur betrieblichen
                        energieeffizienten Mobilität, i.S.
                        ressourcenschonender Arbeitsplatzgestaltung
                        durch hybride Arbeitswelt insbesondere in:
                        <ul>
                            <li>Unternehmensleitung</li>
                            <li>Management</li>
                            <li>Führung</li>
                            <li>Projekt</li>
                        </ul>
                        circalind unterstützt Vor- &amp; Nachbereitung von:
                        <ul>
                            <li>Gesprächen</li>
                            <li>Meetings</li>
                            <li>Besprechungen</li>
                        </ul>
                        so dass Geschäftsreisen reduziert werden als
                        Beitrag zur Senkung von CO2 Ausschuss
                    </div>
                </div>
            </div>
            <div className="philosophy">
                <div className="header green" onClick={() => expand(1)}>
                    <span>Soziale Verantwortung <br /> Mit-Verantwortung &amp; Mit-Wirkung</span>
                </div>
                <div className={"info green" + (collapsed[1] ? " collapsed" : " expanded")}>
                    <span>
                        circalind unterstützt Kommunikation &amp; Interaktion
                        <br />
                        <br />
                        Betreuung von Führungskräften/ Experten Einarbeitung, Onboarding 
                        <br />
                        <br />
                        circalind unterstützt fachübergreifende, multi-fachliche, unternehmens-/ organisationsübergreifende Zusammenarbeit/ Kollaboration. 
                        <br />
                        <br />
                        circalind stärkt nutzerbezogene interaktive „Führung &amp; Zusammenarbeit“ in Unternehmen und Organisationen.
                        <br />
                        <br />
                        circalind unterstützt konstruktives und lösungsorientiertes Zusammenwirken zwischen den Akteuren.
                    </span>
                </div>
            </div>
            <div className="philosophy">
                <div className="header blue" onClick={() => expand(2)}>
                    <span>Governance nachhaltige Unternehmensführung</span>
                </div>
                <div className={"info blue" + (collapsed[2] ? " collapsed" : " expanded")}>
                    <span>
                        circalind ist ein WIN/WIN für Unternehmen und Entscheider/-innen
                        <br />
                        <br />
                        <ul>
                            <li>Ordnungsgemäße Unternehmensführung</li>
                            <li>Organisatorische Integration von Regulatorik/ ESG/CSRD</li>
                            <li>Wandlungs-/ Transformations-Willigkeit und Fähigkeit</li>
                        </ul>
                        circalind Managementsystem:
                        <ul>
                            <li>Unternehmensführung & -steuerung</li>
                            <li>Reputation wahren und ausbauen</li>
                            <li>Innovativer Technologien/ KI Einsatz</li>
                            <li>Führen von Führungskräften</li>
                            <li>Führen von Organisation</li>
                            <li>Schwarm-Intelligenz & Risiko-Frühwarn-Mechanismen</li>
                            <li>Transformations- & Haftungs-Risiken mindern</li>
                        </ul>
                    </span>
                </div>
            </div>
        </div>
        {renderImpulses()}
    </div>
}

const WelcomePage3 = (): ReactElement => {

    const [data, setData] = useState<InfoRequest>(
        { id: -1, firstName: "", form: "FRAU", interest: "PRIVATE", kind: "InfoRequest", mail: "", message: "", name: "", newsleter: false, title: "NONE", durationInMonths: 12, deciders: 1, license: "UK1" }
    );
    const [acceptedDataPrivacy, setDataPrivacy] = useState<boolean>(false);
    const [missingDataPrivacy, setMissingPrivacy] = useState<boolean>(false);

    const [, setLicenseMetaData] = useState<LicenseMetaData | null>(null);

    useEffect(() => {
        const loadMetaData = async () => {
            setLicenseMetaData(await CircalindApi.getLicenseMetaData());
        };

        loadMetaData();
    }, []);


    const sendRequest = async () => {
        if (acceptedDataPrivacy === false) {
            setMissingPrivacy(true);
        } else {
            if (data) {
                for (let key of Object.keys(data)) {
                    const keyTyped = key as keyof typeof data;
                    const isInvalid = data[keyTyped] === undefined || data[keyTyped] === null;

                    if (isInvalid) {
                        if (!(keyTyped === "id" || keyTyped === "message")) {
                            sendOlert("Unvollständig", "Bitte füllen Sie das Formular vollständig aus.", "Error");
                            return;
                        }
                    }
                }

                let res = await CircalindApi.addNewInfoRequest(data);
                if (res) {
                    sendOlert("Anfrage gesendet", "Danke für ihr Interesse", "Success");
                } else {
                    sendOlert("Fehler", "Ihre Anfrage konnte leider nicht gesendet werden", "Error");
                }
            }
        }
    }

    const onAcceptData = (e: ChangeEvent<HTMLInputElement>) => {
        setDataPrivacy(e.target.checked)
    }

    return <div className={"page pos-3"} id="landing-page3">
        <div className="header">
            <span> circalind Managementsystem „Transformation gestalten, Risiken Mindern, Geschäftsprozesse effizienter umstellen“  </span>
        </div>
        <div className="sub-header">
            <span>ein WIN/ WIN für Unternehmen und Entscheider/-innen in instabiler Zeit</span>
            <span>Lebens-, Arbeits-, Wirtschaftswelten sind im Wandel</span>
            <br />
            <br />
            <span>circalind entspricht einem Integrativen Transformations- & Kompetenz-Managementsystem (ITKM)</span>
            <span>für nachhaltige Unternehmensführung & Stärkung von Wettbewerbsfähigkeit </span>
            <span>In der Unternehmensbewertung: ESG/ CSRD mit Schwerpunkt auf Governance-Indikatoren</span>
        </div>
        <div className="cards">
            <div className="card">
                <h3>Selbstführung</h3>
                <img src={personalImage_back} alt="Persönliche Projekte" />
                <span>Reflexives Management</span>
            </div>
            <div className="card">
                <h3>Führung &amp; Zusammenarbeit</h3>
                <img src={leadershipImage_back} alt="Führung & Zusammenarbeit" />
                <span>Unternehmerische Governance Verantwortung</span>
            </div>
            <div className="card">
                <h3>Strategie Projekte</h3>
                <img src={strategyImage_back} alt="Strategie Projekte" />
                <span>Werkzeug, RealLabor & Katalysator</span>
            </div>
        </div>
        <div className="items">
            <div className="inputs">
                <label htmlFor="form">Anrede</label>
                <select name="form" id="form" defaultValue={""} onChange={(e) => setData({ ...data, form: e.target.value as FormType })}>
                    <option value="" disabled hidden>Auswählen</option>
                    <option value="FRAU">Frau</option>
                    <option value="HERR">Herr</option>
                    <option value="DIVERS">Divers</option>
                </select>
            </div>
            <div className="inputs">
                <label htmlFor="firstName">Vorname</label>
                <input id="firstName" value={data.firstName} onChange={(e) => setData({ ...data, firstName: e.target.value })}></input>
            </div>
            <div className="inputs">
                <label htmlFor="lastName">Nachname</label>
                <input id="lastName" value={data.name} onChange={(e) => setData({ ...data, name: e.target.value })}></input>
            </div>
            <div className="inputs">
                <label htmlFor="titel">Titel</label>
                <select name="titel" id="titel" defaultValue={""} onChange={(e) => setData({ ...data, title: e.target.value as TitleType })}>
                    <option value="" disabled hidden>Auswählen</option>
                    <option value="NONE">kein</option>
                    <option value="DR">Dr.</option>
                    <option value="PROF">Prof.</option>
                </select>
            </div>
            <div className="inputs">
                <label htmlFor="mail">E-Mail</label>
                <input id="mail" value={data.mail} onChange={(e) => setData({ ...data, mail: e.target.value })}></input>
            </div>
            <div className="inputs">
                <label htmlFor="interrest">Interesse</label>
                <select name="interrest" id="interrest" defaultValue={""} onChange={(e) => setData({ ...data, interest: e.target.value as InterestType })}>
                    <option value="" disabled hidden>Interesse</option>
                    <option value="PRIVATE">Privatkunde</option>
                    <option value="COMPANY">Unternehmenskunde</option>
                </select>
            </div>
            <div className="inputs">
                <label htmlFor="newsletter">Newsletter</label>
                <select name="newsletter" defaultValue={""} id="newsletter">
                    <option value="" disabled hidden>Auswählen</option>
                    <option value="true">Ja</option>
                    <option value="false">Nein</option>
                </select>
            </div>
            <div className="inputs">
                <label htmlFor="message">Nachricht</label>
                <input id="message" placeholder="Optional" value={data.message} onChange={(e) => setData({ ...data, message: e.target.value })}></input>
            </div>
            <div className="inputs">
                <label></label>
                <button className="send-button" onClick={() => sendRequest()}>Absenden</button>
                <div className="dataprivacy">
                    <input id="agreePrivacy" type={"checkbox"} checked={acceptedDataPrivacy} onChange={(e) => onAcceptData(e)} />
                    <label htmlFor="agreePrivacy" className={missingDataPrivacy ? "alert" : ""}>Hiermit erkenne ich die  <a href="/circalind/dataprivacy">Datenschutzerklärung</a> an.</label>
                </div>
            </div>
        </div>
    </div>
}
