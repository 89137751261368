import { useCallback, useEffect, useState } from "react";
import { getCurrentUserId, getUserRoles } from "../../utils/Auth";
import { CircalindApi } from "../circalindApi";
import { AreaAndRoleInfo, CircaPackage, GroupUser, LicenseMetaData, RoleType } from "../dataTypes/generated";
import { Base64Upload } from "../basicComponents/base64Upload/Base64Upload";
import Logo from '../../resources/Logo-single.svg';
import circalindLeadershipImage from "../../resources/circa/leadership_teamwork.svg";

import "./Id.scss";
import { RoleDisplay } from "../../leading/role";
import { useNavigate } from "react-router";

export const IdView = () => {
    const [currentUser, setCurrentUser] = useState<GroupUser | null>(null);
    const userId = getCurrentUserId("token-circalind");
    const roles = getUserRoles("token-circalind");
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo | null>(null);

    const navigate = useNavigate();

    const loadUser = async () => {
        if (userId) {
            const res = await CircalindApi.getGroupUserWithImage(userId);
            setCurrentUser(res);
        }
    }

    useEffect(() => {
        const internalLoadUser = async () => {
            if (userId) {
                const res = await CircalindApi.getGroupUserWithImage(userId);
                setCurrentUser(res);
            }
        }

        const loadAreaAndRoleInfo = async () => {
            setAreaAndRoleInfo(await CircalindApi.getAreaAndRoleInfo());
        };

        loadAreaAndRoleInfo();
        internalLoadUser();
    }, [userId]);

    const saveImage = async (img: string) => {
        if (userId) {
            const res = await CircalindApi.updateGroupUser({ ...currentUser!, image: img });

            if (res) {
                loadUser();
            }
        }
    };

    const saveColor = async (color: string) => {
        if (userId) {
            const res = await CircalindApi.updateGroupUser({ ...currentUser!, color: color });

            if (res) {
                loadUser();
            }
        }
    };


    const isDecider = roles && roles.includes("DECIDER");
    const noneDeciderRoles = roles.filter(r => r !== "DECIDER" && r !== "ADMIN" && r !== "CIRCALINDMANAGER");

    const renderDeciderArea = () => {
        return <div className="area">
            <div className="roles">
                <RoleDisplay role={"DECIDER"} areaAndRoleInfo={areaAndRoleInfo ? areaAndRoleInfo : undefined} />
            </div>
            <div onClick={() => navigate("/circalind/regular/start")} className="card with-image">
                <img className={!isDecider ? "inactive" : ""} src={Logo} alt="Entscheiderbereich" />
                <div className="text-center">Entscheiderbereich</div>
            </div>
        </div>
    };

    const renderLeadingArea = () => {
        return <div className="area">
            <div className="roles">
                {noneDeciderRoles.map(r => <RoleDisplay key={r} role={r} areaAndRoleInfo={areaAndRoleInfo ? areaAndRoleInfo : undefined} />)}
            </div>
            <div onClick={() => navigate("/circalind/leading/start")} className="card with-image">
                <img src={circalindLeadershipImage} alt="Führung und Zusammenarbeit" />
                <div className="text-center">Führung &amp; Zusammenarbeit</div>
            </div>
        </div>
    };


    return <div className="id-container">
        <div className="two-column">
            <CompactId expandedStart={true} />
            <div>
                {currentUser?.image ? <img className="profile-image" src={currentUser?.image} alt="Profilbild" /> : undefined}
                {currentUser?.image ? undefined : <h3>Hier können Sie ein Profilbild hochladen.</h3>}
                <Base64Upload type="IMAGE" onUpload={saveImage}></Base64Upload>
                <div>
                    <label>Hier können Sie Ihre Hintergrundfarbe wählen:</label>
                    <input type="color" value={currentUser?.color} onChange={(e) => saveColor(e.target.value)} />
                </div>
                <span className="navigate" onClick={() => navigate("../profile")}>Profildaten pflegen</span>
            </div>
        </div>

        <div className="choose-area">
            <div className="area">
                <strong>
                    Zugang zu Rollen
                </strong>
                <strong>
                    Zugang zu Räumen
                </strong>
            </div>
            {isDecider && renderDeciderArea()}
            <hr />
            {noneDeciderRoles.length > 0 && renderLeadingArea()}
        </div>
    </div>;
};

export const CompactId = ({ expandedStart, targetUserId, projectId, activeRole, selectRole, allowedRoles }: { expandedStart: boolean, targetUserId?: number, projectId?: number, activeRole?: RoleType, selectRole?: (r: RoleType) => void, allowedRoles?: Array<RoleType> }) => {
    const [currentUser, setCurrentUser] = useState<GroupUser | null>(null);
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo | null>(null);
    const [license, setLicense] = useState<CircaPackage | null>(null);
    const [licenseMetaData, setLicenseMetaData] = useState<LicenseMetaData | null>(null);
    const userId = getCurrentUserId("token-circalind");
    const roles = getUserRoles("token-circalind");
    const [expanded, setExpanded] = useState<boolean>(expandedStart);

    useEffect(() => {
        setExpanded(expandedStart);
    }, [expandedStart]);

    const getUser = useCallback(async () => {
        if (userId) {
            const res = await CircalindApi.getGroupUserWithImage(userId);
            setCurrentUser(res);
        }
    }, [userId]);

    useEffect(() => {
        getUser();
        CircalindApi.getAreaAndRoleInfo().then(ar => setAreaAndRoleInfo(ar));
    }, [getUser]);

    useEffect(() => {
        const loadLicenseMetaData = async () => {
            setLicenseMetaData(await CircalindApi.getLicenseMetaData());
        }

        loadLicenseMetaData();
    }, []);

    useEffect(() => {
        const loadLicense = async () => {
            setLicense(await CircalindApi.getLicense());
        }

        loadLicense();
    }, [targetUserId, projectId]);

    const renderRoles = () => {
        const filteredRoles = roles.filter(r => allowedRoles === undefined ? true : allowedRoles.includes(r));

        if (filteredRoles && areaAndRoleInfo) {
            return <span className="roles">
                {filteredRoles.map((r, i) => [i > 0 && <br key={'br' + r} />, <span key={'span' + r} className={activeRole === r ? 'role active' : 'role'} onClick={(e) => { if (selectRole) { e.preventDefault(); e.stopPropagation(); selectRole(r) } }}><RoleDisplay key={r} role={r} areaAndRoleInfo={areaAndRoleInfo} /></span>])}
            </span>;
        }
    };

    return <div onClick={() => setExpanded(!expanded)} className="compact-id card">
        <h3>Willkommen {currentUser?.firstName} {currentUser?.lastName}!</h3>
        {expanded && <>
            <h4>Gewähltes Paket: {license && licenseMetaData && licenseMetaData.licenseTitles[license.type]}</h4>
            <h4>Sie haben Zugang zu folgenden Rollen und Räume:</h4>
            {renderRoles()}
        </>
        }
    </div>
};
