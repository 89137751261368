import { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";

import governanceImage from "../../../resources/circa/governance.svg";
import practicalImage from "../../../resources/circa/user/left-menu-1.svg";
import cockpitImage from "../../../resources/circa/user/left-menu-2.svg";
import phasesImage from "../../../resources/circa/projects_welcome.svg";
import myWayImage from "../../../resources/circa/personal_nav.svg";
import competenceImage from "../../../resources/circa/competence.svg";
import communicationImage from "../../../resources/circa/kommunication.svg";
import staffImage from "../../../resources/circa/staff.svg";
import simulationImage from "../../../resources/circa/simulation.svg";
import planningImage from "../../../resources/circa/planning.svg";
import constructionImage from "../../../resources/circa/construction.svg";
import toolboxImage from "../../../resources/circa/toolbox.svg";
import informationImage from "../../../resources/circa/information.png";
import deskImage from "../../../resources/circa/desk.png";
import interactImage from "../../../resources/circa/interact.png";
import qualityManagementImage from "../../../resources/circa/quality.png";
import roomImage from "../../../resources/circa/room_change.jpeg";
import saveImage from "../../../resources/circa/save.png";
import logo from "../../../resources/Logo-single.svg";

import React from "react";
import { defaultHeaders, leadingHeaders, serviceHeaders, useHeaderContext } from "../header/Header";
import { Dialog } from "../../basicComponents/dialogs/Dialog";
import { Area, AreaAndRoleInfo, CircaPackage, Group, Project, RoleType } from "../../dataTypes/generated";
import { CircalindApi } from "../../circalindApi";
import { sendOlert } from "../../../olert/Olert";
import { getCurrentUserId, getUserRoles } from "../../../utils/Auth";
import { useSelectedProject } from "../../../utils/Hooks";
import { SiteType, useSiteType } from "../../hooks/useSiteType";
import { useLocation, useNavigate } from "react-router";
import { InfoSubArea, subAreaInfoPathMatchers } from "../../info/InfoData";
import { InfoCard } from "../../info/InfoCard";
import { blobToBase64Url, refToHtml } from "../../../invoice/utils/Util";
import { renderPhaseStateSVG } from "../../projects/Projects";

import "./Menu.scss";

type MenuEntry = LinkingEntry | SubMenuEntry | ActionEntry;

type Entry = {
    image: string;
    height: number;
    altText: string;
    disabled: boolean;
}

type ActionEntry = Entry & {
    action: () => void;
};

type LinkingEntry = Entry & {
    path: string;
};

type SubMenuEntry = Entry & {
    subEntries: Array<LinkingEntry>;
};

function isLinkingEntry(entry: MenuEntry): entry is LinkingEntry {
    return (entry as LinkingEntry).path !== undefined;
}

function isActionEntry(entry: MenuEntry): entry is ActionEntry {
    return (entry as ActionEntry).action !== undefined;
}


const baseHeight = 60;

const regularSeparators = [1];

const leadingSeparators: Array<number> = [3];

const createRightMenuEntries = (siteType: SiteType, routingPrefix: string, infoCallback: () => void, exportCallback: () => void, activeProject: boolean): Array<MenuEntry> => {
    return [
        {
            image: saveImage,
            height: 1,
            altText: "Seite Speichern",
            action: exportCallback,
            disabled: siteType !== "LEADING" && !activeProject,
        },
        {
            image: deskImage,
            height: 1,
            altText: "Projektschreibtisch",
            path: `${routingPrefix}/desk`,
            disabled: false,
        },
        {
            image: interactImage,
            height: 1,
            altText: "Kommunizieren - interagieren",
            path: "/circalind/interaction",
            disabled: false,
        },
        {
            image: roomImage,
            height: 1,
            altText: "Raum wechseln",
            path: `/circalind/services/room`,
            disabled: false,
        },
        {
            image: informationImage,
            height: 1,
            altText: "Information",
            action: infoCallback,
            disabled: false,
        },
    ];
};

const createRegularMenuEntries = (project: Project | null, availableAreas: Array<Area>): Array<MenuEntry> => {
    return [
        {
            image: governanceImage,
            height: 1,
            altText: "Governance",
            path: "/circalind/regular/governance",
            disabled: !availableAreas.includes("governance") || (project !== null && (project.type === "PERSONAL")),
        },
        {
            image: practicalImage,
            height: 1,
            altText: "Praxis",
            path: "/circalind/regular/practical",
            disabled: !availableAreas.includes("practical"),
        },
        {
            image: cockpitImage,
            height: 1,
            altText: "Persönliches Cockpit",
            path: "/circalind/regular/dashboard",
            disabled: !availableAreas.includes("cockpit"),
        },
        {
            image: phasesImage,
            height: 0.8,
            altText: "Phasen",
            path: "/circalind/regular/phase",
            disabled: !availableAreas.includes("phase"),
        },
        {
            image: myWayImage,
            height: 1,
            altText: "Mein Weg",
            path: "/circalind/regular/documentation",
            disabled: !availableAreas.includes("documentation"),
        },
        {
            image: competenceImage,
            height: 1,
            altText: "Kompetenz",
            path: "/circalind/regular/competence",
            disabled: !availableAreas.includes("competence"),
        },
        {
            image: communicationImage,
            height: 1,
            altText: "Kommunikation",
            path: "/circalind/regular/communication",
            disabled: !availableAreas.includes("communication"),
        }
    ];

}

const createLeadingMenuEntries = (userRoles: RoleType[]): Array<MenuEntry> => {
    const myWayRoles: Array<RoleType> = ["CIRCALINDMANAGER", "CO_COORDINATOR", "COORDINATOR"];
    const qualityRoles: Array<RoleType> = ["CIRCALINDMANAGER", "CO_COORDINATOR", "COORDINATOR", "QM_COORDINATOR"];
    const toolboxRoles: Array<RoleType> = ["CIRCALINDMANAGER", "CO_COORDINATOR", "COORDINATOR", "IT_COORDINATOR"];
    const staticRoles: Array<RoleType> = ["CIRCALINDMANAGER", "CO_COORDINATOR", "COORDINATOR", "SUPPORT_PARTNER"];

    return [
        {
            image: staffImage,
            height: 1,
            altText: "Staffing",
            path: "/circalind/leading/staffing",
            disabled: !userRoles.some(role => staticRoles.includes(role)),
        },
        {
            image: toolboxImage,
            height: 1,
            altText: "IT-Management",
            path: "/circalind/leading/toolbox",
            disabled: !userRoles.some(role => toolboxRoles.includes(role)),
        },
        {
            image: qualityManagementImage,
            height: 1,
            altText: "Nachhaltigkeits-Management",
            path: "/circalind/leading/quality",
            disabled: !userRoles.some(role => qualityRoles.includes(role)),
        },
        {
            image: myWayImage,
            height: 1,
            altText: "Dokumentation",
            path: "/circalind/leading/documentation",
            disabled: !userRoles.some(role => myWayRoles.includes(role))
        },
        {
            image: planningImage,
            height: 0.8,
            altText: "Planung",
            path: "/circalind/leading/collaboration/planning",
            disabled: !userRoles.some(role => staticRoles.includes(role)),
        },
        {
            image: constructionImage,
            height: 0.8,
            altText: "Aufbau",
            path: "/circalind/leading/collaboration/construction",
            disabled: !userRoles.some(role => staticRoles.includes(role)),
        },
        {
            image: simulationImage,
            height: 0.8,
            altText: "Reallabor",
            path: "/circalind/leading/collaboration/simulation",
            disabled: !userRoles.some(role => staticRoles.includes(role)),
        },
    ];
};



type MenuProps = {};

export const Menu: FunctionComponent<React.PropsWithChildren<MenuProps>> = (props: React.PropsWithChildren<MenuProps>) => {
    const [projectId,] = useSelectedProject();
    const [selectedMenuItem, setSelectedMenuItem] = useState<number | null>(null);
    const [leftSide, setLeftSide] = useState<boolean | null>(null);
    const [menuEntries, setMenuEntries] = useState<Array<MenuEntry>>([]);
    const [separators, setSeparators] = useState<Array<number>>([]);
    const [rightSideOpen, setRightSideOpen] = useState<boolean>(false);
    const [leftSideOpen, setLeftSideOpen] = useState<boolean>(true);
    const [rightMenuEntries, setRightMenuEntries] = useState<Array<MenuEntry>>([]);
    const [routingPrefx, setRoutingPrefix] = useState<string>("");
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [showSaveToDeskDialog, setShowSaveToDeskDialog] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>("");
    const [group, setGroup] = useState<Group | null>(null);
    const [project, setProject] = useState<Project | null>(null);
    const [license, setLicense] = useState<CircaPackage | null>(null);
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo | null>(null);
    const siteType = useSiteType();
    const infoCard = useInfoCard(group);

    const myRef = useRef<HTMLDivElement>(null);

    const onSaveToDesk = async () => {
        if (fileName) {
            const blob = new Blob([await refToHtml(myRef)], { type: "text/html" });
            const b64 = await blobToBase64Url(blob, true);

            await CircalindApi.addDeskItem({
                id: -1,
                userId: getCurrentUserId("token-circalind") || -1,
                projectId: siteType === "LEADING" ? -1 : project!.id,
                groupId: siteType === "LEADING" ? group!.id : -1,
                phase: siteType === "LEADING" ? group!.managementPhase : Math.max(1, project!.phase),
                type: "SCREENSHOTS",
                name: fileName,
                filename: `${fileName}.html`,
                base64Data: b64,
                tags: [],
                date: null,
                source: window.location.href
            });

            setShowSaveToDeskDialog(false);
        }
    };


    const getGroupAndProject = useCallback(async () => {
        const group = await CircalindApi.getMyGroup();
        setGroup(group);

        if (projectId > -1) {
            const project = await CircalindApi.getProject(projectId);
            setProject(project);
        }
    }, [projectId]);

    const { updateHeader } = useHeaderContext();

    useEffect(() => {
        const loadData = async () => {
            setLicense(await CircalindApi.getLicense());
            setAreaAndRoleInfo(await CircalindApi.getAreaAndRoleInfo());
        }

        loadData();
    }, []);


    useEffect(() => {
        getGroupAndProject();
    }, [getGroupAndProject]);

    useEffect(() => {
        if (areaAndRoleInfo && license) {
            if (siteType === "LEADING") {
                setMenuEntries(createLeadingMenuEntries(getUserRoles("token-circalind")));
                setSeparators(leadingSeparators);
                setSelectedMenuItem(null);
                setLeftSide(null);
                updateHeader(leadingHeaders);
                setRoutingPrefix("/circalind/leading");
            } else if (siteType === "REGULAR") {
                setMenuEntries(createRegularMenuEntries(project, areaAndRoleInfo.availableAreas[license.type]!));
                setSeparators(regularSeparators);
                setSelectedMenuItem(null);
                setLeftSide(null);
                updateHeader(defaultHeaders);
                setRoutingPrefix("/circalind/regular");
            } else if (siteType === "SERVICE") {
                setMenuEntries([]);
                updateHeader(serviceHeaders);
                setRoutingPrefix("/circalind/services");
            } else if (siteType === "MANAGE") {
                setMenuEntries([]);
                updateHeader(serviceHeaders);
                setRoutingPrefix("/circalind/regular");
            } else {
                setMenuEntries([]);
                updateHeader(leadingHeaders);
                setRoutingPrefix("/circalind/leading");
            }
        }
    }, [siteType, project, updateHeader, areaAndRoleInfo, license]);

    useEffect(() => {
        setRightMenuEntries(createRightMenuEntries(siteType, routingPrefx, () => setShowInfo(true), () => setShowSaveToDeskDialog(true), projectId !== -1));
    }, [siteType, routingPrefx, projectId]);

    const renderSubHeader = () => {
        let elements: Array<{ label: string, path: string }> = [];
        if (window.location.pathname.startsWith("/circalind/regular/practical")) {
            elements = [
                { label: "1. Praxisraum", path: "/circalind/regular/practical/content/personal" },
                { label: "2. Praxisraum", path: "/circalind/regular/practical/content/general" },
                { label: "3. Praxisraum", path: "/circalind/regular/practical/drivers" }
            ];
        } else if (window.location.pathname.startsWith("/circalind/regular/checkups")) {
            elements = [
                { label: "Checkup", path: "/circalind/regular/checkups/content" },
                { label: "Perspektiv-Wechsel", path: "/circalind/regular/checkups/matching" },
            ];
        } else if (window.location.pathname.startsWith("/circalind/regular/dashboard")) {
            elements = [
                { label: "Persönliches Navigations-System", path: "/circalind/regular/dashboard/personal" },
                { label: "Steuerung aller Projekte", path: "/circalind/regular/dashboard/one" },
                { label: "Projekt-Basis", path: "/circalind/regular/dashboard/base" },
                { label: "Vertiefen", path: "/circalind/regular/dashboard/deep" },
                { label: "Projekt-Cluster", path: "/circalind/regular/dashboard/families" },
            ];
        } else if (window.location.pathname.startsWith("/circalind/regular/documentation")) {
            elements = [
                { label: "Best-Practise", path: "/circalind/regular/documentation/practises" },
                { label: "Präsentations-Werkstatt", path: "/circalind/regular/documentation/presentations" },
                { label: "Meine Sammlung", path: "/circalind/regular/documentation/collection" },
                { label: "Projekt-Historie", path: "/circalind/regular/documentation/history" },
            ];
        } else if (window.location.pathname.startsWith("/circalind/regular/communication")) {
            elements = [
                { label: "Info-Sammlung", path: "/circalind/regular/communication/project" },
                { label: "Vor- & Nachbereiten", path: "/circalind/regular/communication/prepost" },
            ];
        } else if (window.location.pathname.startsWith("/circalind/regular/competence")) {
            elements = [
                { label: "Persönliches Profil", path: "/circalind/regular/competence/individual" },
                { label: "Projekt-Kompetenz-Profil", path: "/circalind/regular/competence/team" },
            ];
        }

        if (elements.length > 0) {
            return <div className="sub-menu">
                <span className="sub-menu-svg">
                    {renderPhaseStateSVG(project?.phase || 0)}
                </span>
                <div className="sub-menu-entries">
                    {elements.map((e, index) => <a className={`${window.location.pathname.startsWith(e.path) ? "selected-item" : ""}`} key={index} href={e.path}>{e.label}</a>)}
                </div>
                <span>{new Date().toLocaleDateString()}</span>
            </div>
        }
    };

    const renderTopRow = () => {
        if (siteType === "LEADING") {
            return <div className="leading-header">
                {group && <LeadingModeHeader group={group} updateGroup={() => getGroupAndProject()} edit={false} />}
            </div>
        } else {
            return renderSubHeader();
        }
    };

    const disabled = (menuEntry: MenuEntry, index: number, isLeftSide: boolean) => {
        if (menuEntry.disabled || (leftSide !== isLeftSide && selectedMenuItem !== null) || (selectedMenuItem !== null && selectedMenuItem !== index)) {
            if (isLeftSide) {
                return 'disabled';
            } else {
                return 'inactive';
            }
        } else {
            return '';
        }
    };

    const onMenuClick = (index: number) =>
        () => {
            const entry = menuEntries[index];
            if (!entry.disabled) {
                if (isLinkingEntry(entry)) {
                    window.location.href = entry.path;
                } else if (isActionEntry(entry)) {
                    entry.action();
                } else {
                    setSelectedMenuItem(leftSide === true && selectedMenuItem === index ? null : index);
                    setLeftSide(true);
                }
            }
        };

    const onRightMenuClick = (index: number) =>
        () => {
            const entry = rightMenuEntries[index];
            if (isLinkingEntry(entry)) {
                window.location.href = entry.path;
            } else if (isActionEntry(entry)) {
                entry.action();
            } else {
                setSelectedMenuItem(leftSide === false && selectedMenuItem === index ? null : index);
                setLeftSide(false)
            }
        };

    const renderSideMenu = () => {
        if (menuEntries.length > 0) {
            return <div className={`side-menu-container`}>
                <div className={`side-menu ${leftSideOpen ? "" : "hide"}`}>
                    {menuEntries.map((menuEntry, index) => {
                        const getActiveClass = () => {
                            if (isLinkingEntry(menuEntry) && window.location.pathname.startsWith(menuEntry.path)) {
                                return "active";
                            } else {
                                return "inactive";
                            }
                        };

                        return <React.Fragment key={index}>
                            <img className={(disabled(menuEntry, index, true) + " " + getActiveClass()).trim()}
                                style={{ height: `${baseHeight * menuEntry.height}px` }}
                                src={menuEntry.image}
                                alt={menuEntry.altText}
                                onClick={onMenuClick(index)} />
                            {separators.indexOf(index) !== -1 ? <hr /> : undefined}
                        </React.Fragment>;
                    })}
                </div>
                <div onClick={() => setLeftSideOpen(!leftSideOpen)} className={`left-expand-area ${leftSideOpen ? "" : "hide"}`}>
                    {leftSideOpen ? "<" : ">"}
                </div>
            </div>
        }
    };

    const showProjectScreen = window.location.pathname.includes("regular") ? projectId === -1 : false;

    return <div className="menu-container">
        <div className="bottom-content">
            <div>
                {renderSideMenu()}
            </div>
            <div className="main-content">
                <div className={`top-row ${leftSide ? "left-side" : "right-side"}`}>
                    {renderTopRow()}
                </div>
                <div ref={myRef} >
                    {!showProjectScreen && props.children}
                    {showProjectScreen && <div><h2>Bitte wählen Sie ein Projekt aus.</h2></div>}
                </div>
            </div>
            <div className="change-area">
                <div className="change-area-border">
                    <div onClick={() => setRightSideOpen(!rightSideOpen)} className={`left-expand-area ${rightSideOpen ? "" : "hide"}`}>
                        {rightSideOpen ? ">" : "<"}
                    </div>
                    <div className={`right-expand-area ${rightSideOpen ? "" : "hide"}`}>
                        {rightMenuEntries.map((menuEntry, index) => {
                            return <div key={index} className="change" onClick={menuEntry.disabled ? () => { } : onRightMenuClick(index)}>
                                <div className="image-card">
                                    <img className={disabled(menuEntry, index, false)} src={menuEntry.image} alt={menuEntry.altText} />
                                </div>
                                <h4>{menuEntry.altText}</h4>
                            </div>
                        })}
                        <Dialog toogle={() => setShowInfo(false)} component={infoCard ?? <div className="no-data">Keine Daten</div>} className={""} show={showInfo}></Dialog>
                        <Dialog toogle={() => setShowSaveToDeskDialog(false)} component={<div className="form-input"><input placeholder={"Name"} onChange={(e) => setFileName(e.target.value)} value={fileName} /><button onClick={onSaveToDesk}>Speichern</button></div>} className={"file-save-dialog"} show={showSaveToDeskDialog}></Dialog>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

const useInfoCard = (group: Group | null) => {
    const loc = useLocation();
    const path = loc.pathname;

    let subAreaName: InfoSubArea | null = null;

    for (const subArea in subAreaInfoPathMatchers) {
        if (subAreaInfoPathMatchers[subArea as InfoSubArea](path)) {
            subAreaName = subArea as InfoSubArea;
            break;
        }
    }

    if (subAreaName === null || group === null) {
        return null;
    } else {
        return <InfoCard subAreaName={subAreaName} phase={group.managementPhase} />
    }
}

export const managementPhases = [["Im Einsatz", "Planung", "Aufbau", "RealLabor"]];
export const managementHeadings = [["Modus wählen"]];
export const managementImages = [[logo, planningImage, constructionImage, simulationImage]];

export const LeadingModeHeader = ({ group, updateGroup, edit }: { group: Group, updateGroup: () => void, edit: boolean }) => {
    const navigate = useNavigate();

    return <div className="management-phases">
        {managementPhases.map((sp, outer) => {
            return <div className="management-phase-group" key={outer}>
                {edit && <strong>{managementHeadings[outer]}</strong>}
                <div className="management-phase-group-elements">
                    {
                        sp.map((p, inner) => {
                            const phase = managementPhases.slice(0, outer).map(mp => mp.length).reduce((partialSum, a) => partialSum + a, 0) + inner;

                            const allowedRoles: Array<RoleType> = ["COORDINATOR", "CO_COORDINATOR", "QM_COORDINATOR", "SUPPORT_PARTNER"];

                            const updateManagementPhase = async () => {
                                if (edit) {
                                    if (!allowedRoles.some(r => getUserRoles("token-circalind").includes(r))) {
                                        sendOlert("Keine Berechtigung", "Nur der (QM)-Koordinator-Manager bzw. Support-Manager darf die Phase ändern.", "Warning");
                                    } else {
                                        const res = await CircalindApi.setManagementPhase(phase);
                                        if (res) {
                                            await updateGroup();
                                            switch (phase) {
                                                case 1:
                                                    navigate("/circalind/leading/collaboration/planning");
                                                    break;
                                                case 2:
                                                    navigate("/circalind/leading/collaboration/construction");
                                                    break;
                                                case 3:
                                                    navigate("/circalind/leading/collaboration/simulation");
                                                    break;
                                            }
                                        }
                                    }
                                }
                            }

                            const className = group && group.managementPhase === phase ? "selected-phase" : "";

                            const renderImage = () => {
                                const image = managementImages[outer][inner];
                                if (image) {
                                    return <img alt={p} src={image} />
                                }
                            }

                            return <span className={`management-phase ${className}`} onClick={updateManagementPhase} key={inner}>{p} {renderImage()}</span>;
                        })
                    }
                </div></div>
        })}
    </div>;
};