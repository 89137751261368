import { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import { sendOlert } from "../../olert/Olert";
import { getUserRoles } from "../../utils/Auth";
import { CircalindApi as Api } from "../circalindApi";
import { defaultHeaders, useHeaderContext } from "../page/header/Header";
import './login.scss'

export const Login = (): ReactElement => {

    const navigate = useNavigate();
    const [loginName, setLoginName] = useState("");
    const [password, setPassword] = useState("");
    const { updateHeader } = useHeaderContext();
    const { target } = useParams<{ target: string }>();

    useEffect(() => {
        updateHeader([])
    }, [updateHeader]);

    const sendLogin = async () => {
        const res = await Api.login(loginName.trim(), password.trim())
        if (res) {
            updateHeader(defaultHeaders);
            if (getUserRoles("token-circalind").includes("ADMIN")) {
                if (target) {
                    navigate(decodeURIComponent(target));
                } else {
                    navigate(`/circalind/admin/overview`);
                }
            } else {
                if (target) {
                    navigate(`/circalind/services/choose-license/${encodeURIComponent(target)}`);
                } else {
                    navigate(`/circalind/services/choose-license`);
                }
            }
        } else {
            sendOlert("Ungültig", "Überprüfen Sie ob Sie ihr Password richtig eingeben haben", "Warning", 5000);
        }
    }

    const onEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            sendLogin();
        }
    }

    return <div className="login">
        <h2>Anmeldung</h2>
        <div className="login-form">
            <input type="text" placeholder="Benutzername" onChange={(e) => setLoginName(e.target.value)} onKeyDown={onEnter} />
            <input type="password" onChange={(e) => setPassword(e.target.value)} onKeyDown={onEnter} />
            <label onClick={() => navigate("/circalind/reset")}>Password vergessen</label>
            <button onClick={() => sendLogin()}>Einloggen</button>
        </div>
    </div>
}
