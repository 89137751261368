import Select from 'react-select';
import { ReactElement, useEffect, useReducer, useState } from "react"
import { defaultConfig } from "./Config"
import { Synchronimeter } from "./Synchronimeter"
import { ProjectSelect } from "../../../../../leading/dialogs/ProjectSelect";

import * as ST from './State';
import { CircalindApi } from '../../../../circalindApi';
import { SynchronimeterLegend } from './SynchronimeterLegend';
import { SynchronimeterValues } from './SynchronimeterValues';
import { ProjectFamily } from '../../../../dataTypes/generated';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './SynchronimeterAndControls.scss'
import { useSelectedProject } from '../../../../../utils/Hooks';
import { useSearchParams } from 'react-router-dom';

export type Props = {
    onNext?: () => void;
    onPrev?: () => void;
}

export const SynchronimeterAndControls = (props: Props): ReactElement => {
    const [showIs, setShowIs] = useState(true);
    const [state, dispatch] = useReducer(ST.handleEvent, ST.emptyState);
    const [currentProject,] = useSelectedProject();
    const [searchParams, ] = useSearchParams();

    const parseFamilyNumber = (param: string | null): number | undefined => {
        if (param) {
            return +param;
        } else {
            return undefined
        }
    }

    useEffect(() => { loadData(dispatch, currentProject, parseFamilyNumber(searchParams.get("family"))) }, [currentProject, searchParams]);

    useEffect(() => {
        let canceled = false;

        if (ST.isDirty(state)) {
            setTimeout(() => {
                if (!canceled) {
                    save(state);
                    dispatch({ kind: 'MarkClean' });
                }
            }, 2000);
        }

        return () => { canceled = true; }
    }, [state]);


    return <div className="synchronimeter-and-controls">
        <div className="headline"><h1>Synchronstatus eingeben</h1></div>
        <div className="select-is">
            <label>Synchron-Status</label>
            <Select value={isShouldOptions.find(o => o.value === showIs)} options={isShouldOptions} onChange={(opt) => setShowIs(opt!.value)} />
        </div>
        <div className="project">
            <div>Cluster und Projekt auswählen</div>
            <Select isClearable={true} placeholder={"Projekt-Cluster wählen"} options={createProjectFamilyOptions(state.availableFamilies)} onChange={opt => dispatch({ kind: 'SelectFamily', family: opt?.value ?? null })} value={createProjectFamilyOptions(state.availableFamilies).find(o => o.value.id === state.selectedFamily?.id)} />
            <ProjectSelect projects={state.availableProjects} onSelect={(e) => dispatch({ kind: 'SelectProject', projectId: e?.id ?? null })} valueProjectId={state.selectedProject ?? undefined} />
        </div>
        <div className="edit-legend"><SynchronimeterLegend project={ST.getSelectedProject(state)} handler={(e) => {dispatch(e)}} /></div>
        <div className="synchro">
            <Synchronimeter
                showIs={showIs}
                config={defaultConfig}
                projects={state.availableProjects}
                handler={dispatch}
            />
        </div>
        <div className="values"><SynchronimeterValues project={ST.getSelectedProject(state)} handler={dispatch} showIs={showIs} /></div>
        {!props.onPrev ? null : <button key="prev" className="prev" onClick={props.onPrev}><FontAwesomeIcon icon={faArrowLeft} /></button>}
        {!props.onNext ? null : <button key="next" className="next" onClick={props.onNext}><FontAwesomeIcon icon={faArrowRight} /></button>}
    </div>
}

const createProjectFamilyOptions = (families: Array<ProjectFamily>) => {
    return families.map(family => {
        return {
            label: family.name,
            value: family
        };
    });
}

const isShouldOptions = [{ value: true, label: "Ist-Zustand" }, { value: false, label: "Soll-Zustand" }];

const loadData = async (dispatch: (e: ST.StateEvent) => void, currentProject: number, currentFamily: number | undefined) => {
    dispatch({
        kind: "SetAllProjects",
        projects: await CircalindApi.getProjects()
    });


    const families = await CircalindApi.getMyProjectFamilies();

    dispatch({
        kind: "SetAvailableFamilies",
        families: families
    });


    const family = currentFamily ? families.find(f => f.id === currentFamily) : families.find(f => f.projects.includes(currentProject));

    if (family) {
        dispatch({
            kind: "SelectFamily",
            family: family
        });
    }
}

const save = async (state: ST.State) => {
    for (const pid of state.dirtyProjects) {
        const project = state.availableProjects.find(p => p.id === pid);
        if (project) {
            await CircalindApi.addNewProject(project);
        }
    }
}
