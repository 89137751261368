import phase1Image from './circa/phasen/phase_1.png';
import phase2Image from './circa/phasen/phase_2.png';
import phase3Image from './circa/phasen/phase_3.png';
import phase4Image from './circa/phasen/phase_4.png';
import phase5Image from './circa/phasen/phase_5.png';
import blueFooterBackground from './circa/footer/blue-footer.jpg';
import yellowFooterBackground from './circa/footer/yellow-footer.jpg';
import dashboardFooterBackground from './circa/footer/dashboard-footer.png';
import communicationFooter from './circa/footer/communication-footer.jpg'
import rightCorner from './circa/right.svg';
import leftCorner from './circa/left.svg';

export const Treiber_text = "Treiber";
export const Klima_ESG_Ziele_text = "Klima-/ ESG-Ziele";
export const Monitoring_text = "Monitoring";
export const Entwicklungen_Verlauf_text = "Entwicklungen Verläufe";
export const Konflikte_Spannungsfelder_text = "Konflikte Spannungsfelder";
export const Organisation_Prozesse_text = "Organisation Prozesse ";
export const Struktureller_Wandel_text = "Struktureller Wandel";

export const Phase1 = phase1Image;
export const Phase2 = phase2Image;
export const Phase3 = phase3Image;
export const Phase4 = phase4Image;
export const Phase5 = phase5Image;

export const BlueFooter = blueFooterBackground;
export const YellowFooter = yellowFooterBackground;
export const DashboardFooter = dashboardFooterBackground;
export const CommunicationFooter = communicationFooter;
export const RightCorner = rightCorner;
export const LeftCorner = leftCorner;