import { Fragment, ReactElement, useEffect, useState } from "react";
import { CircaPackage, Project, TypeProject, User } from "../../dataTypes/generated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong, faRightLong } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { CircalindApi } from "../../circalindApi";
import { renderPhaseStateSVG } from "../Projects";
import { Dialog } from "../../basicComponents/dialogs/Dialog";
import { allowedStrategicLicenses, EditStaffingProject } from "../../../leading/dialogs/EditStaffingProject";
import { useSelectedProject } from "../../../utils/Hooks";

import SignImage from "../../../resources/circa/waypoint.svg";
import CompassImg from "../../../resources/circa/control/compas.svg";
import DevelopImg from "../../../resources/circa/control/development.svg";
import LaneChangeImg from "../../../resources/circa/control/lanechange.svg";
import OfferImg from "../../../resources/circa/control/offers.svg";
import StrategyImg from "../../../resources/circa/landing_strategy.svg";
import PersonalImg from "../../../resources/circa/personal_project.svg";
import WayImg from "../../../resources/circa/user/left-menu-5.svg";
import DoorImg from "../../../resources/circa/door.png";
import ImpulseImg from "../../../resources/circa/impulse.png";
import CheckupImage from "../../../resources/circa/risk_management.svg";
import EmptyProjectImage from "../../../resources/circa/cockpit/project-empty.png";



import './control.scss'

export const Control = (): ReactElement => {

    const [projectId,] = useSelectedProject();
    const [project, setProject] = useState<Project>()
    const [users, setUsers] = useState<User[]>([]);
    const [toogle, setToggle] = useState<{ show: boolean, type: TypeProject }>({ show: false, type: "PERSONAL" });
    const [license, setLicense] = useState<CircaPackage | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const getUsers = async () => {
            const us = await CircalindApi.getAllUsersOfGroupUserIsPart();
            if (us) {
                setUsers(us);
            }
        }
        const getProject = async (id: number) => {
            let res = await CircalindApi.getProject(id);
            if (res) {
                setProject(res)
            }
        }
        const getLicense = async () => {
            setLicense(await CircalindApi.getLicense());
        };

        if (projectId >= 0) {
            getProject(projectId);
        }
        getUsers();
        getLicense();
    }, [projectId])

    const lastPhase = (): number => {
        if (project) {
            return project.phase;
        }
        return 0;
    }

    const nextPhase = (): number => {
        if (project) {
            return Math.min(5, project.phase + 1);
        }
        return 5;
    }

    const goToNextPhase = async () => {
        if (project) {
            let updatedProject: Project = ({ ...project, phase: Math.min(5, project.phase + 1) });
            await CircalindApi.setProjectPhase(updatedProject);
            setProject({ ...updatedProject });
        }

        if (nextPhase() <= 5) {
            window.location.href = "/circalind/regular/phase/" + nextPhase();
        }
    }

    const goToPreviousPhase = async () => {
        if (lastPhase() >= 1) {
            navigate("/circalind/regular/phase/" + lastPhase());
        } else {
            navigate("/circalind/regular/dashboard/base/start");
        }
    }

    const toggleDialog = () => {
        setToggle({ show: false, type: "PERSONAL" })
    }

    const onUpdate = () => {
        window.location.reload();
    }

    return <Fragment>
        <div className="control">
            <div>
                <div className="main">
                    <div className="title">
                        {projectId === -1 ? <h1>Es wurde kein Projekt ausgewählt</h1> : <h2>Entscheiden, wie geht’s weiter?</h2>}
                    </div>
                    <div className="header">
                        <div className="left">
                            {project &&
                                <>
                                    <div>
                                        {renderPhaseStateSVG(lastPhase())}
                                        <button onClick={() => goToPreviousPhase()}><FontAwesomeIcon icon={faLeftLong} /></button>
                                    </div>
                                    <span>zur Phase zurück</span>
                                </>
                            }
                        </div>
                        <div className="center">
                            <img src={SignImage} alt={"error"} />
                        </div>
                        <div className="right">
                            {project && project.phase < 5 &&
                                <>
                                    <div>
                                        <button onClick={() => goToNextPhase()}><FontAwesomeIcon icon={faRightLong} /></button>
                                        {renderPhaseStateSVG(nextPhase())}
                                    </div>
                                    <span>in die nächste Phase</span>
                                </>
                            }
                        </div>
                    </div>
                    <div className="lower-menu">
                        <div className="rows">
                            <div className="row">
                                <span className="bold">nächste Schritte</span>
                                <div className="row-item">
                                </div>
                                <div className="row-item" onClick={() => navigate("/circalind/regular/dashboard/base/start")}>
                                    <div className="box">
                                        <img src={EmptyProjectImage} alt="" />
                                    </div>
                                    <div className="text">
                                        <span>Projekt-Basis aktualisieren</span>
                                    </div>
                                </div>
                                <div className="row-item" onClick={() => navigate("/circalind/managing/transfer")}>
                                    <div className="box">
                                        <img src={DoorImg} alt="" />
                                    </div>
                                    <div className="text">
                                        <span>Nächste Schritte konkretisieren</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <span className="bold">ergänzen</span>
                                <div className="row-item" onClick={() => navigate("/circalind/regular/impulse")}>
                                    <div className="box">
                                        <img src={ImpulseImg} alt="" />
                                    </div>
                                    <div className="text">
                                        <span>Impulse</span>
                                    </div>
                                </div>
                                <div className="row-item" onClick={() => navigate("/circalind/regular/services")}>
                                    <div className="box">
                                        <img src={OfferImg} alt="" />
                                    </div>
                                    <div className="text">
                                        <span>Services</span>
                                    </div>
                                </div>
                                <div className="row-item" onClick={() => navigate("/circalind/regular/checkups/content")}>
                                    <div className="box">
                                        <img src={CheckupImage} alt="" />
                                    </div>
                                    <div className="text">
                                        <span>CheckUps</span>
                                    </div>
                                </div>
                                <div className="row-item">
                                    <div className="text bold right">
                                        <span>dokumentieren präsentieren</span>
                                    </div>
                                </div>
                                <div className="row-item">
                                    <div className="box" onClick={() => navigate("/circalind/regular/documentation")}>
                                        <img src={WayImg} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <span className="bold">vertiefen</span>
                                <div className="row-item" onClick={() => navigate("/circalind/regular/transform/self2")}>
                                    <div className="box">
                                        <img src={CompassImg} alt="" />
                                    </div>
                                    <div className="text">
                                        <span>Projekt-Spezifika</span>
                                    </div>
                                </div>
                                <div className="row-item" onClick={() => navigate("/circalind/regular/transform/self3")}>
                                    <div className="box">
                                        <img src={DevelopImg} alt="" />
                                    </div>
                                    <div className="text">
                                        <span>Selbstführung Persönliche Weiterentwicklung</span>
                                    </div>
                                </div>
                                <div className="row-item" onClick={() => navigate("/circalind/regular/transform/self1")}>
                                    <div className="box">
                                        <img src={LaneChangeImg} alt="" />
                                    </div>
                                    <div className="text">
                                        <span>Spur-Wechsel für Veränderung</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="new-items">
                            <span>Kombinieren</span>
                            <div className="new-projects">
                                <div className="row-item" onClick={() => setToggle({ show: true, type: "PERSONAL" })}>
                                    <div className="box">
                                        <img src={PersonalImg} alt="" />
                                    </div>
                                    <div className="text">
                                        <span>Neues Projekt anlegen</span>
                                    </div>
                                </div>
                                {license && allowedStrategicLicenses.includes(license.type) ?
                                    <div className="row-item" onClick={() => setToggle({ show: true, type: "STRATEGIC" })}>
                                        <div className="box">
                                            <img src={StrategyImg} alt="" />
                                        </div>
                                        <div className="text">
                                            <span>Neues Projekt anlegen</span>
                                        </div>
                                    </div>
                                    : undefined}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog className="editStaffing" show={toogle.show} toogle={toggleDialog} component={<EditStaffingProject showAllDeciders={false} showData={true} showRoles={false} preDefinedType={toogle.type} selected={undefined} users={users} onUpdate={onUpdate} onCancel={toggleDialog} licenseType={license?.type || null} />} />

    </Fragment>
}
