import { useEffect, useState } from "react";
import { CircalindApi } from "../circalindApi";
import { AnalysisPackage, AreaAndRoleInfo, Customer, LicenseMetaData, LicenseTypes } from "../dataTypes/generated";
import { SelectCustomer } from "../../invoice/utils/selects/SelectCustomer";
import { formatNumber } from "../../invoice/utils/Util";

import Select from 'react-select';
import writeXlsxFile, { SheetData } from 'write-excel-file'

import "./PackageOverview.scss";

import "../admin/BasicTable.scss";

export const PackageOverview = ({ isAdmin, showTypeSelection = false }: { isAdmin: boolean, showTypeSelection: boolean }) => {
    const [packages, setPackages] = useState<Array<AnalysisPackage>>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
    const [selectedLicenseTypes, setSelectedLicenseTypes] = useState<LicenseTypes | null>(null);
    const [licenseMetaData, setLicenseMetaData] = useState<LicenseMetaData | null>();
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo>();
    const [customers, setCustomers] = useState<Customer[]>([]);

    useEffect(() => {
        const loadOrders = async () => {
            if (isAdmin) {
                if (!showTypeSelection && selectedCustomer) {
                    setPackages(await CircalindApi.getPackageAnalysisForCustomer(selectedCustomer.id));
                } else if (showTypeSelection && selectedLicenseTypes) {
                    setPackages(await CircalindApi.getPackageAnalysisForModule(selectedLicenseTypes));
                }
            } else if (!isAdmin) {
                setPackages(await CircalindApi.getMyPackageAnalysis());
            }
        };

        if (!isAdmin) {
            loadOrders();
        } 
    }, [isAdmin, selectedCustomer, selectedLicenseTypes, showTypeSelection]);

    useEffect(() => {
        const loadMetaData = async () => {
            setLicenseMetaData(await CircalindApi.getLicenseMetaData());
            setAreaAndRoleInfo(await CircalindApi.getAreaAndRoleInfo());
        };

        const getMyCustomer = async () => {
            setSelectedCustomer(await CircalindApi.getMyCustomer());
        }

        const getCustomers = async () => {
            setCustomers(await CircalindApi.getCustomers());
        }

        if (!isAdmin) {
            getMyCustomer();
        } else {
            getCustomers();
        }

        loadMetaData();
    }, [isAdmin]);

    useEffect(() => {
        const loadOrders = async () => {
            if (selectedCustomer) {
                setPackages(await CircalindApi.getPackageAnalysisForCustomer(selectedCustomer.id));
            }
        };

        loadOrders();
    }, [selectedCustomer]);

    useEffect(() => {
        const loadOrders = async () => {
            if (selectedLicenseTypes) {
                setPackages(await CircalindApi.getPackageAnalysisForModule(selectedLicenseTypes));
            }
        };

        loadOrders();
    }, [selectedLicenseTypes]);

    const exportExcel = async () => {
        const header_rows = [
            { value: 'Paket-Nr.', type: String, fontWeight: "bold" },
            { value: 'Kundenname', type: String, fontWeight: "bold" },
            { value: 'Kunden-Nr.', type: String, fontWeight: "bold" },
            { value: 'Basis-Vertrags. Nr.', type: String, fontWeight: "bold" },
            { value: 'Laufzeit des Pakets', type: String, fontWeight: "bold" },
            { value: 'circalind Manager', type: String, fontWeight: "bold" },
            { value: 'alle Koordinatoren des Pakets', type: String, fontWeight: "bold" },
            { value: 'Anzahl Entscheider Lizenzen ges.', type: String, fontWeight: "bold" },
            { value: 'Anzahl Assistenz Lizenzen ges.', type: String, fontWeight: "bold" },
            { value: 'Anzahl Entscheider Lizenzen zusätzlich', type: String, fontWeight: "bold" },
            { value: 'Anzahl Assistenz Lizenzen zusätzlich', type: String, fontWeight: "bold" },
            { value: 'Start-Monat des Pakets', type: String, fontWeight: "bold" },
            { value: 'End-Monat des Pakets', type: String, fontWeight: "bold" },
            { value: 'Rechnungsbetrag des Pakets', type: String, fontWeight: "bold" },
            { value: 'Rechnungsbetrag pro Monat', type: String, fontWeight: "bold" },
            { value: 'Rechnungsbetrag pro Jahr', type: String, fontWeight: "bold" },
        ];

        const values = packages.map(p =>
            [
                { value: `P-${p.cp.id}`, type: String },
                { value: p.customer.name, type: String },
                { value: `K-${p.customer.id}`, type: String },
                { value: p.contractIds.map(cid => `V-${cid}`).join(", "), type: String },
                { value: p.nrOfMonths, type: Number },
                { value: `${p.manager.firstName} ${p.manager.lastName}`, type: String },
                { value: p.coordinators.map(c => `${c.firstName} ${c.lastName}`).join(", "), type: String },
                { value: p.allAssistance, type: Number },
                { value: p.allDeciders, type: Number },
                { value: p.additionalAssistance, type: Number },
                { value: p.additionalDeciders, type: Number },
                { value: `${p.startMonth.toString().padStart(2)}-${p.startYear}`, type: String },
                { value: `${p.endMonth.toString().padStart(2)}-${p.endYear}`, type: String },
                { value: p.invoiceAmount === -1 ? "-" : formatNumber(p.invoiceAmount / 100.0), type: String },
                { value: p.invoiceAmount === -1 ? "-" : formatNumber(p.invoiceAmountPerMonth / 100.0), type: String },
                { value: p.invoiceAmount === -1 ? "-" : formatNumber(p.invoiceAmountPerYear / 100.0), type: String },
            ]
        );

        const data = [header_rows, ...values];

        await writeXlsxFile(data as SheetData, {
            fileName: 'pakete.xlsx',
        });
    };

    const renderCustomerSelect = () => {
        const licenseTypeOptions: Array<{ label: string, value: LicenseTypes }> = [
            {
                value: "UK1",
                label: licenseMetaData ? licenseMetaData.licenseTitles["UK1"]! : "UK1"
            },
            {
                value: "UK2",
                label: licenseMetaData ? licenseMetaData.licenseTitles["UK2"]! : "UK2"
            },
            {
                value: "UK3",
                label: licenseMetaData ? licenseMetaData.licenseTitles["UK3"]! : "UK3"
            },
            {
                value: "PK4",
                label: licenseMetaData ? licenseMetaData.licenseTitles["PK4"]! : "PK4"
            },
        ];

        return <div className="customer-select-wrapper">
            {isAdmin && !showTypeSelection && <SelectCustomer customers={customers} customerId={selectedCustomer?.id || -1} onChange={setSelectedCustomer} />}
            {isAdmin && showTypeSelection && <Select placeholder="Modul-Filter" isClearable={true} value={licenseTypeOptions.find(o => o.value === selectedLicenseTypes)} options={licenseTypeOptions} onChange={(o) => setSelectedLicenseTypes(o ? o.value : o)} />}
            {!isAdmin && <div></div>}
            <button onClick={exportExcel}>Download Excel</button>
        </div>;
    };

    const renderPackage = (p: AnalysisPackage) => {
        return <tr key={p.cp.id}>
            <td>P-{p.cp.id}</td>
            <td>{p.customer.name}</td>
            <td>K-{p.customer.id}</td>
            <td>{p.contractIds.map(cid => `V-${cid}`).join(", ")}</td>
            <td>{p.nrOfMonths} Monat(e)</td>
            <td>{`${p.manager.firstName} ${p.manager.lastName}`}</td>
            <td>{p.coordinators.map(c => `${c.firstName} ${c.lastName}`).join(", ")}</td>
            <td>{p.allAssistance}</td>
            <td>{p.allDeciders}</td>
            <td>{p.additionalAssistance}</td>
            <td>{p.additionalDeciders}</td>
            <td>{`${p.startMonth.toString().padStart(2, "0")}-${p.startYear}`}</td>
            <td>{`${p.endMonth.toString().padStart(2, "0")}-${p.endYear}`}</td>
            <td>{p.invoiceAmount === -1 ? "-" : formatNumber(p.invoiceAmount / 100.0)}</td>
            <td>{p.invoiceAmount === -1 ? "-" : formatNumber(p.invoiceAmountPerMonth / 100.0)}</td>
            <td>{p.invoiceAmount === -1 ? "-" : formatNumber(p.invoiceAmountPerYear / 100.0)}</td>
        </tr>
    };

    const renderOrders = () => {
        if (licenseMetaData && (!isAdmin || selectedCustomer || selectedLicenseTypes) && areaAndRoleInfo) {
            return <div className="table-wrapper">
                <table className="basic-table">
                    <thead>
                        <tr>
                            <th>Paket-Nr.</th>
                            <th>Kundenname</th>
                            <th>Kundennummer</th>
                            <th>Basis-Vertrags. Nr.</th>
                            <th>Laufzeit des Pakets</th>
                            <th>circalind Manager</th>
                            <th>alle Koordinatoren des Pakets</th>
                            <th>Anzahl Assistenz Lizenzen ges.</th>
                            <th>Anzahl Entscheider Lizenzen ges.</th>
                            <th>Anzahl Assistenz Lizenzen zusätzlich</th>
                            <th>Anzahl Entscheider Lizenzen zusätzlich</th>
                            <th>Start-Monat des Pakets</th>
                            <th>End-Monat des Pakets</th>
                            <th>Rechnungsbetrag des Pakets</th>
                            <th>Rechnungsbetrag pro Monat</th>
                            <th>Rechnungsbetrag pro Jahr</th>
                        </tr>
                    </thead>
                    <tbody>
                        {packages.map(renderPackage)}
                    </tbody>
                </table>
            </div>;
        }
    };

    return <div className="order-overview-container">
        {renderCustomerSelect()}
        {renderOrders()}
    </div>;
};