import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import competenceImage from "../../../resources/circa/competence.svg";

import "../../../leading/Start.scss";


export const WelcomeCompetence = () => {
    return <div className="full-page">
        <h1><strong>Kompetenzen</strong></h1>
        <img src={competenceImage} alt="Kompetenzen" />
        <div onClick={() => window.location.href = "content"} className="lower-arrow"><FontAwesomeIcon icon={faArrowRight} /></div>
    </div>;
}