import { useEffect, useRef, useState } from "react";

export const useGlobalMousePosRelativeToRef = (): [[number, number], React.RefObject<any>] => {
    const ref = useRef<any>(null);
    const [mousePos, setMousePos] = useState<[number, number]>([0, 0]);
    useEffect(() => {
        const handler = (e: MouseEvent) => {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                const centerX = rect.left + rect.width / 2;
                const centerY = rect.top + rect.height / 2;
                const x = e.clientX - centerX;
                const y = e.clientY - centerY;
                setMousePos([x, y]);
            }
        };
        document.addEventListener("mousemove", handler);

        return () => { document.removeEventListener("mousemove", handler); }
    }, []);
    return [mousePos, ref];
}
