import { Link, useSearchParams } from "react-router-dom";

import "./OrderConfirmation.scss";

export const OrderConfirmation = () => {
    const [params, ] = useSearchParams();

    const nr = params.get("nr");

    return <div className="order-confirmation">
        <h2>Vielen Dank für Ihre Bestellung!</h2>
        <div>Ihre Bestellnummer ist B-{nr}. Sie haben eine E-Mail mit der Zusammenfassung der Bestellung erhalten.</div>
        <Link to={"/"}>zurück zur Hauptseite</Link>
    </div>;
};