import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { SubGraphProps } from "./Graphs";
import { EditingState } from "./RadarGraph";


export const BarGraph = (props: SubGraphProps): ReactElement => {
    const componentProps = props;
    const ref = useRef<any>(null);
    const [editingState, setEditingState] = useState<EditingState>({ kind: "idle" });

    useEffect(() => {
        if (ref.current && ref.current.current) {
            ref.current.current.addEventListener("pointerup", () => {
                setEditingState({ kind: "idle" });
            });
        }
    }, [ref]);

    const barData = () => {
        let res: any[] = [];
        let bar1: any = { name: "soll" }
        let bar2: any = { name: "ist" }
        for (let i = 0; i < props.data.length; i++) {
            let varr1 = "" + i;//props.data[i].uuid;// +"_1";
            let varr2 = "" + i;//props.data[i].uuid;// +"_2";
            bar1[varr1] = props.data[i].points[0].value;
            bar2[varr2] = props.data[i].points[1].value;
            bar1["yaxis"] = "Soll";
            bar2["yaxis"] = "Ist";
        }
        res.push(bar1);
        res.push(bar2);
        return res;
    }

    const onPointMove = useCallback((e: PointerEvent) => {
        if (editingState.kind === "editing") {
            const axisLine = ref.current.current.querySelector(".recharts-xAxis").querySelector(".recharts-cartesian-axis-line")
            const chartX = e.clientX - axisLine.getBoundingClientRect().x;
            const width = axisLine.getBoundingClientRect().width;

            let newData = [...props.data]
            let newLineIndex = editingState.valueIndex;
            let startForCurrentSegment = 0;

            for (let i = 0; i < newLineIndex; i++) {
                const l = newData[i];
                startForCurrentSegment += l.points[editingState.pointIndex].value;
            }

            let newPointVal = chartX / width * 100 - startForCurrentSegment;
            newPointVal = Math.max(0, Math.min(100, newPointVal));
            newData[newLineIndex].points[editingState.pointIndex].value = newPointVal;

            const dataIsValid = [0, 1].every(pointIndex => newData.map(l => l.points[pointIndex].value).reduce((a, b) => a + b, 0) <= 100);

            if (dataIsValid) {
                props.updateData(newData);
            }
        }
    }, [editingState, props]);

    useEffect(() => {
        const copiedRef = ref?.current?.current;
        if (copiedRef) {
            copiedRef.addEventListener("pointermove", onPointMove);
            return () => {
                copiedRef.removeEventListener("pointermove", onPointMove);
            }
        }
    }, [ref, onPointMove]);

    const renderCustomBar = (props: any, id: string) => {
        const { fill, x, y, width, index } = props;
        const valueIndex = componentProps.data.findIndex(v => v.uuid === id);
        const pointIndex = index as number;

        const editState = {
            kind: "editing" as const,
            valueIndex, pointIndex
        }

        return <g>
            <line x1={x} y1={y + 30} x2={x + width - 15} y2={y + 30} stroke={fill}
                strokeWidth="60"
                onPointerDown={() => setEditingState(editState)}
                 />
            <line x1={x + width - 15} y1={y + 30} x2={x + width} y2={y + 30} stroke={fill}
                strokeWidth="80"
                onPointerDown={() => setEditingState(editState)}
            />
        </g>
    }


    const renderBar = () => {
        return props.data.map((l, i) => <Bar
            key={i}
            fill={l.color}
            shape={(p: any) => renderCustomBar(p, l.uuid)}
            stackId={"asdf"}
            dataKey={i}
        />)
    }


    return <>
        <ResponsiveContainer ref={ref}>
            <BarChart
            ref={props.innerRef}
            data={barData()}
            layout="vertical" barCategoryGap={20}
        >
            <XAxis type="number" interval={0} domain={[0, 100]} />
            <YAxis type="category" dataKey={"yaxis"} />
            {renderBar()}
        </BarChart>
        </ResponsiveContainer>
    </>

}