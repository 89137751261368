import { createContext, ReactElement, useContext, useEffect, useState } from "react";
import { Dialog } from "../basicComponents/dialogs/Dialog";
import { CircalindApi } from "../circalindApi";
import { Group, User } from "../dataTypes/generated";
import { InfoCard } from "./InfoCard";
import { InfoSubArea, nameOfInfoSubArea } from "./InfoData";

import './InfoHull.scss';
import { UserSelect } from "../../leading/dialogs/UserSelect";

export type Props = {
    children: ReactElement | ReactElement[] | null | undefined;
    userId?: number;
    edit: boolean;
}


type ContextType = {
    onSubAreaClick: (subArea: InfoSubArea) => void;
}

const Context = createContext<ContextType>({
    onSubAreaClick: () => { }
});

export const InfoHull = ({ children, userId, edit }: Props): ReactElement => {
    const [openedArea, setOpenedArea] = useState<InfoSubArea | null>(null);
    const [group, setGroup] = useState<Group | null>(null);
    const [selectedUser, setSelectedUser] = useState<number>(-1);
    const [users, setUsers] = useState<Array<User>>([]);

    useEffect(() => {
        CircalindApi.getMyGroup().then(setGroup);
        CircalindApi.getAllUsersOfMyGroup().then(setUsers);
    }, []);

    if (group === null) {
        return <div>Sie scheinen keiner Gruppe zugeordnet zu sein.</div>
    } else {
        return <div>
            {edit && !userId &&
                <UserSelect users={users} onSelect={u => setSelectedUser(u ? u.id : -1)} useName={true} valueUserId={selectedUser} />
            }
            {edit && !userId && selectedUser === -1 && <div>Bitte wählen Sie zunächst einen Nutzer, für den Sie die Informationen bearbeiten wollen</div>}
            <Context.Provider value={{ onSubAreaClick: setOpenedArea }}>
                {children}
            </Context.Provider>
            <Dialog
                toogle={() => setOpenedArea(null)}
                component={<div>{openedArea && <InfoCard phase={group.managementPhase} subAreaName={openedArea!} editForUser={edit ? userId || selectedUser : undefined} />}</div >}
                className={""}
                show={!!openedArea}
            />
        </div>
    }

}

export type InfoBubbleProps = {
    subArea: InfoSubArea;
}
export const InfoBubble = ({ subArea }: InfoBubbleProps): ReactElement => {
    const ctx = useContext(Context);
    return <div className={"info-bubble"} onClick={() => ctx.onSubAreaClick(subArea)}>
        <div className="info-bubble-name">
            {nameOfInfoSubArea[subArea]}
        </div>
        <div className="info-bubble-intro">Intro</div>
    </div>
}
