import { ReactElement } from "react";
import { GraphValues } from "../../../dataTypes/generated";
import { SubGraphProps } from "./Graphs";

import './square.scss';

export const SquareGraph = (props: SubGraphProps): ReactElement => {
    const getColorOrDefault = (strColor: string): string => {
        const s = new Option().style;
        s.color = strColor;
        if (s.color !== '') {
            return strColor;
        } else {
            return "#999999";
        }
    }

    const renderSVGResult = () => {
            const result = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
                <g>
                    <g>
                        <text x="200" y="20">Aktuell</text>
                        <text x="300" y="20">Kurzfristig</text>
                        <text x="400" y="20">Mittelfristig</text>
                        <text x="500" y="20">Langfristig</text>
                    </g>
                    {props.data.map((l, i) => <g key={i}>
                        <text x="0" y={20 + (i + 1) * 30}>{l.title}</text>
                        <rect x="200" y={(i + 1) * 30 + 5} width={50} height={20} fill={getColorOrDefault(l.points[0].description)}></rect>
                        <rect x="300" y={(i + 1) * 30 + 5} width={50} height={20} fill={getColorOrDefault(l.points[1].description)}></rect>
                        <rect x="400" y={(i + 1) * 30 + 5} width={50} height={20} fill={getColorOrDefault(l.points[2].description)}></rect>
                        <rect x="500" y={(i + 1) * 30 + 5} width={50} height={20} fill={getColorOrDefault(l.points[3].description)}></rect>
                        </g>)}
                </g>
            </svg>

            return result;
    };

    const updateValueFor = (uuid: string, index: number, newColor: string) => {
        let newData = [...props.data];
        const found = newData.findIndex(d => d.uuid === uuid);
        if (found >= 0) {
            newData[found].points[index].description = newColor;
            props.updateData(newData);
        }
    }

    const renderRow = (l: GraphValues, index: number) => {
        return <div key={index} className="color-row">
            <span>{l.title}</span>
            <input type={"color"} style={{ color: getColorOrDefault(l.points[0].description), backgroundColor: getColorOrDefault(l.points[0].description) }} value={getColorOrDefault(l.points[0].description)} onChange={(e) => updateValueFor(l.uuid, 0, e.target.value)} />
            <input type={"color"} style={{ color: getColorOrDefault(l.points[1].description), backgroundColor: getColorOrDefault(l.points[1].description) }} value={getColorOrDefault(l.points[1].description)} onChange={(e) => updateValueFor(l.uuid, 1, e.target.value)} />
            <input type={"color"} style={{ color: getColorOrDefault(l.points[2].description), backgroundColor: getColorOrDefault(l.points[2].description) }} value={getColorOrDefault(l.points[2].description)} onChange={(e) => updateValueFor(l.uuid, 2, e.target.value)} />
            <input type={"color"} style={{ color: getColorOrDefault(l.points[3].description), backgroundColor: getColorOrDefault(l.points[3].description) }} value={getColorOrDefault(l.points[3].description)} onChange={(e) => updateValueFor(l.uuid, 3, e.target.value)} />
        </div>
    }

    return <div className="Square-inputs" id="square-graph-id">
        <span>Hier: Aktivitäten/ Maßnahmen eintragen= 1 pro Feld</span>
        <div className="color-inputs">
            <div className="color-row">
                <span></span>
                <span>Aktuell</span>
                <span>Kurzfristig</span>
                <span>Mittelfristig</span>
                <span>Langfristig</span>
            </div>
            {props.data.map((l, i) => renderRow(l, i))}
        </div>
        <span>Hier: Zeit-Schiene auswählen: aktuell/ kurzfristig/ mittelfristig / langfristig</span>
        <div ref={props.innerRef} className="svg">
        {renderSVGResult()}
        </div>
    </div>
}