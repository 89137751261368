import "./ManagingToolbox.scss"

import toolboxImage from "../../resources/circa/toolbox.svg";
import { PhaseDiagrams } from "../projects/competence/PhaseDiagrams";
import { getCurrentUserId } from "../../utils/Auth";
import { serviceHeaders, useHeaderContext } from "../page/header/Header";

export const ManagingToolbox = () => {
    const userId = getCurrentUserId("token-circalind");
    const headers = useHeaderContext();
    headers.updateHeader(serviceHeaders);

    return <div className="managing-toolbox">
        <div className="header">
            <img src={toolboxImage} alt="" />
            <h2>Toolbox</h2>
        </div>
        {userId && <PhaseDiagrams chooseProject={true} userId={userId} authorId={userId} />}
    </div>
};