import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import myWayImage from "../../resources/circa/personal_nav.svg";

import "../Start.scss";


export const WelcomeLeadingDocumentation = () => {
    return <div className="full-page">
        <h1><strong>Dokumentation von Setting & Staffing</strong></h1>
        <img src={myWayImage} alt="Dokumentation von Setting & Staffing" />
        <div onClick={() => window.location.href = "main"} className="lower-arrow"><FontAwesomeIcon icon={faArrowRight} /></div>
    </div>;
}