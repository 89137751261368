import { useCallback, useEffect, useState } from "react";
import { sendOlert } from "../../olert/Olert";
import { decrypt, encrypt } from "../../utils/Encryption";
import { CircalindApi } from "../circalindApi";
import { Answer, GraphValues } from "../dataTypes/generated";

export interface QuestionAnswer {
    cells: TableAnswerCell[];
    comments: QuestionComments;
    graphData: GraphValues[];
}

export interface QuestionComments {
    decision: string;
    events: string;
    development: string;
}

export interface TableAnswerCell {
    column: number;
    row: number;
    answer: string;
}

const createEmptyAnswer = (): QuestionAnswer => {
    return { cells: [], comments: { decision: "", development: "", events: "" }, graphData: [] };
}

export const useAnswer = (questionid: number, phase: number, projectid: number, p: string | null, preventUnsetProjectId?: boolean) => {
    const emptyAnswer: QuestionAnswer = createEmptyAnswer();
    const [rawAnswer, setRawAnswer] = useState<Answer>({ answer: "", phase: phase, questionId: questionid, userId: -1, projectId: projectid, timestamp: "" })
    const [answer, setAnswer] = useState<QuestionAnswer>(emptyAnswer)

    const onSaveAnswer = async (pass: string | null): Promise<boolean> => {
        if (questionid >= 0 && (!preventUnsetProjectId || projectid > 0)) {
            if (pass !== null) {
                let toSave = rawAnswer;
                //encrypt HERE
                toSave.answer = encrypt(JSON.stringify(answer), pass);
                return await CircalindApi.saveAnswer(toSave);
            } else {
                sendOlert("Hinweis", "Es konnten keine Daten gespeichert werden da kein Password vergeben wurde", "Warning")
                return false;
            }
        }
        return false;
    }

    const decryptAnswer = useCallback((password: string | null) => {
        if (rawAnswer && rawAnswer.userId !== -1 && password) {
            try {
                let an: QuestionAnswer = JSON.parse(decrypt(rawAnswer.answer, password));
                setAnswer(an);
            } catch {
                setAnswer(createEmptyAnswer());
            }
        } else {
            setAnswer(createEmptyAnswer);
        }
    }, [rawAnswer]);

    useEffect(() => {
        decryptAnswer(p)
    }, [p, decryptAnswer])

    useEffect(() => {
        const getAndSetAnswer = async (id: number) => {
            if (id > 0) {
                let res = await CircalindApi.getAnswer(id, phase, projectid);
                if (res) {
                    setRawAnswer(res);
                } else {
                    setRawAnswer({ answer: "", questionId: questionid, phase: phase, userId: -1, projectId: projectid, timestamp: "" });
                }
            }
        }
        getAndSetAnswer(questionid);
    }, [questionid, phase, projectid])

    return [answer, setAnswer, onSaveAnswer] as const
}
