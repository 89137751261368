import { useState } from "react";
import { useEffect } from "react"
import { CircalindApi } from "../../circalind/circalindApi";
import { GeneralContent } from "../../circalind/contentManager/GeneralContent";
import { Question, SubArea } from "../../circalind/dataTypes/generated";

import qualityColumnImage from "../../resources/circa/quality_column.png";

import "./quality.scss";
import { useGroupWideSubArea } from "../../utils/Hooks";
import { TableAnswerCell, useAnswer } from "../../circalind/hooks/useAnswer";
import { sendOlert } from "../../olert/Olert";


export const QualityManagement = () => {
    const subArea = useGroupWideSubArea("quality", "quality");
    const [generalContent, setGeneralContent] = useState<Question | null>(null);

    //use a fixed password for it coordination, since it is readable/writeable by both coordinator and it-coordinator
    const password = "quality-coordination";
    const [answer, setAnswer, saveAnswer] = useAnswer(generalContent ? generalContent.id : -1, -1, -1, password);

    const onAnswer = (data: TableAnswerCell[]) => {
        setAnswer({ ...answer, cells: data });
        saveAnswer(password);
    }

    useEffect(() => {
        const getContent = async (subArea: SubArea) => {
            const result = await CircalindApi.getContentQuestionByFilterForSubArea({
                subArea: subArea.id,
                notes: false,
                phase: - 1,
                userId: -1,
                order: -1,
            });

            if (result) {
                setGeneralContent(result);
            }
        };

        if (subArea) {
            getContent(subArea);
        }
    }, [subArea]);

    const renderColumn = (i: number) => {
        return <div className="quality-column" key={i}>
            <img src={qualityColumnImage} alt={"Nachhaltigkeit"} />
            <div>
                <div>Termin {i}</div>
                <div>Verantwortung:</div>
                <div>Einladener:</div>
                <div>Wer:</div>
                <div>Wo:</div>
            </div>
        </div>;
    };

    const renderColumns = () => {
        let columns = [];
        for (let i = 1; i <= 5; i++) {
            columns.push(renderColumn(i));
        }

        return <>
            {columns}
        </>;
    };

    const saveAnswerCallback = async () => {
        const res = await saveAnswer(password);
        if (res) {
            sendOlert("Erfolg", "Antworten erfolgreich gespeichert", "Success");
        } else {
            sendOlert("Fehler", "Fehler beim Speichern der Antworten", "Error");
        }
    };

    return <div>
        <h2>Nachhaltigkeits-Management</h2>
        {
            generalContent
                ? <GeneralContent
                    onUpdate={(d) => setGeneralContent({ ...generalContent, table: d })}
                    canEdit={true}
                    canReview={false}
                    question={generalContent}
                    showControls={true}
                    onCellAnswerd={onAnswer}
                    answers={answer.cells}
                    onSaveAnswers={saveAnswerCallback}
                ></GeneralContent>
                : null
        }
        <div className="quality-description">
            {renderColumns()}
        </div>
        <div className="quality-timeline">
            <div>Nach 1. Monat</div>
            <div>Nach 3 Monaten</div>
            <div>Nach 6 Monaten</div>
            <div>Nach 9 Monaten</div>
            <div>1 x im Quartal</div>
        </div>
    </div>;
}
