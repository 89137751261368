import { useEffect, useRef, useState } from "react";

export type Handlers = {
    onMouseDown: (e: React.MouseEvent<Element, MouseEvent>) => void,
}

type Callbacks = {
    onDragStart: (e: MouseEvent) => void;
    onDragEnd: (e: MouseEvent) => void;
    onClick: (e: MouseEvent) => void;
}

export const useGlobalCustomDragAndDrop = (
    onDragStart: (e: MouseEvent) => void,
    onDragEnd: (e: MouseEvent) => void,
    onClick: (e: MouseEvent) => void,
): Handlers => {
    const callbacks = useRef<Callbacks>({ onDragStart, onDragEnd, onClick });
    const [handlers, setHandlers] = useState<Handlers>({ onMouseDown: () => { } });

    callbacks.current = { onDragStart, onDragEnd, onClick };

    useEffect(() => {
        let state: DragState = { kind: 'MouseUp' };

        let downHandler: (e: React.MouseEvent<Element, MouseEvent>) => void;
        let upHandler: (e: MouseEvent) => void;
        let moveHandler: (e: MouseEvent) => void;

        downHandler = (e: React.MouseEvent<Element, MouseEvent>) => {
            state = { kind: 'MouseDown', startX: e.clientX, startY: e.clientY };
            document.addEventListener('mouseup', upHandler);
            document.addEventListener('mousemove', moveHandler);
        }

        upHandler = (e: MouseEvent) => {
            if (state.kind === 'MouseDown') {
                callbacks.current.onClick(e);
            } else if (state.kind === 'Dragging') {
                callbacks.current.onDragEnd(e);
            }
            state = { kind: 'MouseUp' };
            document.removeEventListener('mouseup', upHandler);
            document.removeEventListener('mousemove', moveHandler);
        }

        moveHandler = (e: MouseEvent) => {
            if (state.kind === "MouseDown") {
                const dx = e.clientX - state.startX;
                const dy = e.clientY - state.startY;
                if (dx * dx + dy * dy > 2 * 2) {
                    state = ({ kind: "Dragging" });
                    callbacks.current.onDragStart(e);
                }
            }
        }

        setHandlers({ onMouseDown: downHandler });

        return () => {
            document.removeEventListener('mouseup', upHandler);
            document.removeEventListener('mousemove', moveHandler);
        }
    }, []);

    return handlers;
}

type DragState = {
    kind: "MouseUp",
} | {
    kind: "MouseDown",
    startX: number,
    startY: number,
} | {
    kind: "Dragging",
}
