import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import myWayImage from "../../resources/circa/personal_nav.svg";

import "../Start.scss";
import { useNavigate } from "react-router";


export const WelcomeStaffing = () => {
    const navigate = useNavigate();

    return <div className="full-page">
        <h1><strong>Setting & Staffing</strong></h1>
        <img src={myWayImage} alt="Setting & Staffing" />
        <div onClick={() => navigate("../overview")} className="lower-arrow"><FontAwesomeIcon icon={faArrowRight} /></div>
    </div>;
}