
import communicationImage from "../../resources/circa/communication/communication.png";
import communicationWorkshopPrePostImage from "../../resources/circa/communication/communication_workshop_pre_post.png";
import DoorImg from "../../resources/circa/door.png";

import { useNavigate } from "react-router";

import "./CommunicationOverview.scss";

export const CommunicationOverview = () => {
    const navigate = useNavigate();

    return <div className="communication-overview">
        <div />
        <div className="card content-card">
            <h3>Info-Sammlung</h3>
            <div onClick={() => { navigate("../project") }} className="image-wrapper card clickable">
                <img src={communicationImage} alt="" />
            </div>
            <div></div>
        </div>
        <div className="card content-card">
            <h3>Vor- & Nachbereiten</h3>
            <div onClick={() => { navigate("../prepost") }} className="image-wrapper card clickable">
                <img src={communicationWorkshopPrePostImage} alt="" />
            </div>
            <div></div>
        </div>
        <div className="card content-card">
            <h3>Nächste Schritte</h3>
            <div onClick={() => { navigate("/circalind/managing/transfer") }} className="image-wrapper card clickable">
                <img src={DoorImg} alt="" />
            </div>
            <div></div>
        </div>
    </div>;
}