import selbstImage from "../../../../resources/circa/compass/Selbstführung.png";
import managementImage from "../../../../resources/circa/compass/ManagementCompass.png";
import spurImage from "../../../../resources/circa/compass/SpurWechsel.png";

import "./DeepOverview.scss";
import { useNavigate } from "react-router";

export const DeepOverview = () => {
    const navigate = useNavigate();

    return <div className="deep-overview">
        <div className="card content-card">
            <h3>Selbstführung</h3>
            <div onClick={() => { navigate("/circalind/regular/transform/self3") }} className="image-wrapper card clickable">
                <img src={selbstImage} alt="selbstführung" />
            </div>
            <div></div>
        </div>
        <div className="card content-card">
            <h3>Management-Kompass</h3>
            <div onClick={() => { navigate("/circalind/regular/transform/self2") }} className="image-wrapper card clickable">
                <img src={managementImage} alt="management" />
            </div>
            <div></div>
        </div>
        <div className="card content-card">
            <h3>Spur-Wechsel</h3>
            <div onClick={() => { navigate("/circalind/regular/transform/self1") }} className="image-wrapper card clickable">
                <img src={spurImage} alt="spurwechsel" />
            </div>
            <div></div>
        </div>
    </div>
};