import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import serviceImage from "../../resources/circa/ServicesMenu.svg";

import "../../leading/Start.scss";
import { useNavigate } from "react-router";


export const WelcomeServices = () => {
    const navigate = useNavigate();

    return <div className="full-page">
        <h1><strong>Services</strong></h1>
        <img src={serviceImage} alt="Services" />
        <div onClick={() => navigate("../main")} className="lower-arrow"><FontAwesomeIcon icon={faArrowRight} /></div>
    </div>;
}