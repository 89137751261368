import { useNavigate } from "react-router";
import "./Card.scss";


interface CardProps {
    title: string;
    subtitle: string | null;
    images: Array<{ path: string; title: string }>;
    navigation: string;
    back: boolean;
}

export const Card = (props: CardProps) => {
    const navigate = useNavigate()

    return <div onClick={() => navigate(props.navigation)} className="card">
        <h4>{props.title}</h4>
        {props.subtitle ? <span>{props.subtitle}</span> : undefined}
        <div className="images">
            {props.images.map((image, index) => {
                return <div key={index} className="image-wrapper">
                    <strong>{image.title}</strong>
                    <img src={image.path} alt={image.title} />
                </div>
            })}
        </div>
    </div>
}
