import { deleteLocalStorage, deleteSessionStorage, readFromLocalStorage, readFromSessionStorage, writeToLocalStorage, writeToSessionStorage } from "./LocalStorage"

import * as CryptoJS from "crypto-js"


//USE JSON.stringify to encrypt objects
export const encrypt = (message: string, password: string) => {
    return CryptoJS.AES.encrypt(message, password).toString();
}


//USE JSON.parse on the result to decrypt objects
export const decrypt = (encrypted: string, password: string) => {
    return CryptoJS.AES.decrypt(encrypted, password).toString(CryptoJS.enc.Utf8);
}

const PASSPHRASE_KEY = "passphrase";

export const getPassphrase = (): string | null => {
    return readFromLocalStorage(PASSPHRASE_KEY);
}

export const getTempPassphrase = (): string | null => {
    return readFromSessionStorage(PASSPHRASE_KEY);
}

export const setTempPassphrase = (passphrase: string) => {
    writeToSessionStorage(PASSPHRASE_KEY, passphrase);
}

export const clearTempPassphrase = () => {
    deleteSessionStorage(PASSPHRASE_KEY);
}

export const clearPassphrase = () => {
    deleteLocalStorage(PASSPHRASE_KEY);
}

export const setPassphrase = (passphrase: string) => {
    writeToLocalStorage(PASSPHRASE_KEY, passphrase);
}
