import governanceImage from "../../resources/circa/governance.svg";
import practicalImage from "../../resources/circa/user/left-menu-1.svg";
import checkUpImage from "../../resources/circa/risk_management.svg"

import "./Info.scss";

export const PhaseInfoBlock = () => {
    return <div className="info-block">
        <div className="info-card">
            <div className="info-image">
                <img src={governanceImage} alt="Governance" />
            </div>
            <div className="info-content">
                Tipp: <br /><br />
                Aktualität prüfen: <br />
                bzgl.  Informationen zu geltenden Gesetzen, formalen Rahmenbedingungen, Betriebsvereinbarungen, Zielen, KPI’s, Kennzahlen u.a.
            </div>
        </div>
        <div className="info-card">
            <div className="info-image">
                <img src={checkUpImage} alt="CheckUp" />
            </div>
            <div className="info-content">
                Tipp: <br /><br />
                I. Maßnahmen zur Risiko-Prävention nutzen. <br />
                z.B. <br />
                a) hinterlegte CheckUps nutzen <br />
                b) Perspektiv Wechsel <br /><br />

                II. sich im Risiko-Management auf dem Laufenden halten
            </div>
        </div>
        <div className="info-card">
            <div className="info-image">
                <img src={practicalImage} alt="Praxis" />
            </div>
            <div className="info-content">
                Tipp: <br /><br />
                a) Informationen aus
                Praxis-Räumen ins Resümee einbeziehen <br />
                b) in Bezug auf die nächste Phase auf Aktualität und
                Vollständigkeit überprüfen <br />
                c) ggf. aktualisieren
            </div>
        </div>
    </div>;
};