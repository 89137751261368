import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useEffect, useState } from "react";
import { CircalindApi } from "../../circalind/circalindApi";
import { AllowedProjectType, AreaAndRoleInfo, Group, LicenseTypes, Project, RoleType, TypeProject, User, UserWithProjectRoles } from "../../circalind/dataTypes/generated";
import { sendOlert } from "../../olert/Olert";
import { StaffingDialogProps } from "../staffing/Staffing";
import { UserSelect } from "./UserSelect";
import Select from 'react-select';

import "./EditStaffingProject.scss";
import { getCurrentUserId, getUserRoles } from "../../utils/Auth";

interface ProjectStaffingDialogProps extends StaffingDialogProps {
    showData: boolean;
    showRoles: boolean;
    showAllDeciders: boolean;
}


export const allowedStrategicLicenses: Array<LicenseTypes> = ["UK1", "UK2", "UK3", "PK4"];

export const strategicRoles: Array<RoleType> = ["CONTENT_MANAGER", "COORDINATOR", "IMPULSE_PARTNER"];
export const personalRoles: Array<RoleType> = ["COORDINATOR", "IMPULSE_PARTNER"];

export const EditStaffingProject = (props: ProjectStaffingDialogProps): ReactElement => {
    const userRoles = getUserRoles("token-circalind");

    const empty: Project = { id: -1, groupId: -1, date: "", description: "", kind: "Project", name: "", ownerId: -1, phase: 0, type: props.preDefinedType ? props.preDefinedType : "PERSONAL", members: [], maxMembers: 0, temporal: 0, importance: "C", status: "GREEN", synchronimeterData: null, compact: false, documentation: false };

    const getDefaultOrSelected = (): Project => {
        if (props.selected !== undefined) {
            return props.selected as Project;
        }
        return empty;
    }

    const [project, setProject] = useState<Project>(getDefaultOrSelected());
    const [group, setGroup] = useState<Group | null>(null);
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo | null>(null);
    const [user, setUser] = useState<User | undefined>();
    const [role, setRole] = useState<RoleType | undefined>(undefined);
    const [availableRoles, setAvailableRoles] = useState<Array<RoleType>>([]);

    useEffect(() => {
        const getInfo = async () => {
            setAreaAndRoleInfo(await CircalindApi.getAreaAndRoleInfo());
        };

        const getGroup = async () => {
            setGroup(await CircalindApi.getMyGroup());
        };

        getInfo();
        getGroup();
    }, []);

    useEffect(() => {
        if (areaAndRoleInfo && project && project.type) {
            const userRoles = getUserRoles("token-circalind");

            const ar: Array<RoleType> = userRoles.includes("DECIDER") ? ["CO_MANAGER_PROJECT", "CO_MANAGER_DRIVERS"] : [];

            const otherRoles = project.type === "PERSONAL" ? personalRoles : strategicRoles;
            const coordinatorRoles = (userRoles.includes("COORDINATOR") || userRoles.includes("CO_COORDINATOR")) ? otherRoles : [];

            setAvailableRoles([...ar, ...coordinatorRoles]);
        }
    }, [areaAndRoleInfo, project.type, project]);

    const onSave = async () => {
        if (project.ownerId > -1) {
            if (project.maxMembers < project.members.length) {
                sendOlert("Fehler", `Die maximale Anzahl Mitglieder liegt bei ${project.maxMembers}`, "Error");
                return;
            }

            if (project.name) {
                const id = await CircalindApi.addNewProject(project);
                if (id > 0) {
                    setProject(empty);
                    sendOlert("Erfolgreich", "Project gespeichert", "Success");
                } else {
                    sendOlert("Fehler", "Project konnte nicht gespeichert werden", "Error");
                }
                props.onUpdate();
            } else {
                sendOlert("Fehler", "Es wurde kein Name vergeben", "Error");
            }
        } else {
            sendOlert("Fehler", `Es wurde kein ${areaAndRoleInfo?.roleTitles["DECIDER"]} bestimmt`, "Error");
        }
    }

    const getProjectOptions = () => {
        const options: Array<{ value: AllowedProjectType, label: string }> = [];

        if (group) {
            if (group.allowedProjectType === "PERSONAL") {
                options.push({ value: "PERSONAL", label: "Persönliches Projekt" });
            } else if (group.allowedProjectType === "STRATEGIC") {
                options.push({ value: "STRATEGIC", label: "Strategie Projekt" });
            } else if (group.allowedProjectType === "BOTH") {
                options.push({ value: "PERSONAL", label: "Persönliches Projekt" });
                options.push({ value: "STRATEGIC", label: "Strategie Projekt" });
            }
        }

        return options.filter(obj => obj.value !== "STRATEGIC" || (props.licenseType && allowedStrategicLicenses.includes(props.licenseType)));
    };

    const renderUserHeading = () => {
        return <div className="list-item">
            <div><strong>Rolle</strong></div>
            <div><strong>Vorname</strong></div>
            <div><strong>Nachname</strong></div>
            <div><strong>E-Mail</strong></div>
        </div>
    }
    const renderUser = (withRole: UserWithProjectRoles, index: number) => {
        const deleteUser = () => {
            if (userRoles.includes("COORDINATOR") || userRoles.includes("DECIDER")) {
                const members = project.members.filter(m => !(m.role === withRole.role && m.user === withRole.user));
                setProject({ ...project, members });
            }
        };

        return <div className="list-item" key={index}>
            <div>{areaAndRoleInfo ? areaAndRoleInfo.roleTitles[withRole.role] : withRole.role}</div>
            <div>{withRole.user.firstName}</div>
            <div>{withRole.user.lastName}</div>
            <div>{withRole.user.mail}</div>
            <div><FontAwesomeIcon icon={faTrashAlt} onClick={deleteUser} /></div>
        </div>
    }

    const addUser = () => {
        if (user && role) {
            const index = project.members.findIndex(m => m.user.mail === user.mail && m.role === role);
            if (index === -1) {
                let newProject = { ...project };
                newProject.members.push({
                    user: user,
                    role: role,
                    start: null,
                    end: null
                });
                setProject(newProject);
            }
        }
    }

    const renderRoleSelect = () => {
        if (areaAndRoleInfo) {
            const options = user ? availableRoles.filter(r => user.roles.includes(r)).map(r => {
                return { label: areaAndRoleInfo.roleTitles[r], value: r };
            }) : [];
            return <Select className="add-role" onChange={(option) => setRole(option!.value as RoleType)} options={options}></Select>;
        }
    };

    const saveRoles: Array<RoleType> = ["CIRCALINDMANAGER", "COORDINATOR", "CO_COORDINATOR", "DECIDER"];

    let compactOptions: Array<{ label: string, value: boolean }> = [];

    if (group && group.allowedProjectDepth === "COMPACT") {
        compactOptions.push({ label: "Kompakt", value: true });
    } else if (group && group.allowedProjectDepth === "EXTENSIVE") {
        compactOptions.push({ label: "Vertiefend", value: false });
    } else if (group && group.allowedProjectType === "BOTH") {
        compactOptions.push({ label: "Kompakt", value: true });
        compactOptions.push({ label: "Vertiefend", value: false });
    }

    const currentUserId = getCurrentUserId("token-circalind");

    const renderData = () => {
        return <>
            <div className="input">
                <label>{areaAndRoleInfo?.roleTitles["DECIDER"]}</label>
                <UserSelect useName={true} valueUserId={project.ownerId} users={props.users?.filter(u => (props.showAllDeciders || u.id === currentUserId) && u.roles.includes("DECIDER"))} onSelect={(e) => setProject({ ...project, ownerId: e ? e.id : -1 })} />
            </div>
            <div className="input">
                <label>Projektart</label>
                <Select isDisabled={project.id !== -1} value={getProjectOptions().find(po => po.value === project.type)} options={getProjectOptions()} onChange={(opt) => setProject({ ...project, type: opt!.value as TypeProject })} />
            </div>
            <div className="input">
                <label>Kompakt oder Vertiefend</label>
                <Select isDisabled={project.id !== -1} value={compactOptions.find(co => co.value === project.compact)} options={compactOptions} onChange={(opt) => setProject({ ...project, compact: opt!.value as boolean })} />
            </div>
            <div className="input">
                <label>Projektname</label>
                <input value={project.name} onChange={(e) => setProject({ ...project, name: e.target.value })} />
            </div>
            <div className="input">
                <label>Beschreibung (optional)</label>
                <textarea value={project.description} onChange={(e) => setProject({ ...project, description: e.target.value })} />
            </div>
        </>
    };

    const renderUsers = () => {
        return <>
            <h3>Nutzerverwaltung</h3>
            <div className="users">
                <div>
                    <label htmlFor="max-members">Maximale Anzahl Projektassistenz</label>
                    <input disabled={!userRoles.includes("COORDINATOR") && !userRoles.includes("CO_COORDINATOR")} value={project.maxMembers} id="max-members" min="0" onChange={(e) => setProject({ ...project, maxMembers: +e.target.value })} />
                </div>
                <div className="add-section">
                    <UserSelect useName={true} valueUserId={user ? user.id : undefined} users={props.users} onSelect={setUser} />
                    {renderRoleSelect()}
                </div>
                <div className="add-button-container">
                    <button disabled={!userRoles.includes("CO_COORDINATOR") && !userRoles.includes("COORDINATOR") && !userRoles.includes("DECIDER")} onClick={() => addUser()}><FontAwesomeIcon icon={faPlus} /></button>
                </div>
                <div className="list">
                    {renderUserHeading()}
                    {project.members.map((u, i) => renderUser(u, i))}
                </div>
            </div>
        </>;
    };

    return <div className="new-staffing-project">
        <div className="inputs">
            {props.showData && renderData()}
            {props.showRoles && renderUsers()}
        </div>
        <div className="buttons">
            <button onClick={() => props.onCancel()}>Abbrechen</button>
            <button disabled={!userRoles.some(r => saveRoles.includes(r))} onClick={() => onSave()}>Speichern</button>
        </div>
    </div>
}
