import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, ReactElement, useCallback, useEffect, useState } from "react";
import { GraphImage, SubArea } from "../../dataTypes/generated";
import { QuestionEndAnswer, useEndAnswer } from "../../hooks/useEndAnswer";
import { getCurrentUserId } from "../../../utils/Auth";
import { CircalindApi } from "../../circalindApi";
import { Dialog } from "../../basicComponents/dialogs/Dialog";
import { DriverNotes } from "./DriverNotes";
import { useSelectedProject } from "../../../utils/Hooks";
import { usePassword } from "../../hooks/usePassword";
import { PhaseDiagram, getTypeForPhase } from "../competence/PhaseDiagram";
import { useCurrentPng } from "recharts-to-png";

export interface PhaseEndProps {
    projectId: number;
    phase: number;
    onNext: () => Promise<void>;
    onBack: () => Promise<void>;
}

export const PhaseEnd = (props: PhaseEndProps): ReactElement => {
    const [selectedProject,] = useSelectedProject();
    const password = usePassword();
    const [answer, setAnswer, saveAnser] = useEndAnswer(props.phase, props.projectId, password);
    const [practicalSubAreas, setPracticalSubareas] = useState<Array<SubArea>>([]);
    const [showDriverNotes, setShowDriverNotes] = useState<boolean>(false);
    const [graphImage, setGraphImage] = useState<GraphImage | null>(null);
    const [getPng, { ref }] = useCurrentPng();

    const userId = getCurrentUserId("token-circalind");

    const setAnswerCallback = useCallback((q: QuestionEndAnswer) => {
        setAnswer(q);
    }, [setAnswer])

    useEffect(() => {
        setAnswerCallback({ title: "", driver: "", graphData: [], comments: "" });

        const getSubareaData = async () => {
            const response = await CircalindApi.getSubAreas({
                area: "practical", targetProjectId: selectedProject, targetInternalGroupId: null, targetUserId: null,
                groupId: -1
            })

            setPracticalSubareas(response);
        };

        const getGraphImage = async () => {
            if (userId) {
                const result = await CircalindApi.getGraphImage(userId, selectedProject, userId, props.phase, "", "PHASE", true);

                if (result) {
                    setGraphImage(result);
                } else {
                    const defaultGraph: GraphImage = {
                        id: -1,
                        userId: userId,
                        projectId: selectedProject,
                        authorId: userId,
                        phase: props.phase,
                        name: "",
                        graphType: getTypeForPhase(props.phase),
                        graphFor: "PHASE",
                        json: "",
                        base64: ""
                    };
                    setGraphImage(defaultGraph);
                }
            }
        };

        getSubareaData();
        getGraphImage();
    }, [props.phase, selectedProject, setAnswerCallback, userId])

    const next = async () => {
        saveAnser(password);
        if (graphImage) {
            const png = graphImage.graphType === "SQARES" ? `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(ref.current.innerHTML)))}` : await getPng();

            if (png) {
                const toBeSavedGraph: GraphImage = { ...graphImage, base64: png };
                await CircalindApi.saveGraphImage(toBeSavedGraph);
            }
        }
        props.onNext();
    }

    const back = async () => {
        saveAnser(password);
        if (graphImage) {
            const png = graphImage.graphType === "SQARES" ? `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(ref.current.innerHTML)))}` : await getPng();

            if (png) {
                const toBeSavedGraph: GraphImage = { ...graphImage, base64: png };
                await CircalindApi.saveGraphImage(toBeSavedGraph);
            }
        }
        props.onBack();
    }


    const setDriverInput = (val: string) => {
        let newAnswer = { ...answer };
        newAnswer.driver = val;
        setAnswer(newAnswer);
    }

    const setCommentInput = (val: string) => {
        let newAnswer = { ...answer };
        newAnswer.comments = val;
        setAnswer(newAnswer);
    }

    const onGraphUpdate = async (graphData: GraphImage) => {
        setGraphImage(graphData);
    }

    const renderDrivers = () => {
        return practicalSubAreas.map(sa => <img key={sa.id} onClick={() => setShowDriverNotes(true)} src={sa.image} alt={sa.name} />);
    };

    return <Fragment>
        <div className="phase">
            <div className="phase-end">
                <div className="top">
                    <div className="left">
                        <div className="drivers">
                            <h2>Treiber und Herausforderungen</h2>
                            <div className="select">
                                <div className="image-select">
                                    {renderDrivers()}
                                </div>
                                <textarea className="driver-input" value={answer.driver}
                                    placeholder={"Beschreiben Sie ..."}
                                    onChange={(e) => setDriverInput(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="graph">
                        {graphImage && <PhaseDiagram innerRef={ref} showTitle={false} edit={true} value={graphImage} updateGraphImage={onGraphUpdate} />}
                    </div>
                </div>
                <div className="comments">
                    <span>Erkentnisse und Entscheidungen</span>
                    <textarea value={answer.comments} placeholder={"Kommentare ..."} onChange={(e) => setCommentInput(e.target.value)} />
                </div>
            </div>
        </div>
        <div className={"navigation"}>
            <button onClick={() => back()}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button onClick={() => next()}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
        <Dialog className={""} component={<DriverNotes showButtons={false} />} show={showDriverNotes} toogle={() => setShowDriverNotes(false)} />
    </Fragment>
}
