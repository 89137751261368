import { useState } from "react";
import { useEffect } from "react"
import { CircalindApi } from "../../circalind/circalindApi";
import { GeneralContent } from "../../circalind/contentManager/GeneralContent";
import { Question, SubArea } from "../../circalind/dataTypes/generated";
import { useGroupWideSubArea } from "../../utils/Hooks";
import { TableAnswerCell, useAnswer } from "../../circalind/hooks/useAnswer";
import { sendOlert } from "../../olert/Olert";


export const ITCoordination = () => {
    const subArea = useGroupWideSubArea("tools", "tools");
    const [generalContent, setGeneralContent] = useState<Question | null>(null);


    //use a fixed password for it coordination, since it is readable/writeable by both coordinator and it-coordinator
    const password = "it-coordination";

    const [answer, setAnswer, saveAnswer] = useAnswer(generalContent ? generalContent.id : -1, -1, -1, password);

    const onAnswer = (data: TableAnswerCell[]) => {
        setAnswer({ ...answer, cells: data });
        saveAnswer(password);
    }

    useEffect(() => {
        const getContent = async (subArea: SubArea) => {
            const result = await CircalindApi.getContentQuestionByFilterForSubArea({
                subArea: subArea.id,
                notes: false,
                phase: - 1,
                userId: -1,
                order: -1,
            });

            if (result) {
                setGeneralContent(result);
            }
        };

        if (subArea) {
            getContent(subArea);
        }
    }, [subArea]);

    const saveAnswerCallback = async () => {
        const res = await saveAnswer(password);
        if (res) {
            sendOlert("Erfolg", "Antworten erfolgreich gespeichert", "Success");
        } else {
            sendOlert("Fehler", "Fehler beim Speichern der Antworten", "Error");
        }
    };


    return <div>
        <h2>IT-Koordination</h2>
        {generalContent
            ? <GeneralContent
                onUpdate={(d) => setGeneralContent({ ...generalContent, table: d })}
                canEdit={true}
                canReview={false}
                question={generalContent}
                showControls={true}
                onCellAnswerd={onAnswer}
                answers={answer.cells}
                onSaveAnswers={saveAnswerCallback}
            ></GeneralContent>
            : null
        }
    </div>;
}
