
import { ReactElement, useEffect } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { serviceHeaders, useHeaderContext } from "../page/header/Header";
import { AdminOnly } from "../route/allowedUserGroups";
import { SecureRouteCircalind } from "../route/SecureRouteCircalind";
import { AdminOrderOverview } from "./AdminOrderOverview";
import { PriceManangementPage } from "./BusinessPriceTable";

import "./adminOverview.scss";
import { GeneralSettingsPage } from "./GeneralSettingsPage";
import { AdminCustomerOverview } from "./AdminCustomerOverview";
import { AdminCustomer } from "./AdminCustomer";
import { TaxManagementPage } from "./TaxManagementPage";
import { AdminAdminPage } from "./AdminAdminPage";
import { AdminPackageOverview } from "./AdminPackageOverview";

export const AdminOverview = (): ReactElement => {
    const { updateHeader } = useHeaderContext();

    useEffect(() => {
        updateHeader(serviceHeaders);
    }, [updateHeader]);


    return <Routes>
        <Route path={`orders/customer`} element={<SecureRouteCircalind children={<AdminOrderOverview showTypeSelection={false} />} allowedUsers={AdminOnly} />} />
        <Route path={`orders/type`} element={<SecureRouteCircalind children={<AdminOrderOverview showTypeSelection={true} />} allowedUsers={AdminOnly} />} />
        <Route path={`packages/module`} element={<SecureRouteCircalind children={<AdminPackageOverview showTypeSelection={true} />} allowedUsers={AdminOnly} />} />
        <Route path={`packages/customer`} element={<SecureRouteCircalind children={<AdminPackageOverview showTypeSelection={false} />} allowedUsers={AdminOnly} />} />
        <Route path={`customers`} element={<SecureRouteCircalind children={<AdminCustomerOverview />} allowedUsers={AdminOnly} />} />
        <Route path={`customer`} element={<SecureRouteCircalind children={<AdminCustomer />} allowedUsers={AdminOnly} />} />
        <Route path={`prices`} element={<SecureRouteCircalind children={<PriceManangementPage />} allowedUsers={AdminOnly} />} />
        <Route path={`tax`} element={<SecureRouteCircalind children={<TaxManagementPage />} allowedUsers={AdminOnly} />} />
        <Route path={`general`} element={<SecureRouteCircalind children={<GeneralSettingsPage />} allowedUsers={AdminOnly} />} />
        <Route path={`admins`} element={<SecureRouteCircalind children={<AdminAdminPage />} allowedUsers={AdminOnly} />} />
        <Route path={`overview`} element={<SecureRouteCircalind children={
            <div className="admin-overview">
                <h1>Administration</h1>
                <ul>
                    <li><Link to={"/circalind/admin/customers"}>Kundenstammdaten</Link></li>
                    <li><Link to={"/circalind/admin/prices"}>Preise</Link></li>
                    <li><Link to={"/circalind/admin/tax"}>Umsatzsteuer</Link></li>
                    <li><Link to={"/circalind/admin/general"}>Allgemeine Informationen</Link></li>
                    <li><Link to={"/circalind/admin/admins"}>Verwaltung der Administratoren</Link></li>
                    <li><Link to={"/circalind/admin/orders/customer"}>Auswertung der Bestellungen pro Kunde</Link></li>
                    <li><Link to={"/circalind/admin/orders/type"}>Auswertung der Bestellungen pro Kunden-Typ</Link></li>
                    <li><Link to={"/circalind/admin/packages/module"}>Auswertung Modulübersicht</Link></li>
                    <li><Link to={"/circalind/admin/packages/customer"}>Auswertung Laufzeitübersicht</Link></li>
                </ul>
            </div>
        } allowedUsers={AdminOnly} />} />
    </Routes>



}
