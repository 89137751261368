import { useEffect, useState } from "react";
import "./GeneralSettingsPage.scss";
import { SettingsInvoice } from "../dataTypes/generated";
import { CircalindApi } from "../circalindApi";
import { sendOlert } from "../../olert/Olert";
import { useNavigate } from "react-router";

export const GeneralSettingsPage = () => {
    const [invoiceSettings, setInvoiceSettings] = useState<SettingsInvoice | null>(null);

    const navigate = useNavigate();

    const reloadSettings = async () => {
        setInvoiceSettings(await CircalindApi.getInvoiceSettings());
    };

    useEffect(() => {
        reloadSettings();
    }, []);

    const saveSettings = async () => {
        if (invoiceSettings) {
            const result = await CircalindApi.saveInvoiceSettings(invoiceSettings);

            if (result) {
                sendOlert("Erfolg", "Daten erfolgreich gespeichert.", "Success");
            } else {
                sendOlert("Fehler", "Daten konnten nicht gespeichert werden.", "Error");
            }
        }
    };

    return <div className="general-settings">
        {invoiceSettings && <div>
            <h2>Allgemeine Informationen</h2>
            <div className="form-group">
                <label htmlFor="fullName">
                    Voller Name (natürliche Person)
                </label>
                <input id="fullName" value={invoiceSettings.fullName} onChange={(e) => setInvoiceSettings({ ...invoiceSettings, fullName: (e.target as any).value })}></input>
            </div>
            <div className="form-group">
                <label htmlFor="company">
                    Unternehmen
                </label>
                <input id="company" value={invoiceSettings.company} onChange={(e) => setInvoiceSettings({ ...invoiceSettings, company: (e.target as any).value })}></input>
            </div>
            <div className="form-group">
                <label htmlFor="address">
                    Adresse
                </label>
                <input id="address" value={invoiceSettings.address} onChange={(e) => setInvoiceSettings({ ...invoiceSettings, address: (e.target as any).value })}></input>
            </div>
            <div className="form-group">
                <label htmlFor="zipCode">
                    PLZ
                </label>
                <input id="zipCode" value={invoiceSettings.zipCode} onChange={(e) => setInvoiceSettings({ ...invoiceSettings, zipCode: (e.target as any).value })}></input>
            </div>
            <div className="form-group">
                <label htmlFor="city">
                    Stadt
                </label>
                <input id="city" value={invoiceSettings.city} onChange={(e) => setInvoiceSettings({ ...invoiceSettings, city: (e.target as any).value })}></input>
            </div>
            <div className="form-group">
                <label htmlFor="phone">
                    Telefon
                </label>
                <input id="phone" value={invoiceSettings.phone} onChange={(e) => setInvoiceSettings({ ...invoiceSettings, phone: (e.target as any).value })}></input>
            </div>
            <div className="form-group">
                <label htmlFor="email">
                    E-Mail
                </label>
                <input id="email" type="email" value={invoiceSettings.email} onChange={(e) => setInvoiceSettings({ ...invoiceSettings, email: (e.target as any).value })}></input>
            </div>
            <div className="form-group">
                <label htmlFor="bank">
                    Bank
                </label>
                <input id="bank" value={invoiceSettings.bank} onChange={(e) => setInvoiceSettings({ ...invoiceSettings, bank: (e.target as any).value })}></input>
            </div>
            <div className="form-group">
                <label htmlFor="iban">
                    IBAN
                </label>
                <input id="iban" value={invoiceSettings.iban} onChange={(e) => setInvoiceSettings({ ...invoiceSettings, iban: (e.target as any).value })}></input>
            </div>
            <div className="form-group">
                <label htmlFor="bic">
                    BIC
                </label>
                <input id="bic" value={invoiceSettings.bic} onChange={(e) => setInvoiceSettings({ ...invoiceSettings, bic: (e.target as any).value })}></input>
            </div>
            <div className="form-group">
                <label htmlFor="taxNr">
                    Steuernummer
                </label>
                <input id="taxNr" value={invoiceSettings.taxNr} onChange={(e) => setInvoiceSettings({ ...invoiceSettings, taxNr: (e.target as any).value })}></input>
            </div>
            <div className="form-group">
                <label htmlFor="taxId">
                    Umsatzsteuer ID
                </label>
                <input id="taxId" value={invoiceSettings.taxId} onChange={(e) => setInvoiceSettings({ ...invoiceSettings, taxId: (e.target as any).value })}></input>
            </div>
            <div className="form-group">
                <label htmlFor="logo">
                    Logo (nur im .svg-Format)
                </label>
                <input onChange={
                    (e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                            const reader = new FileReader();
                            reader.onload = function (event) {
                                if (event.target?.result) {
                                    setInvoiceSettings({ ...invoiceSettings, logo: event.target.result as string });
                                }
                            };
                            reader.readAsDataURL(file);
                        }
                    }
                } type="file" accept=".svg" id="logo"></input>
                <img src={invoiceSettings.logo} alt="logo"></img>
            </div>
            <div className="form-group">
                <label htmlFor="servicesLogo">
                    Services-Logo (nur im .svg-Format)
                </label>
                <input onChange={
                    (e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                            const reader = new FileReader();
                            reader.onload = function (event) {
                                if (event.target?.result) {
                                    setInvoiceSettings({ ...invoiceSettings, servicesLogo: event.target.result as string });
                                }
                            };
                            reader.readAsDataURL(file);
                        }
                    }
                } type="file" accept=".svg" id="servicesLogo"></input>
                <img src={invoiceSettings.servicesLogo} alt="servicesLogo"></img>
            </div>
            <div className="form-group">
                <label htmlFor="agb">
                    AGBs
                </label>
                <input onChange={
                    (e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                            const reader = new FileReader();
                            reader.onload = function (event) {
                                if (event.target?.result) {
                                    setInvoiceSettings({ ...invoiceSettings, agb: (event.target.result as string).split(",")[1], agbFileName: file.name });
                                }
                            };
                            reader.readAsDataURL(file);
                        }
                    }
                } type="file" id="agb"></input>
            </div>
            <div className="form-group">
                <label htmlFor="privacy">
                    Datenschutzvereinbarung
                </label>
                <input onChange={
                    (e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                            const reader = new FileReader();
                            reader.onload = function (event) {
                                if (event.target?.result) {
                                    setInvoiceSettings({ ...invoiceSettings, privacy: (event.target.result as string).split(",")[1], privacyFileName: file.name });
                                }
                            };
                            reader.readAsDataURL(file);
                        }
                    }
                } type="file" id="privacy"></input>
            </div>
            <div className="form-group">
                <label htmlFor="moduleOverview">
                    Modulübersicht
                </label>
                <input onChange={
                    (e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                            const reader = new FileReader();
                            reader.onload = function (event) {
                                if (event.target?.result) {
                                    setInvoiceSettings({ ...invoiceSettings, moduleOverview: (event.target.result as string).split(",")[1], moduleOverviewFileName: file.name });
                                }
                            };
                            reader.readAsDataURL(file);
                        }
                    }
                } type="file" id="moduleOverview"></input>
            </div>
            <div className="right">
                <button onClick={() => navigate("../overview")}>Zurück</button>
                <button onClick={saveSettings}>Speichern</button>
            </div>
        </div>}
    </div>
};