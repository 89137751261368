export const readFromLocalStorage = (key: string): string | null => {
    return localStorage.getItem(key) || null;
}

export const writeToLocalStorage = (key: string, value: string) => {
    localStorage.setItem(key, value);
}

export const readFromSessionStorage = (key: string): string | null => {
    return sessionStorage.getItem(key) || null;
}

export const writeToSessionStorage = (key: string, value: string) => {
    sessionStorage.setItem(key, value)
}

export const deleteSessionStorage = (key: string) => {
    sessionStorage.removeItem(key);
}

export const deleteLocalStorage = (key: string) => {
    localStorage.removeItem(key);
}

export const showWelcomeDialog = (): boolean => {
    const res = readFromLocalStorage("circaLindStart");
    if (res) {
        return false
    }
    return true;
}

export const setWelcomeDialog = () => {
    writeToLocalStorage("circaLindStart", "true")
}
