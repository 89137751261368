import { ReactElement } from "react";
import Select, { createFilter, SingleValue } from 'react-select';
import { User } from "../../circalind/dataTypes/generated";

interface UserOption {
    value: User,
    label: ReactElement
}

interface UserSelectProps {
    users: User[] | undefined;
    onSelect: (user: User | undefined) => void;
    annotations?: Record<number, ReactElement>;
    valueUserId?: number;
    useName?: boolean;
    disabled?: boolean;
    placeholder?: string;
}

export const UserSelect = (props: UserSelectProps): ReactElement => {
    const getUserOptions = (): UserOption[] => {
        if (props.users) {
            return props.users.map(u => {
                const title = props.useName ? `${u.firstName} ${u.lastName}` : u.mail;
                const annotation = props.annotations?.[u.id];
                return { value: u, label: <span style={{ display: "inline-flex", alignItems: "center" }}><span>{title}</span><span style={{ marginTop: "3px" }}>{annotation}</span></span> }
            });
        }
        return []
    }

    const filterConfig = {
        ignoreCase: true,
        trim: true,
        matchFrom: ('start' as const)
    };

    const onUserSelect = (opt: SingleValue<UserOption> | undefined) => {
        if (opt) {
            props.onSelect(opt.value)
        } else {
            props.onSelect(undefined);
        }
    }

    const getSelectedDefault = (): UserOption | null => {
        if (props.valueUserId) {
            return getUserOptions().find(u => u.value.id === props.valueUserId) || null;
        }
        return null;
    }

    return <div className="add-user">
        <Select placeholder={props.placeholder} isDisabled={props.disabled} value={getSelectedDefault()} isSearchable isClearable={true} options={getUserOptions()} filterOption={createFilter(filterConfig)} onChange={(opt) => onUserSelect(opt)} />
    </div>
}