import { useSelectedProject } from "../../utils/Hooks";
import { PhaseDiagramsCompare } from "../projects/competence/PhaseDiagramsCompare";

export const MatchingProjectComplete = () => {
    const [selectedProject,] = useSelectedProject();

    return <div>
        <h2>Projekt-Erfolgsfelder</h2>
        <PhaseDiagramsCompare projectId={selectedProject} graphForChoice={"PHASE"} leftGraphForChoice={"PROJECT_COMPETENCE"} hidePhases={[2,3,4,5]} />
    </div>
};