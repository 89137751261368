import { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import feedbackImage from "../../../../resources/circa/compass/Feedback2.png";
import gestaltungImage from "../../../../resources/circa/compass/Gestaltung.png";
import wandelImage from "../../../../resources/circa/compass/Wandel.png";

import './postPhase.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useSelectedProject } from "../../../../utils/Hooks";
import { CircalindApi } from "../../../circalindApi";
import { Project } from "../../../dataTypes/generated";

export const PostPhaseSelection = (): ReactElement => {

    const [selectedProject,] = useSelectedProject();
    const [project, setProject] = useState<Project>();
    const navigate = useNavigate();

    useEffect(() => {
        const loadProject = async (id: number) => {
            const p = await CircalindApi.getProject(id);
            if (p) {
                setProject(p);
            }
        }

        loadProject(selectedProject)
    }, [selectedProject]);

    const gotToPostPhase = (nav: string) => {
        if (selectedProject !== -1) {
            navigate("/circalind/regular/postPhase/" + nav)
        }
    }

    const onNext = async () => {
        navigate("/circalind/regular/phase/decide");
    }

    const onBack = async () => {
        navigate(`/circalind/regular/phase/${project?.phase}?index=-1`);
    }

    return <div className="post-phase-selection">
        {selectedProject === -1 ? <span className="warning">Bitte wählen Sie ein Projekt aus</span> : null}
        <div className="post-phases">
            <div className="post-phase-card">
                <h2>Plausibilitäts-Check</h2>
                <span></span>
                <div className="round">
                    <img src={feedbackImage} alt="Feedback" />
                </div>
                <button disabled={selectedProject === -1} onClick={() => gotToPostPhase("feedback")}><FontAwesomeIcon icon={faArrowRight} /></button>
            </div>
            <div className="post-phase-card">
                <h2>Wechselwirkungs-Check</h2>
                <span></span>
                <img className="round" src={wandelImage} alt="Wandel" />
                <button disabled={selectedProject === -1} onClick={() => gotToPostPhase("change")}><FontAwesomeIcon icon={faArrowRight} /></button>
            </div>
            <div className="post-phase-card">
                <h2>Wirksamkeits-Hebel-Check</h2>
                <span></span>
                <img className="round" src={gestaltungImage} alt="Gestaltungsfelder" />
                <button disabled={selectedProject === -1} onClick={() => gotToPostPhase("layout")}><FontAwesomeIcon icon={faArrowRight} /></button>
            </div>
        </div>
        <div className={"navigation"}>
            <button onClick={() => onBack()}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button onClick={() => onNext()}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>
}
