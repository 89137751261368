import { ReactElement, useEffect, useState } from "react"
import { useLocation } from "react-router"
import lindnerTeamLogo from '../../../resources/circa/lindnerteam.png'

import './footer.scss'

export const Footer = (): ReactElement => {
    const loc = useLocation();
    const [logo, setLogo] = useState<string>();

    useEffect(() => {
        const loadLogo = async () => {
            if (loc.pathname.includes("/admin") || loc.pathname.includes("/services")) {
                setLogo(await (await fetch(process.env.REACT_APP_API_URL + "/invoice/settings/logo/services/get")).text());
            } else {
                setLogo(await (await fetch(process.env.REACT_APP_API_URL + "/invoice/settings/logo/get")).text());
            }
        };

        loadLogo();
    }, [loc.pathname]);

    const renderBands = () => {
        const noLocations = [
            "/circalind/regular/competence",
            "/circalind/regular/communication",
            "/circalind/regular/phase",
            "/circalind/regular/prePhase",
            "/circalind/regular/postPhase",
            "/circalind/regular/dashboard",
        ];

        if (noLocations.find(l => loc.pathname.startsWith(l))) {
            return undefined;
        }

        const greyLocations = [
            "/circalind/regular/governance",
            "/circalind/regular/practical",
            "/circalind/leading/documentation",
            "/circalind/leading/quality",
            "/circalind/leading/staffing/users/new",
            "/circalind/leading/staffing/users",
            "/circalind/leading/staffing/projects",
            "/circalind/leading/staffing/tools",
            "/circalind/leading/staffing/options",
            "/circalind/leading/staffing/transfer",
            "/circalind/leading/toolbox",
            "/circalind/leading/content",
        ];

        const blueLocations = [
            "/circalind/regular/impulse",
            "/circalind/leading/support"
        ];

        const getColor = (lineIndex: number) => {
            if (greyLocations.find(l => loc.pathname.startsWith(l)) && !loc.pathname.endsWith("/start")) {
                return "#808080";
            } else if (blueLocations.find(l => loc.pathname.startsWith(l))) {
                return "#175583";
            } else {
                if (lineIndex === 0) {
                    return "#A1C74A";
                } else if (lineIndex === 1) {
                    return "#70BDE5";
                } else {
                    return "#175583";
                }
            }
        };

        return <div className="bands">
            <svg className="footer-bands">
                <filter id="shadow1" x="0" y="0" width="200%" height="200%">
                    <feDropShadow dx="5" dy="5" stdDeviation="10" floodColor="#A1C74A" floodOpacity="1" />
                </filter>
                <filter id="shadow2" x="0" y="0" width="200%" height="200%">
                    <feDropShadow dx="5" dy="5" stdDeviation="10" floodColor="#70BDE5" floodOpacity="1" />
                </filter>
                <filter id="shadow3" x="0" y="0" width="200%" height="200%">
                    <feDropShadow dx="5" dy="5" stdDeviation="10" floodColor="#175583" floodOpacity="1" />
                </filter>
                <g>
                    <rect x="60%" y="0" width="40%" height="6" rx="10" ry="10" fill={getColor(0)} filter="url(#shadow1)" />
                    <rect x="40%" y="15" width="40%" height="6" rx="10" ry="10" fill={getColor(1)} filter="url(#shadow2)" />
                    <rect x="20%" y="30" width="40%" height="6" rx="10" ry="10" fill={getColor(2)} filter="url(#shadow3)" />
                </g>
            </svg>
        </div>
    }

    const renderLinderLogo = () => {
        if (loc.pathname === "/" || loc.pathname.match('/circalind/(dataprivacy|impressum|information)')) {
            return <a href="https://lindnerteam.de"><img className="lindner-team-logo" src={lindnerTeamLogo} alt="Lindnerteam" /></a>
        } else
            return null
    }

    return <footer>
        <div className="circalind-footer">
            <img src={logo} alt="logo" />
            {/*renderBackgroundImage()*/}
            {renderLinderLogo()}
            {renderBands()}
        </div>
        <div className="imprint-data">
            <a href="https://lindnerteam.de">Doris Lindner</a>
            <a className="link" href="/circalind/dataprivacy">Datenschutz</a>
            <a className="link" href="/circalind/impressum">Impressum</a>
        </div>
    </footer>

}
