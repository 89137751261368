import { useEffect, useState } from "react";
import { Tax } from "../dataTypes/generated";
import { CircalindApi } from "../circalindApi";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { sendOlert } from "../../olert/Olert";

import "./TaxManagementPage.scss";

export const TaxManagementPage = () => {
    const [taxes, setTaxes] = useState<Array<Tax>>([]);
    const [date, setDate] = useState<string>(new Date().toISOString().split('T')[0]);
    const [percentage, setPercentage] = useState<number>(19);
    const loadTaxes = async () => {
        setTaxes(await CircalindApi.getTaxes());
    };

    useEffect(() => {
        loadTaxes();
    }, []);

    const saveTax = async () => {
        if (percentage < 0) {
            sendOlert("Ungültiger Wert", "Steuer muss >= 0% sein.", "Error");
            return;
        }

        if (taxes.length > 0 && taxes[taxes.length - 1].date >= date) {
            sendOlert("Ungültiger Wert", "Neues Datum muss nach allen bisherigen liegen.", "Error");
            return;
        }

        const response = await CircalindApi.addTax({id: -1, date, percentage});

        if (response) {
            sendOlert("Operation erfolgreich", "Erfolgreich gespeichert.", "Success");
        } else {
            sendOlert("Operation fehlgeschlagen", "Steuern konnten nicht gespeichert werden", "Error");
        }

        loadTaxes();
    };

    const deleteTax = async (id: number) => {
        const response = await CircalindApi.deleteTax(id);

        if (response) {
            sendOlert("Operation erfolgreich", "Erfolgreich gelöscht.", "Success");
        } else {
            sendOlert("Operation fehlgeschlagen", "Steuern konnten nicht gelöscht werden", "Error");
        }

        loadTaxes();
    };

    const navigate = useNavigate();

    return <div className="taxes">
            <h2>Umsatzsteuer</h2>
            <div className="tax-row">
                <label>Gilt ab:</label>
                <label>USt. in %</label>
            </div>
            {taxes.map((tax, index) => {
            return <div key={tax.id} className="tax-row">
               <input disabled readOnly={true} value={tax.date} type="date"></input>
               <input disabled readOnly={true} value={tax.percentage} type="number"></input>
               %
               <button onClick={() => deleteTax(tax.id)} disabled={index === 0}><FontAwesomeIcon icon={faTrashAlt} /></button>
            </div>
            })}
            <div>Neu</div>
            <div className="tax-row">
               <input onChange={(e) => setDate(e.target.value)} value={date} type="date"></input>
               <input min="0" onChange={(e) => setPercentage(Math.floor(+e.target.value))} value={percentage} type="number"></input>
               %
               <button onClick={saveTax}>Hinzufügen</button>
            </div>
            <div className="right">
                <button onClick={() => navigate("../overview")}>Zurück</button>
            </div>
        </div>
};