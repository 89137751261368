import bestPracticeImage from "../../resources/circa/documentation/best-practice.png";
import myCollectionImage from "../../resources/circa/documentation/my-collection.png";
import projectHistoryImage from "../../resources/circa/documentation/project-history.png";
import circalindPersonalImage from "../../resources/circa/personal_project.svg";
import circalindLeadershipImage from "../../resources/circa/leadership_teamwork.svg";
import strategyImage_back from "../../resources/circa/strategy_project.svg"

import "./RegularDocumentation.scss";
import { useNavigate } from "react-router";

export const RegularDocumentation = () => {
    const navigate = useNavigate();

    return <div className="regular-documentation">
        <div className="card content-card">
            <h3>Best-Practise</h3>
            <div onClick={() => { navigate("../practises") }} className="image-wrapper card clickable">
                <img src={bestPracticeImage} alt="" />
            </div>
            <div></div>
        </div>
        <div className="card content-card">
            <h3>Präsentations-Werkstatt</h3>
            <div onClick={() => { navigate("../presentations") }} className="image-wrapper card clickable">
                <img src={circalindPersonalImage} alt="" />
                <img src={circalindLeadershipImage} alt="" />
                <img src={strategyImage_back} alt="" />
            </div>
            <div></div>
        </div>
        <div className="card content-card">
            <h3>Meine Sammlung</h3>
            <div onClick={() => { navigate("../collection") }} className="image-wrapper card clickable">
                <img src={myCollectionImage} alt="" />
            </div>
            <div></div>
        </div>
        <div className="card content-card">
            <h3>Projekt-Historie</h3>
            <div onClick={() => { navigate("/circalind/regular/documentation/history") }} className="image-wrapper card clickable">
                <img src={projectHistoryImage} alt="" />
            </div>
            <div></div>
        </div>
    </div>;
}