import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useEffect, useState } from "react";
import { getCurrentUserId } from "../../../utils/Auth";
import { decrypt } from "../../../utils/Encryption";
import { Dialog } from "../../basicComponents/dialogs/Dialog";
import { CircalindApi } from "../../circalindApi";
import { Answer, GraphImage, SubArea } from "../../dataTypes/generated";
import { QuestionEndAnswer } from "../../hooks/useEndAnswer";
import { DriverNotes } from "./DriverNotes";

import './phaseRadar.scss'
import { useSelectedProject } from "../../../utils/Hooks";
import { usePassword } from "../../hooks/usePassword";

export interface PhaseRadarprops {
    projectId: number;
    onNext?: () => Promise<void>;
    onBack?: () => Promise<void>;
}

export const PhaseRadar = (props: PhaseRadarprops): ReactElement => {

    const [selectedProject,] = useSelectedProject();
    const [answers, setAnswers] = useState<QuestionEndAnswer[]>([]);
    const [rawAnswers, setRawAnswers] = useState<(Answer | null)[]>([]);
    const [images, setImages] = useState<(GraphImage | null)[]>([])
    const [practicalSubAreas, setPracticalSubareas] = useState<Array<SubArea>>([]);
    const [showDriverNotes, setShowDriverNotes] = useState<boolean>(false);

    const password = usePassword();

    useEffect(() => {
        const getSubareaData = async () => {
            const response = await CircalindApi.getSubAreas({
                area: "practical", targetProjectId: selectedProject, targetInternalGroupId: null, targetUserId: null,
                groupId: -1
            })

            setPracticalSubareas(response);
        };

        getSubareaData();
    }, [selectedProject]);

    useEffect(() => {
        //decrypt questions here
        let res: QuestionEndAnswer[] = [];
        rawAnswers.forEach(r => {
            if (r && password) {
                try {
                    res.push(JSON.parse(decrypt(r?.answer, password)));
                } catch {
                    res.push({ comments: "", driver: "", title: "", graphData: [] });
                }
            } else {
                res.push({ comments: "", driver: "", title: "", graphData: [] });
            }
        })
        setAnswers(res);
    }, [password, rawAnswers])

    useEffect(() => {
        const userId = getCurrentUserId("token-circalind");
        const loadData = async () => {
            const phase1 = await CircalindApi.getEndAnswer(1, props.projectId, true);
            const phase2 = await CircalindApi.getEndAnswer(2, props.projectId, true);
            const phase3 = await CircalindApi.getEndAnswer(3, props.projectId, true);
            const phase4 = await CircalindApi.getEndAnswer(4, props.projectId, true);
            const phase5 = await CircalindApi.getEndAnswer(5, props.projectId, true);
            if (userId) {
                const img1 = await CircalindApi.getGraphImage(userId, props.projectId, userId, 1, "", "PHASE", true);
                const img2 = await CircalindApi.getGraphImage(userId, props.projectId, userId, 2, "", "PHASE", true);
                const img3 = await CircalindApi.getGraphImage(userId, props.projectId, userId, 3, "", "PHASE", true);
                const img4 = await CircalindApi.getGraphImage(userId, props.projectId, userId, 4, "", "PHASE", true);
                const img5 = await CircalindApi.getGraphImage(userId, props.projectId, userId, 5, "", "PHASE", true);
                setImages([img1, img2, img3, img4, img5]);
            }
            setRawAnswers([phase1, phase2, phase3, phase4, phase5]);
        }
        loadData();
    }, [props.projectId])

    const getColumnTitleForPhase = (phase: number): string => {
        switch (phase) {
            case 1: return "Anliegen - Ziel formulieren"
            case 2: return "Lage beschreiben"
            case 3: return "Lage bewerten"
            case 4: return "Entscheidungen treffen"
            case 5: return "Vorhaben umsetzen"
            default: return "";
        }
    }

    const renderForPhase = (phase: number) => {
        const a = answers[phase - 1];
        const im = images[phase - 1];
        return <div className="driver">
            <span className="title">{getColumnTitleForPhase(phase)}</span>
            <textarea className="text" value={a ? a.driver : ""} readOnly />
            <span>Einschätzung: </span>
            <div className={"img-container" + (im === null ? " empty" : "")}>
                {im !== null ? <img className="image" src={im ? im.base64 : ""} alt="Kein-Bild" /> : <div className="empty-img" />}
            </div>
            <span>Erkenntnisse:</span>
            <textarea className="text" value={a ? a.comments : ""} readOnly />
        </div>
    }

    const renderNavigationButtons = () => {
        if (props.onNext && props.onBack)
            return <>
                <button onClick={() => props.onBack!()}><FontAwesomeIcon icon={faArrowLeft} /></button>
                <button onClick={() => props.onNext!()}><FontAwesomeIcon icon={faArrowRight} /></button>
            </>
    }

    const renderDrivers = () => {
        return practicalSubAreas.map(sa => <img key={sa.id} onClick={() => setShowDriverNotes(true)} src={sa.image} alt={sa.name} />);
    };

    return <div className="phase-radar">
        <div className="radar">
            <div className="drivers">
                <h2>Notizen</h2>
                <div className="select">
                    <div className="image-select">
                        {renderDrivers()}
                    </div>
                </div>
            </div>
            <div className="driver-phases">
                {renderForPhase(1)}
                {renderForPhase(2)}
                {renderForPhase(3)}
                {renderForPhase(4)}
                {renderForPhase(5)}
            </div>
        </div>
        <div className={"navigation"}>
            {renderNavigationButtons()}
        </div>
        <Dialog className={""} component={<DriverNotes showButtons={false} />} show={showDriverNotes} toogle={() => setShowDriverNotes(false)} />
    </div>
}

export const CurrentProjectRadar = () => {
    const [selectedProject,] = useSelectedProject();
    return <PhaseRadar projectId={selectedProject}></PhaseRadar>
};
