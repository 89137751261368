import { useState } from "react";
import { Dialog } from "../basicComponents/dialogs/Dialog";
import { SendMessage } from "./SendMessage";

import receiveFeedbackImage from "../../resources/circa/feedback_receive.png";
import giveFeedbackImage from "../../resources/circa/feedback_give.png";
import receiveKnowledgeImage from "../../resources/circa/wissen_blau.png";
import giveKnowledgeImage from "../../resources/circa/wissen_gruen.png";
import interactImage from "../../resources/circa/interact.png";

import "./InteractionOverview.scss";
import { useNavigate } from "react-router";

export enum SendMessageType {
    GIVE_FEEDBACK,
    RECEIVE_FEEDBACK,
    GIVE_KNOWLEDGE_TRANSFER,
    RECEIVE_KNOWLEDGE_TRANSFER
}

export const communicationTitles: Record<SendMessageType, string> = {
    [SendMessageType.GIVE_FEEDBACK]: "Feedback anfragen",
    [SendMessageType.RECEIVE_FEEDBACK]: "Wissenstransfer anfragen",
    [SendMessageType.GIVE_KNOWLEDGE_TRANSFER]: "Feedback geben",
    [SendMessageType.RECEIVE_KNOWLEDGE_TRANSFER]: "Wissenstransfer senden"
};

export const InteractionOverview = () => {
    const navigate = useNavigate();

    return <div className="interaction-overview">
        <div />
        <div className="card">
            <h2>Meinung und Feedback einholen</h2>
            <div onClick={() => { navigate("./polls") }} className="card with-image full-width clickable">
                <img src={interactImage} alt="" />
            </div>
        </div>
        <CommunicationRoomCard />
    </div>;
};

const CommunicationRoomCard = () => {
    const [messageDialog, setMessageDialog] = useState<SendMessageType | null>(null);
    return <div className="card">
        <h2>Anfragen und Senden</h2>
        <div className="card-grid">
            <div onClick={() => setMessageDialog(SendMessageType.RECEIVE_FEEDBACK)} className="card with-image clickable">
                <img src={giveKnowledgeImage} alt={communicationTitles[SendMessageType.RECEIVE_FEEDBACK]} />
                <div className="text-center">{communicationTitles[SendMessageType.RECEIVE_FEEDBACK]}</div>
            </div>
            <div onClick={() => setMessageDialog(SendMessageType.RECEIVE_KNOWLEDGE_TRANSFER)} className="card with-image clickable">
                <img src={receiveKnowledgeImage} alt={communicationTitles[SendMessageType.RECEIVE_KNOWLEDGE_TRANSFER]} />
                <div className="text-center">{communicationTitles[SendMessageType.RECEIVE_KNOWLEDGE_TRANSFER]}</div>
            </div>
            <div onClick={() => setMessageDialog(SendMessageType.GIVE_FEEDBACK)} className="card with-image clickable">
                <img src={giveFeedbackImage} alt={communicationTitles[SendMessageType.GIVE_FEEDBACK]} />
                <div className="text-center">{communicationTitles[SendMessageType.GIVE_FEEDBACK]}</div>
            </div>
            <div onClick={() => setMessageDialog(SendMessageType.GIVE_KNOWLEDGE_TRANSFER)} className="card with-image clickable">
                <img src={receiveFeedbackImage} alt={communicationTitles[SendMessageType.GIVE_KNOWLEDGE_TRANSFER]} />
                <div className="text-center">{communicationTitles[SendMessageType.GIVE_KNOWLEDGE_TRANSFER]}</div>
            </div>
        </div>
        <Dialog className="" show={messageDialog != null} toogle={() => setMessageDialog(null)} component={<SendMessage user={undefined} type={messageDialog} />} />
    </div>;
}