import { faArrowLeft, faArrowRight, faEye, faEyeSlash, faPenFancy, faPlusCircle, faSave, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, ReactElement, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { GraphType, Phase as P, Project, Question as Q } from "../../dataTypes/generated";
import { Question } from "../../questions/Question";
import { CircalindApi } from "../../circalindApi";

import './phase.scss'
import "./PhaseInterjection.scss";

import phase1Image from '../../../resources/circa/phase1_placeholder.png'
import phase2Image from '../../../resources/circa/phase2_placeholder.png'
import phase3Image from '../../../resources/circa/phase3_placeholder.png'
import phase4Image from '../../../resources/circa/phase4_placeholder.png'
import phase5Image from '../../../resources/circa/phase5_placeholder.png'
import resumeImage from '../../../resources/circa/resume.png'
import { Phases } from "../../../utils/CircaUtils";
import { Menu, MenuButton, MenuDivider, MenuItem } from "@szhsin/react-menu";
import { PhaseEnd } from "./PhaseEnd";
import { PhaseHeader } from "./PhaseHeader";
import { PhaseRadar } from "./PhaseRadar";
import { PhasePracticalCheck } from "./PhasePracticalCheck";
import { PhaseInterjection } from "./PhaseInterjection";
import { useSelectedProject } from "../../../utils/Hooks";
import { refToHtml } from "../../../invoice/utils/Util";
import { CompetencePage } from "./CompetencePage";
import { CompareSynchronimeters } from "./prePhase/synchro/CompareSynchronimeters";
import { PrePhaseSelection } from "./prePhase/PrePhaseSelection";

export interface PhaseProps {
    editMode?: boolean;
}

export const Phase = (props: PhaseProps): ReactElement => {
    const [selectedProject,] = useSelectedProject();
    const { phaseID } = useParams<{ phaseID: string }>();
    const navigate = useNavigate();
    const [phaseIdNumber, setPhaseIdNumber] = useState(phaseID ? +phaseID : 1);
    const [projectId, setProjectId] = useState<number>(-1);

    const getFromQuery = () => {
        const ind = currentQueryParameters.get("index");
        return ind !== null ? +ind : 0;
    }

    const [project, setProject] = useState<Project>();
    const [phase, setPhase] = useState<P>()
    const [currentQueryParameters, setSearchParams] = useSearchParams();
    const [index, setIndex] = useState<number>(getFromQuery());
    const [questions, setQuestions] = useState<Q[]>([])
    const [edit, setEdit] = useState<boolean>(!!props.editMode);
    const [groupIdOfCurrentAdmin, setGroupId] = useState<number>(-1);

    const myRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const newQueryParameters: URLSearchParams = new URLSearchParams();
        newQueryParameters.set("index", "" + index);
        setSearchParams(newQueryParameters);
    }, [index, setSearchParams]);

    useEffect(() => {
        setProjectId(selectedProject);
    }, [selectedProject]);

    useEffect(() => {
        if (questions.length > 0) {
            if (index < 0) {
                setIndex(questions.length + 9 + index);
            }
        }
    }, [questions, index]);

    useEffect(() => {
        const loadProject = async (id: number) => {
            const p = await CircalindApi.getProject(id);
            if (p) {
                setProject(p);
            }
        }
        const setGroup = async () => {
            const groupId = await CircalindApi.getGroupImAdministrating();
            setGroupId(groupId);
        }
        if (props.editMode === undefined || props.editMode === false) {
            loadProject(projectId);
        } else {
            setGroup()
        }
    }, [projectId, props.editMode])

    useEffect(() => {
        const phases: P[] = [
            { id: 1, kind: "Phase", name: Phases.PHASE_1 },
            { id: 2, kind: "Phase", name: Phases.PHASE_2 },
            { id: 3, kind: "Phase", name: Phases.PHASE_3 },
            { id: 4, kind: "Phase", name: Phases.PHASE_4 },
            { id: 5, kind: "Phase", name: Phases.PHASE_5 },
        ];

        const getPhaseForID = () => {
            if (phaseIdNumber <= 5) {
                const res = phases.find(p => p.id === phaseIdNumber);
                if (res) {
                    return res;
                }
            }
        }

        const getQuestions = async () => {
            const res = await CircalindApi.getQuestonsForPhase(phaseIdNumber);
            setQuestions(res);
        }
        setPhase(getPhaseForID());
        getQuestions();
    }, [phaseIdNumber])


    const updateQuestion = (q: Q, index: number) => {
        let newQuestions = [...questions];
        newQuestions.splice(index, 1, q);
        setQuestions(newQuestions);
    }

    const createEmptyQuestion = (): Q => {
        return {
            compass: "NONE",
            graph: { description: "", title: "", type: "NONE", values: [] },
            id: -1,
            subArea: 1,
            notes: false,
            kind: "Question",
            order: -1,
            phase: phaseIdNumber,
            group: groupIdOfCurrentAdmin,
            title: "",
            editedOn: "",
            owner: -1,
            reviewedBy: -1,
            reviewedOn: "",
            version: "",
            table: { cols: 1, rows: 1, tableCells: [], title: "", style: null }
        }
    }

    const addNewQuestionAtIndex = (index: number) => {
        let newQestions = [...questions];
        newQestions.splice(index, 0, createEmptyQuestion())
        newQestions.forEach((q, i) => q.order = i);
        setQuestions(newQestions);
    }

    const deleteCurrentQuestion = () => {
        if (index >= 1) {
            let newQuestions = [...questions];
            newQuestions.splice(index - 1, 1);
            setQuestions(newQuestions);
            setIndex(index - 1);
        }
    }

    const saveAllQuestions = async () => {
        if (questions.length > 0) {
            let res = await CircalindApi.saveQuestionsForPhase(questions, phaseIdNumber);
            if (res === true) {
                let newQuestions = await CircalindApi.getQuestonsForPhase(phaseIdNumber);
                setQuestions(newQuestions);
            }
        }
    }

    const getImageForPhase = () => {
        switch (phaseIdNumber) {
            case 1: return phase1Image;
            case 2: return phase2Image;
            case 3: return phase3Image;
            case 4: return phase4Image;
            case 5: return phase5Image;
            default: return "error";
        }
    }

    const renderTitle = () => {
        return <Fragment>
            <div className="phase">
                <div className="phase-introduction">
                    <h2>{"Phase " + phase?.id}</h2>
                    <div className="round-image">
                        <img src={getImageForPhase()} alt="error" />
                    </div>
                    <span>{phase?.name}</span>
                </div>
            </div>
            <div className={"navigation" + (props.editMode ? " edit" : "")}>
                <button onClick={() => back()}><FontAwesomeIcon icon={faArrowLeft} /></button>
                <button onClick={() => next()}><FontAwesomeIcon icon={faArrowRight} /></button>
            </div>
        </Fragment>
    }

    const renderPhase = () => {
        if (index === 0) {
            return renderTitle();
        } else if (index === questions.length + 8) {
            return <Fragment>
                <PrePhaseSelection onBack={back} onNext={next} />
            </Fragment>
        } else if (index === questions.length + 7) {
            return <Fragment>
                <ResumeInterjection onBack={back} onNext={next} />
            </Fragment>
        } else if (index === questions.length + 6) {
            return <Fragment>
                <CompareSynchronimeters onPrev={back} onNext={next} />
            </Fragment>
        } else if (index === questions.length + 5) {
            return <Fragment>
                <CompetencePage onBack={back} onNext={next} />
            </Fragment>
        } else if (index === questions.length + 4) {
            return <Fragment>
                <PhasePracticalCheck onBack={back} onNext={next} />
            </Fragment>
        } else if (index === questions.length + 3) {
            return <Fragment>
                <PhaseRadar projectId={projectId} onBack={back} onNext={next} />
            </Fragment>
        } else if (index === questions.length + 2) {
            return <PhaseInterjection onBack={back} onNext={next} />
        } else if (index === questions.length + 1) {
            if (!edit) {
                return <Fragment>
                    <PhaseEnd phase={phaseIdNumber} projectId={projectId} onBack={back} onNext={next} />
                </Fragment>
            } else {
                return <Fragment>
                    {questions.length > 0 && <Question preventUnsetProjectId={true} question={questions[index - 1]} projectId={projectId} index={index - 1} editMode={edit} updateQuestion={updateQuestion} onBack={back} onNext={next} />}
                </Fragment>
            }
        } else if (index >= 0 && index <= questions.length) {
            return <Fragment>
                <Question preventUnsetProjectId={true} question={questions[index - 1]} projectId={projectId} index={index - 1} editMode={edit} updateQuestion={updateQuestion} onBack={back} onNext={next} />
            </Fragment>
        } else {
            return <Fragment>
            </Fragment>
        }
    }

    const next = async () => {
        if (props.editMode === true) {
            if (index < questions.length) {
                setIndex(index + 1);
            }
        } else {
            if (myRef.current) {
                const excludedIndices: Array<number> = [questions.length + 2, questions.length + 4, questions.length + 5, questions.length + 7, questions.length + 8];
                if (project && !excludedIndices.includes(index)) {
                    const html = await refToHtml(myRef);
                    const type = index === questions.length + 6 ? "synchro" : "";
                    await CircalindApi.saveToWay({ projectId: project.id, phase: project.phase, index: index, type: type, html: html });
                }
            }

            if (project && project.compact && index === 2) {
                setIndex(questions.length + 1);
                return;
            }

            if (project && project.compact && index === questions.length + 6) {
                navigate("/circalind/regular/phase/decide");
            }

            if (index === questions.length + 8) {
                navigate("/circalind/regular/postPhase");
            } else {
                setIndex(index + 1);
                return;
            }
        }
    }

    const back = async () => {
        if (index === 0) {
            if (props.editMode !== true) {
                if (project) {
                    let updatedProject: Project = ({ ...project, phase: Math.max(0, project.phase - 1) })
                    const res = await CircalindApi.setProjectPhase(updatedProject);
                    if (res) {
                        window.location.href = "/circalind/regular/phase/decide";
                    }
                }
            } 
        } else if (project && project.compact && index === questions.length + 1) {
            setIndex(2);
        } else {
            setIndex(index - 1)
        }
    }

    const renderEditMenu = () => {
        if (props.editMode) {
            return <div className="edit-toolbar">
                <Menu className="compass-edit" menuButton={<MenuButton><FontAwesomeIcon icon={faPenFancy} /></MenuButton>}>
                    <MenuItem onClick={() => addNewQuestionAtIndex(index)}><FontAwesomeIcon icon={faPlusCircle} />&nbsp;Neue Frage einfügen</MenuItem>
                    <MenuDivider />
                    <MenuItem onClick={() => deleteCurrentQuestion()}><FontAwesomeIcon icon={faTrashAlt} />&nbsp;Frage Löschen</MenuItem>
                    <MenuItem onClick={() => saveAllQuestions()}><FontAwesomeIcon icon={faSave} />&nbsp;Speichern</MenuItem>
                    <MenuDivider />
                    <MenuItem onClick={() => setEdit(!edit)}><FontAwesomeIcon icon={edit ? faEye : faEyeSlash} />&nbsp;{edit ? "Vorschau" : "Editieren"}</MenuItem>
                </Menu>
            </div>
        }
    }

    const getPhaseUpdateCallback = () => {
        if (edit) {
            return (phase: number) => {
                setPhaseIdNumber(phase);
            };
        }
    }

    return <Fragment>
        <PhaseHeader updatePhase={getPhaseUpdateCallback()} current={phaseIdNumber} />
        <div ref={myRef} >
            {renderPhase()}
        </div>
        {renderEditMenu()}
    </Fragment>
}

const ResumeInterjection = ({ onNext, onBack }: { onNext: () => Promise<void>, onBack: () => Promise<void> }) => {
    return <div className="phase-interjection">
        <h1><strong>Resümee</strong></h1>
        <div className="img-container">
            <img src={resumeImage} alt="Resümee" />
        </div>
        <div className="navigation">
            <button onClick={() => onBack!()}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button onClick={() => onNext!()}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>;
};