import { useEffect, useState } from "react";
import { CircalindApi } from "../circalindApi";
import { AreaAndRoleInfo, CircaPackage, LicenseMetaData, Project, RoleType, TotalLicenses, User } from "../dataTypes/generated";
import { DetailAction, ProjectsDetailsView } from "../projects/ProjectsDetailsView";
import { getCurrentUserId } from "../../utils/Auth";
import { Dialog } from "../basicComponents/dialogs/Dialog";
import { EditStaffingProject, strategicRoles } from "../../leading/dialogs/EditStaffingProject";

import practicalImage from "../../resources/circa/practical.svg";
import circalindLeadershipImage from "../../resources/circa/leadership_teamwork.svg";

import "./ProjectCommunications.scss";
import { AvailableRoleDetails } from "../services/Contract";
import { serviceHeaders, useHeaderContext } from "../page/header/Header";

export const ProjectCommunications = () => {
    const [projects, setProjects] = useState<Array<Project>>([]);
    const [users, setUsers] = useState<Array<User>>([]);
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo>();
    const [showAssistanceDialog, setShowAssistanceDialog] = useState<boolean>(false);
    const [showCoManagerDialog, setShowCoManagerDialog] = useState<boolean>(false);
    const [selectedProject, setSelectedProject] = useState<Project>();
    const [licenseMetaData, setLicenseMetaData] = useState<LicenseMetaData | null>();
    const [license, setLicense] = useState<CircaPackage | null>();
    const [totalLicenses, setTotalLicenes] = useState<TotalLicenses | null>();

    const headers = useHeaderContext();
    headers.updateHeader(serviceHeaders);

    const getUsers = async () => {
        setUsers(await CircalindApi.getAllUsersOfMyGroup());
    }

    const getProjects = async () => {
        setProjects((await CircalindApi.getProjects()).filter(p => p.ownerId === getCurrentUserId("token-circalind")));
    };

    const getAreaAndRoleInfo = async () => {
        setAreaAndRoleInfo(await CircalindApi.getAreaAndRoleInfo());
    };

    const getMetaData = async () => {
        setLicenseMetaData(await CircalindApi.getLicenseMetaData());
        setLicense(await CircalindApi.getLicense());
        setTotalLicenes(await CircalindApi.getTotalLicenses());
    };

    const actions: Array<DetailAction> = [
        { image: practicalImage, tooltip: "Co-Manager", action: async (project: Project) => { setSelectedProject(project); setShowCoManagerDialog(true) } },
        { image: circalindLeadershipImage, tooltip: "Assistenz", action: async (project: Project) => { setSelectedProject(project); setShowAssistanceDialog(true) } },
    ];

    useEffect(() => {
        getProjects();
        getAreaAndRoleInfo();
        getMetaData();
        getUsers();
    }, []);

    return <div>
        <ProjectsDetailsView heading="Projektassistenz" actionHeading="Assistenzsystem nutzen" projects={projects} actions={actions} />
        {totalLicenses && areaAndRoleInfo && licenseMetaData && license && <Dialog className={"assistance-dialog"} component={<AssistanceDialog totalLicenses={totalLicenses} areaAndRoleInfo={areaAndRoleInfo} selectedProject={selectedProject} licenseMetaData={licenseMetaData} license={license} />} show={showAssistanceDialog} toogle={() => setShowAssistanceDialog(false)} />}
        <Dialog className="editStaffing" show={showCoManagerDialog} toogle={() => setShowCoManagerDialog(false)} component={<EditStaffingProject showAllDeciders={false} showData={false} showRoles={true} selected={selectedProject} users={users} onUpdate={() => window.location.reload()} onCancel={() => setShowCoManagerDialog(false)} licenseType={license?.type || null} />} />
    </div>
};


const AssistanceDialog = ({ selectedProject, areaAndRoleInfo, licenseMetaData, license, totalLicenses }: { selectedProject?: Project, areaAndRoleInfo: AreaAndRoleInfo, licenseMetaData: LicenseMetaData, license: CircaPackage, totalLicenses: TotalLicenses }) => {
    const [roleAmounts, setRoleAmounts] = useState<Record<RoleType, number>>(
        strategicRoles.reduce((acc: any, val) => {
            acc[val] = 0;
            return acc;
        }, {}) as any
    );

    const sendEmail = () => {
        if (selectedProject) {
            const coordinator = selectedProject?.members.find(m => m.role === "COORDINATOR");
            const assistance = Object.keys(roleAmounts).filter(r => roleAmounts[r as RoleType] > 0).map(r => `${areaAndRoleInfo?.roleTitles[r as RoleType]}: ${roleAmounts[r as RoleType]} mal`).join(", ");
            if (coordinator) {
                const template = `Hallo ${coordinator.user.firstName} ${coordinator.user.lastName},\n\nfür das Projekt Nr. ${selectedProject.id} erfrage ich folgende Assistenzen ${assistance}.`;
                const subject = encodeURIComponent(`Anforderung Assistenz in Projekt Nr. ${selectedProject.id}`);
                const body = encodeURIComponent(template);
                (window as any).location = `mailto:${coordinator.user.mail}?subject=${subject}&body=${body}`;
            }
        }
    };


    return <div>
        <div className="two-columns">
            <AvailableRoleDetails totalLicenses={totalLicenses} licenseMetaData={licenseMetaData} license={license} areaAndRoleInfo={areaAndRoleInfo} users={[]} />
            <div>
                <div className="dialog-row">
                    <h3>Rolle</h3>
                    <h3>Anzahl Rolle</h3>
                </div>
                {strategicRoles.map(r => {
                    return <div className="dialog-row" key={r}><label>{areaAndRoleInfo?.roleTitles[r]}</label><input type="number" min="0" value={roleAmounts[r]} onChange={(e) => setRoleAmounts({ ...roleAmounts, [r]: +e.target.value })} /></div>;
                })}
            </div>
        </div>
        <div className="button-wrapper">
            <button onClick={sendEmail}>Senden</button>
        </div>
    </div>;
}