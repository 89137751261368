import { useLocation } from "react-router";
import { Navigate } from "react-router";
import { validateToken } from "../../utils/Auth";
import { RoleType } from "../dataTypes/generated";

export const SecureRouteCircalind = ({ children, allowedUsers }: { children: JSX.Element, allowedUsers?: RoleType[] }) => {

    let location = useLocation();

    if (!children) {
        throw Error("component for SecureRoute is not defined");
    }

    if (validateToken("token-circalind") === true) {
        return children;
    } else {
        return <Navigate to={"/circalind/login/" + encodeURIComponent(location.pathname)} state={{ from: location }} replace />;
    }
}