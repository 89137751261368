import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUserRoles } from "../../utils/Auth";
import "./ServiceOverview.scss";

export const ServiceOverview = () => {
    return <div className="service-overview">
        <Profile />
        <Id />
        <Contract />
    </div>;
}

const Profile = () => {
    return <div className="card">
        <h2>Profil</h2>
        <div className="description">
            Bearbeiten Sie hier ihr Profil inklusive Daten wie Vorname, Name, Position etc. Hier können Sie ebenfalls Ihr Passwort zurücksetzen.
        </div>
        <div className="go-to-arrow">
            <button onClick={() => { window.location.href = "profile" }}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>;
}

const Id = () => {
    return <div className="card">
        <h2>Ausweis</h2>
        <div className="description">
            Erhalten Sie einen Überblick über Ihre Rollen und ändern Sie ihr Profilbild.
        </div>
        <div className="go-to-arrow">
            <button onClick={() => { window.location.href = "id" }}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>;
}

const Contract = () => {
    const userRoles = getUserRoles("token-circalind");
    const isCircalindManager = userRoles.includes("CIRCALINDMANAGER");
    return <div className="card">
        <h2>Steckbrief Modulpaket</h2>
        <div className="description">
            Übersicht zum Modulpaket einsehen.
        </div>
        <div className="go-to-arrow">
            <button disabled={!isCircalindManager} onClick={() => { window.location.href = "contract" }}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>;
}