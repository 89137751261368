import { ReactElement, useCallback, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import { SubGraphProps, MovePointState } from "./Graphs";

export const PieGraph = (props: SubGraphProps): ReactElement => {

    const lineData = () => {
        let res: any[] = [];
        for (let i = 0; i < props.nrOfPoints; i++) {
            let t = props.data.flatMap(l => l.points).filter(k => k.index === i);
            let point: any = { index: i };
            t.forEach(p => {
                let varr = p.name;
                point[varr] = p.value;
            })
            res.push(point);
        }
        return res;
    }

    const [, setindexToEdit] = useState<MovePointState | undefined>(undefined);
    const [activeIndex, setActiveIndex] = useState(0);

    const getColor = (index: number): string => {
        return props.data[index].color
    }

    const onPieEnter = useCallback(
        (_: any, index: number) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );

    const onPointMouseDown = (e: any, identifier: string) => {
        setindexToEdit({ index: e.index, identifier: identifier });
    }

    const RADIAN = Math.PI / 180;

    const renderCustomizedLabel = (cx: number, cy: number, midAngle: number, innerRadius: number, outerRadius: number, percent: number, index: number) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const renderActiveShape = (props: any) => {
        const RADIAN = Math.PI / 180;
        const {
            cx,
            cy,
            innerRadius,
            outerRadius,
            startAngle,
            endAngle,
            fill,
            name
        } = props;
        const sin = Math.sin(-RADIAN * startAngle);
        const cos = Math.cos(-RADIAN * startAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;

        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius - 10}
                    outerRadius={outerRadius - 10}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 10}
                    outerRadius={outerRadius + 14}
                    fill={fill}
                />
                <circle cx={sx} cy={sy} r="10" fill={fill} onMouseDown={(e) => onPointMouseDown(e, name)} />
            </g>
        );
    };

    return <ResponsiveContainer width="100%" height="100%">
        <PieChart width={500} height={500} >
            <Pie
                ref={props.innerRef}
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={lineData()}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={(e) => renderCustomizedLabel(e.cx, e.cy, e.midAngle, e.innerRadius, e.outerRadius, e.percent, e.index)}
                outerRadius={90}
                fill="#8884d8"
                dataKey="value"
                onMouseEnter={onPieEnter}
            >
                {lineData().map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={getColor(index)} />
                ))}
            </Pie>
        </PieChart>
    </ResponsiveContainer>
}
