import { ReactElement, useEffect, useState } from "react";

import projectImage from '../../../../../resources/circa/personal_project.svg'
import strategyImage from '../../../../../resources/circa/strategy_project.svg';
import { Config } from "./Config";
import { Project } from "../../../../dataTypes/generated";
import { emptyProjectSynchronimeterData, ProjectSynchronimeterData } from "./ProjectSynchronimeterData";
import * as U from './util';
import { useGlobalMousePosRelativeToRef } from "../../../../../invoice/hooks/useGlobalMousePosRelativeToRef";
import { useGlobalCustomDragAndDrop } from "../../../../hooks/useGlobalCustomDragAndDrop";

import "./SynchroCircle.scss";
import { getTodoColor } from "../../../../practical/transfer/NewTodo";
import { useNavigate } from "react-router";
import { useSelectedProject } from "../../../../../utils/Hooks";

export type Props = {
    showIs: boolean;
    project: Project,
    config: Config,
    onClick: () => void
    onDragStart: (e: MouseEvent) => void
    onDragEnd: (e: MouseEvent) => void
    onSetValue: (legendItem: string, phase: number, value: number) => void
}

export const SynchroCircle = (props: Props): ReactElement => {
    const onSetValue = props.onSetValue;
    const projectRadius = props.config.projectRadius;
    const dragAndDropHandlers = useGlobalCustomDragAndDrop(props.onDragStart, props.onDragEnd, props.onClick);
    const synchroData = props.project.synchronimeterData ? JSON.parse(props.project.synchronimeterData) as ProjectSynchronimeterData : emptyProjectSynchronimeterData;
    const effectivePhase = props.showIs ? Math.max(props.project.phase, 1) : 0;
    const values = synchroData.values[effectivePhase] || [];
    const [[mx, my], ref] = useGlobalMousePosRelativeToRef();
    const [dragValue, setDragValue] = useState<string | null>(null);

    const [, setSelectedProject] = useSelectedProject();

    const navigate = useNavigate();
    const goToBase = () => {
        setSelectedProject(props.project.id);
        navigate("/circalind/regular/dashboard/base/importance")
    };

    useEffect(() => {
        if (dragValue) {
            const [, theta] = U.cartesianToPolar(mx, my);
            const valueAngle = (theta + 720 + 90) % 360;
            const newValue = Math.round(valueAngle / 360 * 100);
            onSetValue(dragValue, effectivePhase, newValue);
        }
    }, [mx, my, dragValue, onSetValue, effectivePhase]);

    return <g>
        {props.config.showText && <g transform={`translate(0, ${-(5 + props.config.projectRadius + synchroData.legend.length * props.config.valueWidth)})`}><circle className="clickable" onClick={goToBase} cy="-10" r="3" fill={getTodoColor(props.project.status)} /><text fontSize={8} dominantBaseline="middle" textAnchor="middle" className="title"><tspan className={props.project.importance}>{props.project.importance} </tspan> {props.project.name.substring(0,12)}</text></g>}
        <image ref={ref} height={projectRadius * 1.4} x={-projectRadius / 1.4} y={-projectRadius / 1.4} className="circle-image" xlinkHref={props.project.type === "PERSONAL" ? projectImage : strategyImage} />
        <circle
            cx="0"
            cy="0"
            r={projectRadius}
            fill="transparent" stroke="black"

            {...dragAndDropHandlers}
        />
        {synchroData.legend.map((li, i) =>
            <CircleValue
                key={li.id}
                i={i}
                value={values[li.id] || 0}
                config={props.config}
                color={li.color}
                onDragStart={() => setDragValue(li.id)}
                onDragEnd={() => setDragValue(null)}
            />
        )}

    </g>;
}

type ValueProps = {
    i: number
    value: number
    config: Config
    color: string
    onDragStart: () => void
    onDragEnd: () => void
}
const CircleValue = (props: ValueProps): ReactElement => {
    const handlers = useGlobalCustomDragAndDrop(props.onDragStart, props.onDragEnd, () => { });
    const ri = props.config.projectRadius + props.config.projectRadiusStrokeWidth + props.config.valueWidth * props.i;
    const ro = props.config.projectRadius + props.config.projectRadiusStrokeWidth + props.config.valueWidth * (props.i + 1);

    const [x1, y1] = U.polarToCartesian(ri, -90);
    const [x2, y2] = U.polarToCartesian(ri, -90 + props.value / 100.0 * 360);
    const [x3, y3] = U.polarToCartesian(ro, -90 + props.value / 100.0 * 360);
    const [x4, y4] = U.polarToCartesian(ro, -90);
    const [cx, cy] = [(x2 + x3) / 2, (y2 + y3) / 2];
    const long = props.value > 50 ? 1 : 0;

    return <g>
        <path
            d={`
                M ${x1} ${y1}
                A ${ri} ${ri} 0 ${long} 1 ${x2} ${y2} 
                L ${x3} ${y3}
                A ${ro} ${ro} 0 ${long} 0 ${x4} ${y4} 
                Z
            `}
            fill={props.color}
        />
        <circle {...handlers} cx={cx} cy={cy} r={props.config.valueWidth / 2} stroke={props.color} />
    </g>;
}
