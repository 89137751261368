import { useLocation } from "react-router";

export type SiteType = "LEADING" | "REGULAR" | "MANAGE" | "SERVICE";

const getSiteTypeForLocation = (pathname: string): SiteType => {
    if (pathname.startsWith("/circalind/leading")) {
        return "LEADING";
    } else if (pathname.startsWith("/circalind/regular")) {
        return "REGULAR";
    } else if (pathname.startsWith("/circalind/service")) {
        return "SERVICE";
    } else if (pathname.startsWith("/circalind/managing")) {
        return "MANAGE";
    } else {
        return "LEADING";
    }
};

export const useSiteType = (): SiteType => {
    const location = useLocation();
    return getSiteTypeForLocation(location.pathname);
}
