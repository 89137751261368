import { ReactElement, useMemo } from "react";
import { Project } from "../../../../dataTypes/generated";
import { emptyProjectSynchronimeterData, newLegendItem, ProjectSynchronimeterData } from "./ProjectSynchronimeterData";
import { faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as ST from './State';

import './SynchronimeterLegend.scss';
import { useNavigate } from "react-router";

export type Props = {
    project: Project | null;
    handler: (event: ST.StateEvent) => void;
    readonly?: boolean;
}

export const SynchronimeterLegend = (props: Props): ReactElement => {
    const navigate = useNavigate();
    const project = props.project;
    const json = project?.synchronimeterData;
    const data = useMemo(() => json ? JSON.parse(json) : emptyProjectSynchronimeterData, [json]) as ProjectSynchronimeterData;

    if (project === null) {
        return <div className="synchronimeter-legend"></div>
    } else {
        const addNew = () => {
            props.handler({ kind: 'SetProjectLegendItem', projectId: project.id, item: newLegendItem() })
        };

        return <div className="synchronimeter-legend">
            <button onClick={() => navigate("/circalind/regular/dashboard/base/start")}>Zur Basis</button>
            <h3>Zielfelder</h3>
            {data.legend.map(l => {
                const changeName = (name: string) => props.handler({ kind: 'SetProjectLegendItem', projectId: project.id, item: { ...l, name } });
                const changeColor = (color: string) => props.handler({ kind: 'SetProjectLegendItem', projectId: project.id, item: { ...l, color } });
                const remove = () => props.handler({ kind: 'RemoveProjectLegendItem', projectId: project.id, itemId: l.id });

                return <div key={l.id} className="legend-item">
                    <input disabled={props.readonly} className="color-in" type={"color"} value={l.color} onChange={(e) => changeColor(e.target.value)} />
                    <input disabled={props.readonly} value={l.name} onChange={e => changeName(e.target.value)} />
                    {!props.readonly && <FontAwesomeIcon icon={faTrashAlt} onClick={remove} />}
                </div>
            })}
            {data.legend.length < 3 ? <FontAwesomeIcon icon={faPlusCircle} onClick={addNew} /> : null}
        </div>
    }
}
