import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import governanceImage from "../../../resources/circa/governance.svg";
import practicalImage from "../../../resources/circa/user/left-menu-1.svg";
import checkupsImage from "../../../resources/circa/risk_management.svg";
import perspectiveImage from '../../../resources/circa/perspective.png'

import "./PhasePracticalCheck.scss";
import { useEffect, useState } from "react";
import { Project } from "../../dataTypes/generated";
import { useSelectedProject } from "../../../utils/Hooks";
import { CircalindApi } from "../../circalindApi";

interface PhasePracticalCheckProps {
    onNext?: () => Promise<void>;
    onBack?: () => Promise<void>;
}

export const PhasePracticalCheck = (props: PhasePracticalCheckProps) => {
    const [project, setProject] = useState<Project | null>(null);
    const [selectedProject,] = useSelectedProject();

    useEffect(() => {
        const loadProject = async () => {
            if (selectedProject) {
                setProject(await CircalindApi.getProject(selectedProject));
            }
        };

        loadProject();
    }, [selectedProject]);

    const renderNavigationButtons = () => {
        if (props.onNext && props.onBack) {
            return <>
                <button onClick={() => props.onBack!()}><FontAwesomeIcon icon={faArrowLeft} /></button>
                <button onClick={() => props.onNext!()}><FontAwesomeIcon icon={faArrowRight} /></button>
            </>
        }
    }

    const renderCheckbox = (id: number) => {
        return <div className="checkbox-wrapper ">
            <input id={"checked_" + id} type="checkbox" />
            <label htmlFor={"checked_" + id}>Erledigt</label>
        </div>
    };

    return <div>
        <div className="practical-check">
            {project && project.type === "STRATEGIC" && <div className="card">
                <div className="img-card-wrapper" >
                    <div className="img-card" onClick={() => window.location.href = "/circalind/regular/governance"}>
                        <img src={governanceImage} alt="governance" />
                    </div>
                </div>
                <div>
                    <strong>Tipp:</strong> <br />
                    Aktualität prüfen: <br />
                    bzgl.  Informationen zu geltenden Gesetzen, formalen Rahmenbedingungen, Betriebsvereinbarungen, Zielen, KPI’s, Kennzahlen u.a.
                </div>
                {renderCheckbox(1)}
            </div>}
            <div className="card">
                <div className="img-card-wrapper">
                    <div className="img-card" onClick={() => window.location.href = "/circalind/regular/checkups/content"}>
                        <img src={checkupsImage} alt="checkups" />
                    </div>
                </div>
                <div>
                    <strong>Tipp:</strong> < br />
                    I. Maßnahmen zur Risiko-Prävention nutzen. < br />
                    z.B. < br />
                    hinterlegte CheckUps < br />

                    II. sich im Risiko-Management auf dem Laufenden halten
                </div>
                {renderCheckbox(2)}
            </div>
            <div className="card">
                <div className="img-card-wrapper">
                    <div className="img-card" onClick={() => window.location.href = "/circalind/regular/practical"}>
                        <img src={practicalImage} alt="practical" />
                    </div>
                </div>
                <div>
                    <strong>Tipp:</strong> <br />
                    a) Informationen aus
                    Praxis-Räumen ins Resümee einbeziehen<br />
                    b) in Bezug auf die nächste Phase auf Aktualität und
                    Vollständigkeit überprüfen<br />
                    c) ggf. aktualisieren
                </div>
                {renderCheckbox(3)}
            </div>
            <div className="card">
                <div className="img-card-wrapper">
                    <div className="img-card" onClick={() => window.location.href = "/circalind/regular/checkups/matching"}>
                        <img src={perspectiveImage} alt="checkups" />
                    </div>
                </div>
                <div>
                    <strong>Tipp:</strong> < br />
                    I. Maßnahmen zur Risiko-Prävention nutzen. < br />
                    z.B. < br />
                    Perspektiv Wechsel < br /> < br />

                    II. sich im Risiko-Management auf dem Laufenden halten
                </div>
                {renderCheckbox(2)}
            </div>
        </div>
        <div className={"navigation"}>
            {renderNavigationButtons()}
        </div>
    </div >;
};