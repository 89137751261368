import { useEffect, useState } from "react";
import { CircalindApi } from "../../circalind/circalindApi";
import { Area, CircaPackage, LicenseMetaData } from "../../circalind/dataTypes/generated";
import Select from 'react-select';
import { sendOlert } from "../../olert/Olert";

import "./TransferOptions.scss";

export const TransferOptions = () => {
    const [licenseMetaData, setLicenseMetaData] = useState<LicenseMetaData | null>(null);
    const [licenses, setLicenses] = useState<Array<CircaPackage>>([]);
    const [license, setLicense] = useState<CircaPackage | null>(null);

    useEffect(() => {
        const loadLicenses = async () => {
            setLicenses(await CircalindApi.getAllLicenses());
            setLicenseMetaData(await CircalindApi.getLicenseMetaData());
        };

        loadLicenses();
    }, []);

    const renderLicenseSelect = () => {
        if (licenseMetaData) {
            const options = licenses.map(l => {
                return {
                    value: l,
                    label: `Liefernummer: L-${l.id} - ${l.type} - Bis ${l.endMonth}.${l.endYear} ${l.active ? "aktiv" : "inaktiv"}`
                };
            });

            return <div className="select-container">
                <Select value={options.find(o => license && o.value.id === license.id)} isSearchable isClearable={true} options={options} onChange={(opt) => setLicense(opt!.value)} />
            </div>
        }
    };

    const transferSettings = async () => {
        if (license) {
            const result = await CircalindApi.transferSettings(license.groupId);

            if (result) {
                sendOlert("Erfolg", "Erfolgreich übertragen", "Success");
            } else {
                sendOlert("Fehlgeschlagen", "Konnte nicht übertragen werden, stellen Sie sicher, dass sie ebenfalls Koordinator im anderen Paket sind.", "Error");
            }
        }
    };

    const transferManagmentContent = async () => {
        if (license) {
            const result = await CircalindApi.transferManagementContent(license.groupId);

            if (result) {
                sendOlert("Erfolg", "Erfolgreich übertragen", "Success");
            } else {
                sendOlert("Fehlgeschlagen", "Konnte nicht übertragen werden, stellen Sie sicher, dass sie ebenfalls Koordinator im anderen Paket sind.", "Error");
            }
        }
    };

    const transferContent = (area: Area, subAreaName: string) => {
        return async () => {
            if (license) {
                const result = await CircalindApi.transferContent(license.groupId, area, subAreaName);

                if (result) {
                    sendOlert("Erfolg", "Erfolgreich übertragen", "Success");
                } else {
                    sendOlert("Fehlgeschlagen", "Konnte nicht übertragen werden, stellen Sie sicher, dass sie ebenfalls Koordinator im anderen Paket sind.", "Error");
                }
            }
        };
    }

    return <div className="transfer-options">
        <h3>Bitte wählen Sie Ziel-Paket in das die Daten übertragen werden sollen</h3>
        {renderLicenseSelect()}
        <div>
            <h2>Settings Übertragen</h2>
            <button onClick={transferSettings} disabled={!!!license}>Übertragen</button>
        </div>
        <div>
            <h2>F&Z Phasen Inhalte (Planung, Aufbau, RealLabor) Übertragen</h2>
            <button onClick={transferManagmentContent} disabled={!!!license}>Übertragen</button>
        </div>
        <div>
            <h2>Inhalte aus dem Bereich IT-Management übertragen</h2>
            <button onClick={transferContent("tools", "tools")} disabled={!!!license}>Übertragen</button>
        </div>
        <div>
            <h2>Inhalte aus dem Bereich Nachhaltigkeits-Management übertragen</h2>
            <button onClick={transferContent("quality", "quality")} disabled={!!!license}>Übertragen</button>
        </div>
        <div>
            <h2>Inhalte aus dem Bereich allgemeiner FAQ übertragen</h2>
            <button onClick={transferContent("faq", "faq")} disabled={!!!license}>Übertragen</button>
        </div>
    </div>
};