import { useNavigate } from "react-router";
import { PackageOverview } from "../services/PackageOverview";

import "./AdminOrderOverview.scss";

export const AdminPackageOverview = ({showTypeSelection}: {showTypeSelection: boolean}) => {
    const navigate = useNavigate();

    return <div className="admin-order-overview">
        <h2>Auswertungen {showTypeSelection ? "Modulübersicht" : "Paketübersicht"}</h2>
        <div className="right">
            <button onClick={() => navigate("../overview")}>Zurück</button>
        </div>
        <PackageOverview isAdmin={true} showTypeSelection={showTypeSelection} />
    </div>
};