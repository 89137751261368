import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toolboxImage from "../../resources/circa/toolbox.svg";

import "../Start.scss";


export const WelcomeITCoordination = () => {
    return <div className="full-page">
        <h1><strong>IT-Management für Konnektivität</strong></h1>
        <img src={toolboxImage} alt="IT-Management für Komposibilität" />
        <div onClick={() => window.location.href = "main"} className="lower-arrow"><FontAwesomeIcon icon={faArrowRight} /></div>
    </div>;
}