import { ReactElement, useState } from "react";

export interface PasswordChangeProps {
    onOk: (pass: string) => void;
}

export const PasswordChange = (props: PasswordChangeProps) : ReactElement => {

    const [password, setPassword] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");

    const samePassword = (): boolean => {
        return password.length > 0 && password === password2;
    }

    return <div>
        <h2>Ändern Sie Ihr Passwort</h2>
        <input className={samePassword()? "valid" : "invalid"} type={"password"} value={password} onChange={(e)=>setPassword(e.target.value)}/>
        <label>Wiederholen Sie Ihr Passwort</label>
        <input className={samePassword()? "valid" : "invalid"} type={"password"} value={password2} onChange={(e)=>setPassword2(e.target.value)}/>
        <button disabled={!samePassword()} onClick={()=>props.onOk(password)}>Aktualisieren</button>
    </div>
}