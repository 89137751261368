import moduleImage from "../../resources/circa/staffing/module.png";
import staffingImage from "../../resources/circa/staffing/staffing.png";
import settingsImage from "../../resources/circa/staffing/settings.png";
import transferImage from "../../resources/circa/staffing/transfer.png";

import "./StaffingAndSettings.scss";
import { useNavigate } from "react-router";

export const StaffingAndSettings = () => {
    const navigate = useNavigate();

    return <div className="setting-and-staffing">
        <div className="card">
            <h2>Bestell-Status</h2>
            <div onClick={() => navigate("../module")} className="card clickable">
                <img src={moduleImage} alt="Bestell-Status" />
            </div>
        </div>
        <div className="card clickable">
            <h2>Setting</h2>
            <div onClick={() => navigate("../options")} className="card">
                <img src={settingsImage} alt="Setting" />
            </div>
        </div>
        <div className="card clickable">
            <h2>Staffing</h2>
            <div onClick={() => navigate("../users/new")} className="card">
                <img src={staffingImage} alt="Staffing" />
            </div>
        </div>
        <div className="card clickable">
            <h2>Individualisierung übertragen</h2>
            <div onClick={() => navigate("../transfer")} className="card">
                <img src={transferImage} alt="Transfer" />
            </div>
        </div>
    </div>
};