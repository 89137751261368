import { useEffect, useState } from "react";
import { Customer, User } from "../dataTypes/generated";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CustomerView } from "../services/Customer";

import "./AdminCustomer.scss";
import { CircalindApi } from "../circalindApi";
import { sendOlert } from "../../olert/Olert";

export const AdminCustomer = () => {
    const [searchParams, ] = useSearchParams();
    const id = searchParams.get("id");
    const [customer, setCustomer] = useState<Customer>();
    const [user, setUser] = useState<User>();

    const navigate = useNavigate();

    useEffect(() => {
        const loadData = async () => {
            if (id !== null) {
                if (+id === -1) {
                    const newUser: User = {
                        id: -1,
                        mail: "",
                        secret: "",
                        firstName: "",
                        lastName: "",
                        roles: []
                    };
                    const newCustomer: Customer = {
                        id: -1,
                        discount: 0,
                        basicDiscount: 0,
                        type: "PRIVATE",
                        name: "",
                        taxNr: "",
                        taxId: "",
                        specialContractConditions: "",
                        companyStreet: "",
                        companyStreetNumber: "",
                        companyPostbox: "",
                        companyZipCode: "",
                        companyCity: "",
                        companyCountry: "",
                        managerId: -1,
                        managerDivision: "",
                        managerStreet: "",
                        managerStreetNumber: "",
                        managerPostbox: "",
                        managerZipCode: "",
                        managerCity: "",
                        managerCountry: "",
                        invoiceFirstName: "",
                        invoiceLastName: "",
                        invoiceCompany: "",
                        invoiceDivision: "",
                        invoiceStreet: "",
                        invoiceStreetNumber: "",
                        invoicePostbox: "",
                        invoiceZipCode: "",
                        invoiceCity: "",
                        invoiceCountry: "",
                        invoiceEmail: "",
                        invoiceIban: "",
                        invoicePaymentGoal: 0,
                        purchaseCompany: "",
                        purchaseDivision: "",
                        purchaseStreet: "",
                        purchaseStreetNumber: "",
                        purchasePostbox: "",
                        purchaseZipCode: "",
                        purchaseCity: "",
                        purchaseCountry: ""
                    };
                    setUser(newUser);
                    setCustomer(newCustomer);
                } else {
                    const c = await CircalindApi.getCustomer(+id);
                    if (c) {
                        const u = await CircalindApi.getUser(c.managerId);

                        if (u && c) {
                            setUser(u);
                            setCustomer(c);
                        }
                    }
                }
            }
        };

        loadData();
    }, [id]);

    const saveCustomer = async () => {
        if (customer && user) {
            if (!user.mail || !user.firstName || !user.lastName) {
                sendOlert("Fehler", "Ansprechpartner muss definiert sein", "Error");
                return;
            }

            if (customer.basicDiscount < 0 || customer.basicDiscount > 100 || customer.discount < 0 || customer.discount > 100) {
                sendOlert("Fehler", "Rabatte müssen im Bereich 0 - 100% liegen", "Error");
                return;
            }

            const res = await CircalindApi.updateCustomer({
                customer: customer,
                manager: user,
            });

            if (res) {
                sendOlert("Erfolg", "Daten erfolgreich gespeichert.", "Success");
            } else {
                sendOlert("Fehler", "Daten konnten nicht gespeichert werden.", "Error");
            }
        }
    };

    return <div className="admin-customer">
        {customer && user && <CustomerView customer={customer} setCustomer={setCustomer} user={user} setUser={setUser} isAdmin={true} />}
        <div className="right">
            <button onClick={() => navigate("../customers")}>Zurück</button>
            <button onClick={saveCustomer}>Speichern</button>
        </div>
    </div>
};