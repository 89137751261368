import { Project, ProjectFamily } from "../../../../dataTypes/generated";
import { emptyProjectSynchronimeterData, LegendItem, ProjectSynchronimeterData } from "./ProjectSynchronimeterData";

import * as U from './util';

export type State = {
    userDataDirty: boolean,
    dirtyProjects: Array<number>,
    availableProjects: Array<Project>,
    allProjects: Array<Project>,
    availableFamilies: Array<ProjectFamily>,
    selectedFamily: ProjectFamily | null
    selectedProject: number | null
}

export type StateEvent =
    {
        kind: "SelectFamily",
        family: ProjectFamily | null
    } | {
        kind: "SelectProject",
        projectId: number | null
    } | {
        kind: "SetAvailableFamilies",
        families: Array<ProjectFamily>
    } | {
        kind: "SetAllProjects",
        projects: Array<Project>
    } | {
        kind: "SetProjectSection",
        projectId: number,
        sectionIndex: number,
    } | {
        kind: "SetProjectLegendItem",
        projectId: number,
        item: LegendItem
    } | {
        kind: "RemoveProjectLegendItem"
        projectId: number,
        itemId: string
    } | {
        kind: "SetSynchronimeterValue",
        projectId: number,
        phase: number,
        itemId: string,
        value: number
    } | {
        kind: "MarkClean"
    }

export const handleEvent = (state: State, event: StateEvent): State => {
    switch (event.kind) {
        case "SelectProject":
            return {
                ...state,
                selectedProject: event.projectId
            };
        case "SetAvailableFamilies":
            return {
                ...state,
                availableFamilies: event.families
            };
        case "SetAllProjects":
            return {
                ...state,
                allProjects: event.projects
            };
        case "SelectFamily":
            return {
                ...state,
                selectedFamily: event.family || null,
                availableProjects: event.family?.projects.map(pid => state.allProjects.find(p => p.id === pid)!) || []
            };

        case "SetProjectSection":
            return updateProject(state, event.projectId, p => (
                { ...p, temporal: event.sectionIndex }
            ));

        case "SetProjectLegendItem":
            return updateProjectSynchronimeterData(state, event.projectId, data => ({
                ...data,
                legend: data.legend.find(i => i.id === event.item.id) ?
                    data.legend.map(i => i.id === event.item.id ? event.item : i) :
                    [...data.legend, event.item]
            }));

        case "RemoveProjectLegendItem":
            return updateProjectSynchronimeterData(state, event.projectId, data => ({
                ...data,
                legend: data.legend.filter(i => i.id !== event.itemId)
            }));

        case "SetSynchronimeterValue":
            return updateProjectSynchronimeterData(state, event.projectId, data => ({
                ...data,
                values: {
                    ...data.values,
                    [event.phase]: {
                        ...data.values[event.phase] || {},
                        [event.itemId]: event.value
                    }
                }
            }));

        case "MarkClean":
            return {
                ...state,
                userDataDirty: false,
                dirtyProjects: []
            };
        default:
            return U.assertNever(event);
    }
}

const updateProject = (state: State, projectId: number, update: (data: Project) => Project): State => {
    return {
        ...state,
        availableProjects: state.availableProjects.map(p => p.id === projectId ? update(p) : p),
        dirtyProjects: state.dirtyProjects.includes(projectId) ? state.dirtyProjects : [...state.dirtyProjects, projectId],
    };
}

const updateProjectSynchronimeterData = (state: State, projectId: number, update: (data: ProjectSynchronimeterData) => ProjectSynchronimeterData): State => {
    return updateProject(state, projectId, p => {
        const data = p.synchronimeterData ? JSON.parse(p.synchronimeterData) as ProjectSynchronimeterData : emptyProjectSynchronimeterData;
        return {
            ...p,
            synchronimeterData: JSON.stringify(update(data))
        }
    })
}


export const emptyState: State = {
    userDataDirty: false,
    dirtyProjects: [],
    availableProjects: [],
    allProjects: [],
    availableFamilies: [],
    selectedProject: null,
    selectedFamily: null
}

export const getSelectedProject = (state: State): Project | null => {
    if (state.selectedProject === null) return null;
    return state.availableProjects.find(p => p.id === state.selectedProject) || null;
}

export const isDirty = (state: State): boolean =>
    state.dirtyProjects.length > 0 || state.userDataDirty;
