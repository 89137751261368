import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useEffect, useState } from "react";
import {  AreaAndRoleInfo, Project, User } from "../../circalind/dataTypes/generated";
import {  PermissionsCard } from "./PermissionsCard";
import { useNavigate } from "react-router";
import { loadData } from "./Staffing";


import './leading.scss';

export const ToolPermissions = (): ReactElement => {
    const navigate = useNavigate();

    const [projects, setProjects] = useState<Project[]>([]);
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        const getData = async () => {
            const res = await loadData();
            setProjects(res.projects);
            setUsers(res.users);
        }
        getData();
    }, [])

    const getUsersWithoutAdmin = (): User[] => {
        return users;
    }

    return <div className="staffing">
        <div className="list-cards">
            <PermissionsCard deciderOnly={false} groups={[]} projects={projects} users={getUsersWithoutAdmin()} />
        </div>
        <div className={"navigation"}>
            <button onClick={() => navigate("../projects")}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button onClick={() => navigate("../users")}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>
}

export interface ToolPermissionProps {
    users: User[];
    projects: Project[];
    areaAndRoleInfo: AreaAndRoleInfo | null;
}