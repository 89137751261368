import { ReactElement, useCallback, useState } from "react";
import { useRelativeMousePos } from "../../../../../invoice/hooks/useRelativeMousePos";

import * as C from "./Config";
import * as S from "./Section";
import * as ST from "./State";
import * as U from "./util";
import { SynchroCircle } from "./SynchroCircle";
import { Project } from "../../../../dataTypes/generated";

import './Synchronimeter.scss';

export type Props = {
    showIs: boolean;
    config: C.Config;
    projects: Array<Project>;
    handler: (e: ST.StateEvent) => void;
}

export const Synchronimeter = (props: Props): ReactElement => {
    const c = props.config;
    const [draggedProject, setDraggedProject] = useState<number | null>(null);
    const [[mx, my], ref, mousePosHandler] = useRelativeMousePos(transformXY, transformXY);
    const [mr, mtheta] = U.cartesianToPolar(mx, my);
    const sectionAngleData = S.getSectionAngleData(c);
    const hoveredSection = sectionAngleData.find(s => mr > c.innerRadius && mtheta >= s.startAngle && mtheta <= s.endAngle)?.section;
    const projectPositions = calculateProjectPositions(c, props.projects, sectionAngleData);
    const hoverInner = draggedProject && mr < c.innerRadius;

    return <svg
        className="synchronimeter"
        ref={ref}
        onMouseMove={mousePosHandler}
        viewBox="-115 -115 230 230"
    >
        <defs>
            {c.sections.map(s => S.createSectionPaths(c, s))}
        </defs>
        <circle cx="0" cy="0" r={100} fill="#f1f1f1" stroke="none" />
        <circle cx="0" cy="0" r={c.innerRadius} fill={hoverInner ? "lightblue" : "#008080"} stroke="none" />
        {!draggedProject ? null : <text key="trash" x="0" y="5" textAnchor="middle" alignmentBaseline="middle" fontSize="14" fontWeight="bold" fill="lightgray">🗑</text>}

        {c.sections.map(s => S.renderSection(s, draggedProject !== null && s.name === hoveredSection))}
        {props.projects.map(p =>
            <TranslatedCircle
                key={p.id}
                p={p}
                mx={mx}
                my={my}
                mr={mr}
                draggedProject={draggedProject}
                projectPositions={projectPositions}
                showIs={props.showIs}
                hoveredSection={hoveredSection}
                c={c}
                handler={props.handler}
                setDraggedProject={setDraggedProject}
            />
        )}
    </svg>;
}

const TranslatedCircle = (props: {
    p: Project,
    mx: number,
    my: number,
    mr: number,
    draggedProject: number | null
    projectPositions: Record<number, [number, number]>,
    showIs: boolean,
    hoveredSection: string | undefined,
    c: C.Config,
    handler: (e: ST.StateEvent) => void,
    setDraggedProject: (id: number | null) => void
}): ReactElement => {
    const { p, mx, my, mr, draggedProject, projectPositions, showIs, hoveredSection, c, handler, setDraggedProject } = props;
    const [x, y] = draggedProject === p.id ? [mx, my] : projectPositions[p.id];
    const onSetValue = useCallback((li: string, ph: number, v: number) => handler({ kind: "SetSynchronimeterValue", projectId: p.id, phase: ph, itemId: li, value: v }), [handler, p.id]);
    const onDragStart = useCallback(() => setDraggedProject(p.id), [setDraggedProject, p.id]);

    return <g key={p.id} transform={`translate(${x}, ${y})`}>
        <SynchroCircle
            showIs={showIs}
            project={p}
            config={c}
            onClick={() => handler({ kind: "SelectProject", projectId: p.id })}
            onDragEnd={() => {
                const section = c.sections.findIndex(s => s.name === hoveredSection);
                if (section !== -1) {
                    handler({ kind: 'SetProjectSection', projectId: draggedProject!, sectionIndex: section });
                } else {
                    if (mr < c.innerRadius) {
                    }
                }
                setDraggedProject(null);
            }}
            onDragStart={onDragStart}
            onSetValue={onSetValue}
        />
    </g>
}

const transformXY = (xOrY: number) => xOrY * 230 - 115;

const calculateProjectPositions = (c: C.Config, projects: Array<Project>, sectionAngles: Array<S.SectionAngleData>): Record<number, [number, number]> => {
    const sortedProjects = [...projects];
    sortedProjects.sort((a, b) => a.name.localeCompare(b.name));

    const record: Record<number, [number, number]> = {};

    c.sections.forEach((_, i) => {
        const sectionProjects = sortedProjects.filter(p => p.temporal === i);
        const { startAngle, endAngle } = sectionAngles[i];
        const angleDistance = U.angleDistance(startAngle, endAngle);
        const startAngleWithMargin = startAngle + angleDistance * 0.2;
        const endAngleWithMargin = endAngle - angleDistance * 0.2;
        const angleOffset = U.angleDistance(startAngleWithMargin, endAngleWithMargin) / Math.max(1, (sectionProjects.length - 1));
        sectionProjects.forEach((p, j) => {
            const [cx, cy] = U.polarToCartesian(c.innerRadius + (100 - c.innerRadius) * 0.7, startAngleWithMargin + angleOffset * j);
            record[p.id] = [cx, cy];
        });
    });

    const unassignedProjects = sortedProjects.filter(p => !record[p.id]);
    const angle = 360 / (unassignedProjects.length || 1);
    const start = -90;
    unassignedProjects.forEach((p, i) => {
        const [x, y] = U.polarToCartesian(c.innerRadius, start + i * angle);
        record[p.id] = [x, y];
    });

    return record;
}
