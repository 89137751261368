import { useState } from "react"

export const useDialog = () => {

    const [show, setShow] = useState<boolean>(false);
    const toggle = () => {
        setShow(!show);
    }

    return {
        show,
        toggle
    }
}