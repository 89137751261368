import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import interjectionImage from '../../../resources/circa/interjection.png'

import "./PhaseInterjection.scss";

export const PhaseInterjection = ({ onNext, onBack }: { onNext: () => Promise<void>, onBack: () => Promise<void> }) => {
    return <div className="phase-interjection">
        <h1><strong>Instrumente zum Spezifizieren & Navigieren </strong></h1>
        <div className="img-container">
            <img src={interjectionImage} alt="Spezifizieren & Navigieren" />
        </div>
        <div className="navigation">
            <button onClick={() => onBack!()}><FontAwesomeIcon icon={faArrowLeft} /></button>
            <button onClick={() => onNext!()}><FontAwesomeIcon icon={faArrowRight} /></button>
        </div>
    </div>;
};