import { useEffect, useState } from "react";
import { CircalindApi } from "../../../../circalindApi";
import { defaultConfig } from "./Config"
import { Synchronimeter } from "./Synchronimeter";
import { Project, ProjectFamily } from "../../../../dataTypes/generated";
import { useSelectedProject } from "../../../../../utils/Hooks";
import { faArrowLeft, faArrowRight, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./CompareSynchronimeters.scss";
import { SynchronimeterLegend } from "./SynchronimeterLegend";

export type CompareSynchronimetersProps = {
    onNext?: () => void;
    onPrev?: () => void;
}

export const CompareSynchronimeters = (props: CompareSynchronimetersProps) => {
    const [projects, setProjects] = useState<Array<Project>>([]);
    const [families, setFamilies] = useState<Array<ProjectFamily>>([]);
    const [selectedProject,] = useSelectedProject();
    const [show, setShow] = useState<boolean>(false);

    const loadData = async () => {
        setProjects(await CircalindApi.getProjects());
        setFamilies(await CircalindApi.getMyProjectFamilies());
    };

    useEffect(() => {
        loadData();
    }, []);

    const family = families.find(f => f.projects.includes(selectedProject));

    const filterProjects = () => {
        if (family) {
            return projects.filter(p => family.projects.includes(p.id));
        } else {
            return [];
        }
    };

    const renderLegend = () => {

        const renderProjectGoals = () => {
            if (family) {
                return <div>
                    {family.projects.map(pid => projects.find(p => p.id === pid)!).map(p => {
                        return <div key={p.id}>
                            <h4>{p.name}</h4>
                            <SynchronimeterLegend readonly={true} project={p} handler={() => { }} />
                        </div>;
                    })}
                </div>
            }
        };

        return <div>
            <h3>Legende <FontAwesomeIcon onClick={() => setShow(!show)} icon={show ? faEyeSlash : faEye} /></h3>
            {family && show &&
                <>
                    <div>
                        <h4>Projekt-Cluster: {family.name}</h4>
                    </div>
                    <h4>Projekte</h4>
                    <ul>
                        {family && projects.filter(p => family.projects.includes(p.id)).map(p => <li key={p.id}>{p.name}</li>)}
                    </ul>
                    <div>
                        <h4>Priorität</h4>
                        <ul>
                            <li>A</li>
                            <li>B</li>
                            <li>C</li>
                        </ul>
                    </div>
                    <div>
                        <h4>Zeitdimension</h4>
                        <ul>
                            <li>Aktuell: {family.currentDescription}</li>
                            <li>Mittelfristig: {family.middleDescription}</li>
                            <li>Langfristig: {family.longDescription}</li>
                        </ul>
                    </div>
                    <div>
                        {renderProjectGoals()}
                    </div>
                </>
            }
        </div>
    };

    return <div className="compare-synchronimeters">
        <h2>Vorhaben (SOLL-Status) & Vorankommen (IST-Status) synchronisieren!</h2>
        <div className="synchronimeters">
            {renderLegend()}
            <div className="synchro">
                <h3>Soll-Zustand</h3>
                <div>
                    <Synchronimeter
                        showIs={false}
                        config={defaultConfig}
                        projects={filterProjects()}
                        handler={() => { }}
                    />
                </div>
            </div>
            <div className="synchro">
                <h3>Ist-Zustand</h3>
                <div>
                    <Synchronimeter
                        showIs={true}
                        config={defaultConfig}
                        projects={filterProjects()}
                        handler={() => { }}
                    />
                </div>
            </div>
        </div>

        <div className="navigation">
            {!props.onPrev ? null : <button key="prev" className="prev" onClick={props.onPrev}><FontAwesomeIcon icon={faArrowLeft} /></button>}
            {!props.onNext ? null : <button key="next" className="next" onClick={props.onNext}><FontAwesomeIcon icon={faArrowRight} /></button>}
        </div>
    </div>
};