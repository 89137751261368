import { ReactElement } from "react";

export const Settings = (): ReactElement => {

    return <div className="profile">
        <div className="profile-group">
            <div className="profile-group-header">
                <h2>Farbprofil</h2>
            </div>
            <div className="contract-block">
                <span className="desc">Primärfarbe</span>
                <input type="color" id="primary" name="primary" value="#ff0000" />
                <span className="desc">Primärfarbe</span>
                <input type="color" id="secundary" name="secundary" value="#ff0000" />
                <span className="desc">Akzentfarben  </span>
                <input type="color" id="tertiary" name="tertiary" value="#ff0000" />
            </div>
        </div>
        <hr />
        <div className="profile-group">
            <div className="profile-group-header">
                <h2>Logo</h2>
            </div>
            <div className="profile-block-logo">
                <h3>Hochladen</h3>
                <label>Datei:</label>
                <input type="file" accept="image/png" />
                <label>Maximale Bildgröße 400x400 px</label>
                <button>Hochladen</button>
            </div>
        </div>
        <hr />
    </div>
}