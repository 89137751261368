import { FormEvent, useEffect, useState } from "react";
import { serviceHeaders, useHeaderContext } from "../page/header/Header";
import { CircaPackage, Customer, LicenseMetaData, LicenseTypes, Order, OrderItem, OrderItemType, OrderRequest, PackagePrice, PaymentType, Tax, TypeCustomer } from "../dataTypes/generated";
import { CircalindApi } from "../circalindApi";
import Select from 'react-select';

import "./OrderView.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { formatNumber } from "../../invoice/utils/Util";
import { sendOlert } from "../../olert/Olert";
import { useNavigate } from "react-router";

const ASSISTANCE_PRICE = 2000;

const createNewOrder = (): Order => {
    const newOrder: Order = {
        id: -1,
        customerId: -1,
        date: "",
        notPaid: null,
        paymentType: "MONTHLY",
        customerSnapshot: null,
        pricesSnapshot: null,
        demo: false
    };

    return newOrder;
}

const calcMonths = (startMonth: number, startYear: number, endMonth: number, endYear: number): number => {
    return (endYear - startYear - 1) * 12 + (12 - startMonth + 1) + (endMonth);
};

const getDeciderPriceForLicenseAndCustomer = (customer: Customer, licenseType: LicenseTypes, prices: Array<PackagePrice>) => {
    const table: Record<TypeCustomer, Partial<Record<LicenseTypes, number>>> = {
        PRIVATE: {
            PK4: 1
        },
        PRIVATE_B2B: {
            PK4: 1
        },
        PRIVATE_SALES: {
            PK4: 1
        },
        COMPANY: {
            UK1: 5,
            UK2: 5,
            UK3: 5,
        },
        COMPANY_B2B: {
            UK1: 5,
            UK2: 5,
            UK3: 5,
        },
        COMPANY_SALES: {
            UK1: 5,
            UK2: 5,
            UK3: 5,
        }
    };

    return 0.8 * (prices.find(p => p.type === licenseType)!.price / table[customer.type][licenseType]!);
};

const isCompanyCustomer = (customer: Customer) => {
    const companyTypes: Array<TypeCustomer> = ["COMPANY", "COMPANY_B2B", "COMPANY_SALES"];
    return companyTypes.includes(customer.type);
};

const calculateEndDate = (startMonth: number, startYear: number, month: number): { endMonth: number, endYear: number } => {
    const endMonth = (((startMonth - 1) + (month - 1)) % 12) + 1;
    const endYear = startYear + Math.ceil(Math.max(0, (month - (12 - startMonth + 1))) / 12);

    return {
        endMonth, endYear
    };
};

const createDemoItem = (licenseType: LicenseTypes): OrderItem => {
    return {
        id: -1,
        orderId: -1,
        packageId: -1,
        type: "NEW_PACKAGE",
        startMonth: new Date().getMonth() + 1,
        startYear: new Date().getFullYear(),
        endMonth: new Date().getMonth() + 1,
        endYear: new Date().getFullYear(),
        additionalAssistance: 0,
        additionalDeciders: 0,
        coordinator: {
            id: -1,
            firstName: "",
            lastName: "",
            mail: "",
            secret: "",
            roles: [],
        },
        licenseType: licenseType,
    };
};

export const OrderView = () => {
    const [order, setOrder] = useState<Order>(createNewOrder());
    const [packages, setPackages] = useState<Array<CircaPackage>>([]);
    const [items, setItems] = useState<Array<OrderItem>>([]);
    const [customer, setCustomer] = useState<Customer | null>();
    const { updateHeader } = useHeaderContext();
    const [licenseMetaData, setLicenseMetaData] = useState<LicenseMetaData | null>(null);
    const [acceptedAGB, setAcceptedAGB] = useState<boolean>(false);

    const [months, setMonths] = useState<number | null>(null);
    const [taxes, setTaxes] = useState<Array<Tax>>([]);
    const [prices, setPrices] = useState<Array<PackagePrice>>([]);

    const [isOrdering, setIsOrdering] = useState<boolean>(false);

    const navigate = useNavigate();

    const tax = taxes
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        .filter(t => new Date(t.date) <= new Date())[0];

    useEffect(() => {
        if (customer && order.demo) {
            setItems(isCompanyCustomer(customer) ? [createDemoItem("UK1"), createDemoItem("UK2"), createDemoItem("UK3")] : [createDemoItem("PK4")])
        } else {
            setItems([]);
        }
    }, [order.demo, customer]);

    useEffect(() => {
        const updateMonths = async () => {
            setMonths(await CircalindApi.getNumberOfOpenContractMonths(items));
        };

        updateMonths();
    }, [items]);

    useEffect(() => {
        const loadData = async () => {
            setPrices(await CircalindApi.getPackagePrices());
            setCustomer(await CircalindApi.getMyCustomer());
            setPackages(await CircalindApi.getAllOrderedLicenses());
            setTaxes(await CircalindApi.getTaxes());
            setLicenseMetaData(await CircalindApi.getLicenseMetaData());
        };

        loadData();
    }, []);

    useEffect(() => {
        updateHeader(serviceHeaders);
    }, [updateHeader]);

    const orderOptions = [{ label: "Bestellung Module", value: false }, { label: "Bestellung Testphase (einmalig, 1 Monat, 80% Rabatt auf Basisvertrag)", value: true }];
    const orderItemOptions: Array<{ label: string, value: OrderItemType }> = [
        { label: "Neues Paket", value: "NEW_PACKAGE" },
        { label: "Paketverlängerung / Lizenzerweiterung", value: "EXTEND_PACKAGE" },
    ]

    const licenseTypeOptions: Array<{ label: string, value: LicenseTypes }> = customer && isCompanyCustomer(customer) ? [
        {
            value: "UK1",
            label: licenseMetaData ? licenseMetaData.licenseTitles["UK1"]! : "UK1"
        },
        {
            value: "UK2",
            label: licenseMetaData ? licenseMetaData.licenseTitles["UK2"]! : "UK2"
        },
        {
            value: "UK3",
            label: licenseMetaData ? licenseMetaData.licenseTitles["UK3"]! : "UK3"
        },
    ] : [
        {
            value: "PK4",
            label: licenseMetaData ? licenseMetaData.licenseTitles["PK4"]! : "PK4"
        },
    ];

    const buildOptions = (item: OrderItem, includeZero?: boolean): Array<{ label: string, value: { month: number | null, year: number | null } }> => {
        let months = order.demo ? [1] : (["UK2", "UK3"].includes(item.licenseType!) ? [12, 24] : [6, 12, 24]);

        if (includeZero) {
            months = [0, ...months];
        }

        return months.map(month => {
            const endDate = calculateEndDate(item.startMonth, item.startYear, month);

            return {
                label: month === 0 ? "Keine Verlängerung" : `${("" + endDate.endMonth).padStart(2, "0")}.${endDate.endYear} (${month} ${month > 1 ? "Monate" : "Monat"})`,
                value: {
                    month: month === 0 ? null : endDate.endMonth,
                    year: month === 0 ? null : endDate.endYear
                }
            };
        });
    };

    const packageOptions: Array<{ label: string, value: CircaPackage }> = packages.map(p => {
        return {
            value: p,
            label: `P-${p.id}`,
        }
    })

    const renderNewPackageItem = (item: OrderItem, index: number, customer: Customer) => {
        const endDateOptions: Array<{ label: string, value: { month: number | null, year: number | null } }> = buildOptions(item);

        return <>
            <Select value={licenseTypeOptions.find(o => o.value === item.licenseType)}
                isDisabled={order.demo}
                options={licenseTypeOptions.filter(o => {
                    if (customer.type === "COMPANY" || customer.type === "COMPANY_B2B" || customer.type === "COMPANY_SALES") {
                        return ["UK1", "UK2", "UK3"].includes(o.value)
                    } else {
                        return ["PK4"].includes(o.value)
                    }
                })}
                onChange={o => {
                    if (o) {
                        const endDate = calculateEndDate(item.startMonth, item.startYear, 12);
                        const copyItems = [...items];
                        copyItems[index] = { ...item, licenseType: o.value, endYear: endDate.endYear, endMonth: endDate.endMonth };
                        setItems(copyItems);
                    }
                }} />
            <label htmlFor={"startYear" + index}>Startjahr</label>
            <label htmlFor={"startMonth" + index}>Startmonat</label>
            <input disabled={order.demo} required id={"startYear" + index} type="number" step="1" value={item.startYear} onChange={(e) => {
                const copyItems = [...items];
                const newItem = { ...item, startYear: +e.target.value };
                const newEnd = buildOptions(newItem, false)[0].value;
                copyItems[index] = { ...newItem, endMonth: newEnd.month, endYear: newEnd.year };
                setItems(copyItems);
            }}></input>
            <input disabled={order.demo} required id={"startMonth" + index} type="number" min="1" max="12" step="1" value={item.startMonth} onChange={(e) => {
                const copyItems = [...items];
                const newItem = { ...item, startMonth: Math.max(1, Math.min(+e.target.value, 12)) };
                const newEnd = buildOptions(newItem, false)[0].value;
                copyItems[index] = { ...newItem, endMonth: newEnd.month, endYear: newEnd.year };
                setItems(copyItems);
            }}></input>
            <label>Endmonat (inklusive)</label>
            <Select required value={endDateOptions.find(o => o.value.month === item.endMonth && o.value.year === item.endYear)}
                isDisabled={order.demo}
                options={endDateOptions}
                onChange={o => {
                    if (o) {
                        const copyItems = [...items];
                        copyItems[index] = { ...item, endMonth: o.value.month, endYear: o.value.year };
                        setItems(copyItems);
                    }
                }} />
            <label htmlFor={"additionalAssistance" + index}>Zusätzliche Assistenz-Lizenzen</label>
            <label htmlFor={"additionalDeciders" + index}>Zusätzliche Entscheider-Lizenzen</label>
            <input disabled={order.demo} required min="0" id={"additionalAssistance" + index} type="number" step="1" value={item.additionalAssistance} onChange={(e) => {
                const copyItems = [...items];
                copyItems[index] = { ...item, additionalAssistance: +e.target.value };
                setItems(copyItems);
            }}></input>
            <input disabled={order.demo} required min="0" id={"additionalDeciders" + index} type="number" step="1" value={item.additionalDeciders} onChange={(e) => {
                const copyItems = [...items];
                copyItems[index] = { ...item, additionalDeciders: +e.target.value };
                setItems(copyItems);
            }}></input>
            <label htmlFor={"coordinatorEmail" + index}>E-Mail Koordinator</label>
            <input required id={"coordinatorEmail" + index} type="email" value={item.coordinator!.mail} onChange={(e) => {
                const copyItems = [...items];
                copyItems[index] = { ...item, coordinator: { ...item.coordinator!, mail: e.target.value } };
                setItems(copyItems);
            }}></input>
            <label htmlFor={"coordinatorFirstName" + index}>Vorname Koordinator</label>
            <input required id={"coordinatorFirstName" + index} type="text" value={item.coordinator!.firstName} onChange={(e) => {
                const copyItems = [...items];
                copyItems[index] = { ...item, coordinator: { ...item.coordinator!, firstName: e.target.value } };
                setItems(copyItems);
            }}></input>
            <label htmlFor={"coordinatorLastName" + index}>Nachname Koordinator</label>
            <input required id={"coordinatorLastName" + index} type="text" value={item.coordinator!.lastName} onChange={(e) => {
                const copyItems = [...items];
                copyItems[index] = { ...item, coordinator: { ...item.coordinator!, lastName: e.target.value } };
                setItems(copyItems);
            }}></input>
        </>
    };

    const renderExtendPackageItem = (item: OrderItem, index: number) => {
        const endDateOptions: Array<{ label: string, value: { month: number | null, year: number | null } }> = buildOptions(item, true);

        const pack = packages.find(p => p.id === item.packageId);

        return <>
            <Select required value={packageOptions.find(p => p.value.id === item.packageId)}
                placeholder="Paket wählen"
                options={packageOptions.filter(po => !items.map(i => i.packageId).includes(po.value.id))}
                onChange={o => {
                    if (o) {
                        const endDate = calculateEndDate(o.value.endMonth, o.value.endYear, 13);
                        const startDate = calculateEndDate(o.value.endMonth, o.value.endYear, 2);
                        const copyItems = [...items];
                        copyItems[index] = { ...item, packageId: o.value.id, startMonth: startDate.endMonth, startYear: startDate.endYear, endMonth: endDate.endMonth, endYear: endDate.endYear };
                        setItems(copyItems);
                    }
                }} />
            {pack &&
                <>
                    <label htmlFor={"startYear" + index}>Endjahr des bestehenden Pakets</label>
                    <label htmlFor={"startMonth" + index}>Endmonat des bestehenden Pakets</label>
                    <input disabled id={"startYear" + index} type="number" step="1" value={pack.endYear}></input>
                    <input disabled id={"startMonth" + index} type="number" step="1" value={pack.endMonth}></input>
                    <label>Endmonat der Verlängerung (inklusive)</label>
                    <Select required value={endDateOptions.find(o => o.value.month === item.endMonth && o.value.year === item.endYear)}
                        options={endDateOptions}
                        onChange={o => {
                            if (o) {
                                const copyItems = [...items];
                                copyItems[index] = { ...item, endMonth: o.value.month, endYear: o.value.year };
                                setItems(copyItems);
                            }
                        }} />
                    <label htmlFor={"additionalAssistance" + index}>Zusätzliche Assistenz-Lizenzen</label>
                    <label htmlFor={"additionalDeciders" + index}>Zusätzliche Entscheider-Lizenzen</label>
                    <input required min="0" id={"additionalAssistance" + index} type="number" step="1" value={item.additionalAssistance} onChange={(e) => {
                        const copyItems = [...items];
                        copyItems[index] = { ...item, additionalAssistance: +e.target.value };
                        setItems(copyItems);
                    }}></input>
                    <input required min="0" id={"additionalDeciders" + index} type="number" step="1" value={item.additionalDeciders} onChange={(e) => {
                        const copyItems = [...items];
                        copyItems[index] = { ...item, additionalDeciders: +e.target.value };
                        setItems(copyItems);
                    }}></input>
                </>}
        </>
    };

    const renderItem = (item: OrderItem, index: number, customer: Customer) => {
        return <div className="order-item">
            <Select value={orderItemOptions.find(o => o.value === item.type)}
                isDisabled={order.demo}
                options={orderItemOptions}
                onChange={o => {
                    if (o) {
                        const copyItems = [...items];
                        copyItems[index] = { ...item, type: o.value };
                        setItems(copyItems);
                    }
                }} />
            {item.type === "NEW_PACKAGE" && renderNewPackageItem(item, index, customer)}
            {item.type === "EXTEND_PACKAGE" && renderExtendPackageItem(item, index)}
            <div></div>
            <div className="right"><strong>Netto-Preis: </strong><strong>{formatNumber(calcItemPrice(item, customer) / 100)}</strong></div>
            <div></div>
            <div className="right">
                <button disabled={order.demo} onClick={() => {
                    const copyItems = [...items];
                    copyItems.splice(index, 1);
                    setItems(copyItems);
                }}><FontAwesomeIcon icon={faTrashAlt} /></button>
            </div>
        </div>;
    };

    const renderItems = () => {
        if (customer) {
            return items.map((item, index) => {
                return <li key={index}>{renderItem(item, index, customer)}</li>;
            });
        }
    };

    const calcMonthsForItem = (item: OrderItem): number => {
        if (item.endMonth === null || item.endYear === null) {
            return 0;
        } else {
            return calcMonths(item.startMonth, item.startYear, item.endMonth, item.endYear);
        }
    };

    const averagePriceForCustomer = (customer: Customer) => {
        const lts: Array<LicenseTypes> = isCompanyCustomer(customer) ? ["UK1", "UK2", "UK3"] : ["PK4"];

        //divide twice once for average and once for the number of packages in the demo
        return Math.floor(lts.map(lt => prices.find(p => p.type === lt)!.price).reduce((a, b) => a + b, 0) / lts.length / lts.length);
    }

    const calcItemPrice = (item: OrderItem, customer: Customer): number => {
        const months = calcMonthsForItem(item);
        const price = order.demo ? averagePriceForCustomer(customer) : prices.find(p => p.type === item.licenseType)!.price;

        if (item.type === "NEW_PACKAGE") {
            return months * (price + ASSISTANCE_PRICE * item.additionalAssistance + getDeciderPriceForLicenseAndCustomer(customer, item.licenseType!, prices) * item.additionalDeciders);
        } else {
            if (item.packageId !== -1) {
                const pack = packages.find(p => p.id === item.packageId)!;
                const deciderPrice = getDeciderPriceForLicenseAndCustomer(customer, pack.type, prices)
                const today = new Date();
                const thisMonth = new Date(today.getFullYear(), today.getMonth());
                const startMonth = new Date(pack.startYear, pack.startMonth - 1);
                const endMonth = new Date(pack.endYear, pack.endMonth - 1);
                let beforeExtension = 0;

                if (thisMonth < startMonth) {
                    const months = calcMonths(pack.startMonth, pack.startYear, pack.endMonth, pack.endYear);
                    beforeExtension = months * (ASSISTANCE_PRICE * item.additionalAssistance + deciderPrice * item.additionalDeciders);
                } else if (thisMonth >= startMonth && thisMonth <= endMonth) {
                    const months = calcMonths(thisMonth.getMonth() + 1, thisMonth.getFullYear(), pack.endMonth, pack.endYear);
                    beforeExtension = months * (ASSISTANCE_PRICE * item.additionalAssistance + deciderPrice * item.additionalDeciders);
                }

                const afterExtension = months * (price + ASSISTANCE_PRICE * (pack.additionalAssistance + item.additionalAssistance) + deciderPrice * (pack.additionalDeciders + item.additionalDeciders))
                return beforeExtension + afterExtension;
            } else {
                return 0;
            }
        }
    };

    const renderPrices = () => {
        if (prices && customer && months !== null && tax) {
            const addedPrice = items.map(i => calcItemPrice(i, customer)).reduce((a, b) => a + b, 0);
            const newItemAmount = items.filter(i => i.type === "NEW_PACKAGE").length;
            const bulkDiscount = newItemAmount >= 5 ? (newItemAmount >= 10 ? 10 : 5) : 0;
            const yearlyDiscount = order.paymentType === "YEARLY" ? 1 / 12 : 0;
            const addedPriceWithDiscount = (1 - yearlyDiscount) * (1 - (bulkDiscount / 100.0)) * (1 - (customer.discount / 100)) * addedPrice;

            const basePrice = prices.find(p => isCompanyCustomer(customer) ? p.type === "BASE_COMPANY" : p.type === "BASE_PRIVATE")!.price;
            const basePriceMonths = months * basePrice;
            const discountedBasePrice = months * basePrice / (1 - customer.basicDiscount / 100);

            const priceWithTax = (addedPriceWithDiscount + discountedBasePrice) * (1 + tax.percentage / 100);

            const discounts = [customer.discount > 0 ? `Kundenrabatt ${customer.discount}%` : null, bulkDiscount > 0 ? `Mengenrabatt ${bulkDiscount}%` : null, yearlyDiscount > 0 ? `Jahresrabatt ${(yearlyDiscount * 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%` : null].filter(d => d !== null);

            return <div className="prices-section">
                <div className="price-row"><strong>Summe aller Positionen (netto):</strong> <strong>{formatNumber(addedPrice / 100)}</strong></div>
                {discounts.length > 0 && <div className="price-row"><strong>Summe aller Positionen inkl. {discounts.join(", ")} (netto):</strong><strong>{formatNumber(addedPriceWithDiscount / 100)}</strong></div>}
                {discounts.length > 0 && <div className="price-row"><strong>Ersparnis (netto):</strong> <strong>{formatNumber((addedPrice - addedPriceWithDiscount) / 100)}</strong></div>}
                <hr />
                {
                    months > 0 &&
                    <>
                        <div className="price-row"><strong>Anzahl an Basis-Vertrags-Monaten:</strong> <strong>{months}</strong></div>
                        <div className="price-row"><strong>Basis-Vertrag Netto-Betrag:</strong> <strong>{formatNumber(basePriceMonths / 100)}</strong></div>
                        {customer.basicDiscount > 0 && <div className="price-row"><strong>Basis-Vertrag Netto-Betrag inkl. Rabatt:</strong> <strong>{formatNumber(discountedBasePrice / 100)}</strong></div>}
                        {customer.basicDiscount > 0 && <div className="price-row"><strong>Ersparnis Basis-Vertrag:</strong> <strong>{formatNumber((basePriceMonths - discountedBasePrice) / 100)}</strong></div>}
                    </>
                }
                <hr />
                <div className="price-row"><strong>Netto-Summe:</strong> <strong>{formatNumber((addedPriceWithDiscount + discountedBasePrice) / 100)}</strong></div>
                <div className="price-row"><strong>Brutto-Summe (inkl. {tax.percentage}% USt.):</strong> <strong>{formatNumber(priceWithTax / 100)}</strong></div>
            </div>
        }
    };

    const sendOrderRequest = async (e: FormEvent<HTMLFormElement>) => {
        const request: OrderRequest = {
            order: order,
            items: items
        };

        e.preventDefault();

        if (!validateItems()) {
            return;
        }

        try {
            setIsOrdering(true);
            const response = await CircalindApi.makeOrderRequest(request);

            if (response) {
                sendOlert("Bestellung erfolgreich", "Erfolgreich bestellt.", "Success");
                navigate("/circalind/services/choose-license")
            } else {
                sendOlert("Bestellung fehlgeschlagen", "Bestellung konnte nicht ausgelöst werden", "Error");
            }
        } finally {
            setIsOrdering(false);
        }
    };

    const validateItems = () => {
        for (let item of items) {
            if (item.type === "NEW_PACKAGE") {
                const start = new Date(item.startYear, item.startMonth - 1);
                const today = new Date();
                if (start < new Date(today.getFullYear(), today.getMonth())) {
                    sendOlert("Ungültiger Start", "Start darf nicht in der Vergangenheit liegen.", "Error");
                    return false;
                }

                const maxDate = new Date(today.getFullYear(), today.getMonth());
                maxDate.setMonth(maxDate.getMonth() + 12);

                if (start >= maxDate) {
                    sendOlert("Ungültiger Start", "Start darf nicht mehr als 12 Monate in der Zukunft liegen.", "Error");
                    return false;
                }
            } else {
                if (item.packageId === -1) {
                    sendOlert("Kein Paket gewählt.", "Es muss ein Paket gewählt werden.", "Error");
                    return false;
                }
            }

            if (item.additionalAssistance < 0 || item.additionalDeciders < 0) {
                sendOlert("Ungültiger Wert", "Nur positive Werte sind gestattet.", "Error");
                return false;
            }
        }

        return true;
    };

    const downloadOffer = async () => {
        if (!validateItems()) {
            return;
        }

        const pdf = await CircalindApi.getOffer({
            order, items
        });

        if (pdf) {
            const downloadLink = document.createElement("a");
            downloadLink.href = "data:application/pdf;base64," + pdf;
            downloadLink.download = "angebot.pdf";
            downloadLink.click();
        }
    };

    const paymentOptions: Array<{ label: string, value: PaymentType }> = [
        {
            label: "Monatlich",
            value: "MONTHLY",
        },
        {
            label: "Jährlich (nur 11 Monate zahlen = 8.33% Nachlass)",
            value: "YEARLY",
        }
    ];

    return <div className="order-view">
        <form onSubmit={sendOrderRequest}>
            <h2>Bestellung aufgeben</h2>
            <div style={{ textAlign: "center" }}>Eine Übersicht über den Umfang der verschiedenen Module finden Sie <a target="_blank" rel="noreferrer" href={`${CircalindApi.serverURL}settings/moduleInfo/get`}>hier</a>.</div>
            <label>Bestellart</label>
            <Select value={orderOptions.find(o => o.value === order.demo)} options={orderOptions} onChange={o => setOrder({ ...order, demo: o ? o.value : false })} />
            <label>Zahlungstervall</label>
            <Select value={paymentOptions.find(o => o.value === order.paymentType)} options={paymentOptions} onChange={o => setOrder({ ...order, paymentType: o!.value! })} />
            <h3>Positionen</h3>
            <ol>
                {renderItems()}
            </ol>
            <hr />
            <div className="right">
                <button disabled={order.demo} onClick={() => {
                    if (customer) {
                        const startMonth = new Date().getMonth() + 1;
                        const startYear = new Date().getFullYear();
                        const endDate = calculateEndDate(startMonth, startYear, 12);

                        setItems([...items, {
                            id: -1,
                            orderId: -1,
                            packageId: -1,
                            type: "NEW_PACKAGE",
                            startMonth: startMonth,
                            startYear: startYear,
                            endMonth: endDate.endMonth,
                            endYear: endDate.endYear,
                            additionalAssistance: 0,
                            additionalDeciders: 0,
                            coordinator: {
                                id: -1,
                                firstName: "",
                                lastName: "",
                                mail: "",
                                secret: "",
                                roles: [],
                            },
                            licenseType: isCompanyCustomer(customer) ? "UK1" : "PK4",
                        }])
                    }
                }}>Position hinzufügen</button>
            </div>
            {renderPrices()}
            <hr />
            <div className="agb">
                <input required type="checkbox" id="agb" checked={acceptedAGB} onChange={(e) => setAcceptedAGB(e.target.checked)}></input>
                <label htmlFor="agb">Ich habe die <a target="_blank" rel="noreferrer" href={`${CircalindApi.serverURL}settings/agb/get`}>AGB</a> und die <a target="_blank" rel="noreferrer" href={`${CircalindApi.serverURL}settings/privacy/get`}>Datenschutzbestimmungen</a> gelesen und akzeptiere diese.</label>
            </div>
            <div className="buttons right">
                <button onClick={() => navigate("/circalind/services/choose-license")}>Zurück zur Auswahl</button>
                <button onClick={downloadOffer} type="button">Angebot herunterladen</button>
                <button disabled={!acceptedAGB || isOrdering || items.length === 0} type="submit">{isOrdering ? "Bestellung wird aufgegeben ..." : "Bestellung aufgeben"}</button>
            </div>
        </form>
    </div>;
};
