import toolboxImage from "../../resources/circa/toolbox.svg";
import puzzleImage from "../../resources/circa/info/puzzle.png";
import circalindPersonalImage from "../../resources/circa/personal_project.svg";
import circalindLeadershipImage from "../../resources/circa/leadership_teamwork.svg";
import strategyImage_back from "../../resources/circa/strategy_project.svg"

import { useNavigate } from "react-router";

import "./Managing.scss";
import { serviceHeaders, useHeaderContext } from "../page/header/Header";

export const Managing = () => {
    const navigate = useNavigate();

    const headers = useHeaderContext();
    headers.updateHeader(serviceHeaders);

    return <div className="management-overview">
        <div className="card content-card">
            <h3>Projekte initiieren, löschen und kombinieren</h3>
            <div onClick={() => { navigate("../projects") }} className="image-wrapper card clickable">
                <img src={circalindPersonalImage} alt="" />
                <img src={strategyImage_back} alt="" />
            </div>
            <div></div>
        </div>
        <div className="card content-card">
            <h3>Projekt-Assistenz ergänzen, anfragen & aktivieren</h3>
            <div onClick={() => { navigate("../communicate") }} className="image-wrapper card clickable">
                <img src={circalindLeadershipImage} alt="" />
            </div>
            <div></div>
        </div>
        <div className="card content-card">
            <h3>Toolbox</h3>
            <div onClick={() => { navigate("/circalind/managing/toolbox") }} className="image-wrapper card clickable">
                <img src={toolboxImage} alt="" />
            </div>
            <div></div>
        </div>
        <div className="card content-card">
            <h3>Portfolio - / Laufzeit-Status im Blick haben </h3>
            <div onClick={() => { navigate("/circalind/services/start") }} className="image-wrapper card clickable">
                <img src={puzzleImage} alt="" />
            </div>
            <div></div>
        </div>
    </div>;
};
