import { ReactElement } from "react";
import { SubAreaContent } from "../contentManager/SubAreaContent";

import './information.scss'

export const InfoServices = (): ReactElement => {
    return <div className="info-service">
        <h2>Services</h2>
        <SubAreaContent isProjectSpecific={true} area="service" />
    </div>
}